export const editLabelTableParametersData = {
  statePath: "tables.editLabelTableParams",
  filtersPath: "tables.editLabelTableParams.filters",
  sortPath: "tables.editLabelTableParams.sort",

  columns: [
    {
      name: "labelName",
      label: "Label name",
      sortBackendName: "name",
      filterDefinition: {
        backendFieldValue: {
          name: "name",
        },
      },
    },
    {
      name: "status",
      label: "Status",
      sortBackendName: "status",
      filterDefinition: {
        backendFieldValue: {
          status: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "beatportRank",
      label: "Beatport rank",
      sortBackendName: "rank",
      filterDefinition: {
        backendFieldValue: {
          "rank-min": "min",
          "rank-max": "max",
        },
      },
    },
    {
      name: "dateAdded",
      label: "Date Added",
      sortBackendName: "created",
      filterDefinition: {
        backendFieldValue: {
          "created-from": "startDateTimeTZ",
          "created-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "lastChange",
      label: "Last Change",
      sortBackendName: "updated",
      filterDefinition: {
        backendFieldValue: {
          "updated-from": "startDateTimeTZ",
          "updated-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "claimed",
      label: "Claimed",
      sortBackendName: "claimed",
      filterDefinition: {
        backendFieldValue: {
          claimed: "id",
        },
      },
    },
    { name: "edit", label: "Edit" },
  ],
};
