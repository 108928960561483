import React from "react";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import LocationMap from "../../../input/location/locationMap";
import { mainStateKey } from "../../../../constants/constants";

const LocationBox = ({ venueData }) => {
  let lat = getOr(undefined, "coordinates.lat", venueData);
  let lng = getOr(undefined, "coordinates.lng", venueData);
  lat = lat ? lat : getOr(undefined, "city.coordinates.lat", venueData);
  lng = lng ? lng : getOr(undefined, "city.coordinates.lng", venueData);

  lat = lat ? parseFloat(lat) : lat;
  lng = lng ? parseFloat(lng) : lng;

  return (
    <FormBox title="location">
      <div className="box-body">
        <LocationMap showSearch={false} showMarker={true} lat={lat} lng={lng} />
      </div>
    </FormBox>
  );
};

LocationBox.propTypes = {
  venueData: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    venueData: getOr(null, `${mainStateKey}.formData.venue`, state),
  };
};

export default connect(mapStateToProps)(LocationBox);
