import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import ImageModal from "./modalContent/imageModal";
import LiveMomentsBanModal from "./modalContent/liveMomentsBanModal";
import { modalPath, mainStateKey } from "../../../constants/constants";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import { useOutsideComponentClick } from "../../../lib/functions/customHooks";
import MusicPlayerModal from "./modalContent/musicPlayerModal";
import YoutubePreview from "./modalContent/youtubePreview";
import SpotifyPreview from "./modalContent/spotifyPreview";
import SoundcloudPreview from "./modalContent/soundcloudPreview";
import BeatportPreview from "./modalContent/beatportPreview";

const MainModal = ({ modalData, dispatch }) => {
  let modalComponent;

  const ref = useRef(null);
  useOutsideComponentClick(ref, () => {
    closeModal(dispatch);
  });

  switch (modalData.type) {
    case "liveMomentsBanModal":
      modalComponent = <LiveMomentsBanModal />;
      break;
    case "musicPlayer":
      modalComponent = <MusicPlayerModal />;
      break;
    case "youtubePreview":
      modalComponent = <YoutubePreview />;
      break;
    case "spotifyPreview":
      modalComponent = <SpotifyPreview />;
      break;
    case "beatportPreview":
      modalComponent = <BeatportPreview />;
      break;
    case "soundcloudPreview":
      modalComponent = <SoundcloudPreview />;
      break;
    case "image":
    default:
      modalComponent = <ImageModal />;
  }

  return (
    <div className="popup fixed show" ref={ref}>
      {!modalData.hideCloseButton && (
        <button
          className="btn btn-small btn-close-popup"
          onClick={() => {
            closeModal(dispatch);
          }}
        >
          X
        </button>
      )}
      {modalComponent}
    </div>
  );
};

const closeModal = dispatch => {
  dispatchToReduxStore(modalPath, SET_STATE_VALUE, { show: false }, dispatch);
};

MainModal.propTypes = {
  dispatch: PropTypes.func,
  type: PropTypes.string,
};

const mapStateToProps = state => {
  return { modalData: getOr("", `${mainStateKey}.${modalPath}`, state) };
};

export default connect(mapStateToProps)(MainModal);
