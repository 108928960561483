import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import moment from "moment";

const MatchedEventCard = ({ event, onMatchButtonClick = () => {}, onDeleteButtonClick = () => {} }) => {
  const uuid = getOr("", "uuid", event);
  const name = getOr("", "name", event);
  const start = getOr("", "start", event);
  const lineup = getOr([], "lineup", event);
  const date = start ? moment(start).format("YYYY-MM-DD | HH:mm") : "";

  return (
    <div className="list-item">
      <div className="matched-event">
        <button
          type="button"
          className="btn btn-default btn-delete-event"
          onClick={() => {
            onDeleteButtonClick(uuid);
          }}
        >
          Delete Event
        </button>
        <article>
          <span className="title">{name}</span>
          <time>{date}</time>
          <span className="lineup-list">
            {lineup.length > 0 &&
              lineup.map(artist => {
                return <span key={artist.uuid}>{artist.name}</span>;
              })}
          </span>
        </article>
        <button
          type="button"
          className="btn btn-default btn-match-event"
          onClick={() => {
            onMatchButtonClick(uuid);
          }}
        >
          Match Lineup With This Event
        </button>
      </div>
    </div>
  );
};

MatchedEventCard.propTypes = {
  event: PropTypes.object,
  onMatchButtonClick: PropTypes.func,
  onDeleteButtonClick: PropTypes.func,
};

export default MatchedEventCard;
