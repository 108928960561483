import { getOr } from "lodash/fp";
import { com, event, apis, editor } from "../../constants/apiCalls";
import { callApi } from "./base";

export const addEntity = (type, params, id, edition) => {
  let url = "";
  switch (type) {
    case "artist":
      url = editor.ADD_ARTIST;
      break;
    case "venue":
      url = editor.ADD_VENUE;
      break;
    case "event":
      url = editor.ADD_EVENT;
      break;
    case "festival":
      url = editor.ADD_FESTIVAL;
      break;
    case "label":
      url = editor.ADD_LABEL;
      break;
    case "track":
      url = editor.ADD_TRACK;
      break;
    case "festivalEdition":
      url = editor.ADD_FESTIVAL_EDITION + id + "/new-edition/";
      break;
    case "article":
      url = editor.ADD_ARTICLE;
      break;
    case "helpCenterTopic":
      url = editor.ADD_HELP_CENTER_TOPIC;
      break;
    case "helpCenterCategory":
      url = editor.ADD_HELP_CENTER_CATEGORY;
      break;
    case "webinar":
      url = editor.ADD_WEBINAR;
      break;
    case "feedpost":
      url = editor.ADD_FEEDPOST;
      break;
    case "festivalAppCampaign":
      url = apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_ADD_CAMPAIGN;
      break;
    default:
      throw new Error("Add type not defined");
  }
  return callApi(url, "POST", params);
};

export const editEntity = (uuid, edition, type, params, method = "POST", headers, additionalID) => {
  let url = "";
  switch (type) {
    case "artist":
      url = editor.EDIT_ARTIST + uuid + "/";
      break;
    case "artistSubgenre":
      url = editor.EDIT_ARTIST_SUBGENRE + uuid + "/subgenre/";
      break;
    case "artistSecondarySubgenre":
      url = editor.EDIT_ARTIST_SUBGENRE + uuid + "/subgenre/secondary/";
      break;
    case "venue":
    case "place":
      url = editor.EDIT_VENUE + uuid + "/";
      break;
    case "event":
      url = editor.EDIT_EVENT + uuid + "/";
      break;
    case "matchedEvent":
      url = event.GET_MATCHED_EVENT + uuid + "/";
      break;
    case "festival":
      url = editor.EDIT_FESTIVAL + uuid + "/" + (edition ? edition + "/" : "");
      break;
    case "label":
      url = editor.EDIT_LABEL + uuid + "/";
      break;
    case "track":
      url = editor.EDIT_TRACK + uuid + "/";
      break;
    case "track_match_link":
      url = editor.EDIT_TRACK_MATCH_LINK + uuid + "/";
      break;
    case "article":
      url = editor.EDIT_ARTICLE + uuid + "/";
      break;
    case "contributionReview":
      url = editor.EDIT_CONTRIBUTION_REVIEW.replace("{uuid}", uuid);
      break;
    case "helpCenterTopic":
      url = editor.EDIT_HELP_CENTER_TOPIC + uuid + "/";
      break;
    case "helpCenterCategory":
      url = editor.EDIT_HELP_CENTER_CATEGORY + uuid + "/";
      break;
    case "webinar":
      url = editor.EDIT_WEBINAR + uuid + "/";
      break;
    case "feedpost":
      url = editor.EDIT_FEEDPOST + uuid + "/";
      break;
    case "youtube_suggestion":
      url = editor.EDIT_ARTIST + uuid + "/youtube-suggestion/";
      break;
    case "festivalFinishedPool":
      url = editor.MOVE_TO_FINISHED_FESTIVAL_POOL.replace("{uuid}", uuid).replace("{edition}", edition);
      break;
    case "festivalNextPool":
      url = editor.MOVE_TO_NEXT_FESTIVAL_POOL.replace("{uuid}", uuid).replace("{edition}", edition);
      break;
    case "festivalAppInfo":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_INFO;
      break;
    case "festivalAppExperiences":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_EXPERIENCES;
      break;
    case "festivalAppStages":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_STAGES;
      break;
    case "festivalAppLineup":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_LINEUP;
      break;
    case "festivalAppMedia":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_MEDIA;
      break;
    case "festivalAppAddInfo":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_ADD_INFO;
      break;
    case "festivalAppSponsors":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_SPONSORS;
      break;
    case "festivalAppMerch":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_MERCH;
      break;
    case "festivalAppMap":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_MAP;
      break;
    case "festivalAppCampaign":
      url = apis.EDITOR_API + "mainstage/" + uuid + "/" + edition + editor.APP_EDIT_CAMPAIGN + additionalID + "/";
      break;

    case "festivalAppLiveMomentsPost":
      url =
        apis.EDITOR_API +
        "mainstage/" +
        uuid +
        "/" +
        edition +
        editor.BAN_LIVE_MOMENTS_POST.replace("{postUUID}", additionalID);
      break;
    case "festivalAppLiveMomentsReport":
      url =
        apis.EDITOR_API +
        "mainstage/" +
        uuid +
        "/" +
        edition +
        editor.BAN_LIVE_MOMENTS_REPORT.replace("{postUUID}", additionalID);
      break;

    default:
      throw new Error("Edit type not defined");
  }
  return callApi(url, method, params, {}, headers);
};

export const getEntity = (entityType, id, headers, edition, additionalID) => {
  if (id == null) {
    return;
  }

  switch (entityType) {
    case "artist":
      return get(editor.GET_ARTIST + id + "/");
    case "label":
      return get(editor.GET_LABEL + id + "/");
    case "track":
      return get(editor.GET_TRACK + id + "/");
    case "venue":
    case "place":
      return get(editor.GET_VENUE + id + "/");
    case "event":
      return get(editor.GET_EVENT + id + "/");
    case "matchedEvent":
      return get(event.GET_MATCHED_EVENT + id + "/", true, headers);
    case "artistHistory":
      return get(editor.GET_ARTIST_HISTORY.replace("{uuid}", id));
    case "youtubeHistory":
      return get(editor.GET_ARTIST_HISTORY.replace("{uuid}", id));
    case "labelHistory":
      return get(editor.GET_LABEL_HISTORY.replace("{uuid}", id));
    case "trackHistory":
      return get(editor.GET_TRACK_HISTORY.replace("{uuid}", id));
    case "venueHistory":
      return get(editor.GET_VENUE_HISTORY.replace("{uuid}", id));
    case "eventHistory":
      return get(editor.GET_EVENT_HISTORY.replace("{uuid}", id));
    case "festivalHistory":
      return get(editor.GET_FESTIVAL_HISTORY.replace("{uuid}", id).replace("{edition}", edition));
    case "helpCenterTopic":
      return Promise.all([get(editor.GET_HELP_CENTER_TOPIC + id + "/"), get(editor.GET_HELP_CENTER_CATEGORIES)]).then(
        values => {
          return { helpCenterData: values[0], categories: values[1] };
        }
      );
    case "helpCenterCategory":
      return get(editor.GET_HELP_CENTER_CATEGORY + id + "/");
    case "webinar":
      return get(editor.GET_WEBINAR + id + "/");
    case "festival":
      return get(editor.GET_FESTIVAL + id + "/" + edition + "/");
    case "contributionReview":
      return get(editor.GET_CONTRIBTION_REVIEW + id + "/");
    case "festivalAppInfo":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_INFO);
    case "festivalAppExperiences":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_EXPERIENCES);
    case "festivalAppStages":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_STAGES);
    case "festivalAppLineup":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_LINEUP);
    case "festivalAppMedia":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_MEDIA);
    case "festivalAppAddInfo":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_ADD_INFO);
    case "festivalAppSponsors":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_SPONSORS);
    case "festivalAppMerch":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_MERCH);
    case "festivalAppMap":
      return Promise.all([
        get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_MAP),
        get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_STAGES),
        get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_EXPERIENCES),
        get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_INFO),
      ]).then(values => {
        return { map: values[0], stages: values[1], experiences: values[2], info: values[3] };
      });
    case "article":
      return get(editor.GET_ARTICLE + id + "/");
    case "feedpost":
      return get(editor.GET_FEEDPOST + id + "/");
    case "festivalAppCampaign":
      return additionalID
        ? Promise.all([
            get(
              apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_GET_CAMPAIGN_DETAIL + additionalID + "/"
            ),
            get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_GET_CAMPAIGN_SUGGESTIONS),
          ]).then(values => {
            return { campaignDetail: values[0], suggestions: values[1] };
          })
        : Promise.all([
            get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_GET_CAMPAIGN_SUGGESTIONS),
          ]).then(values => {
            return { suggestions: values[0] };
          });

    default:
      console.warn("get entity  type not defined.");
  }
};

export const getEntityList = (type, headers, urlParamaters = "", id, edition) => {
  switch (type) {
    case "artist":
      return get(editor.GET_ARTIST_LIST + "?" + urlParamaters, true);
    case "label":
      return get(editor.GET_LABEL_LIST + "?" + urlParamaters, true);
    case "track":
      return get(editor.GET_TRACK_LIST + "?" + urlParamaters, true);
    case "venue":
      return get(editor.GET_VENUE_LIST + "?" + urlParamaters, true);
    case "event":
      return get(editor.GET_EVENT_LIST + "?" + urlParamaters, true);
    case "festival":
      return get(editor.GET_FESTIVAL_LIST + "?" + urlParamaters, true);
    case "article":
      return get(editor.GET_ARTICLE);
    case "venueUpcomingEvents":
      return get(com.GET_VENUE_UPCOMING_EVENTS + "?" + urlParamaters, true);
    case "venuePastEvents":
      return get(com.GET_VENUE_PAST_EVENTS + "?" + urlParamaters, true);
    case "matchedEvent":
      return get(event.GET_MATCHED_EVENT + "?limit=10000", true, headers);
    case "contributionHistory":
      return get(editor.GET_CONTRIBUTION_HISTORY_LIST + "?" + urlParamaters, true);
    case "helpCenterTopic":
      return Promise.all([get(editor.GET_HELP_CENTER_TOPIC_LIST), get(editor.GET_HELP_CENTER_CATEGORIES)]).then(
        values => {
          return { helpCenterTopics: values[0], categories: values[1] };
        }
      );
    case "helpCenterCategories":
      return get(editor.GET_HELP_CENTER_CATEGORIES);
    case "webinar":
      return get(editor.GET_WEBINAR_LIST, true);
    case "festivalAppCampaign":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_GET_CAMPAIGN_LIST);
    case "festivalAppLiveMomentsPosts":
      return get(
        apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_LIVE_MOMENTS_POSTS + "?" + urlParamaters,
        true
      );
    case "festivalAppLiveMomentsReports":
      return get(apis.EDITOR_API + "mainstage/" + id + "/" + edition + editor.APP_LIVE_MOMENTS_REPORTS, false);
    case "feedpost":
      return get(editor.GET_FEEDPOST_LIST + "?" + urlParamaters, true);
    default:
      console.warn("get entity list  type not defined.");
  }
};

export const getSearch = (term, types) => {
  if (types === "city") {
    return callApi(com.SEARCH + "city/", "GET", { term, els: false })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        console.warn(e);
      });
  } else if (types === "user") {
    return callApi(editor.SEARCH, "GET", { term, type: types })
      .then(response => {
        return getOr([], "data.data.users", response);
      })
      .catch(e => {
        console.warn(e);
      });
  } else if (types === "youtube_video") {
    return callApi(editor.SEARCH, "GET", { term, type: types })
      .then(response => {
        return getOr([], "data.data.youtube_videos", response);
      })
      .catch(e => {
        console.warn(e);
      });
  } else {
    return callApi(com.SEARCH, "GET", { term, type: types, els: false })
      .then(r => {
        return r.data;
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  }
};

export const getItems = type => {
  switch (type) {
    case "helpCenterCategories":
      return get(editor.GET_HELP_CENTER_CATEGORIES);
    case "articleCategories":
      return get(editor.GET_ARTICLE_CATEGORIES);
    default:
      console.warn("Unknown getItems type", type);
  }
};

const get = (url, returnFirstLevelData = false, headers = {}) => {
  return callApi(
    url,
    "GET",
    {},
    {
      // don't throw error if status of the response is in range [200, 500)
      validateStatus: function(status) {
        return status >= 200 && status < 500; // default
      },
    },
    headers
  )
    .then(r => {
      if (r.status === 404) {
        return r.data;
      } else if (r.status === 423) {
        return { locked: true };
      } else {
        return returnFirstLevelData ? r.data : r.data.data;
      }
    })
    .catch(e => {
      return null;
    });
};
