import React, { useState } from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import Footer from "../../../presentational/page/footer";
import { handleAddFormSubmit } from "../../../../lib/functions/componentFunctions";
import LastAddedLineupsBox from "../shared/lastAddedLineupsBox";
import eventDataManipulation from "../../../../lib/dataManipulation/event/eventDataManipulation";
import LineupBox from "../shared/lineupBox";
import { objectValueExistsInArray } from "../../../../lib/functions/common";
import { mainStateKey } from "../../../../constants/constants";
import BasicInfoBox from "../shared/basicInfoBox";
import TicketInfoBox from "../shared/ticketInfoBox";
import LastAddedVenuesBox from "../shared/lastAddedVenuesBox";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const AddEvent = ({ venue, lineup }) => {
  useResetPageState();

  //Used for updating last added lineup and places box since their content depends on cookie values
  const [forceUpdate, setForceUpdate] = useState(1);
  const numberOfLastAddedVenues = 5;
  const numberOfLastAddedLineups = 5;

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add Event</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-7">
                <BasicInfoBox />
              </div>
              <div className="col-md-5">
                <TicketInfoBox />
              </div>
            </div>
            <LineupBox />
          </div>
          <div className="col-md-3">
            <LastAddedLineupsBox forceUpdate={forceUpdate} numberOfLineups={numberOfLastAddedLineups} />
            <LastAddedVenuesBox forceUpdate={forceUpdate} numberOfVenues={numberOfLastAddedVenues} />
          </div>
        </div>
        <Footer
          onSave={() => {
            onSubmit(false, numberOfLastAddedVenues, numberOfLastAddedLineups, venue, lineup, setForceUpdate);
          }}
          onSaveAndClear={() => {
            onSubmit(true, numberOfLastAddedVenues, numberOfLastAddedLineups, venue, lineup, setForceUpdate);
          }}
        />
      </section>
    </React.Fragment>
  );
};

const onSubmit = async (
  clearForm,
  numberOfLastAddedVenues,
  numberOfLastAddedLineups,
  venue,
  lineup,
  setForceUpdate
) => {
  const submitSuccessfull = await handleAddFormSubmit(clearForm, eventDataManipulation, "event");

  if (submitSuccessfull) {
    saveVenueToLocalStorage(venue, numberOfLastAddedVenues);
    saveLineupToLocalStorage(lineup, numberOfLastAddedLineups);
    setForceUpdate(forceUpdate => forceUpdate + 1);
  }
};

const saveVenueToLocalStorage = (venueObject, numberOfLastAddedVenues) => {
  if (venueObject && venueObject.id) {
    let storedVenues = JSON.parse(localStorage.getItem("lastAddedVenues")) || [];

    if (!objectValueExistsInArray("id", venueObject.id, storedVenues)) {
      storedVenues = [...storedVenues, venueObject];

      if (storedVenues.length > numberOfLastAddedVenues) {
        storedVenues.shift();
      }

      localStorage.setItem("lastAddedVenues", JSON.stringify(storedVenues));
    }
  }
};

const saveLineupToLocalStorage = (lineupArray, numberOfLastAddedLineups) => {
  if (lineupArray && lineupArray.length > 0) {
    let storedLineups = JSON.parse(localStorage.getItem("lastAddedLineups")) || [];

    if (!lineupIsAlreadyStored(storedLineups, lineupArray)) {
      storedLineups = [...storedLineups, lineupArray];

      if (storedLineups.length > numberOfLastAddedLineups) {
        storedLineups.shift();
      }

      localStorage.setItem("lastAddedLineups", JSON.stringify(storedLineups));
    }
  }
};

const lineupIsAlreadyStored = (storedLineups, lineup) => {
  let alreadyStored = false;

  if (storedLineups && storedLineups.length > 0) {
    let storedArtistIds = [];
    let lineupArtistIds = [];

    storedLineups.forEach(storedLineup => {
      storedArtistIds = storedLineup.map(artist => artist.id);
      lineupArtistIds = lineup.map(artist => artist.id);

      if (
        storedArtistIds.length === lineupArtistIds.length &&
        storedArtistIds.sort().join(",") === lineupArtistIds.sort().join(",")
      ) {
        alreadyStored = true;
      }
    });
  }

  return alreadyStored;
};

AddEvent.propTypes = {
  venue: PropTypes.object,
  lineup: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    venue: getOr(null, `${mainStateKey}.formData.venue`, state),
    lineup: getOr(null, `${mainStateKey}.formData.lineup`, state),
  };
};

export default connect(mapStateToProps)(AddEvent);
