import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import Select from "../../../input/selectors/select";
import { enumsPath, mainStateKey } from "../../../../constants/constants";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";
import Subgenres from "../../../input/general/subgenres";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { mapArray } from "../../../../lib/functions/common";

const GenreBox = ({ genres, subgenres, selectedGenre, dispatch, oldGenre }) => {
  const oldGenreName = getOr(null, `name`, oldGenre);
  const genreMap = mapArray(genres);

  return (
    <FormBox title="Track Genre">
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <div className="form-group">
              <label className="col-sm-2 control-label">Main Genre</label>
              <div className="col-sm-4">
                <Select
                  name="formData.genre"
                  placeholder="Select genre"
                  data={genres}
                  displayKey="name"
                  idKey="id"
                  autocomplete={true}
                  clearInputButtonMessage={
                    "Are you sure you want to clear this genre? This will also clear all currently selected genres"
                  }
                  onChange={item => {
                    if (!item || !item.id || getOr(null, "id", item) !== getOr(null, "id", selectedGenre)) {
                      dispatchToReduxStore("formData.subgenres", SET_STATE_VALUE, null, dispatch);
                    }
                  }}
                />
                {oldGenreName && <small className="old-link">{oldGenreName}</small>}
              </div>
            </div>
          </div>
          <Subgenres
            onPrimaryGenreChange={val => {
              if (val && val.genre_id && selectedGenre.id !== val.genre_id && genreMap[val.genre_id]) {
                dispatchToReduxStore("formData.genre", SET_STATE_VALUE, genreMap[val.genre_id], dispatch);
              }
            }}
            name="formData.subgenres"
            selectWrapperClassName="col-sm-4"
            labelClassName="col-sm-2 control-label"
            customSubgenres={subgenres}
            disabled={!selectedGenre}
          />
        </div>
      </div>
    </FormBox>
  );
};

GenreBox.propTypes = {
  genres: PropTypes.array,
  subgenres: PropTypes.array,
  dispatch: PropTypes.func,
  oldGenre: PropTypes.object,
};

const mapStateToProps = state => ({
  genres: getOr(null, `${enumsPath}.genres`, state),
  subgenres: getOr(null, `${enumsPath}.subgenres`, state),
  selectedGenre: getOr(null, `${mainStateKey}.formData.genre`, state),
  oldGenre: getOr(null, `${mainStateKey}.formData.oldGenre`, state),
});

export default connect(mapStateToProps)(GenreBox);
