import commonDataManipulation from "../common/commonDataManipulation";
import moment from "moment";
import { getOr } from "lodash/fp";
import { parseImage, capitalizeString, getMediaLength } from "../../functions/common";

const venueDataManipulation = function venueDataManipulation() {
  const getOutwardVenue = data => {
    let image = data && data.profileImage ? parseImage(data.profileImage) : null;

    const openingHours =
      data.openingHours && data.openingHours.hours
        ? commonDataManipulation().openingHours.outward(data.openingHours.hours, data.openingHours.openOnEvent)
        : null;

    let location = commonDataManipulation().location.outward(data);

    if (!location.city_id) {
      location.city_id = 0;
    }

    return {
      name: data.name && data.name.name ? data.name.name : null,
      email: data.email ? data.email : null,
      phone: data.phone ? data.phone : null,
      ...location,
      //...commonDataManipulation().location.outward(data),
      price_range_id: data.priceRange && data.priceRange.id ? data.priceRange.id : null,
      capacity_type_id: data.type && data.type.id ? data.type.id : null,
      media: commonDataManipulation().media.outward(data.mediaImages, null, data.mediaVideos),
      links: commonDataManipulation().links.outward(data.links),
      capacity_range_id: data.size && data.size.id ? data.size.id : null,
      highlight_ids: data.highlights ? data.highlights : null,
      description: data.description ? data.description : null,
      opening_hours: openingHours,
      image: image,
      status: data.status && data.status.id ? data.status.id : null,
    };
  };

  const getInwardEditVenueItem = data => {
    return {
      oldLinks: data.links ? commonDataManipulation().links.inward(data.links) : [],
      claimed: data && data.claimed ? data.claimed : false,
      video: { type: { id: 5, name: "Video" } },
      profileImage: data.image ? data.image : null,
      name: data && data.name ? { name: data.name } : {},
      status: data && data.status ? { name: capitalizeString(data.status) } : {},
      location: commonDataManipulation().location.inward(data),
      openingHours:
        data && data.opening_hours ? commonDataManipulation().openingHours.inward(data.opening_hours) : null,
      type:
        data && data.capacity_type && data.capacity_type.id && data.capacity_type.name
          ? { id: data.capacity_type.id, name: data.capacity_type.name }
          : null,
      size:
        data && data.capacity_range && data.capacity_range.id && data.capacity_range.name
          ? { id: data.capacity_range.id, name: data.capacity_range.name }
          : null,
      priceRange:
        data && data.price_range && data.price_range.id && data.price_range.name
          ? { id: data.price_range.id, name: data.price_range.name }
          : null,
      email: data && data.email ? data.email : null,
      phone: data && data.phone ? data.phone : null,
      links: data.links ? commonDataManipulation().links.inward(data.links) : [],
      mediaImages:
        data && data.media && data.media[1] ? commonDataManipulation().mediaImages.inward(data.media[1]) : null,
      mediaVideos: data && data.media ? commonDataManipulation().mediaVideos.inward(data.media) : null,
      uuid: data.uuid || null,
      highlights: data && data.highlights ? data.highlights.map(item => item.id) : null,
      initialHighlights: data && data.highlights ? data.highlights.map(item => item.id) : null,
      description: data && data.description ? data.description : null,
    };
  };

  const getInwardDatatable = data => {
    const listItems = [];
    let openingHours;

    if (data && data.length) {
      data.forEach(item => {
        openingHours = getOr(null, "opening_hours", item) || null;

        listItems.push([
          item.name,
          getOr(null, "country.name", item),
          getOr(null, "city.name", item),
          item.address,
          getOr(null, "capacity_type.name", item),
          item.highlights.length,
          item.event_count,
          getMediaLength(item.media),
          (openingHours && openingHours.days && openingHours.days.length) || openingHours.events_only ? "Yes" : "No",
          item.phone || item.email ? "Yes" : "No",
          item.claimed ? "Yes" : "No",
          item.updated_at ? moment(item.updated_at).format("YYYY-MM-DD") : "",
          capitalizeString(item.status),
          item.uuid,
        ]);
      });
    }

    return listItems;
  };

  return { outward: getOutwardVenue, inwardItem: getInwardEditVenueItem, inwardDatatable: getInwardDatatable };
};

export default venueDataManipulation();
