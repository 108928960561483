import React from "react";
import lodash from "lodash";
import { getOr } from "lodash/fp";
import sha256 from "js-sha256";
import apiManager from "../apiManager/apiManager";
import { markerIcons, viberatecomSiteUrl } from "../../constants/constants";

export const prependNumberToSubgenreName = subgenres => {
  let modifiedSubgenres = [];
  let counter = 0;

  if (subgenres && Array.isArray(subgenres) && subgenres.length) {
    subgenres.forEach(item => {
      counter++;
      if (item.name) {
        modifiedSubgenres.push({ ...item, name: counter + " - " + item.name });
      }
    });
  }

  return modifiedSubgenres;
};

export const makeid = length => {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const objectValueExistsInArray = (objectKey, value, objectArray) => {
  if (
    objectArray &&
    objectArray.length > 0 &&
    Array.isArray(objectArray) &&
    objectArray.filter(e => e[objectKey] === value).length > 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const objectExistsInArray = (array, object, omitProperty = []) => {
  let exists = false;

  if (array && array.length > 0) {
    array.forEach(item => {
      if (lodash.isEqual(lodash.omit(item, omitProperty), lodash.omit(object, omitProperty))) {
        exists = true;
      }
    });
  }

  return exists;
};

export const ordinalSuffix = i => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

export const findObjectByValue = (array, key, value) => {
  if (array && array.length > 0 && value) {
    if (typeof value === "string") {
      return array.find(x => x[key].toLowerCase() === value.toLocaleLowerCase()) || null;
    } else {
      return array.find(x => x[key] === value) || null;
    }
  }
};

export const findObjectIndexByValue = (array, key, value) => {
  let compareTo;

  if (array && array.length) {
    for (let i = 0; i < array.length; i++) {
      compareTo = array[i][key];
      if (typeof value == "string") {
        compareTo = compareTo.toLowerCase();
        value = value.toLowerCase();
      }

      if (compareTo === value) {
        return i;
      }
    }
  }

  return -1;
};

var globalSearchTimeout;
export const searchByString = (text, types = [], minTextLen = 2) => {
  let returnData = [];

  return new Promise(function(resolve, reject) {
    clearTimeout(globalSearchTimeout);
    if (text.length >= minTextLen) {
      globalSearchTimeout = setTimeout(() => {
        apiManager
          .getSearch(text, types.join(","))
          .then(resp => {
            if (types[0] === "user" || types[0] === "youtube_video") {
              resolve(resp);
              return;
            }

            if (types[0] !== "city") {
              returnData = [];
              for (let i = 0; i < resp.data.artists.length; i++) {
                returnData.push(resp.data.artists[i]);
              }

              for (let i = 0; i < resp.data.festivals.length; i++) {
                returnData.push(resp.data.festivals[i]);
              }

              for (let i = 0; i < resp.data.venues.length; i++) {
                returnData.push(resp.data.venues[i]);
              }

              for (let i = 0; i < resp.data.events.length; i++) {
                returnData.push(resp.data.events[i]);
              }

              for (let i = 0; i < resp.data.articles.length; i++) {
                returnData.push(resp.data.articles[i]);
              }

              for (let i = 0; i < resp.data.labels.length; i++) {
                returnData.push(resp.data.labels[i]);
              }
            } else {
              returnData = resp.data;
            }

            resolve(returnData);
          })
          .catch(resp => {
            console.warn("Error fetching search results.", resp);
            reject(false);
          });
      }, 500);
    } else {
      resolve(returnData);
    }
  });
};

export const getVenueButtonTextFromObject = venueObject => {
  let venueText = "";

  if (venueObject && !venueObject.spanContent) {
    const country = venueObject.country && venueObject.country.name ? venueObject.country.name : "";
    const city = venueObject.city && venueObject.city.name ? venueObject.city.name : "";
    const seperator = city && country ? " | " : "";
    const spanContent = country + seperator + city;
    venueObject = { ...venueObject, spanContent: spanContent };
  }

  if (venueObject) {
    venueText = (
      <React.Fragment>
        {venueObject.name}
        <span>{venueObject.spanContent}</span>
      </React.Fragment>
    );
  }

  return venueText;
};

export const getYoutubeThumbnail = (videoUrl, size = "big") => {
  let imageUrl = "";
  let results;
  let video;

  if (videoUrl && typeof videoUrl === "string") {
    results = videoUrl.match("[\\?&]v=([^&#]*)");
    video = results === null ? videoUrl : results[1];
    imageUrl =
      size === "small"
        ? "http://img.youtube.com/vi/" + video + "/2.jpg"
        : "http://img.youtube.com/vi/" + video + "/0.jpg";
  }

  return imageUrl;
};

export const capitalizeString = str => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

export const parseImage = image => {
  return image.substring(0, 4) === "http" ? { url: image } : { data: image };
};

export function parseYoutubeId(url) {
  if (url) {
    const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[1].length === 11 ? match[1] : false;
  }
  return false;
}

export const getImage = imageObj => {
  const imageUrl = imageObj && imageObj.url ? imageObj.url : null;
  const imageData = imageObj && imageObj.data ? imageObj.data : null;
  return imageUrl ? imageUrl : imageData;
};

export const IsJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const create_UUID = () => {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const sortArrayOfObjectsByProperty = (array, propertyName) => {
  let textA;
  let textB;

  if (array && Array.isArray(array) && array.length) {
    array = array.sort((a, b) => {
      textA = a && a[propertyName] ? a[propertyName].toUpperCase() : null;
      textB = b && b[propertyName] ? b[propertyName].toUpperCase() : null;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }

  return array;
};

export const sortByKey = (data, key, asc = true, isDate = false) => {
  return data.sort((a, b) => {
    a = getOr(null, key, a);
    b = getOr(null, key, b);
    a = a && isDate ? new Date(a) : a;
    b = b && isDate ? new Date(b) : b;

    if (a === b) {
      return 0;
    } else if (a === null) {
      return 1;
    } else if (b === null) {
      return -1;
    } else {
      if (asc) {
        return a < b ? -1 : 1;
      } else {
        return a > b ? -1 : 1;
      }
    }
  });
};

export const getNameFromPlaceLineup = (lineup, place) => {
  let sortedLineupByRank = [];
  let artistNames = [];

  if (lineup && lineup.length) {
    sortedLineupByRank = [...lineup].sort((a, b) => (a.rank > b.rank ? 1 : -1));
  }

  let placeName = place && place.name ? "at " + place.name : "";
  let artistObj;

  if (sortedLineupByRank && sortedLineupByRank.length) {
    for (let i = 0; i < sortedLineupByRank.length; i++) {
      if (i > 2) {
        break;
      }
      artistObj = sortedLineupByRank[i];

      if (artistObj && artistObj.name) {
        artistNames.push(artistObj.name);
      }
    }
  }

  artistNames = artistNames.length ? artistNames.join(", ") : "";
  return `${artistNames} ${placeName}`;
};

export const getLinkStringFromLinksArray = (links, name) => {
  let linkString = "";

  if (links && links.length) {
    links.forEach(item => {
      if (item.link && item.channel === name) {
        linkString = item.link;
      }
    });
  }

  return linkString;
};

export const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));

export const searchInNewTabClick = (type, searchString, additionalGoogleSearchString) => {
  additionalGoogleSearchString = additionalGoogleSearchString ? " " + additionalGoogleSearchString : "";

  if (searchString) {
    searchString = encodeURIComponent(searchString);
    let url;

    switch (type) {
      case "spotify":
        url = "https://open.spotify.com/search/" + searchString;
        break;
      case "bandcamp":
        url = "https://bandcamp.com/search?q=" + searchString;
        break;
      case "soundcloud":
        url = "https://soundcloud.com/search/people?q=" + searchString;
        break;
      case "deezer":
        url = "https://www.deezer.com/search/" + searchString;
        break;
      case "twitter":
        url = "https://twitter.com/search?q=" + searchString + "&f=user&vertical=default";
        break;
      case "youtube":
        url = "https://www.youtube.com/results?search_query=" + searchString + "&sp=EgIQAg%253D%253D";
        break;
      case "songkick":
        url = "https://www.songkick.com/search?utf8=%E2%9C%93&query=" + searchString;
        break;
      case "beatport":
        url = "http://www.beatport.com/search?q=" + searchString;
        break;
      case "tidal":
        url = "http://listen.tidal.com/search?q=" + searchString;
        break;
      case "napster":
        url = "http://us.napster.com/search?query=" + searchString;
        break;
      case "amazon_music":
        url = "http://music.amazon.com/search/" + searchString;
        break;
      case "facebook_all":
        url = "https://www.facebook.com/search/top/?q=" + searchString + "&epa=SEARCH_BOX";
        break;
      case "facebook":
        url = "https://www.facebook.com/search/pages/?q=" + searchString;
        break;
      case "viberate":
        url = viberatecomSiteUrl[process.env.REACT_APP_ENVIRONMENT] + searchString;
        break;
      case "google_pm":
        url = "https://play.google.com/store/search?q=" + searchString + "&c=music";
        break;
      case "google":
      default:
        url = "http://google.com/search?q=" + searchString + additionalGoogleSearchString;
        break;
    }

    if (url) {
      window.open(url);
    }
  }
};

export const buildUrlFromTableParams = (filters, sortData, tableParams, rowNumber = 10, page = 0) => {
  const parseFilterData = (filterUrlArray, backendFieldName, filterData, valueKey) => {
    if (filterData) {
      switch (typeof filterData) {
        case "object":
          if (filterData.length && Array.isArray(filterData)) {
            let currentFilterString = backendFieldName + "=";
            let values = [];

            filterData.forEach(item => {
              if (item[valueKey]) {
                values.push(item[valueKey]);
              }
            });

            currentFilterString += values.join(",");
            if (values) {
              filterUrlArray.push(currentFilterString);
            }
          } else {
            if (filterData[valueKey]) {
              filterUrlArray.push(backendFieldName + "=" + filterData[valueKey]);
            }
          }
          break;
        default:
          filterUrlArray.push(backendFieldName + "=" + filterData);
      }
    }
  };

  const parseSortData = (sortData, filterUrlArray) => {
    if (sortData && sortData.backendColumnName && sortData.direction) {
      filterUrlArray.push("sort=" + sortData.backendColumnName + "." + sortData.direction);
    }
  };

  let columnsData = tableParams && tableParams.columns ? tableParams.columns : null;
  let filterUrlArray = ["limit=" + rowNumber];
  let filterColumnDefinition;
  let filterBackendFieldValue;
  let valueKey;
  let filterData;

  if (filters && columnsData) {
    for (let filterName in filters) {
      filterColumnDefinition = findObjectByValue(columnsData, "name", filterName);
      filterColumnDefinition = filterColumnDefinition ? filterColumnDefinition.filterDefinition : null;
      filterData = filters[filterName];

      if (filterColumnDefinition && filterColumnDefinition.backendFieldValue && filterData) {
        filterBackendFieldValue = filterColumnDefinition.backendFieldValue;

        switch (typeof filterBackendFieldValue) {
          case "object":
            for (let fieldName in filterBackendFieldValue) {
              valueKey = filterBackendFieldValue[fieldName];
              parseFilterData(filterUrlArray, fieldName, filterData, valueKey);
            }
            break;
          default:
            parseFilterData(filterUrlArray, filterBackendFieldValue, filterData, valueKey);
        }
      }
    }
  }

  parseSortData(sortData, filterUrlArray);

  if (page > 0) {
    filterUrlArray.push("offset=" + page * rowNumber);
  }

  return filterUrlArray.length ? filterUrlArray.join("&") : "";
};

export const getMediaLength = media => {
  let length = 0;

  if (media) {
    for (let mediaType in media) {
      if (media[mediaType] && Array.isArray(media[mediaType])) {
        length += media[mediaType].length;
      }
    }
  }

  return length;
};

export const getMarkerIcon = (category, id) => {
  switch (category) {
    case "amenity":
      return getOr("", `amenities[${id}]`, markerIcons);
    case "experience":
      return getOr("", `experiences`, markerIcons);
    case "stage":
      return getOr("", `stages`, markerIcons);
    default:
      return "";
  }
};

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, "g"), replace);
};

export const checkPermissionString = (userPermissions, permissionString) => {
  userPermissions = Object.keys(userPermissions);
  let returnedVal = true;

  if (permissionString) {
    if (Array.isArray(permissionString)) {
      for (let i = 0; i < permissionString.length; i++) {
        if (userPermissions.includes(sha256(permissionString[i]))) {
          returnedVal = true;
          break;
        }
      }
    } else {
      const hash = sha256(permissionString);
      returnedVal = userPermissions.includes(hash);
    }
  }

  return returnedVal;
};

export const isValidURL = string => {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  return res !== null;
};

export const mapArray = (array, key = "id") => {
  let map = {};
  if (array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key]) {
        map[array[i][key]] = array[i];
      } else {
        map[array[i]] = array[i];
      }
    }
  }
  return map;
};

export const thousandsSeparator = x => {
  if (!Number.isInteger(x)) {
    return x;
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const arrayDiff = (array1 = [], array2 = [], compareKey) => {
  let newValues = [];
  let deletedValues = [];

  if (array1 && !!array1.length && Array.isArray(array1)) {
    array1.forEach(array1Item => {
      if (typeof array1Item === "object") {
        if (!objectValueExistsInArray(compareKey, array1Item[compareKey], array2)) {
          deletedValues.push(array1Item);
        }
      }
    });
  }

  if (array2 && !!array2.length && Array.isArray(array2)) {
    array2.forEach(array2Item => {
      if (typeof array2Item === "object") {
        if (!objectValueExistsInArray(compareKey, array2Item[compareKey], array1)) {
          newValues.push(array2Item);
        }
      }
    });
  }

  return { new: newValues, deleted: deletedValues };
};

export const getEmbedLink = (type, url) => {
  if (!url) {
    return null;
  }

  let splitUrl;

  switch (type) {
    case "youtube":
      splitUrl = url.split("watch?v=");
      return splitUrl[splitUrl.length - 1];
    case "spotify":
    case "beatport":
      splitUrl = url.split("/");
      return splitUrl[splitUrl.length - 1];
    default:
      return url;
  }
};

export const getLinkID = (url, type) => {
  if (!url) {
    return null;
  }

  let regex;
  let result;

  switch (type) {
    case "youtube":
      regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
      break;
    case "spotify":
    case "beatport":
    case "airplay":
    case "tiktok":
      regex = /.+(?:\/)(.+?)(\/|\?|#|$)/;
      break;
    case "soundcloud":
      regex = /.+soundcloud.com\/(.+)/;
      break;
    case "shazam":
      regex = /.+\/([0-9]+?)(\/|\?|#|$)/;
      break;
    default:
      return url;
  }

  result = regex ? regex.exec(url) : null;
  return result && result[1] ? result[1] : url;
};
