import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { mainStateKey } from "../../../constants/constants";

const Lineup = ({ connectedTo, lineup }) => {
  let day;
  let start;
  let end;
  let image;
  let name;

  return (
    <table className="table table-bordered table-striped table-font-10 table-header-white">
      <thead>
        <tr className="filter-row">
          <th>Name</th>
          <th>Day</th>
          <th>Stage</th>
          <th>Start Date</th>
          <th>End Date</th>
        </tr>
      </thead>
      <tbody>
        {lineup &&
          !!lineup.length &&
          lineup.map(item => {
            day = getOr("", "day", item);
            day = day ? moment(day).format("DD.MM.YYYY") : "";

            start = getOr("", "start", item);
            start = start ? moment(start).format("DD.MM.YYYY HH:mm") : "";

            end = getOr("", "end", item);
            end = end ? moment(end).format("DD.MM.YYYY HH:mm") : "";

            image = getOr("", "artist.image", item);
            name = getOr("", "artist.name", item);

            return (
              <tr key={getOr("", "artist.uuid", item)}>
                <td>
                  <div className="btn-search-result btn-artist">
                    {image && <img src={image} alt={name} />}
                    {name}
                    <span>
                      {getOr("/", "artist.genre.name", item)} | {getOr("/", "artist.country.name", item)}
                    </span>
                  </div>
                </td>
                <td>{day}</td>
                <td>{getOr("", "stage.name", item)}</td>
                <td>{start}</td>
                <td>{end}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

Lineup.propTypes = {
  connectedTo: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const mapStateToProps = (state, ownProps) => {
  return { lineup: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state) };
};

export default connect(mapStateToProps)(Lineup);
