import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import Select from "../../../input/selectors/select";
import Input from "../../../input/general/input";
import EntitySelector from "../../../input/selectors/entitySelector";
import { enumsPath, mainStateKey, viberatecomSiteUrl } from "../../../../constants/constants";
import UploadImageButton from "../../../input/media/uploadImageButton";
import Weeks from "../../../input/date/weeks";
import { combineFestivalSizeNameDescription } from "../../../../lib/functions/enumsFunctions";

const BasicAndAdditionalInfoBox = ({ festivalSizes, slug }) => {
  return (
    <FormBox title="BASIC & ADDITIONAL INFO">
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <div className="form-group">
              <label className="col-sm-3 control-label">Name</label>
              <div className="col-sm-9 right-padding-110">
                <div className="input-group">
                  <EntitySelector
                    placeholder="Search Festival..."
                    entityType="festival"
                    name="formData.name"
                    showAddNewButton={false}
                    removeOnSelect={false}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Info Email</label>
              <div className="col-sm-9 right-padding-110">
                <div className="input-group">
                  <Input name="formData.email" type="text" placeholder="Festival Info Email" className="form-control" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Stages</label>
              <div className="col-sm-9 right-padding-110">
                <div className="input-group">
                  <Input
                    name="formData.stagesNumber"
                    type="number"
                    placeholder="Number of stages"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="form-group absolute-top-right">
              <UploadImageButton name="formData.image" title="Profile" />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-3 control-label">Size</label>
            <div className="col-sm-9">
              <div className="input-group">
                <Select
                  placeholder="Select size"
                  data={combineFestivalSizeNameDescription(festivalSizes)}
                  displayKey="name"
                  idKey="id"
                  name="formData.size"
                />
              </div>
            </div>
          </div>
          <Weeks name="formData.weeks" />
          {slug && (
            <div className="form-group">
              <div className="col-sm-12">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={viberatecomSiteUrl[process.env.REACT_APP_ENVIRONMENT] + "festival/" + slug}
                >
                  {viberatecomSiteUrl[process.env.REACT_APP_ENVIRONMENT] + "festival/" + slug}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </FormBox>
  );
};

BasicAndAdditionalInfoBox.propTypes = {
  festivalSizes: PropTypes.array,
  slug: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    festivalSizes: getOr(null, `${enumsPath}.capacity_ranges.festival`, state),
    slug: getOr(null, `${mainStateKey}.formData.slug`, state),
  };
};

export default connect(mapStateToProps)(BasicAndAdditionalInfoBox);
