import React from "react";
import FormBox from "../../../presentational/page/formBox";
import Amenities from "../../../input/general/amenities";

const AmenitiesBox = () => {
  return (
    <FormBox title="Amenities">
      <div className="box-body">
        <Amenities
          amenitiesType="festival"
          name="formData.amenities"
          initialAmenitiesPath="formData.initialAmenities"
        />
      </div>
    </FormBox>
  );
};

export default AmenitiesBox;
