import { set, getOr } from "lodash/fp";
import { COPY_OPENING_HOURS, SET_OPEN_ON_EVENT_DAY, LOCK_OPENING_HOURS_DAY } from "./actionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case COPY_OPENING_HOURS: {
      let openingHours = [...(getOr([], `${action.name}.hours`, state) || [])];
      let updatedOpeninghours = [];
      let data;

      for (let i = 0; i < 7; i++) {
        data = openingHours && openingHours[i] ? openingHours[i] : {};

        if (data.disabled === true) {
          updatedOpeninghours.push({ ...data });
        } else {
          updatedOpeninghours.push({
            ...data,
            start: action.payload.start,
            end: action.payload.end,
            day: i + 1,
          });
        }
      }

      return set(`${action.name}.hours`, updatedOpeninghours, state);
    }

    case LOCK_OPENING_HOURS_DAY: {
      let openingHours = [...(getOr([], `${action.name}.hours`, state) || [])];
      let disabled = getOr(false, `${action.name}.hours.[${action.payload.index}].disabled`, state);

      openingHours[action.payload.index] = {
        ...openingHours[action.payload.index],
        start: "",
        end: "",
        disabled: !disabled,
      };

      return set(`${action.name}.hours`, openingHours, state);
    }

    case SET_OPEN_ON_EVENT_DAY: {
      let updatedOpeninghours = [];
      let obj;

      for (let i = 0; i < 7; i++) {
        if (action.payload.checked) {
          obj = {
            start: "",
            end: "",
            day: i + 1,
            disabled: true,
          };
        } else {
          obj = {
            start: "",
            end: "",
            day: i + 1,
            disabled: false,
          };
        }

        updatedOpeninghours.push(obj);
      }
      return set(`${action.name}`, { hours: updatedOpeninghours, openOnEvent: action.payload.checked }, state);
    }
    default:
      return { ...state };
  }
}
