import React, { useEffect } from "react";
import Footer from "../../../presentational/page/footer";
import GeneralInfoBox from "../shared/generalInfoBox";
import { handleAddFormSubmit, setFormData } from "../../../../lib/functions/componentFunctions";
import { useResetPageState } from "../../../../lib/functions/customHooks";
import ArtistsBox from "../shared/artistsBox";
import GenreBox from "../shared/genreBox";
import LinksBox from "../shared/linksBox";
import trackDataManipulation from "../../../../lib/dataManipulation/track/trackDataManipulation";

const AddTrack = () => {
  useResetPageState();

  useEffect(() => {
    setFormData({ status: { id: 0, name: "Verified" } });
  }, []);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add track</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <GeneralInfoBox />
          </div>
          <div className="col-md-6">
            <ArtistsBox />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <GenreBox />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Spotify Tracks" type={"spotify"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Beatport Tracks" type={"beatport"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Youtube videos" type={"youtube"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Soundcloud Tracks" type={"soundcloud"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Shazam Tracks" type={"shazam"} showPreview={false} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Airplay Tracks" type={"airplay"} showPreview={false} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Tiktok Tracks" type={"tiktok"} showPreview={false} />
          </div>
        </div>
        <Footer
          onSave={() => handleAddFormSubmit(false, trackDataManipulation, "track")}
          onSaveAndClear={() => {
            handleAddFormSubmit(true, trackDataManipulation, "track");
          }}
        />
      </section>
    </React.Fragment>
  );
};

export default AddTrack;
