import React from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import DateTimePicker from "react-datetime-picker";
import { mainStateKey } from "../../../constants/constants";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

const DateTime = ({ value, name, dispatch, className }) => {
  return (
    <DateTimePicker
      dayPlaceholder={"dd"}
      monthPlaceholder={"mm"}
      yearPlaceholder={"yyyy"}
      hourPlaceholder={"hh"}
      minutePlaceholder={"mm"}
      calendarIcon={null}
      disableClock={true}
      className={className}
      onChange={dateTime => {
        setDateTime(name, dateTime, dispatch);
      }}
      value={value}
      maxDate={new Date("9999-12-31")}
    />
  );
};

const setDateTime = (name, dateTime, dispatch) => {
  dispatchToReduxStore(name, SET_STATE_VALUE, dateTime, dispatch);
};

DateTime.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  dispatch: PropTypes.func,
  className: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(DateTime);
