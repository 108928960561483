import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { mainStateKey, modalPath, youtubeDomain } from "../../../constants/constants";
import { objectValueExistsInArray, sortByKey, thousandsSeparator } from "../../../lib/functions/common";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

const Youtube = ({ connectedTo, videos, added = [], deleted = [], onlyShowDiff = false, dispatch }) => {
  let videoID;
  let image;
  let title;
  let channelName;
  let releaseDate;
  let views;
  let channelID = "";
  let isDeleted = false;
  let isAdded = false;
  let diff = [...deleted, ...added];

  videos = videos ? sortByKey(videos, "views", false) : [];
  videos = onlyShowDiff ? diff : videos;

  const previewVideo = (videoID, title) => {
    dispatchToReduxStore(
      modalPath,
      SET_STATE_VALUE,
      {
        show: true,
        data: { videoID, title },
        type: "youtubePreview",
        hideCloseButton: true,
      },
      dispatch
    );
  };

  return (
    <table className="table table-bordered table-striped table-font-10 table-header-white">
      <thead>
        <tr className="filter-row">
          <th>Title</th>
          <th>Channel</th>
          <th>Release date</th>
          <th>Views</th>
        </tr>
      </thead>
      <tbody>
        {videos &&
          !!videos.length &&
          videos.map(item => {
            videoID = getOr("", "yt_video_id", item);
            isDeleted = objectValueExistsInArray("yt_video_id", videoID, deleted);
            isAdded = objectValueExistsInArray("yt_video_id", videoID, added);
            image = videoID ? `https://img.youtube.com/vi/${videoID}/default.jpg` : "";
            title = getOr("", "name", item);
            channelName = getOr("", "channel.name", item);
            releaseDate = item.release_date ? moment(item.release_date).format("YYYY-MM-DD") : "";
            views = thousandsSeparator(getOr("", "views", item));
            channelID = getOr("", "channel.link_id", item);

            return (
              <tr key={getOr("", "yt_video_id", item)}>
                <td
                  style={{ width: "40%", cursor: "pointer" }}
                  onClick={() => {
                    previewVideo(item.yt_video_id, item.name);
                  }}
                >
                  <div className="btn-search-result btn-artist">
                    {image && <img src={image} alt={title} />}
                    <div
                      className="clamp-line-1"
                      title={title}
                      style={{
                        color: isDeleted ? "red" : isAdded ? "green" : undefined,
                        fontWeight: isDeleted || isAdded ? "bold" : undefined,
                      }}
                    >
                      {title}
                    </div>
                  </div>
                </td>
                <td>
                  <a target="_blank" rel="noopener noreferrer" href={`${youtubeDomain}/channel/${channelID}`}>
                    {channelName}
                  </a>
                </td>
                <td>{releaseDate}</td>
                <td>{views}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

Youtube.propTypes = {
  connectedTo: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  added: PropTypes.array,
  deleted: PropTypes.array,
  onlyShowDiff: PropTypes.bool,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return { videos: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state) };
};

export default connect(mapStateToProps)(Youtube);
