import React, { useEffect } from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import TextArea from "../../../input/general/textArea";
import { updateEventName } from "../../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { mainStateKey } from "../../../../constants/constants";
import FormBox from "../../../presentational/page/formBox";
import { searchInNewTabClick } from "../../../../lib/functions/common";

const EventNameBox = ({ dispatch, originalName, originalVenueName, name, venue, lineup }) => {
  useEffect(() => {
    if (venue && venue.id && lineup && lineup.length) {
      updateEventName(lineup, venue, dispatch, "formData.name", SET_STATE_VALUE);
    }
  }, [venue, lineup, dispatch]);

  return (
    <FormBox title="Event Name">
      <div className="box-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <p
                className="orig-event-name"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (originalName) {
                    searchInNewTabClick("google", originalName);
                  }
                }}
              >
                {originalName}
              </p>
              <div className="input-group">
                <TextArea
                  name="formData.name"
                  placeholder="Event name"
                  className="form-control"
                  showClearInputButton={true}
                  rows="3"
                  debounceTime={0}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div className="input-group">
                <button
                  type="button"
                  className="btn btn-default btn-search-outside"
                  onClick={() => {
                    searchInNewTabClick("google", name);
                  }}
                >
                  Search Google
                </button>
                {"\u00A0"}
                <button
                  type="button"
                  className="btn btn-default btn-search-outside"
                  onClick={() => {
                    searchInNewTabClick("facebook_all", name);
                  }}
                >
                  Search FB
                </button>
              </div>
              <div className="input-group">
                <button
                  type="button"
                  className="btn btn-default btn-search-outside"
                  onClick={() => {
                    searchInNewTabClick("google", originalVenueName);
                  }}
                >
                  Search Venue Google
                </button>
                {"\u00A0"}
                <button
                  type="button"
                  className="btn btn-default btn-search-outside"
                  onClick={() => {
                    searchInNewTabClick("facebook_all", originalVenueName);
                  }}
                >
                  Search Venue FB
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

EventNameBox.propTypes = {
  dispatch: PropTypes.func,
  originalName: PropTypes.string,
  name: PropTypes.string,
  venue: PropTypes.object,
  lineup: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    originalName: getOr(null, `${mainStateKey}.formData.originalName`, state),
    originalVenueName: getOr(null, `${mainStateKey}.formData.originalVenueName`, state),
    name: getOr(null, `${mainStateKey}.formData.name`, state),
    venue: getOr(null, `${mainStateKey}.formData.venue`, state),
    lineup: getOr(null, `${mainStateKey}.formData.lineup`, state),
  };
};

export default connect(mapStateToProps)(EventNameBox);
