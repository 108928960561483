import React from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import MultiCheckbox from "../../input/general/multiCheckbox";
import { enumsPath, mainStateKey } from "../../../constants/constants";

const Genres = ({ genres, name = "formData.genres", initialGenres, initialGenresPath }) => {
  genres = genres ? genres : [];
  return (
    <React.Fragment>
      {genres.map(item => {
        return (
          <div className="form-group float-list-item" key={item.id}>
            <div className={"checkbox " + (initialGenres && initialGenres.includes(item.id) ? "pre-selected" : "")}>
              <MultiCheckbox pushedItem={item.id} name={`${name}`} showClearInputButton={false} />
              <label>{item.name}</label>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

Genres.propTypes = {
  initialGenres: PropTypes.array,
  initialGenresPath: PropTypes.string,
  genres: PropTypes.array,
  name: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    genres: getOr(null, `${enumsPath}.genres`, state),
    initialGenres: getOr(null, `${mainStateKey}.${ownProps.initialHighlightsPath}`, state),
  };
};

export default connect(mapStateToProps)(Genres);
