import { getOr } from "lodash/fp";
import moment from "moment";
import commonDataManipulation from "../common/commonDataManipulation";
import { parseImage, getMediaLength } from "../../functions/common";

const festivalDataManipulation = function festivalDataManipulation() {
  const getOutwardFestival = data => {
    const tickets = data && data.ticketInfo ? commonDataManipulation().tickets.outward(data.ticketInfo) : null;
    let image = data && data.image ? parseImage(data.image) : null;

    return {
      stages: data.stages ? data.stages : null,
      name: data && data.name && data.name.name ? data.name.name : null,
      image: image,
      email: data && data.email ? data.email : null,
      stages_num: data && data.stagesNumber ? parseInt(data.stagesNumber) : null,
      capacity_range_id: data && data.size && data.size.id ? data.size.id : null,
      links: data && data.links ? commonDataManipulation().links.outward(data.links) : null,
      highlight_ids: data && data.highlights ? data.highlights : null,
      amenity_ids: data && data.amenities ? data.amenities : null,
      lineup: commonDataManipulation().lineupTable.outward(data.lineup),
      media: data
        ? commonDataManipulation().media.outward(data.impressions, data.officialGallery, data.videoGallery)
        : null,
      ...commonDataManipulation().weeks.outward(data.weeks),
      tickets: tickets,
      ...commonDataManipulation().location.outward(data),
      festival_uuid: data && data.festivalUUID ? data.festivalUUID : null,
      comments: data && data.comments ? data.comments : null,
    };
  };

  const getInwardEditFestivalItem = data => {
    const weeks = commonDataManipulation().weeks.inward(data.weeks, data.start, data.end);
    const newEditionDate = getNewFestivalEditionDate(weeks);

    return {
      stages: data.stages ? data.stages : null,
      image: data.image ? data.image : null,
      lineup: commonDataManipulation().lineupTable.inward(data.lineup),
      name: data && data.name ? { name: data.name } : null,
      email: data && data.email ? data.email : null,
      stagesNumber: data && data.stages_num ? data.stages_num : null,
      size:
        data &&
        data.capacity_range &&
        data.capacity_range.id &&
        data.capacity_range.name &&
        data.capacity_range.description
          ? {
              id: data.capacity_range.id,
              name: data.capacity_range.name + " (" + data.capacity_range.description + ")",
            }
          : null,
      links: data.links ? commonDataManipulation().links.inward(data.links) : [],
      oldLinks: data.links ? commonDataManipulation().links.inward(data.links) : [],
      ticketInfo: data && data.tickets ? commonDataManipulation().tickets.inward(data.tickets) : null,
      location: commonDataManipulation().location.inward(data),
      impressions:
        data && data.media && data.media[1] ? commonDataManipulation().mediaImages.inward(data.media[1]) : null,
      officialGallery:
        data && data.media && data.media[4] ? commonDataManipulation().mediaImages.inward(data.media[4]) : null,
      videoGallery: data && data.media ? commonDataManipulation().mediaVideos.inward(data.media) : null,
      highlights: data && data.highlights ? data.highlights.map(item => item.id) : null,
      amenities: data && data.amenities ? data.amenities.map(item => item.id) : null,
      initialHighlights: data && data.highlights ? data.highlights.map(item => item.id) : null,
      initialAmenities: data && data.amenities ? data.amenities.map(item => item.id) : null,
      weeks: weeks,
      festivalUUID: data && data.festival_uuid ? data.festival_uuid : null,
      claimed: data.claimed ? true : false,
      latest: data.latest ? true : false,
      newEditionDate: newEditionDate,
      slug: data.festival_main_slug ? data.festival_main_slug : "",
      pool: data.pool,
    };
  };

  const getInwardDatatable = data => {
    const listItems = [];

    if (data && data.length) {
      data.forEach(item => {
        listItems.push([
          item.start ? moment(item.start).format("YYYY") : null,
          item.name,
          item.start ? moment(item.start).format("YYYY-MM-DD") : null,
          item.end ? moment(item.end).format("YYYY-MM-DD") : null,
          getOr(null, "country.name", item),
          getOr(null, "city.name", item),
          item.lineup ? item.lineup.length : null,
          item.highlights ? item.highlights.length : null,
          item.amenities ? item.amenities.length : null,
          getMediaLength(item.media),
          getOr([], "media[3]", item).length ? "Yes" : "No",
          getOr([], "media[2]", item).length ? "Yes" : "No",
          item.rank,
          item.latest ? "No" : "Yes",
          item.claimed ? "Yes" : "No",
          item.updated_at ? moment(item.updated_at).format("YYYY-MM-DD") : null,
          item.status ? item.status : "",
          getOr("/", "pool.name", item),
          item.festival_main_uuid + "/" + item.festival_uuid,
        ]);
      });
    }

    return listItems;
  };

  return { outward: getOutwardFestival, inwardItem: getInwardEditFestivalItem, inwardDatatable: getInwardDatatable };
};

const getNewFestivalEditionDate = weeks => {
  const weekStart = weeks && weeks[0] && weeks[0].start ? weeks[0].start : null;
  const weekEnd = weeks && weeks[weeks.length - 1] && weeks[weeks.length - 1].end ? weeks[weeks.length - 1].end : null;

  const newStart = weekStart
    ? moment(weekStart)
        .add(1, "Y")
        .format("YYYY-MM-DD")
    : null;
  const newEnd = weekEnd
    ? moment(weekEnd)
        .add(1, "Y")
        .format("YYYY-MM-DD")
    : null;

  return newStart && newEnd ? { start: newStart, end: newEnd } : null;
};

export default festivalDataManipulation();
