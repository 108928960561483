import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import Select from "../../../input/selectors/select";
import { articleStatus, feedPostType, mainStateKey, userStatus } from "../../../../constants/constants";
import Input from "../../../input/general/input";
import TextArea from "../../../input/general/textArea";
import SimilarArticles from "../../../input/general/similarArticles";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";

const GeneralInfoBox = ({ articleID, dispatch }) => {
  return (
    <FormBox title="General Info">
      <div className="row">
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Article</label>
              <div className="input-group">
                <SimilarArticles
                  name="formData.article"
                  swapOnSelect={true}
                  onSelect={article => {
                    dispatchToReduxStore("formData.title", SET_STATE_VALUE, article.title, dispatch);
                    dispatchToReduxStore("formData.content", SET_STATE_VALUE, null, dispatch);
                    dispatchToReduxStore("formData.lead", SET_STATE_VALUE, article.lead, dispatch);
                    dispatchToReduxStore(
                      "formData.type",
                      SET_STATE_VALUE,
                      { id: "article", name: "Article" },
                      dispatch
                    );
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Title</label>
              <div className="input-group">
                <Input
                  disabled={articleID}
                  name="formData.title"
                  type="text"
                  placeholder="Title"
                  className="form-control"
                  showClearInputButton={false}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Lead Text</label>
              <TextArea
                disabled={articleID}
                name="formData.lead"
                placeholder="Enter lead text"
                className="form-control"
                showClearInputButton={false}
                rows="12"
                maxLength={500}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="box-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Publish Date</label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-addon">START</div>
                          <Input
                            name="formData.startDate"
                            type="date"
                            placeholder="dd-mm-yyyy"
                            className="form-control"
                            showClearInputButton={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>User Status</label>
                  <Select
                    placeholder="Select status"
                    data={userStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={false}
                    name="formData.userStatus"
                    showClearButton={false}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status</label>
                  <Select
                    placeholder="Select status"
                    data={articleStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={false}
                    name="formData.status"
                    showClearButton={false}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Type</label>
                  <Select
                    disabled={articleID}
                    placeholder="Select feed post type"
                    data={feedPostType}
                    displayKey="name"
                    idKey="id"
                    autocomplete={false}
                    name="formData.type"
                    showClearButton={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

GeneralInfoBox.propTypes = {
  dispatch: PropTypes.func,
  articleID: PropTypes.number,
};

const mapStateToProps = state => {
  return { articleID: getOr(null, `${mainStateKey}.formData.article[0].id`, state) };
};

export default connect(mapStateToProps)(GeneralInfoBox);
