import React, { useEffect } from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Footer from "../../../presentational/page/footer";
import BasicAndAdditionalInfoBox from "../shared/basicAndAdditionalInfoBox";
import {
  setItemFormData,
  handleEditFormSubmit,
  handleDeleteEntity,
  showAppLoader,
  showFormSuccess,
  showFormFail,
  showFormErrorReasons,
  hideFormErrorReasons,
  getStateValue,
  redirectTo,
} from "../../../../lib/functions/componentFunctions";
import festivalDataManipulation from "../../../../lib/dataManipulation/festival/festivalDataManipulation";
import LocationBox from "../shared/locationBox";
import LinksBox from "../shared/linksBox";
import TicketInfoBox from "../shared/ticketInfoBox";
import AmenitiesBox from "../shared/amenitiesBox";
import HighlightsBox from "../shared/highlightsBox";
import PhotoAndVideoBox from "../shared/photAndVideoBox";
import LineupBox from "../shared/lineupBox";
import StagesBox from "./stagesBox";
import ClaimedImage from "../../../presentational/general/claimedImage";
import apiManager from "../../../../lib/apiManager/apiManager";
import DateRange from "../../../input/date/dateRange";
import commonDataManipulation from "../../../../lib/dataManipulation/common/commonDataManipulation";
import OpenChangeHistory from "../../../presentational/buttons/openChangeHistory";
import { mainStateKey } from "../../../../constants/constants";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const EditFestival = ({ currentPool }) => {
  useResetPageState();

  const routerHistory = useHistory();
  let { id, edition } = useParams();

  useEffect(() => {
    setItemFormData(id, edition, "festival");
  }, [id, edition]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>
          Edit Festival
          <ClaimedImage connectedTo="formData.claimed" />
          <OpenChangeHistory edition={edition} id={id} type="festival" />
        </h1>
      </section>

      <section className="content">
        <div className="screen">
          <div className="row">
            <div className="col-md-4">
              <BasicAndAdditionalInfoBox />
              <LinksBox />
              <TicketInfoBox />
            </div>
            <div className="col-md-8">
              <LocationBox />
              <HighlightsBox />
              <AmenitiesBox />
              {/*<CommentsBox />*/}
              <Footer showClearButton={false} onSave={() => onSubmit(id, edition)} />
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="row" id="photoVideo">
            <div className="col-md-12">
              <PhotoAndVideoBox />
              <Footer showClearButton={false} onSave={() => onSubmit(id, edition)} />
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="row" id="lineup">
            <div className="col-md-12">
              <StagesBox uuid={id} edition={edition} />
              <Footer showClearButton={false} onSave={() => onSubmit(id, edition)} />
              <LineupBox isEditForm={true} />
              <Footer showClearButton={false} onSave={() => onSubmit(id, edition)}>
                <button
                  type="button"
                  className="btn btn-danger margin"
                  onClick={e => {
                    handleDelete("festival", id, null, routerHistory);
                  }}
                >
                  Delete festival
                </button>

                {/*
                <button
                  type="button"
                  className="btn btn-danger margin"
                  onClick={e => {
                    handleDelete("edition", id, edition, routerHistory);
                  }}
                >
                  Delete edition
                </button>
                */}

                <div className="inline-button-wrapper">
                  <div className="input-group margin">
                    <div className="input-group-btn">
                      <button
                        type="button"
                        className="btn btn-success margin"
                        onClick={() => {
                          if (window.confirm("Are you sure you want to add a new edition")) {
                            addNewEdition(id, routerHistory, edition);
                          }
                        }}
                      >
                        Add New Edition
                      </button>
                    </div>
                    <div className="form-control">
                      <DateRange className="zIndex4" name={"formData.newEditionDate"} />
                    </div>
                  </div>
                </div>

                <div className="inline-button-wrapper">
                  <div className="input-group margin">
                    <div className="form-control">
                      <p>
                        <b>Current pool</b>: {currentPool ? currentPool : "/"}
                      </p>
                    </div>
                    <div className="input-group-btn">
                      <button
                        disabled={currentPool ? false : true}
                        type="button"
                        className="btn  margin"
                        onClick={() => {
                          if (window.confirm("Are you sure?")) {
                            changePool(id, edition, "festivalNextPool", {}, "DELETE");
                          }
                        }}
                      >
                        Move to Next Pool
                      </button>
                      <button
                        type="button"
                        className="btn  margin"
                        onClick={() => {
                          if (window.confirm("Are you sure?")) {
                            changePool(id, edition, "festivalFinishedPool", 1, "PUT");
                          }
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        Move to FINISHED
                      </button>
                    </div>
                  </div>
                </div>
              </Footer>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const handleDelete = async (type, id, edition, routerHistory) => {
  if (window.confirm("Are you sure you want to delete this " + type + "?")) {
    const deleteSuccess = await handleDeleteEntity(id, "festival", null, edition);

    if (deleteSuccess) {
      redirectTo(routerHistory, "/list/editFestival/");
    }
  }
};

const onSubmit = async (id, edition) => {
  const editSuccess = await handleEditFormSubmit(id, festivalDataManipulation, "festival", "POST", edition, false);

  if (editSuccess) {
    await setItemFormData(id, edition, "festival", null, false);
    showFormSuccess();
  }
};

const addNewEdition = (id, routerHistory, edition) => {
  const newEditionStart = getStateValue(null, "formData.newEditionDate.start");
  const newEditionEnd = getStateValue(null, "formData.newEditionDate.end");

  const newEditionData = {
    name: getStateValue(null, "formData.name"),
    location: getStateValue(null, "formData.location"),
    links: getStateValue(null, "formData.links"),
    size: getStateValue(null, "formData.size"),
    amenities: getStateValue(null, "formData.amenities"),
    highlights: getStateValue(null, "formData.highlights"),
    image: getStateValue(null, "formData.image"),
  };

  let parsedData = festivalDataManipulation.outward(newEditionData);
  parsedData = {
    ...parsedData,
    ...commonDataManipulation().weeks.outward(
      newEditionStart && newEditionEnd ? [{ start: newEditionStart, end: newEditionEnd }] : []
    ),
  };

  hideFormErrorReasons();
  showAppLoader();

  apiManager
    .addEntity("festivalEdition", parsedData, id)
    .then(async () => {
      await changePool(id, edition, "festivalFinishedPool", 1, "PUT");
      redirectTo(routerHistory, "/list/editFestival/");
    })
    .catch(e => {
      showFormFail();
      showFormErrorReasons(e);
    });
};

const changePool = (uuid, edition, type, params, method) => {
  hideFormErrorReasons();
  showAppLoader();

  return apiManager
    .editEntity(uuid, edition, type, params, method)
    .then(async () => {
      await setItemFormData(uuid, edition, "festival", {}, false);
      await showFormSuccess();
    })
    .catch(e => {
      showFormFail();
      showFormErrorReasons(e);
    });
};

const mapStateToProps = state => {
  return { currentPool: getOr(null, `${mainStateKey}.formData.pool.name`, state) };
};

EditFestival.propTypes = {
  currentPool: PropTypes.string,
};

export default connect(mapStateToProps)(EditFestival);
