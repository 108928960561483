export const editVenueTableParametersData = {
  statePath: "tables.editVenueTableParams",
  filtersPath: "tables.editVenueTableParams.filters",
  sortPath: "tables.editVenueTableParams.sort",

  columns: [
    {
      name: "venueName",
      label: "Venue Name",
      sortBackendName: "name",
      filterDefinition: {
        backendFieldValue: {
          name: "name",
        },
      },
    },
    {
      name: "country",
      label: "Country",
      sortBackendName: "country",
      filterDefinition: {
        backendFieldValue: {
          country: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "city",
      label: "City",
      sortBackendName: "city",
      filterDefinition: {
        backendFieldValue: {
          city: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "venueAddress",
      label: "Venue Address",
      sortBackendName: "address",
      filterDefinition: {
        backendFieldValue: {
          address: "address",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "type",
      label: "Type",
      sortBackendName: "capacity-type",
      filterDefinition: {
        backendFieldValue: {
          "capacity-type": "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "highlights",
      label: "Highlights",
      sortBackendName: "highlights",
      filterDefinition: {
        backendFieldValue: {
          "highlights-min": "min",
          "highlights-max": "max",
        },
      },
    },
    {
      name: "activeEvents",
      label: "Active Events",
      sortBackendName: "events",
      filterDefinition: {
        backendFieldValue: {
          "events-min": "min",
          "events-max": "max",
        },
      },
    },
    {
      name: "media",
      label: "Media",
      sortBackendName: "media",
      filterDefinition: {
        backendFieldValue: {
          "media-min": "min",
          "media-max": "max",
        },
      },
    },
    {
      name: "openHours",
      label: "Open Hours",
      sortBackendName: "opening-hours",
      filterDefinition: {
        backendFieldValue: {
          "opening-hours": "id",
        },
      },
    },
    {
      name: "contactMailPhone",
      label: "Contact Email/Phone",
      sortBackendName: "updated",
      filterDefinition: {
        backendFieldValue: {
          contact: "id",
        },
      },
    },
    {
      name: "claimed",
      label: "Claimed",
      sortBackendName: "claimed",
      filterDefinition: {
        backendFieldValue: {
          claimed: "id",
        },
      },
    },
    {
      name: "lastUpdated",
      label: "Last Updated",
      sortBackendName: "updated",
      filterDefinition: {
        backendFieldValue: {
          "updated-from": "startDateTimeTZ",
          "updated-to": "endDateTimeTZ",
        },
      },
      cellClassName: "w150px",
    },
    {
      name: "openClosedDeleted",
      label: "Open closed deleted",
      sortBackendName: "status",
      filterDefinition: {
        backendFieldValue: {
          status: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    { name: "edit", label: "Edit" },
  ],
};
