import React from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import Input from "./input";
import { mainStateKey } from "../../../constants/constants";
import {
  REMOVE_ITEM_BY_INDEX,
  SET_STATE_VALUE,
  SWAP_ARRAY_ITEM_LEFT,
  SWAP_ARRAY_ITEM_RIGHT,
} from "../../../redux/reducers/common/actionTypes";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { replaceAll } from "../../../lib/functions/common";

const ItemList = ({ name, value, dispatch = () => {} }) => {
  const addSubcategoryItemToList = () => {
    if (value && value.addedData && value.addedData.name && replaceAll(value.addedData.name, " ", "").length) {
      let append = true;
      let toAppend = [value.addedData];

      if (value && value.list && Array.isArray(value.list)) {
        value.list.forEach(item => {
          if (item.name.toLowerCase() === value.addedData.name) {
            append = false;
          }
        });

        toAppend = [...value.list, value.addedData];
      }

      if (append) {
        dispatchToReduxStore(name + ".addedData", SET_STATE_VALUE, null, dispatch);
        dispatchToReduxStore(name + ".list", SET_STATE_VALUE, toAppend, dispatch);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="box-body">
          <div className="form-group">
            <label>Name</label>
            <div className="input-group">
              <Input name={name + ".addedData.name"} type="text" placeholder="Name" className="form-control" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="box-body pad">
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={() => {
                    addSubcategoryItemToList(value);
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="box-body">
          {value &&
            value.list &&
            !!value.list.length &&
            value.list.map((item, index) => {
              return (
                <button type="button" className="btn btn-default btn-remove btn-order" key={index}>
                  <span
                    className={"order-prev "}
                    onClick={() => {
                      dispatchToReduxStore(name + ".list", SWAP_ARRAY_ITEM_LEFT, { index: index }, dispatch);
                    }}
                  >
                    &lt;
                  </span>

                  <span
                    className={"order-next "}
                    onClick={() => {
                      dispatchToReduxStore(name + ".list", SWAP_ARRAY_ITEM_RIGHT, { index: index }, dispatch);
                    }}
                  >
                    &gt;
                  </span>
                  <Input
                    name={name + ".list[" + index + "].name"}
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    showClearInputButton={false}
                  />
                  <span
                    className={"close-me "}
                    onClick={() => {
                      dispatchToReduxStore(name + ".list", REMOVE_ITEM_BY_INDEX, { index: index }, dispatch);
                    }}
                  >
                    X
                  </span>
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
};

ItemList.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(ItemList);
