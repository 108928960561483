import React, { useEffect } from "react";
import moment from "moment";
import { getOr } from "lodash/fp";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import { mainStateKey } from "../../../constants/constants";

const DateRange = ({ name, value, dispatch, className = "" }) => {
  const start = value && value.start ? new Date(value.start + "T00:00:00") : null;
  const end = value && value.end ? new Date(value.end + "T00:00:00") : null;
  value = [start, end];

  useEffect(() => {
    //Hacky way of removing redundant leading zeroes (003 should be 03) and other bugs (infinite rerendering)
    //Bug in @wojtekmaj/react-daterange-picker
    //See https://github.com/wojtekmaj/react-daterange-picker/issues/42 and other issues for more info
    normalizeInputs();
  }, []);

  return (
    <div>
      <DateRangePicker
        showLeadingZeros={false}
        format={"yyyy-MM-dd"}
        className={className}
        value={value}
        calendarIcon={null}
        dayPlaceholder={"dd"}
        monthPlaceholder={"mm"}
        yearPlaceholder={"yyyy"}
        onChange={date => {
          setDate(name, date, dispatch);
        }}
        maxDate={new Date(9999, 1, 1)}
      />
    </div>
  );
};

const setDate = (name, date, dispatch) => {
  const start = date && date[0] ? moment(date[0]).format("YYYY-MM-DD") : null;
  const end = date && date[1] ? moment(date[1]).format("YYYY-MM-DD") : null;
  const startDateTimeT = start ? start + "T00:00:00" : null;
  const endDateTimeT = end ? end + "T23:59:59" : null;
  const startDateTimeTZ = startDateTimeT ? startDateTimeT + "Z" : null;
  const endDateTimeTZ = endDateTimeT ? endDateTimeT + "Z" : null;

  const objectValue = date
    ? {
        start: start,
        end: end,
        startDateTimeTZ: startDateTimeTZ,
        endDateTimeTZ: endDateTimeTZ,
        startDateTimeT: startDateTimeT,
        endDateTimeT: endDateTimeT,
      }
    : null;

  dispatchToReduxStore(name, SET_STATE_VALUE, objectValue, dispatch);
};

//This function should be removed later when @wojtekmaj/react-daterange-picker is fixed
const normalizeInputs = () => {
  //Used to prevent infinite rerendering (bug in date range picker package)
  let focusTime1 = performance.now();
  let focusTime2;

  const inputs = document.querySelectorAll(".react-daterange-picker__inputGroup__input");
  if (inputs) {
    inputs.forEach(input => {
      input.addEventListener("focus", e => {
        focusTime2 = performance.now();

        if (focusTime2 - focusTime1 <= 10) {
          e.target.style.display = "none";
          setTimeout(() => {
            e.target.style.display = "block";
          }, 10);
        }

        focusTime1 = performance.now();
      });

      input.addEventListener("keypress", e => {
        if (input.name === "month" || input.name === "day") {
          //Removes redundant leading zeroes (eg. 003 becomes 03)
          e.target.value = e.target.value.replace(/^0+/, "");
        }
      });
    });
  }
};

DateRange.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  dispatch: PropTypes.func,
  className: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(DateRange);
