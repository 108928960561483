import React from "react";
import { PropTypes } from "prop-types";

const ClearInputButton = ({ onClick = () => {}, type = "input", disabled = false, confirmationMessage = "" }) => {
  const handleClick = e => {
    if (confirmationMessage) {
      if (window.confirm(confirmationMessage)) {
        onClick(e);
      }
    } else {
      onClick(e);
    }
  };

  return getClearInputButton(type, handleClick, disabled);
};

const getClearInputButton = (type, onClick, disabled) => {
  switch (type) {
    case "select":
      return (
        <span className="reset-field" onClick={onClick}>
          [x]
        </span>
      );
    case "input":
    default:
      return (
        <div className="input-group-btn btn-clear-field">
          <button disabled={disabled} type="button" className="btn btn-default fa fa-remove" onClick={onClick} />
        </div>
      );
  }
};

ClearInputButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  confirmationMessage: PropTypes.string,
};

export default ClearInputButton;
