import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { modalPath, mainStateKey } from "../../../../constants/constants";

const BeatportPreview = ({ value }) => {
  const ID = getOr("", "videoID", value);

  return (
    <iframe
      src={`https://embed.beatport.com/?id=${ID}&type=track`}
      width="100%"
      height="162"
      frameBorder="0"
      scrolling="no"
      allow="autoplay"
      title="iframe"
    ></iframe>
  );
};

BeatportPreview.propTypes = {
  value: PropTypes.object,
};

const mapStateToProps = state => {
  return { value: getOr("", `${mainStateKey}.${modalPath}.data`, state) };
};

export default connect(mapStateToProps)(BeatportPreview);
