import React, { useState } from "react";
import { getOr } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormBox from "../../presentational/page/formBox";
import { mainStateKey } from "../../../constants/constants";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import contributionHistoryDataManipulation from "../../../lib/dataManipulation/contributionHistory/contributionHistoryDataManipulation";

const DatesBox = ({ formData, dispatch }) => {
  const dates = getDates(formData);
  const defaultDate = dates && dates[0] ? dates[0] : null;
  const [activeDate, setActiveDate] = useState(defaultDate);
  const title = dates && dates.length ? "Processed at" : "There is no change history for this entity";
  let active = "";

  return (
    <FormBox title={title}>
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <div className="row margin-0">
              {dates &&
                !!dates.length &&
                dates.map((date, index) => {
                  active = date === activeDate || (index === 0 && !activeDate) ? "active" : "";
                  return (
                    <button
                      style={{ marginRight: "5px", marginBottom: "5px" }}
                      key={index}
                      onClick={() => {
                        setDate(setActiveDate, date, dispatch, formData);
                      }}
                      className={"btn btn-default " + active}
                    >
                      {date}
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

const getDates = formData => {
  const dates = [];

  if (formData && Object.keys(formData).length) {
    Object.keys(formData).forEach(item => {
      if (formData[item].processed_at && item !== "activeItem") {
        dates.push(
          moment(formData[item].processed_at)
            .local()
            .format("YYYY-MM-DD HH:mm:ss")
        );
      }
    });
  }

  return dates;
};

const findObject = (formData, date) => {
  let object = {};
  let processedAt = "";

  if (formData && Object.keys(formData).length) {
    Object.keys(formData).forEach(item => {
      processedAt = formData[item]
        ? moment(formData[item].processed_at)
            .local()
            .format("YYYY-MM-DD HH:mm:ss")
        : "";
      if (processedAt === date && item !== "activeItem") {
        object = formData[item];
      }
    });
  }

  return object;
};

const setDate = (setActiveDate, date, dispatch, formData) => {
  const activeItem = findObject(formData, date);
  const data = contributionHistoryDataManipulation.inwardItem(activeItem);

  setActiveDate(date);
  dispatchToReduxStore("formData", SET_STATE_VALUE, { ...formData, activeItem: { ...data } }, dispatch);
};

const mapStateToProps = state => {
  return {
    formData: getOr(null, `${mainStateKey}.formData`, state),
  };
};

DatesBox.propTypes = {
  formData: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(DatesBox);
