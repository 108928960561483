import React from "react";
import VideoModule from "../components/input/modules/videoModule";
import PictureModule from "../components/input/modules/pictureModule";
import TextModule from "../components/input/modules/textModule";

export const helpCenterComponents = [
  {
    component: <TextModule />,
    value: "",
    selectorTitle: "Add Text",
    selectorIcon: "fa fa-font",
    moduleTitle: "Text Module",
    moduleIcon: "fa fa-fw fa-font",
    fieldName: "text",
  },
  {
    component: <PictureModule />,
    value: null,
    selectorTitle: "Add Picture",
    selectorIcon: "fa fa-picture-o",
    moduleTitle: "Picture Module",
    moduleIcon: "fa fa-fw fa-picture-o",
    fieldName: "photo",
  },
  {
    component: <VideoModule />,
    value: null,
    selectorTitle: "Add Video",
    selectorIcon: "fa fa-video-camera",
    moduleTitle: "Video Module",
    moduleIcon: "fa fa-fw fa-video-camera",
    fieldName: "video",
  },
];
