import React, { useState, useRef } from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { userDataPath } from "../../../constants/constants";
import apiManager from "../../../lib/apiManager/apiManager";
import { useOutsideComponentClick } from "../../../lib/functions/customHooks";
import { showAppLoader, hideAppLoader, redirectTo } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

const Header = ({ userName, userSurname, userEmail, setUserIsLoggedIn, dispatch }) => {
  const routerHistory = useHistory();
  const [openUserDropdown, setOpenUserDropdown] = useState(false);
  const ref = useRef(null);
  useOutsideComponentClick(ref, setOpenUserDropdown);

  return (
    <React.Fragment>
      <div className="wrapper" style={{ height: "auto", minheight: "100%" }} />
      <header className="main-header">
        <Link to={"/"} className="logo">
          <span className="logo-mini">
            <b>V</b>e
          </span>
          <span className="logo-lg">
            <b>V</b>editor
          </span>
        </Link>

        <nav className="navbar navbar-static-top">
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li ref={ref} className={"dropdown user user-menu " + (openUserDropdown ? "open" : "")}>
                <button
                  type="button"
                  style={{ cursor: "pointer", color: "white", textDecoration: "none" }}
                  onClick={() => {
                    setOpenUserDropdown(true);
                  }}
                  className="btn btn-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <span className="hidden-xs">{userEmail}</span>
                </button>
                <ul className="dropdown-menu">
                  <li className="user-header">
                    <p>
                      {userName || userSurname ? (
                        <React.Fragment>
                          {userName} {userSurname}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <small>{userEmail}</small>
                        </React.Fragment>
                      )}
                    </p>
                  </li>
                  <li className="user-footer">
                    <div className="center">
                      <button
                        type="button"
                        className="btn btn-default btn-flat"
                        onClick={async () => {
                          showAppLoader();
                          apiManager
                            .signout()
                            .then(() => {
                              dispatch({ name: "editor.fapp", payload: {}, type: SET_STATE_VALUE });
                              localStorage.removeItem("selectedFapp");
                              setUserIsLoggedIn(false);
                              redirectTo(routerHistory, "/");
                              hideAppLoader();
                            })
                            .catch(e => {
                              console.warn(e);
                              hideAppLoader();
                            });
                        }}
                      >
                        SignOut
                      </button>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  userName: PropTypes.string,
  userSurname: PropTypes.string,
  userEmail: PropTypes.string,
  setUserIsLoggedIn: PropTypes.func,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    userName: getOr(null, `${userDataPath}.name`, state),
    userSurname: getOr(null, `${userDataPath}.surname`, state),
    userEmail: getOr(null, `${userDataPath}.email`, state),
  };
};

export default connect(mapStateToProps)(Header);
