import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { openingHoursInputDefinitions } from "../../../constants/constants";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import {
  COPY_OPENING_HOURS,
  SET_OPEN_ON_EVENT_DAY,
  //LOCK_OPENING_HOURS_DAY,
} from "../../../redux/reducers/inputComponents/openingHours/actionTypes";
import { mainStateKey } from "../../../constants/constants";
import Input from "../../input/general/input";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

const OpeningHours = ({
  name,
  value,
  dispatch,
  disabled = false,
  showOpenEventsOnly = true,
  formGroupClassName = "",
}) => {
  return (
    <React.Fragment>
      <div className="clearfix">
        {!disabled && (
          <button
            type="button"
            className="btn btn-default btn-small btn-copy-time"
            onClick={() => {
              copyOpeningHours(value, name, dispatch);
            }}
          >
            COPY
          </button>
        )}
        <div className="time-picker-wrapper pull-righ">
          {openingHoursInputDefinitions.map((item, index) => {
            return (
              <div key={item.day} className={"form-group float-list-item time-picker " + formGroupClassName}>
                <label>{item.name}</label>
                {/*
                <button
                  type="button"
                  className="btn btn-default fa fa-lock btn-closed-for-the-day"
                  onClick={() => {
                    lockOpeningHour(name, dispatch, index);
                  }}
                />
                */}
                {getOpeningHourInput(name, value, index, "start", dispatch, disabled)}
                {getOpeningHourInput(name, value, index, "end", dispatch, disabled)}
              </div>
            );
          })}
        </div>
      </div>
      {showOpenEventsOnly && (
        <div className={"form-group " + formGroupClassName}>
          <div className="checkbox normal" style={{ marginTop: 0 }}>
            <label>
              <input
                checked={value && value.openOnEvent ? value.openOnEvent : false}
                type="checkbox"
                onChange={e => {
                  dispatchToReduxStore(name, SET_OPEN_ON_EVENT_DAY, { checked: e.target.checked }, dispatch);
                }}
                disabled={disabled}
              />
              Open on event day
            </label>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const copyOpeningHours = (value, name, dispatch) => {
  const start = getOr(null, "hours[0].start", value);
  const end = getOr(null, "hours[0].end", value);
  dispatchToReduxStore(name, COPY_OPENING_HOURS, { start: start, end: end }, dispatch);
};

/*
const lockOpeningHour = (name, dispatch, index) => {
  dispatchToReduxStore(name, LOCK_OPENING_HOURS_DAY, { index: index }, dispatch);
};
*/

const getOpeningHourInput = (name, value, index, timeType, dispatch, disabled) => {
  name = `${name}.hours[${index}][${timeType}]`;

  //Used for checking if  opening hour day has filled in start or end but missing either start or end fields
  const checkForTimeType = timeType === "start" ? "end" : "start";
  const hasError =
    value && value.hours && value.hours[index] && value.hours[index][checkForTimeType] && !value.hours[index][timeType]
      ? "has-error"
      : "";

  return (
    <div className={"input-group " + hasError}>
      <Input
        type="time"
        name={name}
        className="form-control"
        placeholder="HH:MM"
        disabled={disabled ? true : value && value.hours && value.hours[index] ? value.hours[index].disabled : false}
        debounceTime={0}
        onFocus={timeValue => {
          if (!timeValue) {
            dispatchToReduxStore(name, SET_STATE_VALUE, "00:00", dispatch);
          }
        }}
        showClearInputButton={!disabled}
      />
    </div>
  );
};

OpeningHours.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  dispatch: PropTypes.func,
  disabled: PropTypes.bool,
  showOpenEventsOnly: PropTypes.bool,
  formGroupClassName: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(OpeningHours);
