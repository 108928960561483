import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { mainStateKey } from "../../../constants/constants";
import UploadImageButton from "../../input/media/uploadImageButton";

const Media = ({ connectedTo, mediaObject = {} }) => {
  //1 impressions
  //2 after video
  //3 trailer video
  //4 official gallery
  //5 regular video
  const impressions = getOr([], "[1]", mediaObject);
  const afterVideos = getOr([], "[2]", mediaObject);
  const trailerVideos = getOr([], "[3]", mediaObject);
  const officialGallery = getOr([], "[4]", mediaObject);
  const regularVideos = getOr([], "[5]", mediaObject);

  return (
    <React.Fragment>
      <div className="video-section">
        <p>
          <b>Impressions</b>
        </p>
        {!impressions.length && "/"}
        {impressions.map((item, index) => {
          return (
            <UploadImageButton showClearButton={false} disabled={true} key={index} name={`${connectedTo}.1.${index}`} />
          );
        })}
      </div>
      <div className="video-section">
        <p>
          <b>After Videos</b>
        </p>
        {!afterVideos.length && "/"}
        {afterVideos.map((item, index) => {
          return (
            <a href={item} rel="noopener noreferrer" target="_blank" key={index}>
              {item}
            </a>
          );
        })}
      </div>
      <div className="video-section">
        <p>
          <b>Trailer Videos</b>
        </p>
        {!trailerVideos.length && "/"}
        {trailerVideos.map((item, index) => {
          return (
            <a href={item} rel="noopener noreferrer" target="_blank" key={index}>
              {item}
            </a>
          );
        })}
      </div>
      <div className="video-section">
        <p>
          <b>Official Gallery</b>
        </p>
        {!officialGallery.length && "/"}
        {officialGallery.map((item, index) => {
          return (
            <UploadImageButton showClearButton={false} disabled={true} key={index} name={`${connectedTo}.4.${index}`} />
          );
        })}
      </div>
      <div className="video-section">
        <p>
          <b>Regular Videos</b>
        </p>
        {!regularVideos.length && "/"}
        {regularVideos.map((item, index) => {
          return (
            <a href={item} rel="noopener noreferrer" target="_blank" key={index}>
              {item}
            </a>
          );
        })}
      </div>
    </React.Fragment>
  );
};

Media.propTypes = {
  connectedTo: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { mediaObject: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state) };
};

export default connect(mapStateToProps)(Media);
