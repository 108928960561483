import React from "react";
import { PropTypes } from "prop-types";

const Footer = ({
  showClearButton = true,
  showDeleteButton = false,
  showNextButton = false,
  onSave = () => {},
  onDelete = () => {},
  onSaveAndClear = () => {},
  onSaveAndNext = () => {},
  children,
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="box box-default">
          <div className="box-footer">
            <button
              onKeyPress={e => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              type="button"
              className="btn btn-default bg-black margin pull-right"
              onClick={e => {
                onSave(e);
              }}
            >
              Save
            </button>
            {showClearButton && (
              <button
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                type="button"
                onClick={e => {
                  onSaveAndClear(e);
                }}
                className="btn btn-default bg-black margin pull-right"
              >
                Save & Clear
              </button>
            )}
            {showNextButton && (
              <button
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                onClick={e => {
                  onSaveAndNext(e);
                }}
                type="button"
                className="btn btn-default bg-black margin pull-right"
              >
                Save &amp; Next
              </button>
            )}
            {showDeleteButton && (
              <button
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                type="button"
                className="btn btn-danger margin"
                onClick={e => {
                  onDelete(e);
                }}
              >
                Delete
              </button>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  showClearButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  nextButtonLink: PropTypes.string,
  nextButtonLinkAs: PropTypes.string,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onSaveAndClear: PropTypes.func,
  onSaveAndNext: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Footer;
