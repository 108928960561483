import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import Select from "../selectors/select";
import { findObjectByValue, makeid, objectExistsInArray } from "../../../lib/functions/common";
import { articleTagsFirstTier, articleTagsSecondTier } from "../../../constants/article";
import {
  APPEND_ARTICLE_TAG,
  SET_ARTICLE_TAG_SELECTOR,
  SET_ARTICLE_TAG_TIER2_ITEMS,
  SET_SELECTED_TAG_TIER1,
  SET_SELECTED_TAG_TIER2,
  SET_SELECTED_TAG_ENTITY,
  SET_ARTICLE_TAG_NAME,
  REMOVE_ARTICLE_TAG,
} from "../../../redux/reducers/inputComponents/articleTags/actionTypes";

import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { mainStateKey, enumsPath } from "../../../constants/constants";

const ArticleTags = ({ value, name, dispatch, genres, countries, featuredCities, subgenres }) => {
  const selectedTier1 = value && value.selectedTier1 ? value && value.selectedTier1 : null;
  const selectedTier2 = value && value.selectedTier2 ? value && value.selectedTier2 : null;

  useEffect(() => {
    const selectorData = { countries: countries, genres: genres, subgenres: subgenres, featuredCities: featuredCities };
    const onSelect = entityData => {
      dispatchToReduxStore(
        name,
        SET_SELECTED_TAG_ENTITY,
        {
          selectedEntity: { id: entityData && entityData.id ? entityData.id : entityData.code, name: entityData.name },
        },
        dispatch
      );
      dispatchToReduxStore(name, SET_ARTICLE_TAG_NAME, { tagName: entityData.name }, dispatch);
    };

    setSelector(selectedTier1, selectedTier2, onSelect, dispatch, selectorData, name);
    dispatchToReduxStore(name, SET_ARTICLE_TAG_NAME, { tagName: "" }, dispatch);
  }, [selectedTier1, selectedTier2, name, dispatch, countries, genres, featuredCities, subgenres]);

  const appendTag = () => {
    const page = value.selectedTier1 && value.selectedTier1.name ? value.selectedTier1.name : null;
    const section = value.selectedTier2 && value.selectedTier2.name ? value.selectedTier2.name : null;
    const title = (page ? page.toLowerCase() : "") + " " + (section ? section.toLowerCase() : "");

    let tagObject = {
      page: page,
      section: section,
      title: title,
      text: value.tagName,
      id: value.selectedEntity && value.selectedEntity.id ? value.selectedEntity.id : null,
    };

    let append = false;

    if (value.selector) {
      if (value.selectedEntity) {
        append = true;
      }
    } else if (value.selectedTier2) {
      append = true;
    } else if (value.selectedTier1 && !value.tier2Items.length) {
      append = true;
    }

    if (tagObject && !objectExistsInArray(value.tags, tagObject, ["text"]) && append) {
      dispatchToReduxStore(name, APPEND_ARTICLE_TAG, { tag: tagObject }, dispatch);
      dispatchToReduxStore(name, SET_ARTICLE_TAG_NAME, { tagName: "" }, dispatch);
      dispatchToReduxStore(name, SET_SELECTED_TAG_ENTITY, { selectedEntity: null }, dispatch);
    }
  };

  const tier2disabled = value && value.tier2Items && value.tier2Items.length ? false : true;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Select
                  placeholder="Select Tier 1"
                  data={articleTagsFirstTier}
                  displayKey="name"
                  idKey="id"
                  name={`${name}.selectedTier1`}
                  autocomplete={false}
                  showClearButton={false}
                  onChange={el => {
                    let items = findObjectByValue(articleTagsSecondTier, "id", el.id);
                    items = items && items.items ? items.items : [];

                    dispatchToReduxStore(name, SET_ARTICLE_TAG_SELECTOR, { selector: null }, dispatch);
                    dispatchToReduxStore(name, SET_ARTICLE_TAG_TIER2_ITEMS, { items: items }, dispatch);
                    dispatchToReduxStore(name, SET_SELECTED_TAG_TIER1, { selectedTier1: el }, dispatch);
                    dispatchToReduxStore(name, SET_SELECTED_TAG_TIER2, { selectedTier2: null }, dispatch);

                    if (items.length) {
                      dispatchToReduxStore(name, SET_SELECTED_TAG_TIER2, { selectedTier2: items[0] }, dispatch);
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Select
                  disabled={tier2disabled}
                  placeholder="Select Tier 2"
                  data={value && value.tier2Items ? value.tier2Items : []}
                  displayKey="name"
                  idKey="id"
                  name={`${name}.selectedTier2`}
                  autocomplete={false}
                  showClearButton={false}
                  onChange={el => {
                    dispatchToReduxStore(name, SET_SELECTED_TAG_TIER2, { selectedTier2: el }, dispatch);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <div className="input-group relative">
                  <div className="input-group">
                    {value && value.selector ? (
                      value.selector
                    ) : (
                      <React.Fragment>
                        <label>Search</label>
                        <input disabled className="form-control" />
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <div className="input-group">
                  <label>Tag name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tag Custom Name"
                    value={value && value.tagName ? value.tagName : ""}
                    onChange={e => {
                      dispatchToReduxStore(name, SET_ARTICLE_TAG_NAME, { tagName: e.target.value }, dispatch);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <button type="button" className="btn btn-default pull-right" onClick={appendTag}>
                Add tag
              </button>
            </div>
          </div>

          <div className="form-group">
            <label>Tags List</label>
            <div className="clearfix tags-group autoheight">
              {value &&
                value.tags &&
                value.tags.map((tag, index) => generateTagButton(tag.title, tag.text, dispatch, index, name))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const setSelector = (selectedTier1, selectedTier2, onSelect, dispatch, selectorData, name) => {
  const selectorIn = selectedTier1 && selectedTier1.selector ? selectedTier1 : selectedTier2;
  let selector = selectorIn && selectorIn.selector ? selectorIn.selector : null;

  if (selector) {
    selector = React.cloneElement(selector, { onChange: onSelect, data: selectorData[selectedTier1.data] });
  } else {
    dispatchToReduxStore(name, SET_SELECTED_TAG_ENTITY, { selectedEntity: null }, dispatch);
  }

  dispatchToReduxStore(name, SET_ARTICLE_TAG_SELECTOR, { selector: selector }, dispatch);
};

const generateTagButton = (title, text, dispatch, index, name) => {
  const id = makeid(15);

  return (
    <button
      key={id}
      type="button"
      className="btn btn-default btn-remove"
      onClick={() => {
        dispatchToReduxStore(name, REMOVE_ARTICLE_TAG, { index: index }, dispatch);
      }}
    >
      {title}
      <span>{text}</span>
    </button>
  );
};

ArticleTags.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  dispatch: PropTypes.func,
  genres: PropTypes.array,
  countries: PropTypes.array,
  featuredCities: PropTypes.array,
  subgenres: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getOr(null, `${mainStateKey}.${ownProps.name}`, state),
    subgenres: getOr(null, `${enumsPath}.subgenres`, state),
    genres: getOr(null, `${enumsPath}.genres`, state),
    countries: getOr(null, `${enumsPath}.countries`, state),
    featuredCities: getOr(null, `${enumsPath}.featuredCities`, state),
  };
};

export default connect(mapStateToProps)(ArticleTags);
