import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { modalPath, mainStateKey } from "../../../../constants/constants";

const SpotifyPreview = ({ value }) => {
  const ID = getOr("", "videoID", value);

  return (
    <iframe
      src={`https://open.spotify.com/embed/track/${ID}?utm_source=generato&`}
      width="100%"
      id="myFrame"
      height="380"
      frameBorder="0"
      title="iframe"
      allowFullScreen
      allow="autoplay"
    />
  );
};

SpotifyPreview.propTypes = {
  value: PropTypes.object,
};

const mapStateToProps = state => {
  return { value: getOr("", `${mainStateKey}.${modalPath}.data`, state) };
};

export default connect(mapStateToProps)(SpotifyPreview);
