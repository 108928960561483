import { getOr } from "lodash/fp";
import { parseImage } from "../../../functions/common";

const helpCenterCategoryDataManipulation = function helpCenterCategoryDataManipulation() {
  const getOutwardHelpCenterItem = data => {
    let parsedData = {};
    let order = getOr(null, "order", data);
    order = order ? parseFloat(order) : order;

    if (data) {
      parsedData = {
        name: getOr(null, "name", data),
        image: data.image ? { ...parseImage(data.image) } : null,
        order,
        subcategories: parseOutwardSubcategories(data.subcategoryData),
      };
    }

    return parsedData;
  };

  const getInwardDatatable = data => {
    let parsedData = [];

    if (data && data.length && Array.isArray(data)) {
      data.forEach(item => {
        parsedData.push([getOr("", "name", item), getOr([], "subcategories", item), getOr("", "order", item), item.id]);
      });
    }

    return parsedData;
  };

  const getInwardItem = data => {
    return {
      name: getOr(false, "name", data),
      image: getOr("", "image.url", data),
      order: getOr(null, "order", data),
      subcategoryData: { list: getOr([], "subcategories", data) },
    };
  };

  return {
    outward: getOutwardHelpCenterItem,
    inwardDatatable: getInwardDatatable,
    inwardItem: getInwardItem,
  };
};

const parseOutwardSubcategories = subcategoryData => {
  let returnedData = [];
  let obj = {};

  if (subcategoryData && subcategoryData.list) {
    returnedData = subcategoryData.list.map((item, index) => {
      obj = { name: item.name, order: index };
      return item.id ? { ...obj, id: item.id } : obj;
    });
  }

  return returnedData;
};

export default helpCenterCategoryDataManipulation();
