import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";
import EntitySelector from "../../../input/selectors/entitySelector";
import VenueButton from "../../../presentational/general/venueButton";

const VenueBox = ({ dispatch }) => {
  return (
    <FormBox title="Venue">
      <div className="box-body">
        <div className="col-md-12">
          <div className="row">
            <div className="form-group">
              <EntitySelector
                label="Venue Search"
                placeholder="Search venue..."
                entityType="venue"
                name="formData.venueSearchText"
                onChange={place => {
                  if (place.id) {
                    dispatchToReduxStore("formData.venue", SET_STATE_VALUE, place, dispatch);
                  }
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <VenueButton connectedTo="formData.venue" />
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

VenueBox.propTypes = {
  value: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(null)(VenueBox);
