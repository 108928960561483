import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { addVenueLinks } from "../../../../constants/linkDefinitions";
import Links from "../../../input/links/links";
import FormBox from "../../../presentational/page/formBox";
import { connect } from "react-redux";
import { mainStateKey } from "../../../../constants/constants";
import SearchMissingLinkButton from "../../../presentational/buttons/searchMissingLinkButton";
import OpenAllLinksButton from "../../../presentational/buttons/openAllLinksButton";

const LinksBox = ({ venueName, showOpenAllLinksButton = true }) => {
  const mainLinks = addVenueLinks.mainLinks;
  const headerButtons = [
    <SearchMissingLinkButton
      key={1}
      connectedTo={"formData.links"}
      allEntityLinks={addVenueLinks.mainLinks}
      entityName={venueName}
    />,
  ];

  if (showOpenAllLinksButton) {
    headerButtons.push(<OpenAllLinksButton key={2} connectedTo={"formData.links"} />);
  }

  return (
    <FormBox title="Links" headerContent={headerButtons}>
      <div className="box-body">
        <div className="form-horizontal">
          <Links
            entityName={venueName}
            linkInputData={mainLinks}
            name="formData.links"
            oldLinksName="formData.oldLinks"
          />
        </div>
      </div>
    </FormBox>
  );
};

LinksBox.propTypes = {
  venueName: PropTypes.string,
  showOldLinks: PropTypes.bool,
  showOpenAllLinksButton: PropTypes.bool,
};

const mapStateToProps = state => {
  return { venueName: getOr(null, `${mainStateKey}.formData.name.name`, state) };
};

export default connect(mapStateToProps)(LinksBox);
