const linkValidationRules = {
  spotify: "^https?://open\\.spotify\\.com/",
  bandcamp: "^https?://.+\\.bandcamp\\.com",
  soundcloud: "^https?://soundcloud\\.com/",
  deezer: "^https?://www\\.deezer\\.com/",
  itunes: "^https?://(itunes|music)\\.apple\\.com/",
  shazam: "^https?://www\\.shazam\\.com/",

  facebook: "^https?://www\\.facebook\\.com/",
  twitter: "^https?://twitter\\.com/",
  instagram: "^https?://www\\.instagram\\.com/",
  tiktok: "^https?://www\\.tiktok\\.com/",
  youtube: "^https?://www\\.youtube\\.com/(channel/|watch\\?v=|user)",
  youtube_vevo: "^https?://www\\.youtube\\.com/",
  airplay: "^https?://\\onlineradiobox.com\\/",

  songkick: "www\\.songkick\\.com/",
  beatport: "^https?://www\\.beatport\\.com/(artist|label|track)/",
  mixcloud: "^https?://www\\.mixcloud\\.com/",
  bandsintown: "^https?://www\\.bandsintown\\.com",

  gigatools: "^https?://gigs\\.gigatools\\.com/",
  google_pm: "^https?://play\\.google\\.com/",
  tumblr: "^https?://.+\\.tumblr\\.com",
  ticketmaster: "^https?://www\\.ticketmaster\\.",
  tidal: "^https?://tidal\\.com/browse/artist/",
  napster: "^https?://[a-z]{2}\\.napster\\.com/",
  amazon_music: "^https?://music\\.amazon\\.com/",
};
export default linkValidationRules;
