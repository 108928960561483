import React from "react";
import { useResetPageState } from "../../../../lib/functions/customHooks";
import ArticleListBox from "./articlesListBox";

function EditArticle() {
  useResetPageState();

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit Article</h1>
      </section>
      <section className="content">
        <ArticleListBox />
      </section>
    </React.Fragment>
  );
}

export default EditArticle;
