import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import ClearInputButton from "../../input/general/clearInputButton";
import { mainStateKey } from "../../../constants/constants";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import RedirectButton from "./redirectButton";

const Input = ({
  value,
  name,
  onChange = () => {},
  placeholder,
  type = "text",
  dispatch,
  className,
  showClearInputButton = true,
  maxLength,
  disabled = false,
  children,
  debounceTime = 500,
  showRedirectButton = false,
  max,
  style = {},
  onRedirectButtonClick = () => {},
  onClearButtonClick,
  onFocus = () => {},
}) => {
  //Local state used for debouncing
  const [localValue, setLocalValue] = useState(value);

  const sendValueChange = useCallback(
    debounce(val => {
      if (type === "number") {
        val = parseInt(val);
      }
      dispatchToReduxStore(name, SET_STATE_VALUE, val, dispatch);
    }, debounceTime),
    []
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <React.Fragment>
      <input
        style={style}
        disabled={disabled}
        maxLength={maxLength}
        checked={value ? true : false}
        value={localValue || localValue === 0 ? localValue : ""}
        className={className}
        type={type}
        max={max}
        placeholder={placeholder}
        onChange={e => {
          onChangeHandler(name, e, type, dispatch, setLocalValue, sendValueChange);
          onChange(e.target.value);
        }}
        onFocus={() => {
          onFocus(localValue);
        }}
        onKeyDown={e => {
          if (type === "number" && e.key === "e") {
            e.preventDefault();
          }
        }}
      />
      {children}
      {showRedirectButton && (
        <RedirectButton
          onClick={() => {
            onRedirectButtonClick(localValue);
          }}
        />
      )}
      {showClearInputButton && (
        <ClearInputButton
          onClick={() => {
            if (onClearButtonClick) {
              onClearButtonClick();
            } else {
              setLocalValue("");
              sendValueChange("");
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

const onChangeHandler = (name, event, type, dispatch, setLocalValue, sendValueChange) => {
  switch (type) {
    case "checkbox":
      if (!event.target.value) {
        dispatchToReduxStore(name, SET_STATE_VALUE, true, dispatch);
      } else {
        dispatchToReduxStore(name, SET_STATE_VALUE, false, dispatch);
      }
      break;
    default:
      setLocalValue(event.target.value);
      sendValueChange(event.target.value);
      break;
  }
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  dispatch: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  showClearInputButton: PropTypes.bool,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  debounceTime: PropTypes.number,
  showRedirectButton: PropTypes.bool,
  max: PropTypes.string,
  onRedirectButtonClick: PropTypes.func,
  onFocus: PropTypes.func,
  onClearButtonClick: PropTypes.func,
  style: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  const defaultValue = {};

  if (ownProps.name) {
    return { value: getOr("", `${mainStateKey}.${ownProps.name}`, state) };
  } else {
    return { defaultValue };
  }
};

export default connect(mapStateToProps)(Input);
