import React from "react";
import FormBox from "../../../../presentational/page/formBox";
import CmsContent from "../../../../input/general/cmsContent";

function ContentBox() {
  return (
    <FormBox title="Content">
      <div className="box-body">
        <CmsContent name="formData.content" />
      </div>
    </FormBox>
  );
}

export default ContentBox;
