import React, { useState, useEffect, useMemo } from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router";
import { buildUrlFromTableParams } from "../../../lib/functions/common";
import { mainStateKey, defaultListingTableRowNumber } from "../../../constants/constants";
import FiltersBox from "./filtersBox";
import {
  setTableData,
  resetPageOnFilterChange,
  getColumnDefinitions,
  setEditButtonCustomRender,
} from "../../../lib/functions/tableFunctions";
import { contributionHistoryTableParametersData } from "../../../constants/tableParametersData/contributionHistoryTableParametersData";
import { usePrevious, useResetPageState } from "../../../lib/functions/customHooks";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import contributionHistoryDataManipulation from "../../../lib/dataManipulation/contributionHistory/contributionHistoryDataManipulation";
import ListingTable from "../../presentational/table/listingTable";

const ContributionHistoryListPage = ({ filters, sortData, row, page, dispatch }) => {
  useResetPageState();

  const routerHistory = useHistory();
  const statePath = contributionHistoryTableParametersData.statePath;
  const sortPath = contributionHistoryTableParametersData.sortPath;
  const filtersPath = contributionHistoryTableParametersData.filtersPath;
  const allColumns = contributionHistoryTableParametersData.columns;
  const previousFilters = usePrevious(filters);
  const [tableRowData, setTableRowData] = useState([]);
  const [tablePaginationData, setTablePaginationData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTableData(
      setTableRowData,
      setTablePaginationData,
      setLoading,
      buildUrlFromTableParams(filters, sortData, contributionHistoryTableParametersData, row, page),
      contributionHistoryDataManipulation,
      "contributionHistory"
    );
  }, [filters, sortData, row, page, dispatch]);

  resetPageOnFilterChange(previousFilters, filters, statePath, dispatch);

  let columns = useMemo(
    () => setColumnDefinitions(allColumns, sortPath, dispatch, statePath, routerHistory, tableRowData, setTableRowData),
    [allColumns, dispatch, routerHistory, sortPath, statePath, tableRowData]
  );

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>
          Contribution History Log &nbsp;
          <button
            className="btn btn-default btn-small"
            onClick={() => {
              dispatchToReduxStore(filtersPath, SET_STATE_VALUE, null, dispatch);
            }}
          >
            Clear filters
          </button>
        </h1>
      </section>
      <section className="content">
        <FiltersBox />
        <div className={loading ? "loading" : ""}>
          <div className="row">
            <div className="col-md-12">
              <ListingTable
                statePath={statePath}
                sortPath={sortPath}
                tablePaginationData={tablePaginationData}
                columnDefinitions={columns}
                tableRowData={tableRowData}
                columnsConstant={allColumns}
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const setColumnDefinitions = (allColumns, sortPath, dispatch, statePath, routerHistory) => {
  const columns = getColumnDefinitions(allColumns, sortPath);
  setEditButtonCustomRender(columns, dispatch, statePath, routerHistory, "/review/contribution/");

  return columns;
};

ContributionHistoryListPage.propTypes = {
  filters: PropTypes.object,
  row: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sortData: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    filters: getOr(null, `${mainStateKey}.${contributionHistoryTableParametersData.filtersPath}`, state),
    sortData: getOr(null, `${mainStateKey}.${contributionHistoryTableParametersData.sortPath}`, state),
    row: getOr(
      defaultListingTableRowNumber,
      `${mainStateKey}.${contributionHistoryTableParametersData.statePath}.rows`,
      state
    ),
    page: getOr(0, `${mainStateKey}.${contributionHistoryTableParametersData.statePath}.page`, state),
  };
};

export default connect(mapStateToProps)(ContributionHistoryListPage);
