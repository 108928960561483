import React, { useEffect } from "react";
import Footer from "../../../presentational/page/footer";
import GeneralInfoBox from "../shared/generalInfoBox";
import SubgenresBox from "../shared/subgenresBox";
import artistDataManipulation from "../../../../lib/dataManipulation/artist/artistDataManipulation";
import { handleAddFormSubmit, setFormData } from "../../../../lib/functions/componentFunctions";
import LinksBox from "../shared/linksBox";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const AddArtist = () => {
  useResetPageState();

  useEffect(() => {
    setFormData({ status: { id: 0, name: "Active" } });
  }, []);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add Artist</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <GeneralInfoBox />
            <div className="row">
              <div className="col-md-12">
                <SubgenresBox />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <LinksBox showOpenAllLinksButton={false} />
          </div>
        </div>
        <Footer
          onSave={() => handleAddFormSubmit(false, artistDataManipulation, "artist")}
          onSaveAndClear={() => {
            handleAddFormSubmit(true, artistDataManipulation, "artist");
          }}
        />
      </section>
    </React.Fragment>
  );
};

export default AddArtist;
