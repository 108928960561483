export const editTrackTableParametersData = {
  statePath: "tables.editTrackTableParams",
  filtersPath: "tables.editTrackTableParams.filters",
  sortPath: "tables.editTrackTableParams.sort",

  columns: [
    {
      name: "trackName",
      label: "Track name",
      sortBackendName: "name",
      filterDefinition: {
        backendFieldValue: {
          name: "name",
        },
      },
    },
    {
      name: "ISRC",
      label: "ISRC",
      filterDefinition: {
        backendFieldValue: {
          ISRC: "ISRC",
        },
      },
    },
    {
      name: "releaseDate",
      label: "Release date",
      sortBackendName: "release_date",
      filterDefinition: {
        backendFieldValue: {
          "release-date-from": "startDateTimeTZ",
          "release-date-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "status",
      label: "Status",
      sortBackendName: "status",
      filterDefinition: {
        backendFieldValue: {
          status: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "label",
      label: "Label",
      filterDefinition: {
        backendFieldValue: {
          label: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "genre",
      label: "Genre",
      filterDefinition: {
        backendFieldValue: {
          genre: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "subgenre",
      label: "Subgenre",
      filterDefinition: {
        backendFieldValue: {
          subgenre: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "artists",
      label: "Artists",
      filterDefinition: {
        backendFieldValue: {
          artist: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    { name: "rank", label: "Rank", sortBackendName: "rank" },
    {
      name: "score",
      label: "Viberate score",
      sortBackendName: "viberate_score",
    },
    {
      name: "streams",
      label: "Spotify streams",
      sortBackendName: "spotify_streams",
    },
    {
      name: "dateAdded",
      label: "Date Added",
      sortBackendName: "created",
      filterDefinition: {
        backendFieldValue: {
          "created-from": "startDateTimeTZ",
          "created-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "lastChange",
      label: "Last Change",
      sortBackendName: "updated",
      filterDefinition: {
        backendFieldValue: {
          "updated-from": "startDateTimeTZ",
          "updated-to": "endDateTimeTZ",
        },
      },
    },
    {
      hideInTable: true,
      name: "spotifyID",
      filterDefinition: {
        backendFieldValue: {
          "spotify-id": "spotifyID",
        },
      },
    },
    { name: "edit", label: "Edit" },
  ],
};
