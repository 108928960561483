export const SET_ENUMS = "SET_ENUMS";
export const SET_EDITOR_LOADING = "SET_EDITOR_LOADING";
export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const SET_FORM_SUCCESS = "SET_FORM_SUCCESS";
export const SET_FORM_FAIL = "SET_FORM_FAIL";
export const SET_FORM_SHOW_ERROR_WINDOW = "SET_FORM_SHOW_ERROR_WINDOW";
export const SET_FORM_SHOW_ERROR_REASONS = "SET_FORM_SHOW_ERROR_REASONS";
export const SET_FORM_ERROR_REASONS = "SET_FORM_ERROR_REASONS";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_FORM_ERROR_TITLE = "SET_FORM_ERROR_TITLE";
export const SET_FORM_LOCKED = "SET_FORM_LOCKED";
export const SET_SIDEBAR_ASK_FOR_CONFIRMATION = "SET_SIDEBAR_ASK_FOR_CONFIRMATION";
