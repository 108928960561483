import React, { useState, useEffect, useMemo } from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router";
import { buildUrlFromTableParams, findObjectIndexByValue } from "../../../../lib/functions/common";
import { mainStateKey, defaultListingTableRowNumber } from "../../../../constants/constants";
import FiltersBox from "./filtersBox";
import {
  setTableData,
  resetPageOnFilterChange,
  getColumnDefinitions,
  setEditButtonCustomRender,
  setDeleteButtonCustomRender,
} from "../../../../lib/functions/tableFunctions";
import { editEventTableParametersData } from "../../../../constants/tableParametersData/editEventTableParametersData";
import { usePrevious, useResetPageState } from "../../../../lib/functions/customHooks";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { dispatchToReduxStore, handleDeleteEntity } from "../../../../lib/functions/componentFunctions";
import eventDataManipulation from "../../../../lib/dataManipulation/event/eventDataManipulation";
import ListingTable from "../../../presentational/table/listingTable";

const EditEventListPage = ({ filters, sortData, row, page, dispatch }) => {
  useResetPageState();

  const routerHistory = useHistory();
  const statePath = editEventTableParametersData.statePath;
  const sortPath = editEventTableParametersData.sortPath;
  const filtersPath = editEventTableParametersData.filtersPath;
  const allColumns = editEventTableParametersData.columns;
  const previousFilters = usePrevious(filters);
  const [tableRowData, setTableRowData] = useState([]);
  const [tablePaginationData, setTablePaginationData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTableData(
      setTableRowData,
      setTablePaginationData,
      setLoading,
      buildUrlFromTableParams(filters, sortData, editEventTableParametersData, row, page),
      eventDataManipulation,
      "event"
    );
  }, [filters, sortData, row, page, dispatch]);

  resetPageOnFilterChange(previousFilters, filters, statePath, dispatch);

  let columns = useMemo(
    () => setColumnDefinitions(allColumns, sortPath, dispatch, statePath, routerHistory, tableRowData, setTableRowData),
    [allColumns, dispatch, routerHistory, sortPath, statePath, tableRowData]
  );

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>
          Edit Event &nbsp;
          <button
            className="btn btn-default btn-small"
            onClick={() => {
              dispatchToReduxStore(filtersPath, SET_STATE_VALUE, null, dispatch);
            }}
          >
            Clear filters
          </button>
        </h1>
      </section>
      <section className="content">
        <FiltersBox />
        <div className={loading ? "loading" : ""}>
          <div className="row">
            <div className="col-md-12">
              <ListingTable
                statePath={statePath}
                sortPath={sortPath}
                tablePaginationData={tablePaginationData}
                columnDefinitions={columns}
                tableRowData={tableRowData}
                columnsConstant={allColumns}
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const onDeleteButtonClick = async (eventId, tableRowData, setTableRowData, columns, rowIndex) => {
  if (window.confirm("Are you sure you want to delete this event?")) {
    const statusIndex = findObjectIndexByValue(columns, "name", "status");
    if (statusIndex && (rowIndex === 0 || rowIndex)) {
      const deleteSuccess = await handleDeleteEntity(eventId, "event");

      if (deleteSuccess) {
        const data = [...tableRowData];
        data[rowIndex][statusIndex] = "Deleted";
        setTableRowData(data);
      }
    }
  }
};

const setColumnDefinitions = (
  allColumns,
  sortPath,
  dispatch,
  statePath,
  routerHistory,
  tableRowData,
  setTableRowData
) => {
  const columns = getColumnDefinitions(allColumns, sortPath);
  setEditButtonCustomRender(columns, dispatch, statePath, routerHistory, "/edit/event/");
  setDeleteButtonCustomRender(columns, (eventId, rowIndex) => {
    onDeleteButtonClick(eventId, tableRowData, setTableRowData, columns, rowIndex);
  });

  return columns;
};

EditEventListPage.propTypes = {
  filters: PropTypes.object,
  row: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sortData: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    filters: getOr(null, `${mainStateKey}.${editEventTableParametersData.filtersPath}`, state),
    sortData: getOr(null, `${mainStateKey}.${editEventTableParametersData.sortPath}`, state),
    row: getOr(defaultListingTableRowNumber, `${mainStateKey}.${editEventTableParametersData.statePath}.rows`, state),
    page: getOr(0, `${mainStateKey}.${editEventTableParametersData.statePath}.page`, state),
  };
};

export default connect(mapStateToProps)(EditEventListPage);
