import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import {
  SWAP_ARRAY_ITEM_LEFT,
  SWAP_ARRAY_ITEM_RIGHT,
  REMOVE_ITEM_BY_INDEX,
} from "../../../redux/reducers/common/actionTypes";

function CmsModuleBox({ title = "", icon = "", children, index, dispatch, name }) {
  const module = React.cloneElement(children, {
    index: index,
    name: name,
  });

  const moveUp = () => {
    dispatchToReduxStore("formData.content", SWAP_ARRAY_ITEM_LEFT, { index: index }, dispatch);
  };

  const moveDown = () => {
    dispatchToReduxStore("formData.content", SWAP_ARRAY_ITEM_RIGHT, { index: index }, dispatch);
  };

  const remove = () => {
    dispatchToReduxStore("formData.content", REMOVE_ITEM_BY_INDEX, { index: index }, dispatch);
  };

  return (
    <div className="box box-module">
      <div className="box-header">
        <h3 className="box-title">
          <i className={icon} />
          {title}
        </h3>
        <div className="btn-group pull-right">
          <button type="button" className="btn btn-default fa fa-arrow-down" title="Move Down" onClick={moveDown} />
          <button type="button" className="btn btn-default fa fa-arrow-up" title="Move Up" onClick={moveUp} />
          <button type="button" className="btn btn-default fa fa-remove" title="Remove" onClick={remove} />
        </div>
      </div>
      {module}
    </div>
  );
}

CmsModuleBox.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  index: PropTypes.number,
  dispatch: PropTypes.func,
  children: PropTypes.object,
  name: PropTypes.string,
};

export default connect()(CmsModuleBox);
