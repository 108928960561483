import sha256 from "js-sha256";
import { permissionStrings } from "../../constants/permissionStrings";
//import { SET_STATE_VALUE } from "../../redux/reducers/common/actionTypes";
import { SET_USER_DATA } from "../../redux/reducers/general/actionTypes";
import apiManager from "../apiManager/apiManager";

export const getLoggedInUserData = (dispatch, setUserIsPermitted, setUserIsLoggedIn) => {
  Promise.all([apiManager.getUser() /*, apiManager.getUserAllowedFapps()*/])
    .then(values => {
      const user = values[0];
      //const allowedFapps = values[1];

      //setFestivalAppFromLocalStorage(allowedFapps, dispatch);
      setUserIsLoggedIn(true);
      user.hashedEmail = sha256(user.email);
      dispatch({ type: SET_USER_DATA, payload: { ...user /*allowedFapps*/ } });
      checkUserPermission(user.permissions, setUserIsPermitted, dispatch);
    })
    .catch(e => {
      console.warn(e);
      setUserIsPermitted(false);
      setUserIsLoggedIn(false);
    });
};

const checkUserPermission = async (permissions, setUserIsPermitted, dispatch) => {
  const permissionString = permissionStrings.editor;
  const hash = sha256(permissionString);
  const permissionsArray = permissions ? Object.keys(permissions) : [];

  if (permissionsArray.includes(hash)) {
    setUserIsPermitted(true);
  } else {
    setUserIsPermitted(false);
    dispatch({ type: SET_USER_DATA, payload: null });
  }
};

/*
const setFestivalAppFromLocalStorage = (allowedFapps = [], dispatch) => {
  const fappFromStorage = localStorage.getItem("selectedFapp") ? JSON.parse(localStorage.getItem("selectedFapp")) : {};

  const festivalUUID = fappFromStorage.festivalUUID;
  const editionUUID = fappFromStorage.editionUUID;
  const name = fappFromStorage.name;
  const start = fappFromStorage.start;

  if (festivalUUID && editionUUID && allowedFapps.length) {
    let isFappFromStorageAllowed = false;

    allowedFapps.forEach(item => {
      if (item.festival_uuid === festivalUUID && item.current_edition_uuid === editionUUID) {
        isFappFromStorageAllowed = true;
      }
    });

    if (isFappFromStorageAllowed) {
      dispatch({ name: "editor.fapp", payload: { festivalUUID, editionUUID, name, start }, type: SET_STATE_VALUE });
    }
  }

  if (!allowedFapps.length) {
    dispatch({ name: "editor.fapp", payload: {}, type: SET_STATE_VALUE });
    localStorage.removeItem("selectedFapp");
  }
};
*/
