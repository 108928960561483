import { SET_ENUMS } from "../../redux/reducers/general/actionTypes";
import apiManager from "../apiManager/apiManager";
import { getStateValue } from "./componentFunctions";

export const setEnums = async (dispatch, setEnumsAreSet = () => {}) => {
  let enums = await apiManager.enum.getAll().catch(e => {
    apiManager.enum
      .getAll()
      .then(r => r)
      .catch(e => console.error("Fatal: Couldn't fetch enums.", e));
  });

  const articleAuthors = await apiManager.enum.getArticleAuthors();
  const featuredCities = await apiManager.enum.getComCities();

  enums = {
    ...enums,
    article_authors: articleAuthors,
    featuredCities: featuredCities,
  };

  dispatch({ type: SET_ENUMS, payload: enums });
  setEnumsAreSet(true);
};

export const getCountryNameByCode = code => {
  const countries = getStateValue(null, "enums.countries");
  let name = "";

  if (code && countries) {
    for (let i = 0; i < countries.length; i++) {
      if (countries[i] && countries[i].code === code) {
        name = countries[i].name;
        break;
      }
    }
  }

  return name;
};

export const getVenueCapacityTypeName = id => {
  const venueTypes = getStateValue(null, "enums.venue_type");
  let name = "";

  if (id && venueTypes) {
    for (let i = 0; i < venueTypes.length; i++) {
      if (venueTypes[i] && venueTypes[i].id === id) {
        name = venueTypes[i].name;
        break;
      }
    }
  }

  return name;
};

export const getVenueCapacityRangeName = id => {
  const venueRanges = getStateValue(null, "enums.venue_range");
  let name = "";

  if (id && venueRanges) {
    for (let i = 0; i < venueRanges.length; i++) {
      if (venueRanges[i] && venueRanges[i].id === id) {
        name = venueRanges[i].name;
        break;
      }
    }
  }

  return name;
};

export const getFestivalCapacityRangeName = id => {
  const festivalRanges = getStateValue(null, "enums.festival_capacity");
  let name = "";

  if (id && festivalRanges) {
    for (let i = 0; i < festivalRanges.length; i++) {
      if (festivalRanges[i] && festivalRanges[i].id === id) {
        name = festivalRanges[i].description;
        break;
      }
    }
  }

  return name;
};

export const combineFestivalSizeNameDescription = sizes => {
  const data = [];

  if (sizes && Array.isArray(sizes) && sizes.length) {
    sizes.forEach(item => {
      if (item.id && item.name && item.description) {
        data.push({ id: item.id, name: item.name + " (" + item.description + ")" });
      }
    });
  }

  return data;
};
