import { createMuiTheme } from "@material-ui/core/styles";
import React from "react";
import { isEqual } from "lodash";
import apiManager from "../apiManager/apiManager";
import { getStateValue, dispatchToReduxStore, redirectTo } from "./componentFunctions";
import { findObjectByValue, findObjectIndexByValue } from "./common";
import { SET_STATE_VALUE } from "../../redux/reducers/common/actionTypes";
import { defaultListingTableRowNumber } from "../../constants/constants";

export const sortByDate = (data, colIndex, order) => {
  return data.sort((a, b) => {
    const date1 = Date.parse(a.data[colIndex]);
    const date2 = Date.parse(b.data[colIndex]);

    const orderNum = order === "desc" ? 1 : -1;

    if (!a.data[colIndex]) {
      return 1 * orderNum;
    }

    if (!b.data[colIndex]) {
      return -1 * orderNum;
    }

    return (date1 >= date2 ? -1 : 1) * orderNum;
  });
};

export const sortByStatus = (data, colIndex, order) => {
  return data.sort((a, b) => {
    const val1 = a.data[colIndex] ? a.data[colIndex] : "a";
    const val2 = b.data[colIndex] ? b.data[colIndex] : "b";
    const orderNum = order === "desc" ? 1 : -1;
    return (val1 < val2 ? -1 : 1) * orderNum;
  });
};

export const defaultSort = (data, colIndex, order) => {
  return data.sort((a, b) => {
    let val1 = a.data[colIndex];
    let val2 = b.data[colIndex];

    if (typeof val1 === "string" && typeof val2 === "string") {
      val1 = val1.toLowerCase();
      val2 = val2.toLowerCase();
    }

    const orderNum = order === "desc" ? 1 : -1;
    return (val1 < val2 ? -1 : 1) * orderNum;
  });
};

export const getTableTheme = (fontSizeHead = "14px", fontSizeCell = "12px") => {
  return createMuiTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: {
          fontSize: fontSizeHead,
          fontWeight: "bold",
        },
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: fontSizeCell,
          fontFamily: "Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif",
        },
      },
    },
  });
};

export const setTableData = async (
  setTableRowData,
  setTablePaginationData,
  setLoading,
  urlParameters,
  dataManipulation,
  entityType,
  id,
  edition,
  enums
) => {
  setLoading(true);

  let data = await apiManager.getEntityList(entityType, null, urlParameters, id, edition);

  if (data && data.data && Array.isArray(data.data)) {
    setTableRowData(dataManipulation.inwardDatatable(data.data, enums));
  } else {
    setTableRowData([]);
  }

  if (data && data.pagination) {
    setTablePaginationData(data.pagination);
  }

  setLoading(false);
};

export const getCurrentTablePage = (tableRowData, tablePaginationData = {}, statePath) => {
  const total = tablePaginationData.total;
  const current = tableRowData && tableRowData.length ? tableRowData.length : 0;

  let currentPageInState = parseInt(getStateValue(0, `${statePath}.page`));
  return currentPageInState && current && total >= currentPageInState * current ? currentPageInState : 0;
};

export const getCurrentTableRowPerPage = statePath => {
  return getStateValue(defaultListingTableRowNumber, `${statePath}.rows`);
};

export const onColumnSort = (changedColumn, direction, allColumns, dispatch, sortPath) => {
  direction = direction === "ascending" ? "asc" : "desc";

  const sortedColumnObject = findObjectByValue(allColumns, "name", changedColumn);

  if (sortedColumnObject && sortedColumnObject.sortBackendName) {
    dispatchToReduxStore(
      sortPath,
      SET_STATE_VALUE,
      { columnName: changedColumn, backendColumnName: sortedColumnObject.sortBackendName, direction: direction },
      dispatch
    );
  }
};

export const setCurrentScrollPosition = (dispatch, statePath) => {
  dispatchToReduxStore(`${statePath}.scrollPosition`, SET_STATE_VALUE, window.pageYOffset, dispatch);
};

export const scrollToVerticalPosition = (dispatch, statePath, tableData) => {
  const scrollTo = getStateValue(null, `${statePath}.scrollPosition`) || null;

  if (scrollTo && tableData && tableData.length) {
    window.scrollTo(0, scrollTo);
    dispatchToReduxStore(`${statePath}.scrollPosition`, SET_STATE_VALUE, null, dispatch);
  }
};

export const getColumnDefinitions = (allColumns, sortPath) => {
  let columns = [];
  if (allColumns) {
    let currentlySortedColumn = getStateValue("", `${sortPath}.columnName`);
    let direction = getStateValue("none", `${sortPath}.direction`);

    allColumns.forEach(item => {
      if (!item.hideInTable) {
        columns.push({
          name: item.name || "",
          label: item.label || "",
          options: {
            sort: item.sortBackendName ? true : false,
            sortDirection: currentlySortedColumn === item.name ? direction : "none",
            customBodyRender: item.customBodyRender ? item.customBodyRender : null,
            setCellProps: () => {
              return { className: item.cellClassName || "" };
            },
          },
        });
      }
    });
  }

  return columns;
};

export const setEditButtonCustomRender = (
  columns,
  dispatch,
  statePath,
  routerHistory,
  redirectUrl,
  name = "edit",
  btnClassName = "btn btn-default fa fa-edit",
  newTab = false
) => {
  let editColumnIndex = findObjectIndexByValue(columns, "name", name);

  if (editColumnIndex) {
    columns[editColumnIndex] = {
      name: "",
      label: "",
      options: {
        sort: false,
        customBodyRender: value => (
          <button
            className={btnClassName}
            onClick={() => {
              setCurrentScrollPosition(dispatch, statePath);
              if (newTab) {
                window.open(redirectUrl + value);
              } else {
                redirectTo(routerHistory, redirectUrl + value);
              }
            }}
          />
        ),
      },
    };
  }
};

export const setDeleteButtonCustomRender = (columns, onDeleteClick = () => {}) => {
  let editColumnIndex = findObjectIndexByValue(columns, "name", "delete");

  if (editColumnIndex) {
    columns[editColumnIndex] = {
      name: "",
      label: "",
      options: {
        sort: false,
        customBodyRender: (value, row) => (
          <button
            className="btn btn-default"
            onClick={() => {
              onDeleteClick(value, row.rowIndex);
            }}
          >
            <span className="fa fa-trash" />
          </button>
        ),
      },
    };
  }
};

export const resetPageOnFilterChange = (previousFilters, filters, statePath, dispatch) => {
  if (typeof previousFilters !== "undefined" && !isEqual(previousFilters, filters)) {
    dispatchToReduxStore(`${statePath}.page`, SET_STATE_VALUE, 0, dispatch);
  }
};
