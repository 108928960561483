import React from "react";
import FormBox from "../../../presentational/page/formBox";
import TicketInfo from "../../../input/general/ticketInfo";

const TicketInfoBox = () => {
  return (
    <FormBox title="Ticket Info">
      <TicketInfo name="formData.ticketInfo" />
    </FormBox>
  );
};

export default TicketInfoBox;
