import { getOr } from "lodash/fp";
import { findObjectByValue } from "../../functions/common";
import { getStateValue } from "../../functions/componentFunctions";
import commonDataManipulation from "../common/commonDataManipulation";

const festivalAppInfoDataManipulation = function festivalAppInfoDataManipulation() {
  const getInwardEditFestivalItem = data => {
    return {
      name: data && data.name ? { name: data.name } : null,
      weeks: commonDataManipulation().weeks.inward(data.weeks, data.start, data.end),
      location: commonDataManipulation().location.inward(data),
      stages: data && data.stages_num ? data.stages_num : null,
      size:
        data &&
        data.capacity_range &&
        data.capacity_range.id &&
        data.capacity_range.name &&
        data.capacity_range.description
          ? {
              id: data.capacity_range.id,
              name: data.capacity_range.name + " (" + data.capacity_range.description + ")",
            }
          : null,
      ticketInfo: data && data.tickets ? commonDataManipulation().tickets.inward(data.tickets) : null,
      links: data && data.links ? commonDataManipulation().links.inward(data.links) : [],
      initialHighlights: data && data.highlights ? data.highlights.map(item => item.id) : null,
      initialAmenities: data && data.amenities ? data.amenities.map(item => item.id) : null,
      highlights: data && data.highlights ? data.highlights.map(item => item.id) : null,
      amenities: data && data.amenities ? data.amenities.map(item => item.id) : null,
    };
  };

  const getOutwardFestival = data => {
    return {
      name: data && data.name && data.name.name ? data.name.name : null,
      ...commonDataManipulation().location.outward(data),
      ...commonDataManipulation().weeks.outward(data.weeks),
      stages_num: data && data.stages ? parseInt(data.stages) : null,
      //tickets: data && data.ticketInfo ? commonDataManipulation().tickets.outward(data.ticketInfo) : null,
      highlights: outwardArrayWithIDs(data.highlights),
      amenities: outwardArrayWithIDs(data.amenities),
      //city must be an object because of BE mess
      city: { id: getOr(null, "location.city.id", data) },
      //country must be an object because of BE mess
      country: {
        code: getOr(null, "location.country.code", data),
      },
      //capacity_range must be an object because of BE mess
      capacity_range: {
        id: getOr(null, "size.id", data),
      },

      tickets: outwardTickets(data.ticketInfo),

      links: outwardLinks(data.links),
    };
  };

  return { outward: getOutwardFestival, inwardItem: getInwardEditFestivalItem };
};

const outwardLinks = (links = []) => {
  const channels = getStateValue(null, "enums.channels");
  const parsedLinks = [];

  let channelObject;
  let channelName;

  if (links && Array.isArray(links) && links.length) {
    links.forEach((item, channelID) => {
      channelObject = findObjectByValue(channels, "id", channelID);
      channelName = getOr(null, "name", channelObject);

      if (item && channelName) {
        parsedLinks.push({ channel: { id: channelID, name: channelName }, url: item });
      }
    });
  }

  return parsedLinks;
};

const outwardTickets = data => {
  let tickets = [];
  let obj = {
    link: null,
    price_min: null,
    price_currency_id: 1,
  };

  const ticketType = data && data.type ? data.type : null;

  if (ticketType) {
    switch (ticketType) {
      case "free":
        obj.ticket_provider = { id: 1 };
        tickets.push(obj);
        break;
      case "door":
        obj.ticket_provider = { id: 2 };
        obj.price_min = data.price ? parseInt(data.price) : null;
        tickets.push(obj);
        break;
      case "online":
        obj.price_min = data.price ? parseInt(data.price) : null;
        obj.ticket_provider = { id: 3 };

        if (data.providers && data.providers.length > 0) {
          data.providers.forEach(item => {
            if (item) {
              obj.link = item;
              tickets.push({ ...obj });
            }
          });
        }
        break;
      default:
        break;
    }
  }

  return tickets;
};

const outwardArrayWithIDs = (data = []) => {
  const parsedArray = [];

  if (data && Array.isArray(data) && data.length) {
    data.forEach(item => {
      parsedArray.push({ id: item });
    });
  }
  return parsedArray;
};

export default festivalAppInfoDataManipulation();
