import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { mainStateKey } from "../../../constants/constants";
import { connect } from "react-redux";

const ClaimedImage = ({ connectedTo, isClaimed }) => {
  return (
    <React.Fragment>
      {isClaimed && <img src={require("../../../static/img/icon-claimed.svg")} alt="Claimed" className="claimed" />}
    </React.Fragment>
  );
};

ClaimedImage.propTypes = {
  connectedTo: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isClaimed: getOr(false, `${mainStateKey}.${ownProps.connectedTo}`, state),
  };
};

export default connect(mapStateToProps)(ClaimedImage);
