import React, { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import ClearInputButton from "../../input/general/clearInputButton";
import { mainStateKey } from "../../../constants/constants";

function TextArea({
  value,
  name,
  showClearInputButton = true,
  onChange = () => {},
  dispatch,
  className,
  placeholder,
  rows,
  maxLength,
  debounceTime = 0,
  disabled = false,
  style,
}) {
  //Local state used for debouncing
  const [localValue, setLocalValue] = useState(value);
  const sendValueChange = useCallback(
    debounce(val => {
      dispatchToReduxStore(name, SET_STATE_VALUE, val, dispatch);
    }, debounceTime),
    []
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <React.Fragment>
      <textarea
        style={style}
        disabled={disabled}
        maxLength={maxLength}
        value={localValue ? localValue : ""}
        className={className}
        rows={rows}
        placeholder={placeholder}
        onChange={e => {
          setLocalValue(window.event.target.value);
          sendValueChange(window.event.target.value);
          onChange(e.target.value);
        }}
      />
      {showClearInputButton && (
        <ClearInputButton
          onClick={() => {
            setLocalValue("");
            sendValueChange("");
          }}
        />
      )}
    </React.Fragment>
  );
}

TextArea.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  showClearInputButton: PropTypes.bool,
  onChange: PropTypes.func,
  dispatch: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  maxLength: PropTypes.number,
  debounceTime: PropTypes.number,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr("", `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(TextArea);
