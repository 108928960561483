import React from "react";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import YoutubeSuggestionsTable from "../../../input/table/youtubeSuggestionsTable";

const YoutubeSuggestionsBox = () => {
  return (
    <FormBox title="Suggested videos">
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <div className="row">
              <div className="col-md-12">
                <YoutubeSuggestionsTable name="formData.youtubeSuggestions" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default connect()(YoutubeSuggestionsBox);
