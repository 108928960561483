import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import GeneralInfoBox from "../shared/generalInfoBox";
import ContentBox from "../shared/contentBox";
import CategoryBox from "../shared/categoryBox";
import {
  handleDeleteEntity,
  handleEditFormSubmit,
  redirectTo,
  setItemFormData,
  showFormSuccess,
} from "../../../../../lib/functions/componentFunctions";
import ContentComponentSelector from "../../../../input/selectors/contentComponentSelector";
import { helpCenterComponents } from "../../../../../constants/helpCenter";
import helpCenterTopicDataManipulation from "../../../../../lib/dataManipulation/helpCenter/topics/helpCenterTopicDataManipulation";
import { useResetPageState } from "../../../../../lib/functions/customHooks";

const EditHelpCenterTopic = () => {
  useResetPageState();

  const routerHistory = useHistory();
  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "helpCenterTopic");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit Help Center Topic</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-8">
            <GeneralInfoBox />
            <div className="row">
              <div className="col-md-12">
                <ContentBox />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <CategoryBox />
          </div>
        </div>
        <ContentComponentSelector
          showDeleteButton={true}
          onSubmit={async () => {
            const submitSuccess = await handleEditFormSubmit(
              id,
              helpCenterTopicDataManipulation,
              "helpCenterTopic",
              "PUT",
              "",
              false
            );

            if (submitSuccess) {
              showFormSuccess();
            }
          }}
          onDelete={async () => {
            if (window.confirm("Are you sure you want to delete this event?")) {
              const deleteSuccess = await handleDeleteEntity(id, "helpCenterTopic");

              if (deleteSuccess) {
                redirectTo(routerHistory, "/list/help-center-topic/");
              }
            }
          }}
          showPreviewButton={false}
          availableComponents={helpCenterComponents}
        />
      </section>
    </React.Fragment>
  );
};

export default connect(null)(EditHelpCenterTopic);
