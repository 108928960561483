export const permissionStrings = {
  editor: "page|editor||read|",
  artist: "page|editor|contribution artist|read|",
  venue: "page|editor|contribution venue|read|",
  event: "page|editor|contribution event|read|",
  festival: "page|editor|contribution festival|read|",
  label: "page|editor|contribution label|read|",
  track: "page|editor|contribution track|read|",
  article: "page|editor|com article|read|",
  CMSHelpCenter: "page|editor|com helpcenter|read|",
  CMSWebinar: "page|editor|com webinar|read|",
  CMSFeedPost: "page|editor|com feedpost|read|",
  contributionHistory: "page|editor|contribution history|read|",
  entityHistory: "page|editor|entity history|read|",
};
