import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_FREE, SET_DOOR, SET_ONLINE, CLEAR } from "../../../redux/reducers/inputComponents/ticketInfo/actionTypes";
import { mainStateKey } from "../../../constants/constants";
import Input from "../../input/general/input";
import { makeid } from "../../../lib/functions/common";

const TicketInfo = ({
  numberOfTicketProviders = 3,
  name,
  value,
  dispatch,
  disabled = false,
  showResetButton = true,
  formGroupClassName = "",
}) => {
  const onTicketInfoTypeClick = e => {
    const checkedValue = e.target.value;

    switch (checkedValue) {
      case "free":
        dispatchToReduxStore(name, SET_FREE, null, dispatch);
        break;
      case "door":
        dispatchToReduxStore(name, SET_DOOR, null, dispatch);
        break;
      case "online":
        dispatchToReduxStore(name, SET_ONLINE, null, dispatch);
        break;
      default:
        break;
    }
  };

  const rButtonName = makeid(5);

  const ticketInputs = getTicketInputs(numberOfTicketProviders, value, name, disabled, formGroupClassName);

  return (
    <React.Fragment>
      {showResetButton && (
        <button
          type="button"
          className="btn btn-default pull-right btn-reset-tickets btn-small"
          onClick={() => {
            dispatchToReduxStore(name, CLEAR, null, dispatch);
          }}
        >
          Reset
        </button>
      )}

      <div className="row">
        <div className="col-md-6">
          <div className="box-body">
            <div className="radio">
              <label>
                <input
                  checked={value && value.free ? value.free : false}
                  type="radio"
                  name={rButtonName}
                  value="free"
                  onClick={onTicketInfoTypeClick}
                  onChange={() => {}}
                  disabled={disabled}
                />
                Free Entry
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  checked={value && value.door ? value.door : false}
                  type="radio"
                  name={rButtonName}
                  value="door"
                  onClick={onTicketInfoTypeClick}
                  onChange={() => {}}
                  disabled={disabled}
                />
                Tickets at the door
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  checked={value && value.online ? value.online : false}
                  type="radio"
                  name={rButtonName}
                  value="online"
                  onClick={onTicketInfoTypeClick}
                  onChange={() => {}}
                  disabled={disabled}
                />
                Tickets Online
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="box-body">
            <div className={"form-group " + formGroupClassName}>
              <label>Ticket Price</label>
              <div className="input-group">
                <div className="input-group-addon">EUR</div>
                <div className="input-group">
                  <Input
                    disabled={disabled ? true : value && value.priceDisabled ? value.priceDisabled : false}
                    name={`${name}.price`}
                    className="form-control"
                    placeholder="Enter the ticket's lowest price"
                    type="number"
                    showClearInputButton={!disabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="box-body">{ticketInputs}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

function getTicketInputs(numberOfTicketProviders, value, name, disabled, formGroupClassName) {
  let ticketProviders = [];
  const isDisabled = !disabled && value && value.type === "online" ? false : true;

  for (let i = 0; i < numberOfTicketProviders; i++) {
    ticketProviders.push(
      <React.Fragment key={i}>
        <div className={"form-group " + formGroupClassName}>
          {i === 0 && <label>Ticket Providers</label>}
          <div className="input-group">
            <Input
              showClearInputButton={!disabled}
              disabled={isDisabled}
              name={`${name}.providers[${i}]`}
              className="form-control"
              placeholder="Ticket provider (optional)"
              showRedirectButton={true}
              onRedirectButtonClick={inputValue => {
                if (inputValue) {
                  window.open(inputValue);
                }
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  return ticketProviders;
}

TicketInfo.propTypes = {
  numberOfTicketProviders: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.object,
  dispatch: PropTypes.func,
  disabled: PropTypes.bool,
  showResetButton: PropTypes.bool,
  formGroupClassName: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(TicketInfo);
