import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Footer from "../../../presentational/page/footer";
import {
  handleEditFormSubmit,
  setItemFormData,
  handleDeleteEntity,
  redirectTo,
} from "../../../../lib/functions/componentFunctions";
import eventDataManipulation from "../../../../lib/dataManipulation/event/eventDataManipulation";
import LineupBox from "../shared/lineupBox";
import BasicInfoBox from "../shared/basicInfoBox";
import TicketInfoBox from "../shared/ticketInfoBox";
import OpenChangeHistory from "../../../presentational/buttons/openChangeHistory";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const EditEvent = () => {
  useResetPageState();

  const routerHistory = useHistory();
  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "event");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit Event</h1>
        <OpenChangeHistory id={id} type="event" />
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <BasicInfoBox />
              </div>
              <div className="col-md-6">
                <TicketInfoBox />
              </div>
              <div className="col-md-12">
                <LineupBox />
              </div>
            </div>
          </div>
        </div>
        <Footer
          showClearButton={false}
          showDeleteButton={true}
          onDelete={async () => {
            if (window.confirm("Are you sure you want to delete this event?")) {
              const deleteSuccess = await handleDeleteEntity(id, "event");

              if (deleteSuccess) {
                redirectTo(routerHistory, "/list/editEvent/");
              }
            }
          }}
          onSave={() => {
            handleEditFormSubmit(id, eventDataManipulation, "event", "POST", null);
          }}
        />
      </section>
    </React.Fragment>
  );
};

export default EditEvent;
