import { combineReducers } from "redux";
import { set } from "lodash/fp";
import reduceReducers from "reduce-reducers";
import common from "./common/common";
import ticketInfo from "./inputComponents/ticketInfo/ticketInfo";
import articleTags from "./inputComponents/articleTags/articleTags";
import openingHours from "./inputComponents/openingHours/openingHours";
import general from "./general/general";
import { mainStateKey } from "../../constants/constants";

const appStateReducer = reduceReducers(general, common, ticketInfo, articleTags, openingHours);

const appReducer = combineReducers({
  [mainStateKey]: appStateReducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      return action.value;
    case "SET_FORM_DATA":
      return set(`${mainStateKey}.formData`, action.formData, state);
    case "CLEAR_FORM_DATA":
      return set(`${mainStateKey}.formData`, undefined, state);
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
