import React from "react";
import PropTypes from "prop-types";

import FormBox from "../../../presentational/page/formBox";
import TextArea from "../../../input/general/textArea";

const CommentBox = ({ connectedTo }) => {
  return (
    <FormBox title="Comment">
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <TextArea
              debounceTime={0}
              style={{ width: "50%" }}
              rows="5"
              name={connectedTo + ".review.comment"}
              showClearInputButton={false}
            />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

CommentBox.propTypes = {
  connectedTo: PropTypes.string,
};

export default CommentBox;
