import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import articleDataManipulation from "../../../../lib/dataManipulation/article/articleDataManipulation";
import apiManager from "../../../../lib/apiManager/apiManager";
import { sortByDate, sortByStatus, defaultSort, getTableTheme } from "../../../../lib/functions/tableFunctions";
import { Link } from "react-router-dom";
import { analyticsSiteUrl } from "../../../../constants/constants";

const ArticleListBox = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      name: "Title",
      options: {
        sort: false,
        setCellProps: () => {
          return {
            className: "w20",
          };
        },
      },
    },
    "Author",
    "Start Date",
    "End Date",
    {
      name: "Status",
      options: {
        customBodyRender: value => getStatusJSX(value),
      },
    },
    {
      name: "Tags",
      options: {
        sort: false,
        customBodyRender: value => getTagsJSX(value),
        setCellProps: () => {
          return {
            className: "w30",
          };
        },
      },
    },
    {
      name: "URL",
      options: {
        sort: false,
        customBodyRender: value => getUrlJSX(value),
      },
    },
    "Views",
    {
      name: "Edit",
      options: {
        customBodyRender: value => <Link to={"/edit/article/" + value} className="btn btn-default fa fa-edit" />,
      },
    },
  ];

  const datatableOptions = {
    selectableRows: "none",
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: "stacked",
    customSort: (data, colIndex, order) => {
      if (colIndex === 2 || colIndex === 3) {
        return sortByDate(data, colIndex, order);
      } else if (colIndex === 4) {
        return sortByStatus(data, colIndex, order);
      } else {
        return defaultSort(data, colIndex, order);
      }
    },
  };

  useEffect(() => {
    setArticleListData(setData, setLoading);
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="timetable">
          <MuiThemeProvider theme={getTableTheme()}>
            <MUIDataTable
              className={loading ? "loading" : ""}
              title={"Articles"}
              data={data}
              columns={columns}
              options={datatableOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

const getStatusJSX = status => {
  let parsedStatus = "";

  switch (status) {
    case "finished":
      parsedStatus = (
        <React.Fragment>
          <i className="fa fa-fw fa-circle green" />
          Finished
        </React.Fragment>
      );
      break;
    case "draft":
      parsedStatus = (
        <React.Fragment>
          <i className="fa fa-fw fa-circle grey" />
          Draft
        </React.Fragment>
      );
      break;
    case "inactive":
      parsedStatus = (
        <React.Fragment>
          <i className="fa fa-fw fa-circle red" />
          Inactive
        </React.Fragment>
      );
      break;
    default:
      break;
  }

  return parsedStatus;
};

const getUrlJSX = value => {
  const status = value.status;
  const slug = value.slug;
  const categorySlug = value.category_slug;

  return (
    <React.Fragment>
      {status === "finished" ? (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={analyticsSiteUrl[process.env.REACT_APP_ENVIRONMENT] + "blog/" + categorySlug + "/" + slug}
        >
          {analyticsSiteUrl[process.env.REACT_APP_ENVIRONMENT] + "blog/" + categorySlug + "/" + slug}
        </a>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const getTagsJSX = tags => {
  let parsedTags = [];

  if (tags && tags.length > 0) {
    tags.forEach((tag, index) => {
      parsedTags.push(
        <span key={index} className="tag">
          {tag.text ? tag.text : (tag.page ? tag.page : "") + " " + (tag.section ? tag.section : "")}
        </span>
      );
    });
  }

  return (
    <React.Fragment>
      <div className="tags-list">{parsedTags}</div>
    </React.Fragment>
  );
};

const setArticleListData = async (setData, setLoading) => {
  const data = await apiManager.getEntityList("article");

  if (data && data.length) {
    setData(articleDataManipulation.inwardDatatable(data));
    setLoading(false);
  }

  setLoading(false);
  return [];
};

export default ArticleListBox;
