import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import LinkInput from "./linkInput";
import { connect } from "react-redux";
import { searchInNewTabClick } from "../../../lib/functions/common";
import { mainStateKey } from "../../../constants/constants";

const Links = ({ linkInputData, name, entityName, oldLinksName, oldLinks }) => {
  if (linkInputData && linkInputData.length > 0) {
    return (
      <React.Fragment>
        {linkInputData.map(link => {
          return (
            <div className="form-group" key={link.id}>
              <label
                className="col-sm-2 control-label label-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  searchInNewTabClick(link.name, entityName, link.placeholder);
                }}
              >
                {link.placeholder}
                {link.isImportant && <React.Fragment>*</React.Fragment>}
              </label>
              <div className="col-sm-10">
                <div className="input-group">
                  <LinkInput type={link.name} name={name} placeholder={link.placeholder} id={link.id} />
                </div>
                {oldLinks && (
                  <small
                    style={{ cursor: "pointer" }}
                    className="old-link"
                    onClick={() => {
                      if (oldLinks[link.id]) {
                        window.open(oldLinks[link.id]);
                      }
                    }}
                  >
                    {oldLinks[link.id]}
                  </small>
                )}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  return false;
};

Links.propTypes = {
  linkInputData: PropTypes.array,
  name: PropTypes.string,
  entityName: PropTypes.string,
  oldLinksName: PropTypes.string,
  oldLinks: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  return { oldLinks: getOr(null, `${mainStateKey}.${ownProps.oldLinksName}`, state) };
};

export default connect(mapStateToProps)(Links);
