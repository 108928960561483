import React from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";

import GeneralInfoBox from "../shared/generalInfoBox";
import CategoryBox from "../shared/categoryBox";
import ContentBox from "../shared/contentBox";
import ContentComponentSelector from "../../../../input/selectors/contentComponentSelector";
import { helpCenterComponents } from "../../../../../constants/helpCenter";
import { handleAddFormSubmit, redirectTo } from "../../../../../lib/functions/componentFunctions";
import helpCenterTopicDataManipulation from "../../../../../lib/dataManipulation/helpCenter/topics/helpCenterTopicDataManipulation";
import { useResetPageState } from "../../../../../lib/functions/customHooks";

const AddHelpCenterTopic = () => {
  useResetPageState();

  const routerHistory = useHistory();

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add Help Center Topic</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-8">
            <GeneralInfoBox />
            <div className="row">
              <div className="col-md-12">
                <ContentBox />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <CategoryBox />
          </div>
        </div>
        <ContentComponentSelector
          onSubmit={async () => {
            const submitSuccessfull = await handleAddFormSubmit(
              false,
              helpCenterTopicDataManipulation,
              "helpCenterTopic"
            );

            if (submitSuccessfull) {
              redirectTo(routerHistory, "/list/help-center-topic/");
            }
          }}
          showPreviewButton={false}
          availableComponents={helpCenterComponents}
        />
      </section>
    </React.Fragment>
  );
};

export default connect(null)(AddHelpCenterTopic);
