import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import moment from "moment";
import FormBox from "../../../presentational/page/formBox";
import UploadImageButton from "../../../input/media/uploadImageButton";
import Select from "../../../input/selectors/select";
import EntitySelector from "../../../input/selectors/entitySelector";
import { mainStateKey, trackStatus } from "../../../../constants/constants";
import Input from "../../../input/general/input";
import { searchInNewTabClick } from "../../../../lib/functions/common";

const GeneralInfoBox = ({ oldName }) => {
  const statusOptions = trackStatus.filter(item => {
    return item.id !== "deleted";
  });

  return (
    <FormBox title="General Info">
      <div className="box-body" style={{ minHeight: "100px" }}>
        <div className="form-horizontal">
          <div className="relative">
            <div className="form-group">
              <label className="col-sm-3 control-label">Name</label>
              <div className="col-sm-9" style={{ zIndex: 101 }}>
                <div className="input-group">
                  <Input
                    name="formData.name"
                    showAddNewButton={false}
                    removeOnSelect={false}
                    className="form-control"
                    showRedirectButton={true}
                    onRedirectButtonClick={val => {
                      if (val) {
                        searchInNewTabClick(null, val);
                      }
                    }}
                  />
                </div>
                {oldName && <small>{oldName}</small>}
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">ISRC</label>
              <div className="col-sm-9" style={{ zIndex: 101 }}>
                <div className="input-group">
                  <Input
                    maxLength={12}
                    name="formData.ISRC"
                    showAddNewButton={false}
                    removeOnSelect={false}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Label</label>
              <div className="col-sm-9" style={{ zIndex: 100 }}>
                <EntitySelector
                  placeholder="Label name"
                  entityType="label"
                  name="formData.label"
                  removeOnSelect={false}
                  showClearButton={true}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Release date</label>
              <div className="col-sm-9 ">
                <div className="input-group">
                  <Input
                    name="formData.releaseDate"
                    type="date"
                    placeholder="dd-mm-yyyy"
                    className="form-control"
                    max={moment().format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Status</label>
              <div className="col-sm-9">
                <Select
                  placeholder="Select status"
                  data={statusOptions}
                  displayKey="name"
                  idKey="id"
                  name="formData.status"
                  showClearButton={false}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Artwork</label>
              <div className="col-sm-9">
                <UploadImageButton
                  name="formData.image"
                  title="Artwork"
                  enlargeImageOnPreviewHover={false}
                  showModalOnPreviewButtonClick={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

GeneralInfoBox.propTypes = {
  oldName: PropTypes.string,
};

const mapStateToProps = state => {
  return { oldName: getOr(null, `${mainStateKey}.formData.oldName`, state) };
};

export default connect(mapStateToProps)(GeneralInfoBox);
