import { createStore } from "redux";
import rootReducer from "../reducers";

let storeObj;

if (typeof window == "undefined") {
  storeObj = createStore(rootReducer);
} else {
  storeObj = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = storeObj;

export default store;
