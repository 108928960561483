import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { mainStateKey } from "../../../constants/constants";
import { connect } from "react-redux";

const OpenAllLinksButton = ({ connectedTo, filledLinks }) => {
  return (
    <button
      disabled={filledLinks && filledLinks.length ? false : true}
      className="btn btn-default btn-small pull-right"
      onClick={() => {
        openMissingLinksInNewTab(filledLinks);
      }}
    >
      Open All Links
    </button>
  );
};

const openMissingLinksInNewTab = filledLinks => {
  if (filledLinks && filledLinks.length && Array.isArray(filledLinks)) {
    filledLinks.forEach(item => {
      if (item) {
        window.open(item);
      }
    });
  }
};

OpenAllLinksButton.propTypes = {
  connectedTo: PropTypes.string,
  value: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  return { filledLinks: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state) };
};

export default connect(mapStateToProps)(OpenAllLinksButton);
