import { getOr } from "lodash/fp";
import { getDataByType } from "./fieldDataManipulation";

const youtubeHistoryDataManipulation = function youtubeHistoryDataManipulation() {
  const getInwardReviewItem = data => {
    // Get only data that has youtube video fields
    data = data.filter(item => {
      let isYoutube = false;
      if (item.details && item.details.length) {
        for (let i = 0; i < item.details.length; i++) {
          if (item.details[i].field === "youtube_videos") {
            isYoutube = true;
            break;
          }
        }
      }
      return isYoutube;
    });

    const activeItem = data && data[0] ? data[0] : {};
    const type = getOr(null, "entity_type", activeItem);

    return {
      ...data,
      activeItem: {
        ...activeItem,
        submitted: { ...getDataByType(activeItem, "submitted", type) },
        accepted: { ...getDataByType(activeItem, "new", type) },
        old: { ...getDataByType(activeItem, "old", type) },
        reviewData: { ...getDataByType(activeItem, "", type, true) },
      },
    };
  };

  return { inwardItem: getInwardReviewItem };
};

export default youtubeHistoryDataManipulation();
