import { useRef, useEffect } from "react";
import { resetPageState } from "./componentFunctions";

export const useOutsideComponentClick = (ref, setOpen) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useResetPageState = () => {
  useEffect(() => {
    resetPageState();
  }, []);
};
