import React, { useEffect } from "react";
import Footer from "../../../presentational/page/footer";
import GeneralInfoBox from "../shared/generalInfoBox";
import {
  handleDeleteEntity,
  handleEditFormSubmit,
  redirectTo,
  setItemFormData,
} from "../../../../lib/functions/componentFunctions";
import { useResetPageState } from "../../../../lib/functions/customHooks";
import ArtistsBox from "../shared/artistsBox";
import GenreBox from "../shared/genreBox";
import LinksBox from "../shared/linksBox";
import { useHistory, useParams } from "react-router";
import trackDataManipulation from "../../../../lib/dataManipulation/track/trackDataManipulation";
import OpenChangeHistory from "../../../presentational/buttons/openChangeHistory";

const EditTrack = () => {
  useResetPageState();

  const routerHistory = useHistory();
  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "track");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>
          Edit track
          <OpenChangeHistory id={id} type="track" />
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <GeneralInfoBox />
          </div>
          <div className="col-md-6">
            <ArtistsBox />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <GenreBox />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Spotify Tracks" type={"spotify"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Beatport Tracks" type={"beatport"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Youtube videos" type={"youtube"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Soundcloud Tracks" type={"soundcloud"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Shazam Tracks" type={"shazam"} showPreview={false} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Airplay Tracks" type={"airplay"} showPreview={false} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LinksBox boxTitle="Tiktok Tracks" type={"tiktok"} showPreview={false} />
          </div>
        </div>
        <Footer
          showClearButton={false}
          showDeleteButton={true}
          onDelete={() => {
            deleteTrack(id, routerHistory);
          }}
          onSave={() => {
            handleEditFormSubmit(id, trackDataManipulation, "track", "POST");
          }}
        />
      </section>
    </React.Fragment>
  );
};

const deleteTrack = async (id, routerHistory) => {
  if (window.confirm("Are you sure you want to delete this track?")) {
    const deleteSuccess = await handleDeleteEntity(id, "track");

    if (deleteSuccess) {
      redirectTo(routerHistory, "/list/editTrack");
    }
  }
};

export default EditTrack;
