import React from "react";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import UploadImageButton from "../../../input/media/uploadImageButton";
import Select from "../../../input/selectors/select";
import EntitySelector from "../../../input/selectors/entitySelector";
import { labelStatus } from "../../../../constants/constants";

const GeneralInfoBox = () => {
  const statusOptions = labelStatus.filter(item => {
    return item.id !== "deleted";
  });

  return (
    <FormBox title="General Info">
      <div className="box-body" style={{ minHeight: "100px" }}>
        <div className="form-horizontal">
          <div className="relative">
            <div className="form-group">
              <label className="col-sm-2 control-label">Name</label>
              <div className="col-sm-10 right-padding-110">
                <EntitySelector
                  placeholder="Label name"
                  entityType="label"
                  name="formData.name"
                  showAddNewButton={false}
                  removeOnSelect={false}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">Status</label>
              <div className="col-sm-10 right-padding-110">
                <Select
                  placeholder="Select status"
                  data={statusOptions}
                  displayKey="name"
                  idKey="id"
                  name="formData.status"
                  showClearButton={false}
                />
              </div>
            </div>
            <div className="form-group absolute-top-right">
              <UploadImageButton
                name="formData.image"
                title="Profile"
                enlargeImageOnPreviewHover={false}
                showModalOnPreviewButtonClick={true}
              />
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default connect(null)(GeneralInfoBox);
