import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import EntitySelector from "../../../input/selectors/entitySelector";
import { venueStatus, enumsPath, venuePriceRange } from "../../../../constants/constants";
import Select from "../../../input/selectors/select";
import Input from "../../../input/general/input";
import UploadImageButton from "../../../input/media/uploadImageButton";

const NameAndAdditionalInfoBox = ({ venueTypes, venueSizes }) => {
  const statusOptions = venueStatus.filter(item => {
    return item.id !== "deleted";
  });

  return (
    <FormBox title="Name & Additional Info">
      <div className="box-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-horizontal">
              <div className="relative">
                <div className="form-group">
                  <label className="col-sm-3 control-label">Name</label>
                  <div className="col-sm-9 right-padding-110">
                    <EntitySelector
                      placeholder="Search Venue..."
                      entityType="place"
                      name="formData.name"
                      showAddNewButton={false}
                      removeOnSelect={false}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Status</label>
                  <div className="col-sm-9 right-padding-110">
                    <Select
                      placeholder="Select status"
                      data={statusOptions}
                      displayKey="name"
                      idKey="id"
                      name="formData.status"
                      showClearButton={false}
                    />
                  </div>
                </div>
                <div className="form-group absolute-top-right">
                  <UploadImageButton
                    showModalOnPreviewButtonClick={true}
                    name="formData.profileImage"
                    imagePreviewOrinetation="br"
                  />
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Type</label>
                  <div className="col-sm-9 right-padding-110">
                    <Select
                      placeholder="Select type"
                      data={venueTypes}
                      displayKey="name"
                      idKey="id"
                      showClearButton={false}
                      name="formData.type"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Size</label>
                  <div className="col-sm-9">
                    <Select
                      placeholder="Select size"
                      data={venueSizes}
                      displayKey="name"
                      idKey="id"
                      name="formData.size"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Price Range $</label>
                  <div className="col-sm-9">
                    <Select
                      placeholder="Select price range"
                      data={venuePriceRange}
                      displayKey="name"
                      idKey="id"
                      name="formData.priceRange"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Email</label>
                  <div className="col-sm-9">
                    <div className="input-group">
                      <Input name="formData.email" type="text" placeholder="Enter Email" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Phone</label>
                  <div className="col-sm-9">
                    <div className="input-group">
                      <Input name="formData.phone" type="text" placeholder="Enter Phone" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

NameAndAdditionalInfoBox.propTypes = {
  venueTypes: PropTypes.array,
  venueSizes: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    venueTypes: getOr(null, `${enumsPath}.capacity_types.venue`, state),
    venueSizes: getOr(null, `${enumsPath}.capacity_ranges.venue`, state),
  };
};
export default connect(mapStateToProps)(NameAndAdditionalInfoBox);
