import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import Input from "../general/input";

import { mainStateKey } from "../../../constants/constants";

const TikTokEmbed = ({ index, name, value }) => {
  return (
    <div className="box-body">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <div className="input-group">
              <Input
                className="form-control"
                placeholder="TikTok  url"
                name={`${name}.[${index}].data.url`}
                showClearInputButton={false}
                debounceTime={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TikTokEmbed.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}.[${ownProps.index}].data`, state) };
};

export default connect(mapStateToProps)(TikTokEmbed);
