import { getOr } from "lodash/fp";
import moment from "moment";
import { mapArray } from "../../functions/common";
import commonDataManipulation from "../common/commonDataManipulation";

const feedpostDataManipulation = function feedpostDataManipulation() {
  const getOutwardFeedpost = data => {
    const type = getOr(null, "type.id", data);
    const content = type !== "article" && data.content ? commonDataManipulation().content.outward(data.content) : [];

    return {
      start: data.startDate ? moment(data.startDate).format("YYYY-MM-DD") + "T00:00:00Z" : null,
      article_id: getOr(null, "article[0].id", data),
      title: getOr(null, "title", data),
      user_status: getOr("", "userStatus.name", data).toLowerCase(),
      genre_ids: getOr([], "genres", data),
      content: content,
      type: type,
      lead: getOr(null, "lead", data),
      status: getOr("", "status.name", data).toLowerCase(),
    };
  };

  const getInwardDatatable = (data, enums) => {
    let parsedData = [];
    let genres = enums && enums.genres ? enums.genres : null;
    genres = genres ? mapArray(genres, "id") : null;
    let genreArray = [];
    let genreString = "";

    if (data && data.length && Array.isArray(data)) {
      data.forEach(item => {
        genreArray = [];

        genreString = "";
        if (item && item.genre_ids && item.genre_ids.length && genres) {
          item.genre_ids.forEach(genreID => {
            genreArray.push(genres[genreID].name);
          });

          genreString = genreArray.join(", ");
        }

        parsedData.push([
          getOr("", "title", item),
          getOr("", "lead", item),
          item.start ? moment(item.start).format("YYYY-MM-DD") : "",
          getOr("", "status", item),
          getOr("", "user_status", item),
          getOr("", "type", item),
          genreString,
          item.created_at ? moment(item.created_at).format("YYYY-MM-DD") : "",
          item.updated_at ? moment(item.updated_at).format("YYYY-MM-DD") : "",
          item.id,
        ]);
      });
    }

    return parsedData;
  };

  const getInwardItem = data => {
    const type = data.type;
    const article = getOr(null, "article", data);
    const content = type !== "article" && data.content ? commonDataManipulation().contentItem.inward(data.content) : [];

    return {
      startDate: data.start ? moment(data.start).format("YYYY-MM-DD") : "",
      article: article ? [article] : null,
      title: article ? getOr(null, "title", article) : getOr(null, "title", data),
      lead: article ? getOr(null, "lead", article) : getOr(null, "lead", data),
      userStatus: data.user_status ? commonDataManipulation().userStatus.inward(data.user_status) : {},
      status: data.status ? commonDataManipulation().contentStatus.inward(data.status) : {},
      genres: getOr([], "genre_ids", data),
      type: data.type ? commonDataManipulation().feedpostType.inward(data.type) : {},
      content: content,
    };
  };

  return {
    outward: getOutwardFeedpost,
    inwardDatatable: getInwardDatatable,
    inwardItem: getInwardItem,
  };
};

export default feedpostDataManipulation();
