import React from "react";
import helpCenterCategoryDataManipulation from "../../../../../lib/dataManipulation/helpCenter/categories/helpCenterCategoryDataManipulation";
import { handleAddFormSubmit } from "../../../../../lib/functions/componentFunctions";
import { useResetPageState } from "../../../../../lib/functions/customHooks";
import Footer from "../../../../presentational/page/footer";
import GeneralInfoBox from "../shared/generalInfoBox";
import AddSubcategoryBox from "../shared/addSubcategoryBox";

const AddHelpCenterCategory = () => {
  useResetPageState();

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add Help Center category</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-4">
            <GeneralInfoBox />
          </div>
          <div className="col-md-8">
            <AddSubcategoryBox />
          </div>
        </div>

        <Footer
          onSave={() => handleAddFormSubmit(false, helpCenterCategoryDataManipulation, "helpCenterCategory")}
          onSaveAndClear={() => {
            handleAddFormSubmit(true, helpCenterCategoryDataManipulation, "helpCenterCategory");
          }}
        />
      </section>
    </React.Fragment>
  );
};

export default AddHelpCenterCategory;
