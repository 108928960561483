import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { mainStateKey, modalPath, youtubeDomain } from "../../../constants/constants";
import moment from "../../../../node_modules/moment/moment";
import { sortByKey, thousandsSeparator } from "../../../lib/functions/common";
import RedirectButton from "../../input/general/redirectButton";
import Pagination from "./pagination";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

const YoutubeTable = ({
  data = [],
  zeroStateText = "No videos",
  showCheckbox = false,
  onCheckedItemsChange = () => {},
  onMatchClick = () => {},
  onDeleteClick = () => {},
  showMatchButton = true,
  dispatch,
}) => {
  let videoID = "";
  let image = "";
  let title = "";
  let channelName = "";
  let releaseDate = "";
  let views = "";
  let channelID = "";

  const defaultState = {
    data: data,
    filteredData: data,
    videoSearch: "",
    channelSearch: "",
    idSearch: "",
    sort: "",
    sortDirection: "desc",
    allChecked: false,
    checkedItems: [],
    currentPage: 1,
  };

  const [state, setState] = useState(defaultState);

  const itemsPerPage = 15;
  const filteredDataLength = state.filteredData ? state.filteredData.length : 0;
  const startOffset = (state.currentPage - 1) * itemsPerPage;
  const endOffset = startOffset + itemsPerPage;

  useEffect(() => {
    setState({ ...defaultState, data, filteredData: data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setState({ ...state, filteredData: getAllFilteredData(state.data), currentPage: 1 });
    removeAllChecked();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.videoSearch, state.channelSearch, state.idSearch, state.sort, state.sortDirection]);

  const getAllFilteredData = data => {
    let filteredData = data;

    if (state.idSearch) {
      filteredData = getFilteredData(state.idSearch, "yt_video_id", filteredData);
    }
    if (state.videoSearch) {
      filteredData = getFilteredData(state.videoSearch, "name", filteredData);
    }
    if (state.channelSearch) {
      filteredData = getFilteredData(state.channelSearch, "channel.name", filteredData);
    }

    if (state.sort && state.sortDirection) {
      filteredData = getSortedData(state.sort, filteredData, state.sortDirection);
    }

    return filteredData;
  };

  const getFilteredData = (searchText, searchBy, data = []) => {
    data = data ? data : [];
    let filteredData = data;
    let searchByValue = "";

    if (searchText) {
      filteredData = data.filter(item => {
        searchByValue = getOr("", searchBy, item).toLowerCase();
        return searchByValue.includes(searchText.toLowerCase());
      });
    }

    return filteredData;
  };

  const getSortedData = (sortBy, data = [], dir) => {
    return sortByKey([...data], sortBy, dir === "asc", sortBy === "release_date");
  };

  const removeAllChecked = () => {
    setState(prevState => {
      return {
        ...prevState,
        allChecked: false,
        checkedItems: [],
      };
    });
  };

  const setAllChecked = () => {
    const allChecked = !state.allChecked;
    const checkedArray = [];

    if (allChecked) {
      state.filteredData &&
        state.filteredData.slice(startOffset, endOffset).forEach(item => {
          checkedArray.push(item.yt_video_id);
        });
    }

    setState({ ...state, allChecked, checkedItems: checkedArray });
  };

  const paginationClick = page => {
    removeAllChecked();
    setState(prevState => ({ ...prevState, currentPage: page }));
  };

  useEffect(() => {
    onCheckedItemsChange(state.checkedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checkedItems]);

  const previewVideo = (videoID, title) => {
    dispatchToReduxStore(
      modalPath,
      SET_STATE_VALUE,
      {
        show: true,
        data: { videoID, title },
        type: "youtubePreview",
        hideCloseButton: true,
      },
      dispatch
    );
  };

  return (
    <React.Fragment>
      <table className="table table-striped table-font-12 table-header-white">
        <thead>
          <tr>
            <th>
              <input
                style={{ visibility: showCheckbox ? "visible" : "hidden" }}
                type="checkbox"
                checked={state.allChecked}
                onChange={() => {
                  setAllChecked();
                }}
              ></input>
            </th>
            <th>
              <input
                type="text"
                value={state.idSearch}
                className="search-input"
                placeholder="Video ID"
                onChange={e => {
                  setState({ ...state, idSearch: e.target.value });
                }}
              />
            </th>
            <th>
              <input
                type="text"
                value={state.videoSearch}
                className="search-input"
                placeholder="Video title"
                onChange={e => {
                  setState({ ...state, videoSearch: e.target.value });
                }}
              />
            </th>
            <th>
              <input
                type="text"
                value={state.channelSearch}
                className="search-input"
                placeholder="Channel"
                onChange={e => {
                  setState({ ...state, channelSearch: e.target.value });
                }}
              />
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => {
                setState({
                  ...state,
                  sort: "release_date",
                  sortDirection: state.sortDirection === "asc" ? "desc" : "asc",
                });
              }}
            >
              <React.Fragment>
                Release date
                {state.sort === "release_date" && (
                  <i className={"left-padding-8 fa fa-sort-" + state.sortDirection} aria-hidden="true"></i>
                )}
              </React.Fragment>
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => {
                setState({ ...state, sort: "views", sortDirection: state.sortDirection === "asc" ? "desc" : "asc" });
              }}
            >
              <React.Fragment>
                Views
                {state.sort === "views" && (
                  <i className={"left-padding-8 fa fa-sort-" + state.sortDirection} aria-hidden="true"></i>
                )}
              </React.Fragment>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {state.filteredData && !!state.filteredData.length ? (
            <React.Fragment>
              {state.filteredData.slice(startOffset, endOffset).map((item, index) => {
                videoID = getOr("", "yt_video_id", item);
                image = videoID ? `https://img.youtube.com/vi/${videoID}/default.jpg` : "";
                title = getOr("", "name", item);
                channelName = getOr("", "channel.name", item);
                releaseDate = item.release_date ? moment(item.release_date).format("YYYY-MM-DD") : "";
                views = thousandsSeparator(getOr("", "views", item));
                channelID = getOr("", "channel.link_id", item);

                return (
                  <tr key={videoID}>
                    <td>
                      <div className="flex-row center">
                        <input
                          style={{ visibility: showCheckbox ? "visible" : "hidden" }}
                          type="checkbox"
                          checked={state.checkedItems.includes(videoID) ? true : false}
                          onChange={e => {
                            if (state.checkedItems.includes(item.yt_video_id)) {
                              setState({
                                ...state,
                                checkedItems: state.checkedItems.filter(filteredItem => {
                                  return filteredItem === item.yt_video_id ? false : true;
                                }),
                              });
                            } else {
                              setState({ ...state, checkedItems: [...state.checkedItems, item.yt_video_id] });
                            }
                          }}
                        ></input>
                      </div>
                    </td>

                    <td>
                      <div className="flex-row center">
                        <img height="30" width="50" alt={title} src={image}></img>
                        <div className="left-padding-8" title={title}>
                          <RedirectButton
                            onClick={() => {
                              previewVideo(item.yt_video_id, item.name);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td style={{ verticalAlign: "middle", width: "30%" }}>
                      <div className="clamp-line-1" title={title} style={{ width: "100%" }}>
                        {title}
                      </div>
                    </td>
                    <td style={{ width: "20%" }}>
                      <div className="clamp-line-1" title={channelName} style={{ width: "100%" }}>
                        <a target="_blank" rel="noopener noreferrer" href={`${youtubeDomain}/channel/${channelID}`}>
                          {channelName}
                        </a>
                      </div>
                    </td>
                    <td style={{ width: "10%" }}>{releaseDate}</td>
                    <td style={{ width: "10%" }}>{views}</td>
                    <td>
                      <button
                        style={{ visibility: showMatchButton ? "visibile" : "hidden" }}
                        className="btn btn-default default-small"
                        onClick={() => {
                          onMatchClick(item, index);
                        }}
                      >
                        Match
                      </button>

                      <button
                        className="btn btn-danger default-small"
                        onClick={() => {
                          onDeleteClick(item, index);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <tr>
                <td>
                  <div className="flex-row center">
                    <input style={{ visibility: "hidden" }} type="checkbox"></input>
                  </div>
                </td>
                <td>
                  <div className="flex-row center" style={{ visibility: "hidden" }}>
                    <img height="30" width="50" alt={""} src={""}></img>
                    <div className="left-padding-8" title={""}>
                      <RedirectButton onClick={() => {}} />
                    </div>
                  </div>
                </td>
                <td style={{ verticalAlign: "middle", width: "30%" }}>
                  <div className="clamp-line-1" style={{ width: "100%" }}></div>
                </td>
                <td style={{ width: "20%" }}>
                  <div className="clamp-line-1" style={{ width: "100%" }}></div>
                </td>
                <td style={{ width: "10%" }}></td>
                <td style={{ width: "10%" }}></td>
                <td>
                  <button style={{ visibility: "hidden" }} className="btn btn-default default-small">
                    Match
                  </button>
                  <button style={{ visibility: "hidden" }} className="btn btn-danger default-small">
                    Delete
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan="100%">
                  <div>
                    <p style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{zeroStateText}</p>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
      <Pagination
        currentPage={state.currentPage}
        maxPages={Math.ceil(filteredDataLength / itemsPerPage)}
        totalResults={filteredDataLength}
        itemsPerPage={itemsPerPage}
        maxResultsInPagination={9999}
        showNumberOfResults={false}
        pageClickHandler={paginationClick}
        alwaysShowPrevButton={true}
        alwaysShowNextButton={true}
      />
    </React.Fragment>
  );
};

YoutubeTable.propTypes = {
  data: PropTypes.array,
  zeroStateText: PropTypes.string,
  onCheckedItemsChange: PropTypes.func,
  onMatchClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  showMatchButton: PropTypes.bool,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    data: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state),
  };
};

export default connect(mapStateToProps)(YoutubeTable);
