import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import Select from "../../../input/selectors/select";
import { articleStatus, enumsPath } from "../../../../constants/constants";
import Input from "../../../input/general/input";
import TextArea from "../../../input/general/textArea";
import SimilarArticles from "../../../input/general/similarArticles";

const GeneralInfoBox = ({ authors }) => {
  return (
    <FormBox title="General Info">
      <div className="row">
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Title *</label>
              <div className="input-group">
                <Input name="formData.title" type="text" placeholder="Title" className="form-control" />
              </div>
            </div>
            <div className="form-group">
              <label>Lead Text *</label>
              <TextArea
                name="formData.leadText"
                placeholder="Enter lead text"
                className="form-control"
                showClearInputButton={false}
                rows="12"
                maxLength={500}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="box-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Author *</label>
                  <Select
                    placeholder="Select author"
                    data={authors}
                    displayKey="name"
                    idKey="uuid"
                    additionalDataKey="surname"
                    additionalDataKeyDelimiter=" "
                    autocomplete={false}
                    name="formData.author"
                    showClearButton={false}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status *</label>
                  <Select
                    placeholder="Select status"
                    data={articleStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={false}
                    name="formData.status"
                    showClearButton={false}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Publish Dates</label>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-addon">START</div>
                      <Input
                        name="formData.startDate"
                        type="date"
                        placeholder="dd-mm-yyyy"
                        className="form-control"
                        showClearInputButton={false}
                      />
                    </div>
                    <div className="form-group float-list-item">
                      <div>
                        <br />
                        <label>
                          <Input type="checkbox" name="formData.featured" showClearInputButton={false} /> Highlighted on
                          Blog
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-addon">END</div>
                      <Input
                        name="formData.endDate"
                        type="date"
                        placeholder="dd-mm-yyyy"
                        className="form-control"
                        showClearInputButton={false}
                      />
                    </div>
                    <div className="form-group float-list-item">
                      <div>
                        <br />
                        <label>
                          <Input type="checkbox" name="formData.featured_learn" showClearInputButton={false} />{" "}
                          Highlighted on Resources
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <br />
                <label>Lead Youtube Video</label>
                <div className="input-group">
                  <Input name="formData.cover_video" type="text" placeholder="Youtube Video" className="form-control" />
                </div>
              </div>
              <div className="form-group">
                <br />
                <label>Similar articles</label>
                <div className="input-group">
                  <SimilarArticles name="formData.similarArticles" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

GeneralInfoBox.propTypes = {
  authors: PropTypes.array,
};

const mapStateToProps = state => {
  return { authors: getOr(null, `${enumsPath}.article_authors`, state) };
};

export default connect(mapStateToProps)(GeneralInfoBox);
