import React from "react";
import { useResetPageState } from "../../../lib/functions/customHooks";

function Dashboard() {
  useResetPageState();

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Dashboard</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-default">
              <div className="box-header">
                <h3 className="box-title">Coming soon</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Dashboard;
