import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import LinkInput from "./linkInput";
import { getLinkID, makeid, objectValueExistsInArray, getEmbedLink } from "../../../lib/functions/common";
import {
  PREPEND_ITEM_TO_ARRAY,
  REMOVE_ITEM_BY_INDEX,
  SET_STATE_VALUE,
  SET_UNSET_OBJECT_ARRAY_ITEM,
} from "../../../redux/reducers/common/actionTypes";
import { mainStateKey, modalPath } from "../../../constants/constants";
import { dispatchToReduxStore, handleDeleteEntity } from "../../../lib/functions/componentFunctions";

const LinkGroup = ({ type, name, value, addLink, dispatch, hasPrimaryButton, primaryLink, showPreview = true }) => {
  const [addDisabled, setAddDisabled] = useState(true);
  const [linkError, setLinkError] = useState("");

  const appendLinkToList = item => {
    if (
      item &&
      item.link &&
      !linkIDAlreadyExists(item.link) &&
      !objectValueExistsInArray("link", item.link, getOr([], "links", value)) &&
      !objectValueExistsInArray("link", item.link, getOr([], "matchLinks", value))
    ) {
      dispatchToReduxStore(name + ".addLink", SET_STATE_VALUE, "", dispatch);
      dispatchToReduxStore(name + ".links", PREPEND_ITEM_TO_ARRAY, item, dispatch);
      setAddDisabled(true);
    }
  };

  const manuallyAddedLinks = getOr([], "links", value).filter(item => {
    return value && value.matchLinks && value.matchLinks.findIndex(mlink => mlink.link === item.link) !== -1
      ? false
      : true;
  });

  const openLink = link => {
    window.open(link);
  };

  const openEmbed = url => {
    dispatchToReduxStore(
      modalPath,
      SET_STATE_VALUE,
      { show: true, data: { videoID: getEmbedLink(type, url) }, type: type + "Preview", hideCloseButton: true },
      dispatch
    );
  };

  const playSample = (sampleUrl, trackName) => {
    dispatchToReduxStore(
      modalPath,
      SET_STATE_VALUE,
      { show: true, data: { sampleUrl, trackName }, type: "musicPlayer", hideCloseButton: true },
      dispatch
    );
  };

  const match = (matched, item, linkIndex) => {
    if (!matched) {
      dispatchToReduxStore(name + ".links", SET_UNSET_OBJECT_ARRAY_ITEM, { ...item, link: item.link, type }, dispatch);
    } else {
      if (item.link === primaryLink) {
        dispatchToReduxStore("formData.primaryLink", SET_STATE_VALUE, "", dispatch);
      }

      dispatchToReduxStore(name + ".links", REMOVE_ITEM_BY_INDEX, { index: linkIndex }, dispatch);
    }
  };

  const removeMatch = async (item, linkIndex, matchLinkIndex) => {
    if (window.confirm("Are you sure you want to delete this match link? \n" + item.link)) {
      dispatchToReduxStore(name + ".links", REMOVE_ITEM_BY_INDEX, { index: linkIndex }, dispatch);
      dispatchToReduxStore(name + ".matchLinks", REMOVE_ITEM_BY_INDEX, { index: matchLinkIndex }, dispatch);
      await handleDeleteEntity(item.id, "track_match_link");
    }
  };

  const markPrimary = item => {
    if (window.confirm("Are you sure you want to change the primary link?")) {
      const dispatchedValue = item.link === primaryLink ? "" : item.link;
      dispatchToReduxStore("formData.primaryLink", SET_STATE_VALUE, dispatchedValue, dispatch);
    }
  };

  const linkIDAlreadyExists = url => {
    url = getLinkID(url, type);
    let exists = false;

    if (value) {
      if (value.links && value.links.length) {
        value.links.forEach(item => {
          if (getLinkID(item.link, type) === url) {
            exists = true;
          }
        });
      }
      if (value.matchLinks && value.matchLinks.length) {
        value.matchLinks.forEach(item => {
          if (getLinkID(item.link, type) === url) {
            exists = true;
          }
        });
      }
    }

    if (exists) {
      setLinkError("This link ID already exists in the form");
    } else {
      setLinkError("");
    }

    return exists;
  };

  let artistNames;
  let trackImg;
  let trackSample;
  let trackLink;
  let matched;
  let style;
  let linkIndex;

  return (
    <React.Fragment>
      <div className="form-group">
        <label className="col-sm-2 control-label">Add link to the list</label>
        <div className="col-sm-4">
          <LinkInput
            type={type}
            showClearInputButton={false}
            showRedirectButton={false}
            name={name + ".addLink"}
            onChange={(isValid, val) => {
              setLinkError("");
              setAddDisabled(isValid && val ? false : true);
            }}
          >
            <div className="input-group-btn">
              <button
                className="btn btn-default btn-small"
                style={{ display: "inline-block" }}
                disabled={addDisabled}
                onClick={() => {
                  appendLinkToList({ id: makeid(3), link: addLink, type });
                }}
              >
                Add
              </button>
            </div>
          </LinkInput>
          <p style={{ color: "red" }}>{linkError}</p>
        </div>
      </div>

      <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-sm-6">
          {!!manuallyAddedLinks.length && <b>Manually added links</b>}
          {value &&
            value.links &&
            value.links.map((item, index) => {
              let isPrimary = false;
              let style = isPrimary ? { border: "2px solid black" } : {};
              let isMatched =
                value && value.matchLinks && value.matchLinks.findIndex(mlink => mlink.link === item.link) !== -1
                  ? true
                  : false;

              return (
                <React.Fragment key={item.id}>
                  {!isMatched && (
                    <div className="input-group">
                      <LinkInput
                        style={style}
                        type={type}
                        name={name + ".links[" + index + "].link"}
                        onClearButtonClick={() => {
                          dispatchToReduxStore(name + ".links", SET_UNSET_OBJECT_ARRAY_ITEM, item, dispatch);
                        }}
                      >
                        {hasPrimaryButton && (
                          <div className="input-group-btn">
                            <button
                              onClick={markPrimary.bind(this, item, index)}
                              type="button"
                              style={{ minWidth: "79.7px" }}
                              className="btn btn-default "
                            >
                              Primary
                            </button>
                          </div>
                        )}
                        {showPreview && (
                          <span className="input-group-btn">
                            <button
                              type="button"
                              className="btn btn-default "
                              title="play"
                              onClick={openEmbed.bind(this, item.link)}
                            >
                              <i className="fa fa-play" />
                            </button>
                          </span>
                        )}
                      </LinkInput>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>

      <div className="row" style={{ marginTop: "30px" }}>
        <div className="col-md-12">
          {!!getOr([], "matchLinks", value).length && (
            <React.Fragment>
              <b>Match links</b>
              <table
                className="table  table-striped table-font-12 table-header-white"
                style={{ borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Name</th>
                    <th>Artists</th>
                    <th>Engagement</th>
                    <th>Label/Channel</th>
                    <th>Match Type Source</th>
                    <th>Release date</th>
                    <th>Match</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {getOr([], "matchLinks", value).map((item, index) => {
                    artistNames = [];
                    trackImg = getOr(null, "image", item);
                    trackSample = getOr(null, "sample", item);
                    trackLink = getOr(null, "link", item);
                    linkIndex = value && value.links && value.links.findIndex(link => link.link === trackLink);
                    matched = (linkIndex !== -1 && linkIndex) || linkIndex === 0 ? true : false;
                    style = matched ? { backgroundColor: "#bafdab" } : {};
                    const isPrimary = false;
                    const primaryStyle = isPrimary ? { border: "2px solid black" } : {};

                    if (item.artists) {
                      artistNames = item.artists.map(artist => {
                        return artist.name;
                      });
                    }

                    return (
                      <tr key={item.id} style={primaryStyle}>
                        <td style={style}>
                          <button
                            disabled={!trackSample}
                            type="button"
                            className="btn btn-default fa fa-play"
                            title="play"
                            onClick={playSample.bind(this, trackSample, item.name)}
                          ></button>
                          <button
                            disabled={!trackLink}
                            type="button"
                            className="btn btn-default fa fa-external-link"
                            onClick={openLink.bind(this, trackLink)}
                          ></button>
                        </td>
                        <td style={style}>
                          {" "}
                          {trackImg && <img height="30" width="30" src={trackImg} alt={item.name} />}{" "}
                          <a href={trackLink} target="_blank" without rel="noopener noreferrer">
                            {" "}
                            {getOr("", "name", item)}
                          </a>
                        </td>
                        <td style={style}>{artistNames.join(",")}</td>
                        <td style={style}>{getOr("", "engagement", item)}</td>
                        <td style={style}>{getOr("", "music_group", item)}</td>
                        <td style={style}>{getOr("", "match_source", item)}</td>
                        <td style={style}>{getOr("", "release_date", item)}</td>
                        <td style={style} className="action-buttons-last-col">
                          <button
                            type="button"
                            style={{ minWidth: "79.7px" }}
                            className="btn btn-default default-small "
                            onClick={match.bind(this, matched, item, linkIndex)}
                          >
                            {matched ? "Unmatch" : "Match"}
                          </button>{" "}
                          {hasPrimaryButton && (
                            <React.Fragment>
                              <button
                                onClick={markPrimary.bind(this, item, linkIndex)}
                                disabled={!matched}
                                type="button"
                                style={{ minWidth: "79.7px" }}
                                className="btn btn-default default-small "
                              >
                                Primary
                              </button>{" "}
                            </React.Fragment>
                          )}
                          <button
                            type="button"
                            className="btn btn-danger default-small"
                            onClick={removeMatch.bind(this, item, linkIndex, index)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

LinkGroup.propTypes = {
  boxTitle: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.object,
  addLink: PropTypes.string,
  dispatch: PropTypes.func,
  hasPrimaryButton: PropTypes.bool,
  primaryLink: PropTypes.string,
  showPreview: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  value: getOr(null, `${mainStateKey}.${ownProps.name}`, state),
  addLink: getOr(null, `${mainStateKey}.${ownProps.name}.addLink`, state),
  primaryLink: getOr(null, `${mainStateKey}.formData.primaryLink`, state),
});

export default connect(mapStateToProps)(LinkGroup);
