import React, { useState, useEffect, useMemo } from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { buildUrlFromTableParams } from "../../../../lib/functions/common";
import { mainStateKey, defaultListingTableRowNumber } from "../../../../constants/constants";
import artistDataManipulation from "../../../../lib/dataManipulation/artist/artistDataManipulation";
import FiltersBox from "./filtersBox";
import {
  setTableData,
  resetPageOnFilterChange,
  getColumnDefinitions,
  setEditButtonCustomRender,
} from "../../../../lib/functions/tableFunctions";
import { editArtistTableParametersData } from "../../../../constants/tableParametersData/editArtistTableParametersData";
import { usePrevious, useResetPageState } from "../../../../lib/functions/customHooks";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";
import { useHistory } from "react-router";
import ListingTable from "../../../presentational/table/listingTable";

const EditArtistListPage = ({ filters, sortData, row, page, dispatch }) => {
  useResetPageState();

  const routerHistory = useHistory();
  const statePath = editArtistTableParametersData.statePath;
  const sortPath = editArtistTableParametersData.sortPath;
  const filtersPath = editArtistTableParametersData.filtersPath;
  const allColumns = editArtistTableParametersData.columns;
  const previousFilters = usePrevious(filters);
  const [tableRowData, setTableRowData] = useState([]);
  const [tablePaginationData, setTablePaginationData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTableData(
      setTableRowData,
      setTablePaginationData,
      setLoading,
      buildUrlFromTableParams(filters, sortData, editArtistTableParametersData, row, page),
      artistDataManipulation,
      "artist"
    );
  }, [filters, sortData, row, page, dispatch]);

  resetPageOnFilterChange(previousFilters, filters, statePath, dispatch);
  let columns = useMemo(
    () => setColumnDefinitions(allColumns, sortPath, dispatch, statePath, routerHistory, tableRowData),
    [allColumns, dispatch, routerHistory, sortPath, statePath, tableRowData]
  );

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>
          Edit Artist &nbsp;
          <button
            className="btn btn-default btn-small"
            onClick={() => {
              dispatchToReduxStore(filtersPath, SET_STATE_VALUE, null, dispatch);
            }}
          >
            Clear filters
          </button>
        </h1>
      </section>
      <section className="content">
        <FiltersBox />
        <div className={loading ? "loading" : ""}>
          <div className="row">
            <div className="col-md-12">
              <ListingTable
                statePath={statePath}
                sortPath={sortPath}
                tablePaginationData={tablePaginationData}
                columnDefinitions={columns}
                tableRowData={tableRowData}
                columnsConstant={allColumns}
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const setColumnDefinitions = (allColumns, sortPath, dispatch, statePath, routerHistory) => {
  const columns = getColumnDefinitions(allColumns, sortPath);
  setEditButtonCustomRender(columns, dispatch, statePath, routerHistory, "/edit/artist/", "edit");
  setEditButtonCustomRender(
    columns,
    dispatch,
    statePath,
    routerHistory,
    "/edit/artist-youtube/",
    "youtube",
    "btn btn-default fa fa-youtube"
  );
  return columns;
};

EditArtistListPage.propTypes = {
  filters: PropTypes.object,
  row: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sortData: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    filters: getOr(null, `${mainStateKey}.${editArtistTableParametersData.filtersPath}`, state),
    sortData: getOr(null, `${mainStateKey}.${editArtistTableParametersData.sortPath}`, state),
    row: getOr(defaultListingTableRowNumber, `${mainStateKey}.${editArtistTableParametersData.statePath}.rows`, state),
    page: getOr(0, `${mainStateKey}.${editArtistTableParametersData.statePath}.page`, state),
  };
};

export default connect(mapStateToProps)(EditArtistListPage);
