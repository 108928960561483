import { getOr } from "lodash/fp";
import { getDataByType } from "./fieldDataManipulation";

const changeHistoryDataManipulation = function changeHistoryDataManipulation() {
  const getInwardReviewItem = data => {
    const activeItem = data && data[0] ? data[0] : {};
    const type = getOr(null, "entity_type", activeItem);

    return {
      ...data,
      activeItem: {
        ...activeItem,
        submitted: { ...getDataByType(activeItem, "submitted", type) },
        accepted: { ...getDataByType(activeItem, "new", type) },
        old: { ...getDataByType(activeItem, "old", type) },
        reviewData: { ...getDataByType(activeItem, "", type, true) },
      },
    };
  };

  return { inwardItem: getInwardReviewItem };
};

export default changeHistoryDataManipulation();
