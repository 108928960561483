import React from "react";
import { PropTypes } from "prop-types";
import { capitalizeString } from "../../../../../lib/functions/common";

const DecisionColumn = ({ fieldName }) => {
  return <td>{capitalizeString(fieldName.replace(/_/g, " "))}</td>;
};

DecisionColumn.propTypes = {
  fieldName: PropTypes.string,
};

export default DecisionColumn;
