import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { mainStateKey } from "../../../constants/constants";
import { connect } from "react-redux";
import { searchInNewTabClick } from "../../../lib/functions/common";
import ClaimedImage from "../general/claimedImage";
import OpenChangeHistory from "./openChangeHistory";

const VenueExternalHeaderButtons = ({ venueName, headerTitle, connectedTo, id, showOpenHistoryLog = true }) => {
  return (
    <h1>
      {headerTitle}
      <ClaimedImage connectedTo="formData.claimed" />{" "}
      <button
        disabled={!venueName}
        className="btn btn-default btn-search-outside"
        type="button"
        onClick={() => {
          searchInNewTabClick("google", venueName + " yelp");
        }}
      >
        Yelp
      </button>{" "}
      <button
        disabled={!venueName}
        className="btn btn-default btn-search-outside"
        type="button"
        onClick={() => {
          searchInNewTabClick("google", venueName + " tripadvisor");
        }}
      >
        Trip Advisor
      </button>
      {showOpenHistoryLog && <OpenChangeHistory type="venue" id={id} />}
    </h1>
  );
};

VenueExternalHeaderButtons.propTypes = {
  venueName: PropTypes.string,
  headerTitle: PropTypes.string,
  connectedTo: PropTypes.string,
  id: PropTypes.string,
  showOpenHistoryLog: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return {
    venueName: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state),
  };
};

export default connect(mapStateToProps)(VenueExternalHeaderButtons);
