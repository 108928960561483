import { set, getOr } from "lodash/fp";
import {
  SET_UNSET_PRIMITIVE_ARRAY_ITEM,
  SET_STATE_VALUE,
  SET_UNSET_OBJECT_ARRAY_ITEM,
  SWAP_ARRAY_ITEM_LEFT,
  SWAP_ARRAY_ITEM_RIGHT,
  REMOVE_ITEM_BY_INDEX,
  APPEND_ITEM_TO_ARRAY,
  PREPEND_ITEM_TO_ARRAY,
} from "./actionTypes";
import { objectExistsInArray } from "../../../lib/functions/common";

export default function(state = {}, action) {
  switch (action.type) {
    case SET_STATE_VALUE: {
      return set(action.name, action.payload, state);
    }

    case SET_UNSET_PRIMITIVE_ARRAY_ITEM: {
      let items = [...(getOr([], action.name, state) || [])];

      if (items.includes(action.payload)) {
        items = items.filter(item => item !== action.payload);
        return set(action.name, items, state);
      } else {
        items = [...items, action.payload];
      }

      return set(action.name, items, state);
    }

    case SET_UNSET_OBJECT_ARRAY_ITEM: {
      let items = [...(getOr([], action.name, state) || [])];

      if (objectExistsInArray(items, action.payload)) {
        items = items.filter(item => item.id !== action.payload.id);
        return set(action.name, items, state);
      } else {
        items = [...items, action.payload];
      }

      return set(action.name, items, state);
    }

    case APPEND_ITEM_TO_ARRAY: {
      let items = [...(getOr([], action.name, state) || [])];
      return set(action.name, [...items, action.payload], state);
    }

    case PREPEND_ITEM_TO_ARRAY: {
      let items = [...(getOr([], action.name, state) || [])];
      return set(action.name, [action.payload, ...items], state);
    }

    case REMOVE_ITEM_BY_INDEX: {
      let items = [...(getOr([], action.name, state) || [])];
      let newItems = items.filter((module, index) => {
        return index !== action.payload.index;
      });
      return set(action.name, newItems, state);
    }

    case SWAP_ARRAY_ITEM_LEFT: {
      let items = [...(getOr([], action.name, state) || [])];
      if (action.payload.index !== 0) {
        const temp = items[action.payload.index - 1];
        items[action.payload.index - 1] = items[action.payload.index];
        items[action.payload.index] = temp;
      }
      return set(action.name, items, state);
    }

    case SWAP_ARRAY_ITEM_RIGHT: {
      let items = [...(getOr([], action.name, state) || [])];
      if (action.payload.index !== items.length - 1) {
        const temp = items[action.payload.index + 1];
        items[action.payload.index + 1] = items[action.payload.index];
        items[action.payload.index] = temp;
      }
      return set(action.name, items, state);
    }

    default:
      return { ...state };
  }
}
