import React, { useEffect } from "react";
import Footer from "../../../presentational/page/footer";
import HighlightsBox from "../shared/highlightsBox";
import DescriptionBox from "../shared/descriptionBox";
import LinksBox from "../shared/linksBox";
import MediaBox from "../shared/mediaBox";
import OpeningHoursBox from "../shared/openingHoursBox";
import venueDataManipulation from "../../../../lib/dataManipulation/venue/venueDataManipulation";
import { handleAddFormSubmit, setFormData } from "../../../../lib/functions/componentFunctions";
import NameAndAdditionalInfoBox from "../shared/nameAndAdditionalInfoBox";
import LocationBox from "../shared/locationBox";
import VenueExternalHeaderButtons from "../../../presentational/buttons/venueExternalHeaderButtons";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const AddVenue = () => {
  useResetPageState();

  useEffect(() => {
    const defaultValues = {
      status: { id: 0, name: "Open" },
      video: {
        type: { id: 5, name: "Video" },
      },
    };

    setFormData(defaultValues);
  }, []);

  return (
    <React.Fragment>
      <section className="content-header">
        <VenueExternalHeaderButtons
          headerTitle="Add Venue"
          connectedTo="formData.name.name"
          showOpenHistoryLog={false}
        />
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-6">
                <NameAndAdditionalInfoBox />
              </div>
              <div className="col-md-6">
                <LocationBox />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <OpeningHoursBox />
                <MediaBox />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12"></div>
            </div>
          </div>
          <div className="col-md-5">
            <LinksBox showOpenAllLinksButton={false} />
            <HighlightsBox />
            <DescriptionBox />
          </div>
        </div>
        <Footer
          onSave={() => handleAddFormSubmit(false, venueDataManipulation, "venue")}
          onSaveAndClear={() => {
            handleAddFormSubmit(true, venueDataManipulation, "venue");
          }}
        />
      </section>
    </React.Fragment>
  );
};

export default AddVenue;
