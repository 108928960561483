import { getOr } from "lodash/fp";
import moment from "moment";

const webinarDataManipulation = function webinarDataManipulation() {
  const getOutwardWebinar = data => {
    return data;
  };

  const getInwardDatatable = data => {
    let parsedData = [];

    if (data && data.length && Array.isArray(data)) {
      data.forEach(item => {
        parsedData.push([
          getOr("", "title", item),
          item.start ? moment(item.start).format("YYYY-MM-DD HH:mm") : "",
          getOr("", "speakers", item),
          getOr("", "content_url", item),
          getOr("", "duration", item),
          item.id,
        ]);
      });
    }

    return parsedData;
  };

  const getInwardItem = data => {
    return { ...data, start: data.start ? new Date(data.start) : null };
  };

  return {
    outward: getOutwardWebinar,
    inwardDatatable: getInwardDatatable,
    inwardItem: getInwardItem,
  };
};

export default webinarDataManipulation();
