import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { mainStateKey } from "../../../constants/constants";
import { checkPermissionString } from "../../../lib/functions/common";
import { permissionStrings } from "../../../constants/permissionStrings";

const OpenChangeHistory = ({ id, type, edition, userPermissions }) => {
  userPermissions = userPermissions ? userPermissions : {};

  return (
    <React.Fragment>
      {checkPermissionString(userPermissions, permissionStrings.entityHistory) && (
        <button
          style={{ float: "right" }}
          className="btn btn-default"
          type="button"
          onClick={() => {
            if (id) {
              window.open("/history/" + type + "/" + id + (edition ? "/" + edition : ""));
            }
          }}
        >
          Open change history log
        </button>
      )}
    </React.Fragment>
  );
};

OpenChangeHistory.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  userPermissions: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    userPermissions: getOr(null, `${mainStateKey}.user.permissions`, state),
  };
};

export default connect(mapStateToProps)(OpenChangeHistory);
