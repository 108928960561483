import React from "react";
import QuoteModule from "../components/input/modules/quoteModule";
import VideoModule from "../components/input/modules/videoModule";
import PictureModule from "../components/input/modules/pictureModule";
import TextModule from "../components/input/modules/textModule";
import EntitySelector from "../components/input/selectors/entitySelector";
import JoinViberateModule from "../components/input/modules/joinViberateModule";
import NewsletterModule from "../components/input/modules/newsletterModule";
import Mp4Module from "../components/input/modules/mp4Module";
import SpotifyEmbed from "../components/input/modules/spotifyEmbed";
import SoundcloudEmbed from "../components/input/modules/soundcloudEmbed";
import TiktokEmbed from "../components/input/modules/tiktokEmbed";
import TwitterEmbed from "../components/input/modules/twitterEmbed";
import InstagramEmbed from "../components/input/modules/instagramEmbed";
import FacebookEmbed from "../components/input/modules/facebookEmbed";
import TableOfContents from "../components/input/modules/tableOfContents";
import CtaModule from "../components/input/modules/ctaModule";

export const articleComponents = [
  {
    component: <CtaModule />,
    value: null,
    selectorTitle: "Add CTA",
    selectorIcon: "fa fa-hand-o-up",
    moduleTitle: "CTA Module",
    moduleIcon: "fa fa-fw fa-hand-o-up",
    fieldName: "CTA",
  },
  {
    component: <JoinViberateModule />,
    value: "",
    selectorTitle: "Add Join Module",
    selectorIcon: "fa fa-sign-in",
    moduleTitle: "Join Module",
    moduleIcon: "fa fa-fw fa-sign-in",
    fieldName: "login",
  },
  {
    component: <VideoModule />,
    value: null,
    selectorTitle: "Add Youtube",
    selectorIcon: "fa fa-youtube",
    moduleTitle: "Youtube Module",
    moduleIcon: "fa fa-fw fa-youtube",
    fieldName: "video",
  },
  {
    component: <TextModule />,
    value: "",
    selectorTitle: "Add Key takeaways",
    selectorIcon: "fa fa-list-alt",
    moduleTitle: "Key takeaways",
    moduleIcon: "fa fa-fw fa-list-alt",
    fieldName: "takeaways",
  },
  {
    component: <NewsletterModule />,
    value: "",
    selectorTitle: "Add Newsletter",
    selectorIcon: "fa fa-envelope-o",
    moduleTitle: "Newsletter",
    moduleIcon: "fa fa-fw fa-envelope-o",
    fieldName: "newsletter",
  },
  {
    component: <Mp4Module />,
    value: "",
    selectorTitle: "Add Video",
    selectorIcon: "fa fa-video-camera",
    moduleTitle: "Video",
    moduleIcon: "fa fa-fw fa-video-camera",
    fieldName: "mp4Video",
  },
  {
    component: <InstagramEmbed />,
    value: "",
    selectorTitle: "Add Instagram embed",
    selectorIcon: "fa fa-instagram",
    moduleTitle: "Instagram embed",
    moduleIcon: "fa fa-fw fa-instagram",
    fieldName: "instagramEmbed",
  },
  {
    component: <TwitterEmbed />,
    value: "",
    selectorTitle: "Add Twitter embed",
    selectorIcon: "fa fa-twitter",
    moduleTitle: "Twitter embed",
    moduleIcon: "fa fa-fw fa-twitter",
    fieldName: "twitterEmbed",
  },
  {
    component: <TiktokEmbed />,
    value: "",
    selectorTitle: "Add TikTok embed",
    selectorIcon: "fa fa-music",
    moduleTitle: "TikTok Embed",
    moduleIcon: "fa fa-fw fa-music",
    fieldName: "tiktokEmbed",
  },
  {
    component: <SoundcloudEmbed />,
    value: "",
    selectorTitle: "Add Soundcloud embed",
    selectorIcon: "fa fa-soundcloud",
    moduleTitle: "Soundcloud embed",
    moduleIcon: "fa fa-fw fa-soundcloud",
    fieldName: "soundcloudEmbed",
  },
  {
    component: <SpotifyEmbed />,
    value: "",
    selectorTitle: "Add Spotify embed",
    selectorIcon: "fa fa-spotify",
    moduleTitle: "Spotify embed",
    moduleIcon: "fa fa-fw fa-spotify",
    fieldName: "spotifyEmbed",
  },
  {
    component: <FacebookEmbed />,
    value: "",
    selectorTitle: "Add Facebook embed",
    selectorIcon: "fa fa-facebook",
    moduleTitle: "Facebook embed",
    moduleIcon: "fa fa-fw fa-facebook",
    fieldName: "facebookEmbed",
  },
  {
    component: <TableOfContents />,
    value: "",
    selectorTitle: "Add table of contents",
    selectorIcon: "fa fa-list",
    moduleTitle: "Table of contents",
    moduleIcon: "fa fa-fw fa-list",
    fieldName: "tableOfContents",
  },
  {
    component: <PictureModule />,
    value: null,
    selectorTitle: "Add Picture",
    selectorIcon: "fa fa-picture-o",
    moduleTitle: "Picture Module",
    moduleIcon: "fa fa-fw fa-picture-o",
    fieldName: "photo",
  },
  {
    component: <QuoteModule />,
    value: "",
    selectorTitle: "Add Quote",
    selectorIcon: "fa fa-quote-left",
    moduleTitle: "Quote Module",
    moduleIcon: "fa fa-fw fa-quote-left",
    fieldName: "quote",
  },
  {
    component: <TextModule />,
    value: "",
    selectorTitle: "Add Text",
    selectorIcon: "fa fa-font",
    moduleTitle: "Text Module",
    moduleIcon: "fa fa-fw fa-font",
    fieldName: "text",
  },
];

export const articleTagsFirstTier = [
  { id: 1, name: "Artist" },
  { id: 2, name: "Venue" },
  { id: 3, name: "Event" },
  { id: 4, name: "Festival" },
  { id: 11, name: "Opinions" },
  { id: 12, name: "Insights" },
  { id: 13, name: "How-tos" },
  { id: 16, name: "Updates" },
  { id: 17, name: "Hidden" },
  { id: 18, name: "Festival Report" },
];

export const articleTagsSecondTier = [
  {
    id: 1,
    items: [{ id: 2, name: "Detail", selector: <EntitySelector entityType="artist" placeholder="Search Artist..." /> }],
  },
  {
    id: 2,
    items: [{ id: 2, name: "Detail", selector: <EntitySelector entityType="place" placeholder="Search Place..." /> }],
  },
  {
    id: 3,
    items: [{ id: 2, name: "Detail", selector: <EntitySelector entityType="event" placeholder="Search Event..." /> }],
  },
  {
    id: 4,
    items: [
      { id: 2, name: "Detail", selector: <EntitySelector entityType="festival" placeholder="Search Festival..." /> },
    ],
  },
];

export const componentCkEditorKeyPath = {
  photo: "data.caption",
  text: "data",
  takeaways: "data",
};
