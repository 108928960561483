import React from "react";
import FormBox from "../../../presentational/page/formBox";
import ImageGallery from "../../../input/media/imageGallery";
import VideoGallery from "../../../input/media/videoGallery";

const PhotoAndVideoBox = () => {
  return (
    <FormBox title="Photo And Video">
      <div className="box-body">
        <ImageGallery title="Impressions" name="formData.impressions" />
        <ImageGallery title="Official Gallery" name="formData.officialGallery" />
        <VideoGallery name="formData.videoGallery" />
      </div>
    </FormBox>
  );
};

export default PhotoAndVideoBox;
