import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import lodash from "lodash";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import LineupBox from "../shared/lineupBox";
import TicketInfoBox from "../shared/ticketInfoBox";
import EventNameBox from "./eventNameBox";
import VenueBox from "./venueBox";
import DateAndTimeBox from "./dateAndTimeBox";
import ScrappedInfoBox from "./scrappedInfoBox";
import LocationBox from "./locationBox";
import MatchedEventsBox from "./matchedEventsBox";
import Footer from "../../../presentational/page/footer";
import {
  setItemFormData,
  handleDeleteEntity,
  handleAddFormSubmit,
  showAppLoader,
  showFormSuccess,
  showFormFail,
  hideFormErrorReasons,
  showFormErrorReasons,
  redirectTo,
} from "../../../../lib/functions/componentFunctions";
import { mainStateKey } from "../../../../constants/constants";
import matchedEventDataManipulation from "../../../../lib/dataManipulation/event/matchedEventDataManipulation";
import apiManager from "../../../../lib/apiManager/apiManager";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const AddMatchedEvent = ({ hashedUserEmail, nextId }) => {
  useResetPageState();

  const routerHistory = useHistory();
  const routerParams = useParams();
  const routerId = routerParams.id;
  const [id, setId] = useState(routerId ? parseInt(routerId) : null);

  useEffect(() => {
    setItemFormData(id, "", "addMatchedEvent", { Email: hashedUserEmail });
    updateUrl(id);
  }, [id, hashedUserEmail]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Event Matching</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-8">
            <LineupBox />
            <div className="row">
              <div className="col-md-4">
                <EventNameBox />
              </div>
              <div className="col-md-5">
                <VenueBox />
              </div>
              <div className="col-md-3">
                <DateAndTimeBox />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                <LocationBox />
              </div>
              <div className="col-md-7">
                <MatchedEventsBox
                  handleMatch={(uuid, currentLineup, currentEventId) => {
                    onMatchButtonClick(uuid, currentLineup, currentEventId, nextId, routerHistory, true, setId);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <TicketInfoBox />
            <ScrappedInfoBox />
          </div>
        </div>
        <Footer
          onSaveAndNext={async () => {
            handleSave(true, id, nextId, routerHistory, setId);
          }}
          onSave={() => {
            handleSave(false, id, nextId, routerHistory, setId);
          }}
          onDelete={async () => {
            handleDelete(id, nextId, routerHistory, setId);
          }}
          showClearButton={false}
          showDeleteButton={true}
          showNextButton={nextId ? true : false}
        >
          <button
            type="button"
            onClick={() => {
              handleMissingEventData(id, nextId, 4, routerHistory, setId);
            }}
            className="btn btn-default bg-black margin"
          >
            No Artist
          </button>

          <button
            type="button"
            onClick={() => {
              handleMissingEventData(id, nextId, 3, routerHistory, setId);
            }}
            className="btn btn-default bg-black margin"
          >
            No Place
          </button>

          <button
            type="button"
            onClick={() => {
              handleMissingEventData(id, nextId, 5, routerHistory, setId);
            }}
            className="btn btn-default bg-black margin"
          >
            Not a music event
          </button>
        </Footer>
      </section>
    </React.Fragment>
  );
};

const handleSave = async (saveAndNext = false, id, nextId, routerHistory, setId) => {
  const submitSuccess = await handleAddFormSubmit(true, matchedEventDataManipulation, "event");
  if (submitSuccess) {
    apiManager.editEntity(id, "", "matchedEvent", { status: 2 }, "POST");
    redirectAfterSubmit(nextId, saveAndNext, routerHistory, setId);
  }
};

const handleMissingEventData = (id, nextId, status, routerHistory, setId) => {
  showAppLoader();
  apiManager
    .editEntity(id, "", "matchedEvent", { status: status }, "POST")
    .then(async () => {
      await showFormSuccess();
      redirectAfterSubmit(nextId, true, routerHistory, setId);
    })
    .catch(() => {
      showFormFail();
    });
};

const handleDelete = async (id, nextId, routerHistory, setId) => {
  const deleteSuccess = await handleDeleteEntity(id, "matchedEvent");

  if (deleteSuccess) {
    redirectAfterSubmit(nextId, true, routerHistory, setId);
  }
};

const redirectAfterSubmit = (nextId, redirectToNextMatchedEvent, routerHistory, setId) => {
  if (nextId && redirectToNextMatchedEvent) {
    hideFormErrorReasons();
    setId(nextId);
  } else {
    redirectTo(routerHistory, "/list/matchedEvent");
  }
};

const updateUrl = id => {
  window.history.replaceState("", "", window.location.origin + "/add/matchedEvent/" + id);
};

const onMatchButtonClick = async (uuid, currentLineup, currentEventId, nextId, routerHistory, saveAndNext, setId) => {
  showAppLoader();
  const matchedEventData = await apiManager.getEntity("event", uuid);

  if (matchedEventData) {
    const matchedEventLineup = matchedEventData.lineup ? matchedEventData.lineup.map(item => item.uuid) : [];
    currentLineup = currentLineup ? currentLineup.map(item => item.uuid) : [];
    const mergedLineup = lodash.uniq(matchedEventLineup.concat(currentLineup));
    matchedEventData.artist_uuids = mergedLineup;

    await apiManager
      .editEntity(uuid, "", "event", matchedEventData, "POST")
      .then(async () => {
        await apiManager.editEntity(currentEventId, "", "matchedEvent", { status: 2 }, "POST");
        showFormSuccess();
        redirectAfterSubmit(nextId, saveAndNext, routerHistory, setId);
      })
      .catch(e => {
        showFormFail();
        showFormErrorReasons(e);
      });
  } else {
    showFormFail();
  }
};

AddMatchedEvent.propTypes = {
  hashedUserEmail: PropTypes.string,
  dispatch: PropTypes.func,
  nextId: PropTypes.number,
};

const mapStateToProps = state => {
  return {
    nextId: getOr(null, `${mainStateKey}.formData.nextId`, state),
    hashedUserEmail: getOr(null, `${mainStateKey}.user.hashedEmail`, state),
  };
};

export default connect(mapStateToProps)(AddMatchedEvent);
