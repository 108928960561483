import { parseImage } from "../../functions/common";

const festivalAppExperiencesDataManipulation = function festivalAppExperiencesDataManipulation() {
  const getInwardEditFestivalItem = data => {
    return {
      experiencesList: data.map(experience => {
        return {
          id: experience.id,
          name: experience.name,
          description: experience.description,
          media: experience.images ? inwardImages(experience.images) : null,
          tag: experience.tag,
        };
      }),
    };
  };

  const getOutwardFestival = data => {
    return data.experiencesList.map(experience => {
      const experienceID = experience.id !== 0 ? { id: experience.id } : "";
      return {
        ...experienceID,
        name: experience.title ? experience.title : experience.name,
        description: experience.description,
        images: experience.media ? outwardImages(experience.media) : null,
        tag: experience.tag,
      };
    });
  };

  return { outward: getOutwardFestival, inwardItem: getInwardEditFestivalItem };
};

export default festivalAppExperiencesDataManipulation();

const inwardImages = data => {
  let profileImage = data.length > 0 ? data[0].url : null;
  let mediaImages = [];

  if (data.length > 1) {
    for (let i = 1; i < data.length; i++) {
      mediaImages.push(data[i].url);
    }
  }

  return {
    profileImage: profileImage,
    mediaImages: mediaImages,
  };
};

const outwardImages = data => {
  let images = [];

  if (data && data.profileImage) {
    images.push(parseImage(data.profileImage));
  }

  if (data && data.mediaImages) {
    data.mediaImages.map(image => images.push(parseImage(image)));
  }

  return images;
};
