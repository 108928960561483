import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import { mainStateKey } from "../../../../constants/constants";

const ScrappedInfoBox = ({ value }) => {
  return (
    <FormBox title="Scrapped Info">
      <div className="box-body">
        <div className="scraped-info">{ReactHtmlParser(value)}</div>
      </div>
    </FormBox>
  );
};

ScrappedInfoBox.propTypes = {
  value: PropTypes.string,
};

const mapStateToProps = state => {
  return { value: getOr(null, `${mainStateKey}.formData.scrappedInfo`, state) };
};

export default connect(mapStateToProps)(ScrappedInfoBox);
