import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import MultiSelector from "../../../input/selectors/multiSelector";
import { enumsPath } from "../../../../constants/constants";
import CurrentlySelectedFiltersList from "../../../presentational/general/currentlySelectedFiltersList";
import Input from "../../../input/general/input";
import { editEventTableParametersData } from "../../../../constants/tableParametersData/editEventTableParametersData";
import DateRange from "../../../input/date/dateRange";

const FiltersBox = ({ countries }) => {
  const filtersPath = editEventTableParametersData.filtersPath;
  return (
    <FormBox title="Filters" style={{ zIndex: "999" }}>
      <div className="box-body">
        <table className="filters-table">
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <small className="label">Event Starts from-to</small>
              </td>
              <td>
                <small className="label">Event Added from-to</small>
              </td>
            </tr>
            <tr>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input placeholder="Search event..." className="form-control" name={`${filtersPath}.eventName`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <MultiSelector
                      placeholder="Venue lookup filter"
                      displayKey="name"
                      idKey="uuid"
                      autocomplete={true}
                      showClearButton={false}
                      searchOnBackend={true}
                      backendSearchEntity={["venue"]}
                      name={`${filtersPath}.venueName`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Country lookup filter"
                    data={countries}
                    displayKey="name"
                    idKey="code"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.country`}
                  />
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="City lookup filter"
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    searchOnBackend={true}
                    backendSearchEntity={["city"]}
                    name={`${filtersPath}.city`}
                  />
                </div>
              </td>

              <td style={{ width: "200px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Artist lookup filter"
                    displayKey="name"
                    idKey="uuid"
                    autocomplete={true}
                    showClearButton={false}
                    searchOnBackend={true}
                    backendSearchEntity={["artist"]}
                    name={`${filtersPath}.lineup`}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <DateRange name={`${filtersPath}.eventDate`} />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <DateRange name={`${filtersPath}.dateAdded`} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="row">
          <div className="col-md-12">
            <CurrentlySelectedFiltersList
              tableParamsData={editEventTableParametersData}
              connectedTo={editEventTableParametersData.filtersPath}
            />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

FiltersBox.propTypes = {
  countries: PropTypes.array,
  subgenres: PropTypes.array,
  dispatch: PropTypes.func,
  genres: PropTypes.array,
};

const mapStateToProps = state => ({
  countries: getOr(null, `${enumsPath}.countries`, state),
});

export default connect(mapStateToProps)(FiltersBox);
