import React from "react";
import PropTypes from "prop-types";

function Pagination({
  currentPage,
  maxPages,
  totalResults,
  pageClickHandler,
  className,
  itemsPerPage,
  maxResultsInPagination,
  showNumberOfResults = true,
  alwaysShowPrevButton = false,
  alwaysShowNextButton = false,
  label = "",
}) {
  let pagination = [];
  let min = currentPage;
  let max = maxPages;

  if (currentPage === 1) {
    max = currentPage + 2 < maxPages ? currentPage + 2 : maxPages;
  } else if (currentPage >= maxPages - 3) {
    max = maxPages;
  } else {
    max = currentPage + 1 < maxPages ? currentPage + 1 : maxPages;
  }

  if (currentPage < 5) {
    min = 1;
  } else if (currentPage >= maxPages - 2) {
    min = maxPages - 3;
  } else {
    min = currentPage - 1;
  }

  const clickHandler = e => {
    pageClickHandler(parseInt(e.target.dataset.page, 10));
  };

  if (currentPage > 1 || alwaysShowPrevButton === true) {
    pagination.push(
      <button
        disabled={currentPage <= 1}
        key={pagination.length + 1}
        type="button"
        className="btn btn-default default-small"
        onClick={clickHandler}
        data-page={currentPage - 1}
      >
        Prev
      </button>
    );
  }

  if (currentPage - 3 > 1) {
    pagination.push(
      <button
        key={pagination.length + 1}
        type="button"
        onClick={clickHandler}
        className={"btn btn-default default-small"}
        data-page={1}
      >
        1
      </button>
    );
    pagination.push(
      <button key={pagination.length + 1} type="button" disabled={true} className="btn btn-pagination">
        ...
      </button>
    );
  }

  for (let i = min; i <= max; i++) {
    pagination.push(
      <button
        key={pagination.length + 1}
        type="button"
        onClick={clickHandler}
        className={"btn btn-default default-small " + (i === currentPage ? "active" : "")}
        data-page={i}
      >
        {i}
      </button>
    );
  }

  if (currentPage + 3 < maxPages) {
    pagination.push(
      <button key={pagination.length + 1} type="button" disabled={true} className="btn btn-default default-small">
        ...
      </button>
    );
    pagination.push(
      <button
        key={pagination.length + 1}
        type="button"
        onClick={clickHandler}
        className={"btn btn-default default-small"}
        data-page={maxPages}
      >
        {maxPages}
      </button>
    );
  }

  if (currentPage < maxPages || alwaysShowNextButton === true) {
    pagination.push(
      <button
        disabled={currentPage === maxPages}
        key={pagination.length + 1}
        type="button"
        className="btn btn-default default-small"
        onClick={clickHandler}
        data-page={currentPage + 1}
      >
        Next
      </button>
    );
  }

  let pageStart = (currentPage - 1) * itemsPerPage;
  if (pageStart === 0) {
    pageStart = 1;
  } else {
    pageStart++;
  }

  let pageEnd = (currentPage - 1) * itemsPerPage + itemsPerPage;
  if (pageEnd > totalResults) {
    pageEnd = totalResults;
  }

  return (
    <React.Fragment>
      {maxPages > 1 && (
        <div className={className} style={{ float: "right" }}>
          {label && <label>{label}</label>}
          {pagination}
          {showNumberOfResults && (
            <p>
              {pageStart}-{pageEnd} from{" "}
              {totalResults > maxResultsInPagination ? maxResultsInPagination + "+" : totalResults}
            </p>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  maxPages: PropTypes.number,
  pageClickHandler: PropTypes.func,
  totalResults: PropTypes.number,
  className: PropTypes.string,
  itemsPerPage: PropTypes.number,
  maxResultsInPagination: PropTypes.number,
  showNumberOfResults: PropTypes.bool,
  alwaysShowPrevButton: PropTypes.bool,
  alwaysShowNextButton: PropTypes.bool,
  label: PropTypes.string,
};

export default Pagination;
