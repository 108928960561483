export const apiCategories = {
  development: {
    account_api_url: "https://account.dev.vibe.rate/api/v2/",
    tonight_api_url: "https://tonight-test.viberate.com/api/v1/",
    com_api_url: "https://com.dev.vibe.rate/api/v1/",
    com_intapi_url: "https://com.dev.vibe.rate/intapi/v1/",
    event_api_url: "https://event-api-test.viberate.com/",
    editor_api_url: "https://editor.dev.vibe.rate/api/v1/",
  },
  test: {
    account_api_url: "https://staging-account-api.cluster.viberate.com/api/v2/",
    tonight_api_url: "https://tonight-test.viberate.com/api/v1/",
    com_api_url: "https://staging-com-api.cluster.viberate.com/api/v1/",
    com_intapi_url: "https://staging-com-api.cluster.viberate.com/intapi/v1/",
    event_api_url: "https://event-api-test.viberate.com/",
    editor_api_url: "https://staging-editor-api.cluster.viberate.com/api/v1/",
  },
  production: {
    account_api_url: "https://account.viberate.com/api/v2/",
    tonight_api_url: "https://tonight.viberate.com/api/v1/",
    com_api_url: "https://api.viberate.com/api/v1/",
    com_intapi_url: "https://api.viberate.com/intapi/v1/",
    event_api_url: "https://event-api.viberate.com/",
    editor_api_url: "https://editor-api.viberate.com/api/v1/",
  },
};

export const apis = {
  ACCOUNT_API: apiCategories[process.env.REACT_APP_ENVIRONMENT].account_api_url,
  TONIGHT_API: apiCategories[process.env.REACT_APP_ENVIRONMENT].tonight_api_url,
  COM_API: apiCategories[process.env.REACT_APP_ENVIRONMENT].com_api_url,
  COM_INTAPI: apiCategories[process.env.REACT_APP_ENVIRONMENT].com_intapi_url,
  EVENT_API: apiCategories[process.env.REACT_APP_ENVIRONMENT].event_api_url,
  EDITOR_API: apiCategories[process.env.REACT_APP_ENVIRONMENT].editor_api_url,
};

export const account = {
  CHALLENGE: apis.ACCOUNT_API + "auth/challenge/",
  AUTHENTICATE: apis.ACCOUNT_API + "auth/signin/",
  FACEBOOK_LOGIN: apis.ACCOUNT_API + "auth/facebook/signin/token/",
  USER_PERMISSIONS: apis.ACCOUNT_API + "user/permission/list/",
};

export const com = {
  GET_CHANNEL: apis.COM_API + "enum/channel/",
  GET_COUNTRY: apis.COM_API + "enum/country/",
  GET_GENRE: apis.COM_API + "enum/genre/",
  GET_FESTIVAL_AMENITY: apis.COM_API + "enum/festival/amenity/",
  GET_FESTIVAL_HIGHLIGHT: apis.COM_API + "enum/festival/highlight/",
  GET_FESTIVAL_CAPACITY: apis.COM_API + "enum/festival/capacity/",
  GET_VENUE_CAPACITY: apis.COM_API + "enum/venue/capacity/",

  SEARCH: apis.COM_API + "search/",
  USER_PROFILE: apis.COM_API + "user/profile/",
  SIGNIN: apis.COM_API + "auth/signin/",
  SIGNOUT: apis.COM_API + "auth/signout/",
  REFRESH_TOKEN: apis.COM_API + "auth/refresh/",
  GET_ARTIST: apis.COM_API + "artist/",
  GET_VENUE: apis.COM_API + "venue/",
  GET_EVENT: apis.COM_API + "event/",
  GET_FESTIVAL: apis.COM_API + "festival/",
  GET_FEATURED_CITIES: apis.COM_API + "city/featured/",
  GET_VENUE_UPCOMING_EVENTS: apis.COM_API + "event/upcoming/",
  GET_VENUE_PAST_EVENTS: apis.COM_API + "event/past/",
};

export const event = {
  GET_MATCHED_EVENT: apis.EVENT_API + "events/",
};

export const editor = {
  //Artist
  ADD_ARTIST: apis.EDITOR_API + "entity/artist/",
  GET_ARTIST: apis.EDITOR_API + "entity/artist/",
  EDIT_ARTIST: apis.EDITOR_API + "entity/artist/",
  EDIT_ARTIST_SUBGENRE: apis.EDITOR_API + "entity/artist/",
  GET_ARTIST_LIST: apis.EDITOR_API + "entity/artist/",

  //Venue
  GET_VENUE: apis.EDITOR_API + "entity/venue/",
  EDIT_VENUE: apis.EDITOR_API + "entity/venue/",
  ADD_VENUE: apis.EDITOR_API + "entity/venue/",
  GET_VENUE_LIST: apis.EDITOR_API + "entity/venue/",

  //Event
  GET_EVENT_LIST: apis.EDITOR_API + "entity/event/",
  ADD_EVENT: apis.EDITOR_API + "entity/event/",
  EDIT_EVENT: apis.EDITOR_API + "entity/event/",
  GET_EVENT: apis.EDITOR_API + "entity/event/",

  //Festival
  EDIT_FESTIVAL: apis.EDITOR_API + "entity/festival/",
  ADD_FESTIVAL: apis.EDITOR_API + "entity/festival/",
  ADD_FESTIVAL_EDITION: apis.EDITOR_API + "entity/festival/",
  GET_FESTIVAL: apis.EDITOR_API + "entity/festival/",
  GET_FESTIVAL_LIST: apis.EDITOR_API + "entity/festival/",
  MOVE_TO_NEXT_FESTIVAL_POOL: apis.EDITOR_API + "entity/festival/{uuid}/{edition}/pool/",
  MOVE_TO_FINISHED_FESTIVAL_POOL: apis.EDITOR_API + "entity/festival/{uuid}/{edition}/pool/",

  //Label
  ADD_LABEL: apis.EDITOR_API + "entity/label/",
  GET_LABEL: apis.EDITOR_API + "entity/label/",
  EDIT_LABEL: apis.EDITOR_API + "entity/label/",
  GET_LABEL_LIST: apis.EDITOR_API + "entity/label/",

  //Track
  ADD_TRACK: apis.EDITOR_API + "entity/track/",
  GET_TRACK: apis.EDITOR_API + "entity/track/",
  EDIT_TRACK: apis.EDITOR_API + "entity/track/",
  GET_TRACK_LIST: apis.EDITOR_API + "entity/track/",

  //TrackMatchLink
  EDIT_TRACK_MATCH_LINK: apis.EDITOR_API + "entity/track/match/",

  //Article
  GET_ARTICLE: apis.EDITOR_API + "com/article/",
  GET_ARTICLE_AUTHORS: apis.EDITOR_API + "com/article/author/",
  ADD_ARTICLE: apis.EDITOR_API + "com/article/",
  EDIT_ARTICLE: apis.EDITOR_API + "com/article/",

  GET_ARTICLE_CATEGORIES: apis.EDITOR_API + "com/article/category/",

  //ContributionHistory
  GET_CONTRIBUTION_HISTORY_LIST: apis.EDITOR_API + "contribution/change/",
  GET_CONTRIBTION_REVIEW: apis.EDITOR_API + "contribution/change/",
  EDIT_CONTRIBUTION_REVIEW: apis.EDITOR_API + "contribution/change/{uuid}/review/",

  GET_ARTIST_HISTORY: apis.EDITOR_API + "entity/artist/{uuid}/history/",
  GET_LABEL_HISTORY: apis.EDITOR_API + "entity/label/{uuid}/history/",
  GET_TRACK_HISTORY: apis.EDITOR_API + "entity/track/{uuid}/history/",
  GET_VENUE_HISTORY: apis.EDITOR_API + "entity/venue/{uuid}/history/",
  GET_EVENT_HISTORY: apis.EDITOR_API + "entity/event/{uuid}/history/",
  GET_FESTIVAL_HISTORY: apis.EDITOR_API + "entity/festival/{uuid}/{edition}/history/",

  //Help Center
  GET_HELP_CENTER_CATEGORIES: apis.EDITOR_API + "com/help-center/category/",
  GET_HELP_CENTER_CATEGORY: apis.EDITOR_API + "com/help-center/category/",
  EDIT_HELP_CENTER_CATEGORY: apis.EDITOR_API + "com/help-center/category/",
  ADD_HELP_CENTER_CATEGORY: apis.EDITOR_API + "com/help-center/category/",

  GET_HELP_CENTER_TOPIC: apis.EDITOR_API + "com/help-center/article/",
  GET_HELP_CENTER_TOPIC_LIST: apis.EDITOR_API + "com/help-center/article/",
  ADD_HELP_CENTER_TOPIC: apis.EDITOR_API + "com/help-center/article/",
  EDIT_HELP_CENTER_TOPIC: apis.EDITOR_API + "com/help-center/article/",

  //Webinar
  ADD_WEBINAR: apis.EDITOR_API + "com/webinar/",
  GET_WEBINAR_LIST: apis.EDITOR_API + "com/webinar/",
  GET_WEBINAR: apis.EDITOR_API + "com/webinar/",
  EDIT_WEBINAR: apis.EDITOR_API + "com/webinar/",

  //Feedpost
  GET_FEEDPOST_LIST: apis.EDITOR_API + "com/feed-post-editorial/",
  ADD_FEEDPOST: apis.EDITOR_API + "com/feed-post-editorial/",
  EDIT_FEEDPOST: apis.EDITOR_API + "com/feed-post-editorial/",
  GET_FEEDPOST: apis.EDITOR_API + "com/feed-post-editorial/",

  SEARCH: apis.EDITOR_API + "search/",

  //FAPP
  APP_INFO: "/info/",
  APP_EXPERIENCES: "/experiences/",
  APP_STAGES: "/stages/",
  APP_LINEUP: "/lineup/",
  APP_MEDIA: "/media/",
  APP_ADD_INFO: "/misc/",
  APP_SPONSORS: "/sponsors/",
  APP_MERCH: "/merchandise/",
  APP_MAP: "/map/",
  APP_ADD_CAMPAIGN: "/voting/campaign/",
  APP_GET_CAMPAIGN_SUGGESTIONS: "/voting/suggestions/",
  APP_GET_CAMPAIGN_LIST: "/voting/campaign/",
  APP_GET_CAMPAIGN_DETAIL: "/voting/campaign/",
  APP_EDIT_CAMPAIGN: "/voting/campaign/",
  APP_LIVE_MOMENTS_POSTS: "/livemoments/newest/",
  APP_LIVE_MOMENTS_REPORTS: "/livemoments/reported/",
  BAN_LIVE_MOMENTS_POST: "/livemoments/post/{postUUID}/ban/",
  BAN_LIVE_MOMENTS_REPORT: "/livemoments/post/{postUUID}/ban/",

  //OTHER
  GET_ENUMS: apis.EDITOR_API + "enum/",
  GET_ALLOWED_FAPPS: apis.EDITOR_API + "mainstage/user/allowed-fapps/",
};
