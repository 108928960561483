import React from "react";
import { getOr } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mainStateKey } from "../../../../constants/constants";
import FormBox from "../../../presentational/page/formBox";

const GeneralInfoBox = ({ formData, connectedTo }) => {
  const contribType = getOr("", `type`, formData);
  const entityType = getOr("", `entity_type`, formData);
  const status = getOr("", `status`, formData);
  const modEmail = getOr("", `mod.email`, formData);
  const modPoints = getOr("", `mod_points`, formData);
  const maxContributorPoints = getOr("", `user_max_points`, formData);
  const contributorPoints = getOr("", `user_points`, formData);
  const entityName = getOr("", `entity_name`, formData);
  const userEmail = getOr("", `user.email`, formData);
  const supervisorName = getOr("", `review.supervisor.name`, formData);
  const supervisorSurname = getOr("", `review.supervisor.surname`, formData);
  const supervisorEmail = getOr("", `review.supervisor.email`, formData);

  let submittedAt = getOr("", `created_at`, formData);
  let processedAt = getOr("", `processed_at`, formData);
  let supervisedAt = getOr("", `review.created_at`, formData);

  submittedAt = submittedAt
    ? moment(submittedAt)
        .local()
        .format("YYYY-MM-DD HH:mm:ss")
    : submittedAt;

  processedAt = processedAt
    ? moment(processedAt)
        .local()
        .format("YYYY-MM-DD HH:mm:ss")
    : processedAt;

  supervisedAt = supervisedAt
    ? moment(supervisedAt)
        .local()
        .format("YYYY-MM-DD HH:mm:ss")
    : supervisedAt;

  return (
    <FormBox title="">
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <div className="row">
              <div className="col-md-3">
                <p>
                  <b>Entity Name</b>: {entityName}
                </p>
                <p>
                  <b>Contribution type</b>: {contribType}
                </p>
                <p>
                  <b>Entity type</b>: {entityType}
                </p>
                <p>
                  <b>Contributor submitted at</b>: {submittedAt}
                </p>
                <p>
                  <b>Mod Processed at</b>: {processedAt}
                </p>
              </div>
              <div className="col-md-3">
                {supervisorName && (
                  <p>
                    <b>Supervisor name</b>: {supervisorName}
                  </p>
                )}
                {supervisorSurname && (
                  <p>
                    <b>Supervisor surname</b>: {supervisorSurname}
                  </p>
                )}
                {supervisorEmail && (
                  <p>
                    <b>Supervisor email</b>: {supervisorEmail}
                  </p>
                )}
                {supervisedAt && (
                  <p>
                    <b>Supervised at</b>: {supervisedAt}
                  </p>
                )}
              </div>
              <div className="col-md-3">
                <p>
                  <b>Mod Points</b>: {modPoints}
                </p>
                <p>
                  <b>Mod Decision</b>: {status}
                </p>
                <p>
                  <b>Mod Email</b>: {modEmail}
                </p>
              </div>
              <div className="col-md-3">
                <p>
                  <b>Max Contributor Points</b>: {maxContributorPoints}
                </p>
                <p>
                  <b>Achieved Contributor Points</b>: {contributorPoints}
                </p>
                <p>
                  <b>Contributor Email</b>: {userEmail}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    formData: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state),
  };
};

GeneralInfoBox.propTypes = {
  formData: PropTypes.object,
  connectedTo: PropTypes.string,
};

export default connect(mapStateToProps)(GeneralInfoBox);
