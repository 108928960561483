import React from "react";
import FormBox from "../../../presentational/page/formBox";
import Genres from "../../../input/general/genres";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { enumsPath } from "../../../../constants/constants";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";

const GenresBox = ({ dispatch, genres }) => {
  return (
    <FormBox title="Genres">
      <div className="box-body">
        <div className="form-group">
          <label style={{ fontWeight: "normal" }}>
            <input
              type="checkbox"
              onChange={e => {
                if (e.target.checked) {
                  const genreIDs = genres.map(item => {
                    return item.id;
                  });
                  dispatchToReduxStore("formData.genres", SET_STATE_VALUE, genreIDs, dispatch);
                } else {
                  dispatchToReduxStore("formData.genres", SET_STATE_VALUE, [], dispatch);
                }
              }}
            />{" "}
            All
          </label>
        </div>
        <Genres name="formData.genres" initialGenresPath="formData.initialGenres" />
      </div>
    </FormBox>
  );
};

GenresBox.propTypes = {
  dispatch: PropTypes.func,
  genres: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    genres: getOr(null, `${enumsPath}.genres`, state),
  };
};

export default connect(mapStateToProps)(GenresBox);
