import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import Select from "../../../input/selectors/select";
import FormBox from "../../../presentational/page/formBox";
import { enumsPath, mainStateKey } from "../../../../constants/constants";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";

const CategoryBox = ({ enums, domain, dispatch }) => {
  let articleCategories = getOr([], "article_categories", enums);
  const [categories, setCategories] = useState(articleCategories);

  useEffect(() => {
    if (domain) {
      const filtered = articleCategories.filter(ac => ac.domain_id === domain.id);
      setCategories(filtered);
    }
  }, [domain, articleCategories, dispatch]);

  useEffect(() => {
    if (domain?.id !== categories[0]?.domain_id) {
      dispatchToReduxStore("formData.category", SET_STATE_VALUE, null, dispatch);
    }
  }, [domain, categories, dispatch]);

  return (
    <FormBox title="Category">
      <div className="box-body">
        <div className="form-group">
          <Select
            data={categories}
            placeholder="Category"
            name="formData.category"
            idKey="id"
            displayKey="name"
            disabled={!domain || !categories.length}
          />
        </div>
      </div>
    </FormBox>
  );
};

CategoryBox.propTypes = {
  category: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    enums: getOr(null, `${enumsPath}`, state),
    domain: getOr(null, `${mainStateKey}.formData.domain`, state),
  };
};

export default connect(mapStateToProps)(CategoryBox);
