import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { modalPath, mainStateKey, youtubeDomain } from "../../../../constants/constants";

const YoutubePreview = ({ value }) => {
  const title = getOr("", "title", value);
  const videoID = getOr("", "videoID", value);

  const watchURL = `${youtubeDomain}watch?v=${videoID}`;
  const embedURL = `${youtubeDomain}embed/${videoID}?autoplay=1`;

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 title={title} className="modal-title clamp-line-1 " style={{ maxWidth: "600px" }}>
          {title}
        </h5>
        <a rel="noopener noreferrer" target="_blank" href={watchURL}>
          {watchURL}
        </a>
      </div>
      <div className="modal-body">
        <iframe allow="autoplay" title={title} width="500px" height="250px" src={embedURL}></iframe>
      </div>
    </div>
  );
};

YoutubePreview.propTypes = {
  value: PropTypes.object,
};

const mapStateToProps = state => {
  return { value: getOr("", `${mainStateKey}.${modalPath}.data`, state) };
};

export default connect(mapStateToProps)(YoutubePreview);
