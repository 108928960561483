import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import CmsContent from "../../../input/general/cmsContent";
import FormBox from "../../../presentational/page/formBox";
import { mainStateKey } from "../../../../constants/constants";

function ContentBox({ articleID }) {
  return (
    <FormBox title="Content">
      <div className="box-body">{articleID ? "Content from article..." : <CmsContent name="formData.content" />}</div>
    </FormBox>
  );
}

ContentBox.propTypes = {
  articleID: PropTypes.number,
};

const mapStateToProps = state => {
  return { articleID: getOr(null, `${mainStateKey}.formData.article[0].id`, state) };
};

export default connect(mapStateToProps)(ContentBox);
