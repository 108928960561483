import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { enumsPath, mainStateKey } from "../../../constants/constants";
import { mapArray } from "../../../lib/functions/common";

const TextList = ({ connectedTo, list = [], enumsProperty = "", enumValues = [] }) => {
  // Convert string to array (in some cases BE may send us a string instead of actualy array)
  if (typeof list === "string") {
    try {
      list = JSON.parse(list);
    } catch {
      list = [];
    }
  }

  if (enumsProperty && enumValues && enumValues.length && list) {
    enumValues = mapArray(enumValues, "id");
    list = list.map(item => {
      return { name: getOr("N/A", `[${item}].name`, enumValues) };
    });
  }

  return (
    <React.Fragment>
      {list &&
        list.map((item, index) => {
          return <p key={index}>{item.name}</p>;
        })}
    </React.Fragment>
  );
};

TextList.propTypes = {
  connectedTo: PropTypes.string,
  useEnums: PropTypes.bool,
  enumValues: PropTypes.array,
  enumsProperty: PropTypes.string,
  list: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

const mapStateToProps = (state, ownProps) => {
  return {
    list: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state),
    enumValues: getOr(null, `${enumsPath}.${ownProps.enumsProperty}`, state),
  };
};

export default connect(mapStateToProps)(TextList);
