import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import Select from "../selectors/select";
import { enumsPath, mainStateKey } from "../../../constants/constants";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import { prependNumberToSubgenreName } from "../../../lib/functions/common";

const Subgenres = ({
  subgenreEnums,
  name,
  value,
  dispatch,
  oldSubgenres,
  selectWrapperClassName = "col-sm-10",
  labelClassName = "col-sm-2 control-label",
  customSubgenres,
  disabled = false,
  onPrimaryGenreChange = () => {},
}) => {
  subgenreEnums = prependNumberToSubgenreName(subgenreEnums);
  const primary = getOr(null, [0], value);
  const secondary = getOr(null, [1], value);
  const tertiary = getOr(null, [2], value);

  const oldPrimary = getOr(null, `${[0]}.name`, oldSubgenres);
  const oldSecondary = getOr(null, `${[1]}.name`, oldSubgenres);
  const oldTertiary = getOr(null, `${[2]}.name`, oldSubgenres);

  const subgenres =
    customSubgenres && customSubgenres.length && Array.isArray(customSubgenres) ? customSubgenres : subgenreEnums;

  // Prevents user from selecting the same subgenre twice
  const availableSubgenres = [
    ...subgenres.filter(item => {
      return !(
        (primary && primary.id === item.id) ||
        (secondary && secondary.id === item.id) ||
        (tertiary && tertiary.id === item.id)
      );
    }),
  ];

  return (
    <React.Fragment>
      <div className="form-group">
        <label className={labelClassName}>Primary</label>
        <div className={selectWrapperClassName}>
          <Select
            disabled={disabled}
            name={`${name}[0]`}
            placeholder="Select primary subgenre"
            data={availableSubgenres}
            displayKey="name"
            idKey="id"
            autocomplete={true}
            onChange={val => {
              //Clear secondary and tertiary subgenres if primary is empty
              if (!val || !val.id) {
                dispatchToReduxStore(`${name}[1]`, SET_STATE_VALUE, null, dispatch);
                dispatchToReduxStore(`${name}[2]`, SET_STATE_VALUE, null, dispatch);
              }
              onPrimaryGenreChange(val);
            }}
            clearInputButtonMessage={
              "Are you sure you want to clear this subgenre? This will also clear secondary and tertiary subgenres"
            }
          />
          {oldPrimary && <small className="old-link">{oldPrimary}</small>}
        </div>
      </div>
      <div className="form-group">
        <label className={labelClassName}>Secondary</label>
        <div className={selectWrapperClassName}>
          <Select
            name={`${name}[1]`}
            placeholder="Select secondary subgenre"
            data={availableSubgenres}
            displayKey="name"
            idKey="id"
            disabled={!(primary && primary.id)}
            autocomplete={true}
            onChange={val => {
              //Clear  tertiary subgenres if secondary is empty
              if (!val || !val.id) {
                dispatchToReduxStore(`${name}[2]`, SET_STATE_VALUE, null, dispatch);
              }
            }}
            clearInputButtonMessage={
              "Are you sure you want to clear this subgenre? This will also clear tertiary subgenre"
            }
          />
          {oldSecondary && <small className="old-link">{oldSecondary}</small>}
        </div>
      </div>
      <div className="form-group">
        <label className={labelClassName}>Tertiary</label>
        <div className={selectWrapperClassName}>
          <Select
            name={`${name}[2]`}
            placeholder="Select tertiary subgenre"
            data={availableSubgenres}
            displayKey="name"
            idKey="id"
            disabled={!(secondary && secondary.id)}
            autocomplete={true}
            clearInputButtonMessage={"Are you sure you want to clear this subgenre?"}
          />
          {oldTertiary && <small className="old-link">{oldTertiary}</small>}
        </div>
      </div>
    </React.Fragment>
  );
};

Subgenres.propTypes = {
  numberOfSelects: PropTypes.number,
  name: PropTypes.string,
  subgenres: PropTypes.array,
  dispatch: PropTypes.func,
  selectWrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  customSubgenres: PropTypes.array,
  disabled: PropTypes.bool,
  onPrimaryGenreChange: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  subgenreEnums: getOr(null, `${enumsPath}.subgenres`, state),
  value: getOr(null, `${mainStateKey}.${ownProps.name}`, state),
  oldSubgenres: getOr(null, `${mainStateKey}.formData.oldSubgenres`, state),
});

export default connect(mapStateToProps)(Subgenres);
