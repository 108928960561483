// Most stuff is commented because article preview is now an iframe from analytics page
// THIS MAY CHANGE IN THE FUTURE

import React, { useEffect } from "react";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mainStateKey, /*ignoredArticleTagPages,*/ analyticsSiteUrl } from "../../../../constants/constants";
/*
import moment from "moment";
import articleDataManipulation from "../../../../lib/dataManipulation/article/articleDataManipulation";
import Text from "../articlePreview/contentBlocks/text";
import Quote from "../articlePreview/contentBlocks/quote";
import Photo from "../articlePreview/contentBlocks/photo";
import Video from "../articlePreview/contentBlocks/video";
import Login from "../articlePreview/contentBlocks/login";

import SpotifyEmbed from "./contentBlocks/spotifyEmbed";
import TwitterEmbed from "./contentBlocks/twitterEmbed";
import SoundcloudEmbed from "./contentBlocks/soundcloudEmbed";
import InstagramEmbed from "./contentBlocks/instagramEmbed";
import TikTokEmbed from "./contentBlocks/tiktokEmbed";
import FacebookEmbed from "./contentBlocks/facebookEmbed";
import Newsletter from "./contentBlocks/newsletter";
import Takeaways from "./contentBlocks/takeaways";
import Mp4Video from "./contentBlocks/mp4Video";
import SimilarArticle from "./similarArticle";
*/

const ArticlePreview = ({ setShowPreview, articleTagLimit = 5, formData }) => {
  let slug = getOr("", "slug", formData);

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  /*
  let data = articleDataManipulation.outward(formData);

  

  const authorName = formData && formData.author && formData.author.name ? formData.author.name : "";
  const authorSurname = formData && formData.author && formData.author.surname ? formData.author.surname : "";
  const authorDescription =
    formData && formData.author && formData.author.description ? formData.author.description : "";
  const author = authorName && authorSurname ? authorName + " " + authorSurname : authorName;
  const content = data && data.content ? data.content : [];
  const tags = data && data.tags ? data.tags : [];
  const start = data && data.start ? moment(data.start).format("D MMM YYYY") : "";
  const similarArticles = getOr([], "similarArticles", formData);

  const getContentBlock = (block, index) => {
    let component;

    switch (block.type) {
      case "text":
        component = <Text key={index} data={block} />;
        break;
      case "quote":
        component = <Quote key={index} data={block} />;
        break;
      case "photo":
        component = <Photo key={index} data={block} />;
        break;
      case "video":
        component = <Video key={index} data={block} />;
        break;
      case "spotifyEmbed":
        component = <SpotifyEmbed key={index} data={block} />;
        break;
      case "soundcloudEmbed":
        component = <SoundcloudEmbed key={index} data={block} />;
        break;
      case "twitterEmbed":
        component = <TwitterEmbed key={index} data={block} />;
        break;
      case "instagramEmbed":
        component = <InstagramEmbed key={index} data={block} />;
        break;
      case "tiktokEmbed":
        component = <TikTokEmbed key={index} data={block} />;
        break;
      case "facebookEmbed":
        component = <FacebookEmbed key={index} data={block} />;
        break;
      case "login":
        component = <Login key={index} data={block} />;
        break;
      case "newsletter":
        component = <Newsletter key={index} data={block} />;
        break;
      case "takeaways":
        component = <Takeaways key={index} data={block} />;
        break;
      case "mp4video":
        component = <Mp4Video key={index} data={block} />;
        break;
      default:
        component = null;
    }

    return component;
  };

  const getTags = () => {
    const tagsArray = [];
    let limit = articleTagLimit - 1;
    let tagObject;

    if (tags && tags.length) {
      for (let i = 0; i < tags.length; i++) {
        tagObject = tags[i];

        if (ignoredArticleTagPages.includes(tagObject.page) || !tagObject.text || tagObject.page === "") {
          limit++;
          continue;
        }

        tagsArray.push(<div className="blog-tag">{tagObject && tagObject.text ? tagObject.text : ""}</div>);

        if (i >= limit) {
          break;
        }
      }
    }

    return tagsArray;
  };
  */

  return (
    <div className={"custom-modal open"}>
      <article className="section white section-editors-pick-article v2">
        <button
          className="btn btn-default fa fa-close btn-close-modal"
          onClick={() => {
            setShowPreview(false);
          }}
        >
          {" "}
          Article has to be saved before being previewed
        </button>
        <iframe
          width="100%"
          height="100%"
          src={analyticsSiteUrl[process.env.REACT_APP_ENVIRONMENT] + "resources/blog/" + slug}
          title="Article preview"
          frameBorder="0"
        />
      </article>
    </div>
  );
};

ArticlePreview.propTypes = {
  setShowPreview: PropTypes.func,
  formData: PropTypes.object,
  articleTagLimit: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  return ownProps.showPreview === true ? { formData: getOr({}, `${mainStateKey}.formData`, state) } : {};
};

export default connect(mapStateToProps)(ArticlePreview);
