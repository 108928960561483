import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { objectValueExistsInArray, findObjectByValue } from "../../../lib/functions/common";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import EntitySelector from "../selectors/entitySelector";
import { mainStateKey } from "../../../constants/constants";
import { SET_UNSET_OBJECT_ARRAY_ITEM } from "../../../redux/reducers/common/actionTypes";
import ArtistListCard from "../../presentational/cards/artistListCard";

const ArtistList = ({ value, name, dispatch, disabled = false, formGroupClassName = "" }) => {
  const [matchedArtist, setMatchedArtist] = useState({});

  const appendArtistToList = artist => {
    if (!objectValueExistsInArray("id", artist.id, value)) {
      dispatchToReduxStore(name, SET_UNSET_OBJECT_ARRAY_ITEM, artist, dispatch);
    }
  };

  const setHighlightArtist = artistName => {
    const matchedArtistByName = findObjectByValue(value, "name", artistName);

    if (matchedArtistByName && matchedArtistByName.id) {
      setMatchedArtist(matchedArtistByName);
    } else {
      setMatchedArtist({});
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            {!disabled && (
              <EntitySelector
                entityType="artist"
                placeholder="Search Artist..."
                name="formData.artistSearchText"
                onChange={artist => {
                  appendArtistToList(artist);
                  setMatchedArtist({});
                }}
                onSearchTextChange={artistName => {
                  setHighlightArtist(artistName);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className={"form-group " + formGroupClassName}>
        <label>Lineup</label>
        <div className="clearfix lineup-group">
          {value &&
            value.length > 0 &&
            value.map(artist => {
              const matched = artist.id === matchedArtist.id ? true : false;
              return (
                <ArtistListCard key={artist.id} artist={artist} matched={matched} formName={name} disabled={disabled} />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

ArtistList.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
  dispatch: PropTypes.func,
  disabled: PropTypes.bool,
  formGroupClassName: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(ArtistList);
