import moment from "moment";
import commonDataManipulation from "../common/commonDataManipulation";
import { parseImage, capitalizeString } from "../../functions/common";

const artistDataManipulation = () => {
  const getOutwardArtist = data => {
    const youtubeVideos =
      data && data.youtubeVideos
        ? data.youtubeVideos.map(item => {
            return { yt_video_id: item.yt_video_id };
          })
        : [];

    return {
      city_id: data && data.city && data.city.id ? data.city.id : null,
      name: data.name && data.name.name ? data.name.name : null,
      country_code: data.country && data.country.code ? data.country.code : "",
      subgenre_ids: data.subgenres ? commonDataManipulation().subgenres.outward([...data.subgenres]) : [],
      image: data.image ? parseImage(data.image) : null,
      links: commonDataManipulation().links.outward(data.links),
      status: data.status && data.status.name ? data.status.name.toLowerCase() : null,
      youtube_videos: youtubeVideos,
    };
  };

  const getInwardEditArtistItem = data => {
    return {
      city: data && data.city ? data.city : null,
      name: data && data.name ? { name: data.name } : {},
      country: data && data.country ? data.country : null,
      links: data.links ? commonDataManipulation().links.inward(data.links) : [],
      oldLinks: data.links ? commonDataManipulation().links.inward(data.links) : [],
      subgenres: data.subgenres ? commonDataManipulation().subgenres.inwardItem(data.subgenres) : [],
      oldSubgenres: data.subgenres ? commonDataManipulation().subgenres.inwardItem(data.subgenres) : [],
      image: data && data.image ? data.image : "",
      uuid: data.uuid || null,
      status: data.status ? { name: capitalizeString(data.status) } : "",
      claimed: data && data.claimed ? data.claimed : false,
      initialYoutubeSuggestions: data.youtube_suggestions || [],
      youtubeSuggestions: data.youtube_suggestions || [],
      youtubeVideos: data.youtube_videos || [],
    };
  };

  const getInwardDatatable = data => {
    const listItems = [];

    if (data && data.length) {
      data.forEach(item => {
        listItems.push([
          item.name,
          item.country && item.country.name ? item.country.name : "",
          item.genre && item.genre.name ? item.genre.name : "",
          commonDataManipulation().subgenres.inwardDatatable(item.subgenres),
          item.status ? capitalizeString(item.status) : "",
          item.created_at ? moment(item.created_at).format("YYYY-MM-DD") : "",
          item.updated_at ? moment(item.updated_at).format("YYYY-MM-DD") : "",
          item.rank,
          item.claimed ? "Yes" : "No",
          item.uuid,
          item.uuid,
        ]);
      });
    }

    return listItems;
  };

  return {
    outward: getOutwardArtist,
    inwardItem: getInwardEditArtistItem,
    inwardDatatable: getInwardDatatable,
  };
};

export default artistDataManipulation();
