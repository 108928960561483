import React from "react";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import Stages from "../../../input/general/stages";
import { mainStateKey } from "../../../../constants/constants";

const StagesBox = ({ uuid, edition, stagesNumber = null }) => {
  return (
    <FormBox title="Stages">
      <div className="box-body">
        <Stages
          name="formData.stages"
          showFooter={false}
          editEntityType="festival"
          setItemDataEntityType="editFestival-stages"
          uuid={uuid}
          edition={edition}
          parseStagesAsObject={true}
          stagesNumber={stagesNumber}
        />
      </div>
    </FormBox>
  );
};

StagesBox.propTypes = {
  uuid: PropTypes.string,
  edition: PropTypes.string,
  stagesNumber: PropTypes.number,
};

const mapStateToProps = state => {
  return { stagesNumber: getOr(null, `${mainStateKey}.${"formData.stagesNumber"}`, state) };
};

export default connect(mapStateToProps)(StagesBox);
