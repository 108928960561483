import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import { enumsPath, mainStateKey } from "../../../../constants/constants";
import LinkGroup from "../../../input/links/linkGroup";

const LinksBox = ({ boxTitle, type, hasPrimaryButton, showPreview }) => {
  return (
    <FormBox title={boxTitle}>
      <div className="box-body">
        <div className="form-horizontal">
          <div className="">
            <LinkGroup
              showPreview={showPreview}
              hasPrimaryButton={hasPrimaryButton}
              type={type}
              name={"formData.links." + type}
            />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

LinksBox.propTypes = {
  boxTitle: PropTypes.string,
  type: PropTypes.string,
  hasPrimaryButton: PropTypes.bool,
  showPreview: PropTypes.bool,
};

const mapStateToProps = state => ({
  genres: getOr(null, `${enumsPath}.genres`, state),
  subgenres: getOr(null, `${enumsPath}.subgenres`, state),
  selectedGenre: getOr(null, `${mainStateKey}.formData.genre`, state),
});

export default connect(mapStateToProps)(LinksBox);
