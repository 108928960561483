import React from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { mainStateKey } from "../../../constants/constants";
import { SET_UNSET_PRIMITIVE_ARRAY_ITEM } from "../../../redux/reducers/common/actionTypes";

const MultiCheckbox = ({ value, name, onChange = () => {}, dispatch, className, pushedItem }) => {
  return (
    <React.Fragment>
      <input
        checked={Array.isArray(value) && value.includes(pushedItem) ? true : false}
        type="checkbox"
        className={className}
        onChange={e => {
          dispatchToReduxStore(name, SET_UNSET_PRIMITIVE_ARRAY_ITEM, pushedItem, dispatch);
          onChange(e.target.value);
        }}
      />
    </React.Fragment>
  );
};

MultiCheckbox.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  dispatch: PropTypes.func,
  className: PropTypes.string,
  pushedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(MultiCheckbox);
