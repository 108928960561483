import { getOr } from "lodash/fp";
import { findObjectByValue, getYoutubeThumbnail, parseImage } from "../../functions/common";
import { articleComponents } from "../../../constants/article";

const moduleDataManipulation = function moduleDataManipulation() {
  const outwardText = data => {
    return {
      type: "text",
      text: data && data.data ? data.data : null,
      media: null,
    };
  };

  const outwardQuote = data => {
    return {
      type: "quote",
      text: data.data || null,
      media: null,
    };
  };

  const outwardPicture = data => {
    return {
      type: "photo",
      text: data && data.data && data.data.caption ? data.data.caption : null,
      media: data && data.data && data.data.imageUrl ? { ...parseImage(data.data.imageUrl) } : null,
      text2: data && data.data && data.data.alt ? data.data.alt : null,
      text3: data && data.data && data.data.imageLink ? data.data.imageLink : null,
    };
  };

  const outwardVideo = data => {
    return {
      type: "video",
      text: data && data.data && data.data.caption ? data.data.caption : null,
      media: {
        url: data && data.data && data.data.videoUrl ? data.data.videoUrl : null,
      },
    };
  };

  const outwardLogin = data => {
    return {
      type: "login",
      text: getOr(null, "data.headline", data),
      text2: getOr(null, "data.subtext", data),
    };
  };

  const outwardTakeaways = data => {
    return {
      type: "takeaways",
      text: getOr(null, "data", data),
      media: null,
    };
  };

  const outwardNewsletter = data => {
    return {
      type: "newsletter",
      text: getOr(null, "data.title", data),
      text2: getOr(null, "data.subtext", data),
      text3: getOr(null, "data.cta", data),
      media: null,
    };
  };

  const outwardMp4Video = data => {
    return {
      type: "mp4Video",
      text: getOr(null, "data.videoUrl", data),
      text2: getOr(null, "data.caption", data),
      media: data && data.data && data.data.thumbnail ? { ...parseImage(data.data.thumbnail) } : null,
    };
  };

  const outwardEmbed = data => {
    return {
      type: data.fieldName,
      text: getOr(null, "data.url", data),
    };
  };

  const outwardTableOfContents = data => {
    return {
      type: "tableOfContents",
      text: JSON.stringify(getOr([], "data.listOfElements", data)),
    };
  };

  const outwardCTA = data => {
    return {
      type: "CTA",
      text: getOr(null, "data.text", data),
      text2: getOr(null, "data.url", data),
    };
  };

  const inwardText = data => {
    const type = data.type || null;
    const component = getComponent(type);
    const componentValue = data.text || "";

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardQuote = data => {
    const type = data.type || null;
    const component = getComponent(type);
    const componentValue = data.text || "";

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardPicture = data => {
    const type = data.type || null;
    const component = getComponent(type);
    const componentValue = {
      imageUrl: data.media && data.media.url ? data.media.url : "",
      caption: data.text || "",
      alt: data.text2 || "",
      imageLink: data.text3 || "",
    };

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardVideo = data => {
    const type = data.type || null;
    const component = getComponent(type);

    const componentValue = {
      videoUrl: data.media && data.media.url ? data.media.url : "",
      caption: data.text || "",
      preview: data.media && data.media.url ? getYoutubeThumbnail(data.media.url) : "",
    };

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardLogin = data => {
    const type = data.type || null;
    const component = getComponent(type);

    const componentValue = {
      headline: data.text || "",
      subtext: data.text2 || "",
    };

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardTakeaways = data => {
    const type = data.type || null;
    const component = getComponent(type);
    const componentValue = data.text || "";

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardNewsletter = data => {
    const type = data.type || null;
    const component = getComponent(type);

    const componentValue = {
      title: data.text || "",
      subtext: data.text2 || "",
      cta: data.text3 || "",
    };

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardMp4Video = data => {
    const type = data.type || null;
    const component = getComponent(type);

    const componentValue = {
      thumbnail: getOr("", "media.url", data),
      videoUrl: getOr("", "text", data),
      caption: getOr("", "text2", data),
    };

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardEmbed = data => {
    const component = getComponent(data.type);

    const componentValue = {
      url: getOr("", "text", data),
    };

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardTableOfConents = data => {
    const component = getComponent(data.type);

    const componentValue = {
      listOfElements: JSON.parse(getOr("", "text", data)),
    };

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  const inwardCTA = data => {
    const type = data.type || null;
    const component = getComponent(type);
    const componentValue = {
      text: getOr("", "text", data),
      url: getOr("", "text2", data),
    };

    return component && componentValue ? getContentObject(component, componentValue) : null;
  };

  return {
    text: {
      outward: outwardText,
      inward: inwardText,
    },
    quote: {
      outward: outwardQuote,
      inward: inwardQuote,
    },
    photo: {
      outward: outwardPicture,
      inward: inwardPicture,
    },
    video: {
      outward: outwardVideo,
      inward: inwardVideo,
    },
    login: {
      outward: outwardLogin,
      inward: inwardLogin,
    },
    newsletter: {
      outward: outwardNewsletter,
      inward: inwardNewsletter,
    },
    takeaways: {
      outward: outwardTakeaways,
      inward: inwardTakeaways,
    },
    mp4Video: {
      outward: outwardMp4Video,
      inward: inwardMp4Video,
    },
    spotifyEmbed: {
      outward: outwardEmbed,
      inward: inwardEmbed,
    },
    soundcloudEmbed: {
      outward: outwardEmbed,
      inward: inwardEmbed,
    },
    tiktokEmbed: {
      outward: outwardEmbed,
      inward: inwardEmbed,
    },
    twitterEmbed: {
      outward: outwardEmbed,
      inward: inwardEmbed,
    },
    instagramEmbed: {
      outward: outwardEmbed,
      inward: inwardEmbed,
    },
    facebookEmbed: {
      outward: outwardEmbed,
      inward: inwardEmbed,
    },
    tableOfContents: {
      outward: outwardTableOfContents,
      inward: inwardTableOfConents,
    },
    CTA: {
      outward: outwardCTA,
      inward: inwardCTA,
    },
  };
};

export default moduleDataManipulation();

function getComponent(type) {
  return findObjectByValue(articleComponents, "fieldName", type);
}

function getContentObject(component, componentValue) {
  return {
    component: component.component,
    data: componentValue,
    fieldName: component.fieldName,
    icon: component.moduleIcon,
    title: component.moduleTitle,
  };
}
