import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { mainStateKey } from "../../../constants/constants";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

function TextEditor({ onChange = () => {}, name, dispatch, value }) {
  return getEditor(onChange, name, dispatch, value);
}

function getEditor(onChange, name, dispatch, value) {
  if (typeof window === "undefined") {
    return <textarea />;
  } else {
    //Import has to be conditional since CKEditor depends on clients window and cannot be rendered server side
    const CKEditor = require("@ckeditor/ckeditor5-react");
    const ClassicEditor = require("@bste/ckeditor5-custom");

    const config = {
      toolbar: {
        items: [
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "subscript",
          "superscript",
          "link",
          "heading",
          "htmlEmbed",
          "BulletedList",
        ],
      },
      heading: {
        options: [
          { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
          { model: "heading1", view: "h1", title: "Heading 1" },
          { model: "heading2", view: "h2", title: "Heading 2" },
          { model: "heading3", view: "h3", title: "Heading 3" },
        ],
      },
    };

    return (
      <CKEditor
        editor={ClassicEditor}
        config={config}
        data={value ? value : ""}
        onBlur={(event, editor) => {
          const data = editor.getData();
          onChange(data);
          dispatchToReduxStore(name, SET_STATE_VALUE, data, dispatch);
        }}
      />
    );
  }
}

TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(TextEditor);
