import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { mainStateKey } from "../../../constants/constants";
import YoutubeTable from "../../presentational/table/youtubeTable";
import { dispatchToReduxStore, handleDeleteEntity } from "../../../lib/functions/componentFunctions";
import { PREPEND_ITEM_TO_ARRAY, REMOVE_ITEM_BY_INDEX } from "../../../redux/reducers/common/actionTypes";
import { findObjectIndexByValue } from "../../../lib/functions/common";

const YoutubeSuggestionsTable = ({ value, dispatch, name, artistUUID }) => {
  const matchSuggestion = item => {
    const index = findObjectIndexByValue(value, "yt_video_id", item.yt_video_id);

    if (index !== -1) {
      dispatchToReduxStore(name, REMOVE_ITEM_BY_INDEX, { index }, dispatch);
      dispatchToReduxStore("formData.youtubeVideos", PREPEND_ITEM_TO_ARRAY, item, dispatch);
    }
  };

  const deleteSuggestion = async item => {
    const index = findObjectIndexByValue(value, "yt_video_id", item.yt_video_id);

    if (index !== -1) {
      if (window.confirm("Are you sure you want to delete this suggestion?")) {
        const deleteSuccess = await handleDeleteEntity(artistUUID, "youtube_suggestion", {}, "", "PUT", [
          { yt_video_id: item.yt_video_id },
        ]);

        if (deleteSuccess) {
          dispatchToReduxStore("formData.youtubeSuggestions", REMOVE_ITEM_BY_INDEX, { index }, dispatch);
        }
      }
    }
  };

  return (
    <YoutubeTable
      connectedTo={name}
      zeroStateText="No pending youtube suggestions found"
      onMatchClick={matchSuggestion}
      onDeleteClick={deleteSuggestion}
    />
  );
};

YoutubeSuggestionsTable.propTypes = {
  name: PropTypes.string,
  artistUUID: PropTypes.string,
  dispatch: PropTypes.func,
  value: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getOr(null, `${mainStateKey}.${ownProps.name}`, state),
    artistUUID: getOr(null, `${mainStateKey}.formData.uuid`, state),
  };
};

export default connect(mapStateToProps)(YoutubeSuggestionsTable);
