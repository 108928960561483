import React from "react";
import { PropTypes } from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {
  getCurrentTableRowPerPage,
  getCurrentTablePage,
  onColumnSort,
  scrollToVerticalPosition,
  getTableTheme,
} from "../../../lib/functions/tableFunctions";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { connect } from "react-redux";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import CustomTableFooter from "./customTableFooter";
import { makeid } from "../../../lib/functions/common";

const ListingTable = ({
  statePath,
  sortPath,
  tablePaginationData,
  columnsConstant,
  columnDefinitions,
  dispatch,
  tableRowData,
}) => {
  const datatableOptions = {
    selectableRows: "none",
    serverSide: true,
    count: tablePaginationData.total || 0,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "stacked",
    filter: false,
    search: false,
    rowsPerPage: getCurrentTableRowPerPage(statePath),
    page: getCurrentTablePage(tableRowData, tablePaginationData, statePath),
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
      <CustomTableFooter
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        pageInputName={`${statePath}.page`}
      />
    ),
    onColumnSortChange: (changedColumn, direction) => {
      onColumnSort(changedColumn, direction, columnsConstant, dispatch, sortPath);
    },
    onTableInit: (action, tableState) => {
      scrollToVerticalPosition(dispatch, statePath, tableState.data);
    },
    onChangeRowsPerPage: rows => {
      dispatchToReduxStore(`${statePath}.rows`, SET_STATE_VALUE, rows, dispatch);
    },
    onChangePage: page => {
      dispatchToReduxStore(`${statePath}.page`, SET_STATE_VALUE, page, dispatch);
    },
  };

  return (
    <MuiThemeProvider theme={getTableTheme()}>
      <MUIDataTable key={makeid(5)} data={tableRowData} columns={columnDefinitions} options={datatableOptions} />
    </MuiThemeProvider>
  );
};

ListingTable.propTypes = {
  statePath: PropTypes.string,
  sortPath: PropTypes.string,
  tablePaginationData: PropTypes.object,
  columnDefinitions: PropTypes.array,
  dispatch: PropTypes.func,
  tableRowData: PropTypes.array,
  columnsConstant: PropTypes.array,
};

export default connect(null)(ListingTable);
