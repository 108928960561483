import React from "react";
import FormBox from "../../../presentational/page/formBox";
import OpeningHours from "../../../input/general/openingHours";

const OpeningHoursBox = () => {
  return (
    <FormBox title="Opening Hours">
      <div className="box-body">
        <OpeningHours name="formData.openingHours" />
      </div>
    </FormBox>
  );
};

export default OpeningHoursBox;
