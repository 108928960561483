export const contributionHistoryTableParametersData = {
  statePath: "tables.editEventTableParams",
  filtersPath: "tables.editEventTableParams.filters",
  sortPath: "tables.editEventTableParams.sort",
  columns: [
    {
      name: "user",
      label: "Email",
      filterDefinition: {
        backendFieldValue: {
          "user-uuid": "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "contributionType",
      label: "Type",
      filterDefinition: {
        backendFieldValue: {
          type: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "entityType",
      label: "Entity Type",
      sortBackendName: "entity-type",
      filterDefinition: {
        backendFieldValue: {
          "entity-type": "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "entityName",
      label: "Entity Name",
      sortBackendName: "entity-name",
      filterDefinition: {
        backendFieldValue: {
          "entity-name": "name",
        },
      },
    },
    {
      name: "userPoints",
      label: "User Points",
    },
    {
      name: "modPoints",
      label: "Mod Points",
    },

    {
      name: "dateCreated",
      label: "Date & Time Created",
      sortBackendName: "created",
      filterDefinition: {
        backendFieldValue: {
          "created-from": "startDateTimeTZ",
          "created-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "dateModerated",
      label: "Date & Time Moderated",
      sortBackendName: "processed",
      filterDefinition: {
        backendFieldValue: {
          "processed-from": "startDateTimeTZ",
          "processed-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "modName",
      label: "Mod Name",
      filterDefinition: {
        backendFieldValue: {
          mod_name: "name",
        },
      },
    },
    {
      name: "action",
      label: "Action",
    },
    {
      name: "reviewComment",
      label: "Comment",
    },
    {
      name: "review",
      //hideInTable: true,
      sortBackendName: "review",
      label: "Reviewed",
      filterDefinition: {
        backendFieldValue: {
          review: "id",
        },
      },
    },
    {
      name: "edit",
    },
  ],
};
