import { set, getOr } from "lodash/fp";

import {
  APPEND_ARTICLE_TAG,
  SET_ARTICLE_TAG_SELECTOR,
  SET_ARTICLE_TAG_TIER2_ITEMS,
  SET_SELECTED_TAG_TIER1,
  SET_SELECTED_TAG_TIER2,
  SET_SELECTED_TAG_ENTITY,
  SET_ARTICLE_TAG_NAME,
  REMOVE_ARTICLE_TAG,
} from "./actionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case APPEND_ARTICLE_TAG: {
      let articleTags = [...(getOr([], `${action.name}.tags`, state) || [])];
      return set(`${action.name}.tags`, [...articleTags, action.payload.tag], state);
    }

    case SET_ARTICLE_TAG_SELECTOR: {
      return set(`${action.name}.selector`, action.payload.selector, state);
    }

    case SET_ARTICLE_TAG_TIER2_ITEMS: {
      return set(`${action.name}.tier2Items`, action.payload.items, state);
    }

    case SET_SELECTED_TAG_TIER1: {
      return set(`${action.name}.selectedTier1`, action.payload.selectedTier1, state);
    }

    case SET_SELECTED_TAG_TIER2: {
      return set(`${action.name}.selectedTier2`, action.payload.selectedTier2, state);
    }

    case SET_SELECTED_TAG_ENTITY: {
      return set(`${action.name}.selectedEntity`, action.payload.selectedEntity, state);
    }

    case SET_ARTICLE_TAG_NAME: {
      return set(`${action.name}.tagName`, action.payload.tagName, state);
    }

    case REMOVE_ARTICLE_TAG: {
      let articleTags = [...(getOr([], `${action.name}.tags`, state) || [])];
      let newArticleTags = articleTags.filter(function(tag, index) {
        return index !== action.payload.index;
      });
      return set(`${action.name}.tags`, newArticleTags, state);
    }

    default:
      return { ...state };
  }
}
