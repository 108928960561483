import moment from "moment";
import commonDataManipulation from "../common/commonDataManipulation";
import { getOr } from "lodash/fp";
import { parseImage, capitalizeString } from "../../functions/common";

const labelDataManipulation = labelDataManipulation => {
  const getOutwardlabel = data => {
    return {
      name: data.name && data.name.name ? data.name.name : null,
      image: data.image ? parseImage(data.image) : null,
      links: commonDataManipulation().links.outward(data.links),
      status: data.status && data.status.name ? data.status.name.toLowerCase() : null,
    };
  };

  const getInwardEditlabelItem = data => {
    return {
      name: data && data.name ? { name: data.name } : {},
      links: data.links ? commonDataManipulation().links.inward(data.links) : [],
      oldLinks: data.links ? commonDataManipulation().links.inward(data.links) : [],
      image: data && data.image ? data.image : "",
      uuid: data.uuid || null,
      status: data.status ? { name: capitalizeString(data.status) } : "",
      claimed: data && data.claimed ? data.claimed : false,
    };
  };

  const getInwardDatatable = data => {
    const listItems = [];

    if (data && data.length) {
      data.forEach(item => {
        listItems.push([
          item.name,
          item.status ? capitalizeString(item.status) : "",
          getOr("", "beatport_ranks.current.overall", item),
          item.created_at ? moment(item.created_at).format("YYYY-MM-DD") : "",
          item.updated_at ? moment(item.updated_at).format("YYYY-MM-DD") : "",
          item.claimed ? "Yes" : "No",
          item.uuid,
        ]);
      });
    }

    return listItems;
  };

  return {
    outward: getOutwardlabel,
    inwardItem: getInwardEditlabelItem,
    inwardDatatable: getInwardDatatable,
  };
};

export default labelDataManipulation();
