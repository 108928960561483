import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import FormBox from "../../../presentational/page/formBox";
import Select from "../../../input/selectors/select";
import { enumsPath } from "../../../../constants/constants";
import { connect } from "react-redux";

function DomainBox({ enums }) {
  let articleDomains = getOr([], "article_domains", enums);

  return (
    <FormBox title="Domain">
      <div className="box-body">
        <div className="form-group">
          <Select data={articleDomains} placeholder="domain" name="formData.domain" idKey="id" displayKey="name" />
        </div>
      </div>
    </FormBox>
  );
}

DomainBox.propTypes = {
  enums: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    enums: getOr(null, `${enumsPath}`, state),
  };
}

export default connect(mapStateToProps)(DomainBox);
