import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import Input from "../../../input/general/input";
import { connect } from "react-redux";
import { modalPath, mainStateKey } from "../../../../constants/constants";
import {
  clearFormData,
  dispatchToReduxStore,
  handleEditFormSubmit,
  showFormSuccess,
} from "../../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";

const LiveMomentsBanModal = ({ value, dispatch, eol }) => {
  useEffect(() => {
    clearFormData();
  }, []);

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Ban details</h5>
      </div>
      <div className="modal-body">
        <label style={{ marginBottom: "0px" }}>
          Remove this post and prevent this user from posting for a set duration
        </label>
        <br />
        <Input
          name="formData.duration"
          showClearInputButton={false}
          placeholder="Ban duration in hours"
          type="number"
          onChange={item => {
            if (item) {
              dispatchToReduxStore("formData.eol", SET_STATE_VALUE, false, dispatch);
            }
          }}
          debounceTime={0}
        />
        <p style={{ marginTop: "10px" }}>Or</p>
        <label style={{ marginBottom: "0px" }}>
          <Input
            debounceTime={0}
            onChange={item => {
              if (!item) {
                dispatchToReduxStore("formData.duration", SET_STATE_VALUE, "", dispatch);
              }
            }}
            type="checkbox"
            name="formData.eol"
            showClearInputButton={false}
          />{" "}
          Remove all posts that belong to this user and prevent this user from posting forever
        </label>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-default bg-black"
          onClick={async () => {
            const success = await handleEditFormSubmit(
              value.appID,
              value.manipulation,
              value.banType,
              "POST",
              value.editionID,
              false,
              value.uuid
            );

            if (success) {
              if (value.tableRowData && value.setTableRowData) {
                const filterByKey = eol ? "user_uuid" : "uuid";
                value.setTableRowData(
                  value.tableRowData.filter(item => {
                    return item[4] && item[4][filterByKey] === value[filterByKey] ? false : true;
                  })
                );
              }
              dispatchToReduxStore(modalPath, SET_STATE_VALUE, { show: false }, dispatch);
              showFormSuccess();
            }
          }}
        >
          Submit
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={() => {
            dispatchToReduxStore(modalPath, SET_STATE_VALUE, { show: false }, dispatch);
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

LiveMomentsBanModal.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    value: getOr("", `${mainStateKey}.${modalPath}.data`, state),
    eol: getOr("", `${mainStateKey}.formData.eol`, state),
  };
};

export default connect(mapStateToProps)(LiveMomentsBanModal);
