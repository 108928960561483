import React from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { mainStateKey } from "../../../../constants/constants";
import FormBox from "../../../presentational/page/formBox";
import ReviewColumn from "./tableColumns/reviewColumn";
import DecisionColumn from "./tableColumns/decisionColumn";
import FieldNameColumn from "./tableColumns/fieldNameColumn";
import DataColumn from "./tableColumns/dataColumn";
import LinkRows from "./tableColumns/linkRows";
import { disabledReviewTypes } from "../../../../constants/constants";
import { DIFFERENCE_KEYS } from "../../../../constants/historyLog";
import { arrayDiff } from "../../../../lib/functions/common";

const FieldsBox = ({
  submitted,
  accepted,
  old,
  reviewData,
  contributionType,
  connectedTo,
  showReview = true,
  entityType,
}) => {
  let decision;
  let className = "";
  let diff1 = {};
  let diff2 = {};

  return (
    <FormBox title="">
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <table className="compare-table">
              <thead>
                <tr>
                  <th>Field name</th>
                  <th>Old (before submission)</th>
                  <th>Submitted by Contributor</th>
                  {!disabledReviewTypes.includes(contributionType) && (
                    <React.Fragment>
                      <th>Submitted/Changed by Curator</th>
                      <th>Curator Decision</th>
                      {showReview && <th>Review</th>}
                    </React.Fragment>
                  )}
                </tr>
              </thead>
              <tbody>
                {submitted &&
                  Object.keys(submitted).map((fieldName, index) => {
                    decision = getOr("", `${fieldName}.status`, reviewData);
                    diff1 = arrayDiff(old[fieldName], submitted[fieldName], DIFFERENCE_KEYS[fieldName]);
                    diff2 = arrayDiff(old[fieldName], accepted[fieldName], DIFFERENCE_KEYS[fieldName]);
                    className = decision === "accepted" ? "green" : decision === "denied" ? "red" : "";

                    return (
                      <React.Fragment key={index}>
                        {!fieldName.includes("link_") && (
                          <tr>
                            <FieldNameColumn fieldName={fieldName} />
                            <DataColumn
                              entityType={entityType}
                              type={"old"}
                              fieldName={fieldName}
                              data={old}
                              connectedTo={connectedTo}
                            />
                            <DataColumn
                              entityType={entityType}
                              className={className}
                              type={"submitted"}
                              fieldName={fieldName}
                              data={submitted}
                              connectedTo={connectedTo}
                              added={diff1.new}
                              deleted={diff1.deleted}
                            />
                            {!disabledReviewTypes.includes(contributionType) && (
                              <React.Fragment>
                                <DataColumn
                                  entityType={entityType}
                                  type={"accepted"}
                                  fieldName={fieldName}
                                  data={accepted}
                                  connectedTo={connectedTo}
                                  added={diff2.new}
                                  deleted={diff2.deleted}
                                />
                                <DecisionColumn fieldName={fieldName} reviewData={reviewData} />
                                {showReview && <ReviewColumn fieldName={fieldName} reviewData={reviewData} />}
                              </React.Fragment>
                            )}
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                <LinkRows
                  old={old}
                  submitted={submitted}
                  accepted={accepted}
                  reviewData={reviewData}
                  showReview={showReview}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

FieldsBox.propTypes = {
  submitted: PropTypes.object,
  reviewData: PropTypes.object,
  accepted: PropTypes.object,
  old: PropTypes.object,
  connectedTo: PropTypes.string,
  showReview: PropTypes.bool,
  entityType: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    submitted: getOr(null, `${mainStateKey}.${ownProps.connectedTo}.submitted`, state),
    reviewData: getOr(null, `${mainStateKey}.${ownProps.connectedTo}.reviewData`, state),
    accepted: getOr(null, `${mainStateKey}.${ownProps.connectedTo}.accepted`, state),
    old: getOr(null, `${mainStateKey}.${ownProps.connectedTo}.old`, state),
    contributionType: getOr("", `${mainStateKey}.${ownProps.connectedTo}.type`, state),
    entityType: getOr("", `${mainStateKey}.${ownProps.connectedTo}.entity_type`, state),
  };
};

export default connect(mapStateToProps)(FieldsBox);
