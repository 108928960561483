import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import GeneralInfoBox from "../shared/generalInfoBox";
import Footer from "../../../presentational/page/footer";
import labelDataManipulation from "../../../../lib/dataManipulation/label/labelDataManipulation";
import {
  handleEditFormSubmit,
  setItemFormData,
  handleDeleteEntity,
  redirectTo,
} from "../../../../lib/functions/componentFunctions";
import LinksBox from "../shared/linksBox";
import { connect } from "react-redux";
import ClaimedImage from "../../../presentational/general/claimedImage";
import OpenChangeHistory from "../../../presentational/buttons/openChangeHistory";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const EditLabel = () => {
  useResetPageState();

  const routerHistory = useHistory();
  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "label");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>
          Edit Label
          <ClaimedImage connectedTo="formData.claimed" />
          <OpenChangeHistory id={id} type="label" />
        </h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <GeneralInfoBox isEditForm={true} />
          </div>
          <div className="col-md-6">
            <LinksBox />
          </div>
        </div>
        <Footer
          showClearButton={false}
          showDeleteButton={true}
          onDelete={() => {
            deletelabel(id, routerHistory);
          }}
          onSave={() => {
            handleEditFormSubmit(id, labelDataManipulation, "label", "POST");
          }}
        />
      </section>
    </React.Fragment>
  );
};

const deletelabel = async (id, routerHistory) => {
  if (window.confirm("Are you sure you want to delete this label?")) {
    const deleteSuccess = await handleDeleteEntity(id, "label");

    if (deleteSuccess) {
      redirectTo(routerHistory, "/list/editlabel");
    }
  }
};

export default connect(null)(EditLabel);
