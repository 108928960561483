import React from "react";
import { connect } from "react-redux";
import FormBox from "../../../../presentational/page/formBox";
import Input from "../../../../input/general/input";
import UploadImageButton from "../../../../input/media/uploadImageButton";

const GeneralInfoBox = () => {
  return (
    <FormBox title="General Info">
      <div className="row">
        <div className="col-md-9">
          <div className="box-body">
            <div className="form-group">
              <label>Name</label>
              <div className="input-group">
                <Input name="formData.name" type="text" placeholder="Name" className="form-control" />
              </div>
            </div>
            <div className="form-group">
              <label>Order - can be a decimal number</label>
              <Input name="formData.order" placeholder="Order" className="form-control" showClearInputButton={false} />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Image</label>
                  <div className="input-group">
                    <UploadImageButton name="formData.image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default connect(null)(GeneralInfoBox);
