export const editEventTableParametersData = {
  statePath: "tables.editEventTableParams",
  filtersPath: "tables.editEventTableParams.filters",
  sortPath: "tables.editEventTableParams.sort",

  columns: [
    {
      name: "eventName",
      label: "Event Name",
      sortBackendName: "name",
      filterDefinition: {
        backendFieldValue: {
          name: "name",
        },
      },
    },
    {
      name: "venueName",
      label: "Venue Name",
      sortBackendName: "venue",
      filterDefinition: {
        backendFieldValue: {
          venue: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "country",
      label: "Country",
      sortBackendName: "country",
      filterDefinition: {
        backendFieldValue: {
          country: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "city",
      label: "City",
      sortBackendName: "city",
      filterDefinition: {
        backendFieldValue: {
          city: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "eventDate",
      label: "Event Date/Hour",
      sortBackendName: "start",
      filterDefinition: {
        backendFieldValue: {
          "start-from": "startDateTimeT",
          "start-to": "endDateTimeT",
        },
      },
    },
    {
      name: "lineup",
      label: "Lineup",
      sortBackendName: "lineup",
      filterDefinition: {
        backendFieldValue: {
          lineup: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "dateAdded",
      label: "Date/Hour Added",
      sortBackendName: "created",
      filterDefinition: {
        backendFieldValue: {
          "created-from": "startDateTimeTZ",
          "created-to": "endDateTimeTZ",
        },
      },
    },
    { name: "status", label: "Status", sortBackendName: "status" },
    { name: "edit", label: "Edit" },
    { name: "delete", label: "Delete" },
  ],
};
