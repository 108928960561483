import React from "react";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import YoutubeVideosTable from "../../../input/table/youtubeVideosTable";

const YoutubeVideosBox = () => {
  return (
    <FormBox title="Videos">
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <div className="row">
              <div className="col-md-12">
                <YoutubeVideosTable name="formData.youtubeVideos" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default connect()(YoutubeVideosBox);
