import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextArea from "../general/textArea";

const QuoteModule = ({ index, name }) => {
  return (
    <div className="box-body">
      <TextArea
        className="form-control"
        rows="2"
        placeholder="Enter quote..."
        name={`${name}.[${index}].data`}
        showClearInputButton={false}
      />
    </div>
  );
};

QuoteModule.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
};

export default connect()(QuoteModule);
