import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { mainStateKey } from "../../../constants/constants";

import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import {
  APPEND_ITEM_TO_ARRAY,
  REMOVE_ITEM_BY_INDEX,
  SWAP_ARRAY_ITEM_LEFT,
  SWAP_ARRAY_ITEM_RIGHT,
} from "../../../redux/reducers/common/actionTypes";
import UploadImageButton from "./uploadImageButton";

const ImageGallery = ({ title, disclaimer, name, value, dispatch }) => {
  const appendImpressionItem = item => {
    dispatchToReduxStore(name, APPEND_ITEM_TO_ARRAY, { url: item }, dispatch);
  };

  return (
    <React.Fragment>
      <label>
        {title} <small>{disclaimer}</small>
      </label>

      <div className="form-group">
        <UploadImageButton
          title="ADD"
          onChange={item => {
            appendImpressionItem(item);
          }}
        />
        {value
          ? value.map((item, index) => {
              return (
                <UploadImageButton
                  key={index}
                  deleteComponentOnRemove={true}
                  name={name + ".[" + index + "].url"}
                  title=""
                  onRemove={() => {
                    dispatchToReduxStore(name, REMOVE_ITEM_BY_INDEX, { index: index }, dispatch);
                  }}
                  showOrderButtons={true}
                  onLeftOrderButtonClick={() => {
                    dispatchToReduxStore(name, SWAP_ARRAY_ITEM_LEFT, { index: index }, dispatch);
                  }}
                  onRightOrderButtonClick={() => {
                    dispatchToReduxStore(name, SWAP_ARRAY_ITEM_RIGHT, { index: index }, dispatch);
                  }}
                />
              );
            })
          : ""}
      </div>
    </React.Fragment>
  );
};

ImageGallery.propTypes = {
  title: PropTypes.string,
  disclaimer: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(ImageGallery);
