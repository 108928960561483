import React from "react";
import { PropTypes } from "prop-types";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import Input from "../../input/general/input";

const CustomTableFooter = ({ count, page, rowsPerPage, changeRowsPerPage, changePage, pageInputName }) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(_, page) => changePage(page)}
          onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
          rowsPerPageOptions={[10, 15, 100]}
        />
        <TableCell>
          <div style={{ width: "60px" }}>
            <Input
              className="form-control"
              type="number"
              placeholder={"Page"}
              name={pageInputName}
              showClearInputButton={false}
              debounceTime={250}
            />
          </div>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

CustomTableFooter.propTypes = {
  filters: PropTypes.object,
  row: PropTypes.number,
  page: PropTypes.number,
};

export default CustomTableFooter;
