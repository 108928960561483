import commonDataManipulation from "../common/commonDataManipulation";

const festivalAppMediaDataManipulation = function festivalAppMediaDataManipulation() {
  const getInwardEditFestivalItem = data => {
    return {
      impressions: data && data[1] ? commonDataManipulation().mediaImages.inward(data[1]) : [],
      officialGallery: data && data[4] ? commonDataManipulation().mediaImages.inward(data[4]) : [],
      videoGallery: data ? commonDataManipulation().mediaVideos.inward(data) : null,
    };
  };

  const getOutwardMedia = data => {
    const media = data
      ? commonDataManipulation().media.outward(data.impressions, data.officialGallery, data.videoGallery)
      : null;
    return {
      ...media,
    };
  };

  return { outward: getOutwardMedia, inwardItem: getInwardEditFestivalItem };
};

export default festivalAppMediaDataManipulation();
