import React from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import FormBox from "../../../../presentational/page/formBox";
import Select from "../../../../input/selectors/select";
import { mainStateKey } from "../../../../../constants/constants";
import { dispatchToReduxStore } from "../../../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../../../redux/reducers/common/actionTypes";

const CategoryBox = ({ category, dispatch }) => {
  const subcategories = getOr([], "subcategories", category);

  return (
    <FormBox title="Category">
      <div className="box-body">
        <div className="form-group">
          <Select
            placeholder="Category"
            name="formData.category"
            getItemsFromBackendType="helpCenterCategories"
            idKey="id"
            displayKey="name"
            onChange={() => {
              dispatchToReduxStore("formData.subcategory", SET_STATE_VALUE, null, dispatch);
            }}
          />
        </div>
        <div className="form-group">
          <Select
            placeholder="Subcategory"
            data={subcategories}
            disabled={!(subcategories && subcategories.length)}
            displayKey="name"
            idKey="id"
            name="formData.subcategory"
          />
        </div>
      </div>
    </FormBox>
  );
};

CategoryBox.propTypes = {
  category: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return { category: getOr(null, `${mainStateKey}.formData.category`, state) };
};

export default connect(mapStateToProps)(CategoryBox);
