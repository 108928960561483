import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { enumsPath, mainStateKey } from "../../../constants/constants";
import { mapArray } from "../../../lib/functions/common";

const TextField = ({ connectedTo, name, isLink = false, enumsProperty = "", enumValues = [] }) => {
  if (typeof name === "boolean") {
    name = name.toString();
  } else if (typeof name === "object") {
    name = JSON.stringify(name);
  }

  if (enumsProperty && enumValues && enumValues.length && name) {
    enumValues = mapArray(enumValues, "id");
    name = enumValues[name].name;
  }

  return isLink ? (
    <a rel="noopener noreferrer" target="_blank" href={name}>
      {name}
    </a>
  ) : (
    <p>{name}</p>
  );
};

TextField.propTypes = {
  connectedTo: PropTypes.string,
  isLink: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const mapStateToProps = (state, ownProps) => {
  return {
    name: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state),
    enumValues: getOr(null, `${enumsPath}.${ownProps.enumsProperty}`, state),
  };
};

export default connect(mapStateToProps)(TextField);
