import { refreshCookie } from "./auth";
const axios = require("axios");

var tokenRefreshing = null;

export const callApi = (url, type, params = {}, options = {}, headers = {}) => {
  function catchHandler(e) {
    if (e.response && e.response.status === 498) {
      if (!tokenRefreshing) {
        tokenRefreshing = refreshCookie()
          .then(() => {
            return callApi(url, type, params);
          })
          .catch(error => {
            throw error;
          })
          .finally(() => {
            tokenRefreshing = null;
          });
        return tokenRefreshing;
      } else {
        return tokenRefreshing.then(() => {
          return callApi(url, type, params);
        });
      }
    } else {
      throw e;
    }
  }

  switch (type) {
    case "GET":
      return axios
        .get(url, {
          headers: headers,
          params: { ...params },
          withCredentials: true,

          ...options,
        })
        .catch(catchHandler);
    case "DELETE":
      return axios
        .delete(url, {
          headers: headers,
          params: { ...params },
          withCredentials: true,
          ...options,
        })
        .catch(catchHandler);
    case "POST":
      return axios
        .post(url, params, {
          withCredentials: true,
          ...options,
          headers: headers,
        })
        .catch(catchHandler);
    case "PUT":
      return axios
        .put(url, params, {
          withCredentials: true,
          ...options,
          headers: headers,
        })
        .catch(catchHandler);
    default:
      throw new Error("Invalid api type!");
  }
};
