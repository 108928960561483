import React from "react";
import { connect } from "react-redux";
import FormBox from "../../../../presentational/page/formBox";
import ItemList from "../../../../input/general/itemList";

const AddSubcategoryBox = () => {
  return (
    <FormBox title="Subcategories">
      <ItemList name="formData.subcategoryData" />
      <div className="row">
        <div className="col-md-12"></div>
      </div>
    </FormBox>
  );
};

export default connect(null)(AddSubcategoryBox);
