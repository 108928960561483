import React from "react";
import FormBox from "../../../presentational/page/formBox";
import Highlights from "../../../input/general/highlights";

const HighlightsBox = () => {
  return (
    <FormBox title="Highlights">
      <div className="box-body">
        <Highlights highlightsType="festival" initialHighlightsPath="formData.initialHighlights" />
      </div>
    </FormBox>
  );
};

export default HighlightsBox;
