import { authenticate, getUser, facebookLogin, signout, getUserAllowedFapps } from "./auth";
import { addEntity, editEntity, getEntity, getEntityList, getSearch, getItems } from "./entity";
import { enums } from "./enum";

let apiManager = function Manager() {
  return {
    authenticate,
    getUser,
    getUserAllowedFapps,
    facebookLogin,
    signout,
    addEntity,
    editEntity,
    getEntity,
    getEntityList,
    enum: enums,
    getSearch,
    getItems,
  };
};

export default apiManager();
