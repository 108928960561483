import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { modalPath, mainStateKey } from "../../../../constants/constants";

const SoundcloudPreview = ({ value }) => {
  const ID = getOr("", "videoID", value);

  return (
    <iframe
      title="soundcloud"
      width="100%"
      height="166"
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      src={
        "https://w.soundcloud.com/player/?url=" +
        ID +
        "&amp;color=%23ff5500&amp;auto_play=true&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true"
      }
    ></iframe>
  );
};

SoundcloudPreview.propTypes = {
  value: PropTypes.object,
};

const mapStateToProps = state => {
  return { value: getOr("", `${mainStateKey}.${modalPath}.data`, state) };
};

export default connect(mapStateToProps)(SoundcloudPreview);
