import React from "react";
import FormBox from "../../../presentational/page/formBox";
import Stages from "../../../input/general/stages";

const StagesBox = () => {
  return (
    <FormBox title="Stages">
      <div className="box-body">
        <Stages name="formData.stages" showFooter={false} autoIncrementStageId={true} />
      </div>
    </FormBox>
  );
};

export default StagesBox;
