import React from "react";
import Footer from "../../../presentational/page/footer";
import BasicAndAdditionalInfoBox from "../shared/basicAndAdditionalInfoBox";
import { handleAddFormSubmit } from "../../../../lib/functions/componentFunctions";
import festivalDataManipulation from "../../../../lib/dataManipulation/festival/festivalDataManipulation";
import LocationBox from "../shared/locationBox";
import LinksBox from "../shared/linksBox";
import TicketInfoBox from "../shared/ticketInfoBox";
import AmenitiesBox from "../shared/amenitiesBox";
import HighlightsBox from "../shared/highlightsBox";
//import CommentsBox from "../shared/commentsBox";
import PhotoAndVideoBox from "../shared/photAndVideoBox";
import LineupBox from "../shared/lineupBox";
import StagesBox from "./stagesBox";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const AddFestival = () => {
  useResetPageState();

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add Festival</h1>
      </section>

      <section className="content">
        <div className="screen">
          <div className="row">
            <div className="col-md-4">
              <BasicAndAdditionalInfoBox />
              <LinksBox />
              <TicketInfoBox />
            </div>
            <div className="col-md-8">
              <LocationBox />
              <HighlightsBox />
              <AmenitiesBox />
              {/*<CommentsBox />*/}
              <Footer showClearButton={false} onSave={() => onSubmit(false)} />
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="row" id="photoVideo">
            <div className="col-md-12">
              <PhotoAndVideoBox />
              <Footer showClearButton={false} onSave={() => onSubmit(false)} />
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="row" id="lineup">
            <div className="col-md-12">
              <StagesBox />
              <LineupBox />
              <Footer
                showClearButton={true}
                onSaveAndClear={() => {
                  onSubmit(true);
                }}
                onSave={() => onSubmit(false)}
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const onSubmit = clearForm => {
  handleAddFormSubmit(clearForm, festivalDataManipulation, "festival");
};

export default AddFestival;
