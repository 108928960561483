import React from "react";
import { connect } from "react-redux";
import Subgenres from "../../../input/general/subgenres";
import FormBox from "../../../presentational/page/formBox";

const SubgenresBox = () => {
  return (
    <FormBox title="Subgenres">
      <div className="box-body">
        <div className="form-horizontal">
          <Subgenres name="formData.subgenres" />
        </div>
      </div>
    </FormBox>
  );
};

export default connect(null)(SubgenresBox);
