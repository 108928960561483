import React from "react";
import { PropTypes } from "prop-types";
import FormBox from "../../../presentational/page/formBox";
import LineupTable from "../../../input/table/lineupTable";
import EntitySelector from "../../../input/selectors/entitySelector";
import { connect } from "react-redux";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";
import { APPEND_ITEM_TO_ARRAY } from "../../../../redux/reducers/common/actionTypes";
import { addFestivalLineupHeader } from "../../../../constants/constants";

const LineupBox = ({ dispatch, isEditForm = false }) => {
  return (
    <FormBox title="Artist Search & Timetable">
      <div className="box-body">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <div className="input-group relative">
                <EntitySelector
                  entityType="artist"
                  placeholder="Search Artist..."
                  name="formData.searchTxt"
                  removeOnSelect={true}
                  focusOnSelect={true}
                  onChange={artist => {
                    dispatchToReduxStore("formData.lineup", APPEND_ITEM_TO_ARRAY, { artist: artist }, dispatch);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ paddingTop: "200px" }}>
            <div className="timetable">
              <LineupTable
                name="formData.lineup"
                stagesPath="formData.stages"
                customHeader={!isEditForm ? addFestivalLineupHeader : null}
              />
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

LineupBox.propTypes = {
  dispatch: PropTypes.func,
  isEditForm: PropTypes.bool,
};

export default connect(null)(LineupBox);
