import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import FormBox from "../../../presentational/page/formBox";
import { mainStateKey } from "../../../../constants/constants";
import MatchedEventCard from "../../../presentational/cards/matchedEventCard";
import apiManager from "../../../../lib/apiManager/apiManager";
import {
  showAppLoader,
  handleDeleteEntity,
  showFormSuccess,
  showFormFail,
} from "../../../../lib/functions/componentFunctions";

const MatchedEventsBox = ({ venueUUID, currentEventDate, currentLineup, currentEventId, handleMatch = () => {} }) => {
  const [venueEvents, setVenueEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getVenueEvents(venueUUID, currentEventDate, setVenueEvents, setLoading);
  }, [venueUUID, currentEventDate, setVenueEvents]);

  return (
    <FormBox title="Matched Events">
      <div className="box-body">
        <div className={"list-matched-events " + (loading ? "loading" : "")}>
          {venueEvents &&
            venueEvents.length > 0 &&
            venueEvents.map((item, index) => {
              return (
                <MatchedEventCard
                  event={item}
                  key={index}
                  onMatchButtonClick={uuid => {
                    handleMatch(uuid, currentLineup, currentEventId);
                  }}
                  onDeleteButtonClick={uuid => {
                    onDeleteButtonClick(uuid, venueEvents, setVenueEvents);
                  }}
                />
              );
            })}
          {!venueEvents || (!venueEvents.length && <p>There are no matched events</p>)}
        </div>
      </div>
    </FormBox>
  );
};

const getVenueEvents = async (venueUUID, currentEventDate, setVenueEvents, setLoading) => {
  setLoading(true);

  const previousDay = currentEventDate
    ? moment(currentEventDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD")
    : null;
  const nextDay = currentEventDate
    ? moment(currentEventDate)
        .add(1, "days")
        .format("YYYY-MM-DD")
    : null;

  if (venueUUID && previousDay && nextDay) {
    const urlParameter =
      "limit=100&start-from=" + previousDay + "T00:00:00&start-to=" + nextDay + "T23:59:59&venue=" + venueUUID;

    let events = await apiManager.getEntityList("event", null, urlParameter);
    events = events && events.data ? events.data : [];
    events = events.filter(item => {
      return item && item.status && item.status === "deleted" ? false : true;
    });

    setVenueEvents([...events]);
  }

  setLoading(false);
};

const onDeleteButtonClick = async (uuid, venueEvents, setVenueEvents) => {
  if (window.confirm("Are you sure you want to delete this event?")) {
    showAppLoader();

    const newVenueEvents = [...venueEvents].filter(obj => {
      return obj.uuid !== uuid;
    });

    const deleteSuccess = await handleDeleteEntity(uuid, "event");

    if (deleteSuccess) {
      setVenueEvents(newVenueEvents);
      showFormSuccess();
    } else {
      showFormFail();
    }
  }
};

MatchedEventsBox.propTypes = {
  venueUUID: PropTypes.string,
  currentEventDate: PropTypes.string,
  currentLineup: PropTypes.array,
  currentEventId: PropTypes.number,
  handleMatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    venueUUID: getOr(null, `${mainStateKey}.formData.venue.uuid`, state),
    currentEventDate: getOr(null, `${mainStateKey}.formData.date`, state),
    currentLineup: getOr(null, `${mainStateKey}.formData.lineup`, state),
    currentEventId: getOr(null, `${mainStateKey}.formData.id`, state),
  };
};

export default connect(mapStateToProps)(MatchedEventsBox);
