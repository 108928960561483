import { getOr } from "lodash/fp";
import commonDataManipulation from "../../common/commonDataManipulation";

const helpCenterTopicDataManipulation = function helpCenterTopicDataManipulation() {
  const getOutwardHelpCenterItem = data => {
    let parsedData = {};
    let order = data && data.order ? data.order : null;
    order = order ? parseFloat(order) : order;

    if (data) {
      parsedData = {
        category_id: getOr(null, "category.id", data),
        subcategory_id: getOr(null, "subcategory.id", data),
        title: getOr(null, "title", data),
        content: data && data.content ? commonDataManipulation().content.outward(data.content) : null,
        featured: data.featured || false,
        status: getOr("", "status.name", data).toLowerCase(),
        order,
      };
    }

    return parsedData;
  };

  const getInwardDatatable = (helpCenterTopics, categories = []) => {
    let parsedData = [];
    let categoryName;
    let subcategoryName;
    let categoryObject;

    if (helpCenterTopics && helpCenterTopics.length && Array.isArray(helpCenterTopics)) {
      helpCenterTopics.forEach(item => {
        categoryObject = getCategoryObject({ helpCenterData: item, categories });
        categoryName = categoryObject.category.name || "";
        subcategoryName = categoryObject.subcategory.name || "";

        parsedData.push([
          categoryName,
          subcategoryName,
          getOr("", "title", item),
          item.featured ? "Yes" : "No",
          getOr("", "status", item),
          getOr("", "order", item),
          item.id,
        ]);
      });
    }

    return parsedData;
  };

  const getInwardItem = data => {
    const categoryObjects = getCategoryObject(data);

    return {
      category: categoryObjects.category,
      subcategory: categoryObjects.subcategory,
      featured: getOr(false, "helpCenterData.featured", data),
      status: commonDataManipulation().contentStatus.inward(getOr(false, "helpCenterData.status", data)),
      title: getOr(null, "helpCenterData.title", data),
      content: commonDataManipulation().contentItem.inward(getOr(null, "helpCenterData.content", data)),
      order: getOr(null, "helpCenterData.order", data),
    };
  };

  const getCategoryObject = data => {
    const categoryID = getOr(false, "helpCenterData.category_id", data);
    const subcategoryID = getOr(false, "helpCenterData.subcategory_id", data);

    let categoryObject = {};
    let subcategoryObject = {};

    if (data && data.categories && typeof data.categories === "object") {
      categoryObject = data.categories.find(item => {
        return item.id === categoryID;
      });

      if (subcategoryID) {
        data.categories.forEach(category => {
          if (category.subcategories && Array.isArray(category.subcategories)) {
            category.subcategories.forEach(subcategory => {
              if (subcategory.id === subcategoryID) {
                subcategoryObject = subcategory;
              }
            });
          }
        });
      }
    }
    return { category: categoryObject, subcategory: subcategoryObject };
  };

  return {
    outward: getOutwardHelpCenterItem,
    inwardDatatable: getInwardDatatable,
    inwardItem: getInwardItem,
  };
};

export default helpCenterTopicDataManipulation();
