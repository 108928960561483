import React from "react";
import FormBox from "../../../presentational/page/formBox";
import ArtistList from "../../../input/general/artistList";

const LineupBox = () => {
  return (
    <FormBox title="Lineup">
      <div className="box-body">
        <ArtistList name="formData.lineup" />
      </div>
    </FormBox>
  );
};

export default LineupBox;
