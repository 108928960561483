import React, { useState, useEffect } from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import MatchedEventTable from "./matchedEventTable";
import { mainStateKey } from "../../../../constants/constants";
import apiManager from "../../../../lib/apiManager/apiManager";
import matchedEventDataManipulation from "../../../../lib/dataManipulation/event/matchedEventDataManipulation";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const MatchedEventsListPage = ({ filters, hashedUserEmail }) => {
  useResetPageState();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMatchedEventsTableData(setData, setLoading, () => {}, hashedUserEmail);
  }, [filters, hashedUserEmail]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Matched Events List</h1>
      </section>
      <section className="content">
        <div className={loading ? "loading" : ""}>
          <div className={"zindex1 "}>
            <MatchedEventTable data={data} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const setMatchedEventsTableData = async (setData, setLoading, url, hashedUserEmail) => {
  setLoading(true);

  let data = await apiManager.getEntityList("matchedEvent", {
    Email: hashedUserEmail,
  });

  if (data && data.length) {
    setData(matchedEventDataManipulation.inwardDatatable(data));
    setLoading(false);
  }

  setLoading(false);
};

MatchedEventsListPage.propTypes = {
  filters: PropTypes.object,
  hashedUserEmail: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    filters: getOr(null, `${mainStateKey}.tables.matchedEventsTable.filters`, state),
    hashedUserEmail: getOr(null, `${mainStateKey}.user.hashedEmail`, state),
  };
};

export default connect(mapStateToProps)(MatchedEventsListPage);
