import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { mainStateKey } from "../../../constants/constants";
import { connect } from "react-redux";
import { searchInNewTabClick } from "../../../lib/functions/common";

const SearchMissingLinkButton = ({ connectedTo, filledLinks, allEntityLinks, entityName }) => {
  return (
    <button
      disabled={entityName ? false : true}
      className="btn btn-default btn-small"
      onClick={() => {
        openMissingLinksInNewTab(filledLinks, allEntityLinks, entityName);
      }}
    >
      Search Missing Links
    </button>
  );
};

const openMissingLinksInNewTab = (filledLinks, allEntityLinks, entityName) => {
  if (allEntityLinks && allEntityLinks.length && Array.isArray(allEntityLinks)) {
    allEntityLinks.forEach(item => {
      if (item && item.name) {
        if (filledLinks && filledLinks[item.id]) {
        } else {
          searchInNewTabClick(item.name, entityName, item.placeholder);
        }
      }
    });
  }
};

SearchMissingLinkButton.propTypes = {
  connectedTo: PropTypes.string,
  value: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  return { filledLinks: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state) };
};

export default connect(mapStateToProps)(SearchMissingLinkButton);
