import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import articleDataManipulation from "../../../../lib/dataManipulation/article/articleDataManipulation";
import GeneralInfoBox from "../shared/generalInfoBox";
import ContentBox from "../shared/contentBox";
import TagsBox from "../shared/tagsBox";
import { handleEditFormSubmit, setItemFormData } from "../../../../lib/functions/componentFunctions";
import ContentComponentSelector from "../../../input/selectors/contentComponentSelector";
import ArticlePreview from "../articlePreview/articlePreview";
import MetaTagsBox from "../shared/metaTagsBox";
import { articleComponents } from "../../../../constants/article";
import { useResetPageState } from "../../../../lib/functions/customHooks";
import CategoryBox from "../shared/categoryBox";
import DomainBox from "../shared/domainBox";

const EditArticleItem = () => {
  useResetPageState();

  let { id } = useParams();
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setItemFormData(id, "", "editArticle");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit Article</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-8">
            <GeneralInfoBox />
            <div className="row">
              <div className="col-md-12">
                <ContentBox />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <TagsBox />
          </div>
          <div className="col-md-4">
            <MetaTagsBox />
          </div>
          <div className="col-md-4">
            <DomainBox />
          </div>
          <div className="col-md-4">
            <CategoryBox />
          </div>
        </div>
        <div style={{ height: "100px" }}></div>
        <ContentComponentSelector
          setShowPreview={setShowPreview}
          onSubmit={() => {
            handleEditFormSubmit(id, articleDataManipulation, "article", "PUT");
          }}
          availableComponents={articleComponents}
        />
      </section>

      {showPreview && <ArticlePreview showPreview={showPreview} setShowPreview={setShowPreview} />}
    </React.Fragment>
  );
};

export default EditArticleItem;
