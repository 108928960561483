import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import apiManager from "../../../../lib/apiManager/apiManager";
import { getTableTheme, defaultSort, sortByDate } from "../../../../lib/functions/tableFunctions";
import { Link } from "react-router-dom";
import webinarDataManipulation from "../../../../lib/dataManipulation/webinar/webinarDataManipulation";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const EditWebinarListPage = () => {
  useResetPageState();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    "Title",
    "Start",
    "Speakers",
    "ContentUrl",
    "Duration",
    {
      name: "Edit",
      options: {
        customBodyRender: value => <Link to={"/edit/webinar/" + value} className="btn btn-default fa fa-edit" />,
      },
    },
  ];

  const datatableOptions = {
    selectableRows: "none",
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: "stacked",
    customSort: (data, colIndex, order) => {
      if (colIndex === 1) {
        return sortByDate(data, colIndex, order);
      } else {
        return defaultSort(data, colIndex, order);
      }
    },
  };

  useEffect(() => {
    setWebinarsListData(setData, setLoading);
  }, []);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit Webinar</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="timetable">
              <MuiThemeProvider theme={getTableTheme()}>
                <MUIDataTable
                  className={loading ? "loading" : ""}
                  data={data}
                  columns={columns}
                  options={datatableOptions}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const setWebinarsListData = async (setData, setLoading) => {
  const data = await apiManager.getEntityList("webinar");

  if (data && data.data && data.data.length) {
    setData(webinarDataManipulation.inwardDatatable(data.data));
    setLoading(false);
  }

  setLoading(false);
  return [];
};

export default EditWebinarListPage;
