import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { getYoutubeThumbnail } from "../../../lib/functions/common";
import Input from "../general/input";
import TextArea from "../general/textArea";
import { mainStateKey } from "../../../constants/constants";

const VideoModule = ({ index, name, value }) => {
  const backgroundImageStyle =
    value && value.videoUrl ? { backgroundImage: "url('" + getYoutubeThumbnail(value.videoUrl) + "')" } : {};

  return (
    <div className="box-body">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <div className="input-group">
              <Input
                className="form-control"
                placeholder="Video URL"
                name={`${name}.[${index}].data.videoUrl`}
                showClearInputButton={false}
                debounceTime={0}
              />
              <span className="input-group-btn">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    if (value && value.videoUrl) {
                      window.open(value.videoUrl, "_blank");
                    }
                  }}
                >
                  <i className="fa fa-external-link" />
                </button>
              </span>
            </div>
          </div>
          <div className="form-group">
            <TextArea
              className="form-control"
              rows="2"
              placeholder="Enter caption..."
              name={`${name}.[${index}].data.caption`}
              showClearInputButton={false}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="video-preview" style={backgroundImageStyle}>
              {value && value.videoUrl ? (
                ""
              ) : (
                <label>
                  <span>PREVIEW</span>
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoModule.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}.[${ownProps.index}].data`, state) };
};

export default connect(mapStateToProps)(VideoModule);
