import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import {
  handleDeleteEntity,
  handleEditFormSubmit,
  redirectTo,
  setItemFormData,
} from "../../../../../lib/functions/componentFunctions";
import Footer from "../../../../presentational/page/footer";
import GeneralInfoBox from "../shared/generalInfoBox";
import helpCenterCategoryDataManipulation from "../../../../../lib/dataManipulation/helpCenter/categories/helpCenterCategoryDataManipulation";
import { useResetPageState } from "../../../../../lib/functions/customHooks";
import AddSubcategoryBox from "../shared/addSubcategoryBox";

const EditHelpCenterCategory = () => {
  useResetPageState();

  const routerHistory = useHistory();
  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "helpCenterCategory");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit help center category</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-4">
            <GeneralInfoBox />
          </div>
          <div className="col-md-8">
            <AddSubcategoryBox />
          </div>
        </div>
        <Footer
          showClearButton={false}
          showDeleteButton={true}
          onDelete={async () => {
            if (window.confirm("Are you sure you want to delete this event?")) {
              const deleteSuccess = await handleDeleteEntity(id, "helpCenterCategory");

              if (deleteSuccess) {
                redirectTo(routerHistory, "/list/help-center-category/");
              }
            }
          }}
          onSave={() => {
            handleEditFormSubmit(id, helpCenterCategoryDataManipulation, "helpCenterCategory", "PUT", null);
          }}
        />
      </section>
    </React.Fragment>
  );
};

export default EditHelpCenterCategory;
