import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import { makeid, findObjectByValue, searchByString } from "../../../lib/functions/common";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { mainStateKey } from "../../../constants/constants";
import { SET_UNSET_OBJECT_ARRAY_ITEM } from "../../../redux/reducers/common/actionTypes";
import { useOutsideComponentClick } from "../../../lib/functions/customHooks";

const MultiSelector = ({
  data = null,
  idKey,
  displayKey,
  autocomplete = false,
  value,
  placeholder = "",
  disabled = false,
  name,
  onChange = () => {},
  dispatch,
  searchOnBackend = false,
  backendSearchEntity = null,
}) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState(getFilteredItems(searchText, data, displayKey));

  useEffect(() => {
    setItems(getFilteredItems("", data, displayKey));
  }, [data, displayKey]);

  const myid = makeid(15);

  const ref = useRef(null);
  useOutsideComponentClick(ref, setOpen);

  const handleSelectClick = () => {
    if (!open) {
      setOpen(!open);
    }
  };

  const searchTextChanged = async e => {
    setSearchText(e.target.value);

    if (searchOnBackend && backendSearchEntity) {
      const filteredItems = await searchByString(e.target.value, backendSearchEntity);
      setItems(filteredItems);
    } else {
      setItems(getFilteredItems(e.target.value, data, displayKey));
    }
  };

  const selectClass = getSelectClass(open, autocomplete, disabled);

  return (
    <React.Fragment>
      <div className={selectClass.join(" ")} onClick={handleSelectClick} ref={ref}>
        <div className="selected-wrapper">
          <div tabIndex={0} className={"selected-option " + (!value ? "placeholder" : "")}>
            <span>{placeholder}</span>
          </div>
        </div>
        {autocomplete && open ? (
          <input
            autoFocus
            id={myid}
            onClick={e => e.stopPropagation()}
            placeholder="Search..."
            type="text"
            value={searchText}
            onChange={e => {
              searchTextChanged(e);
            }}
          />
        ) : (
          ""
        )}
        <ul className="results" onWheel={e => e.stopPropagation()}>
          {items &&
            items.map(el => {
              const isChecked = findObjectByValue(value, "id", el[idKey]) ? true : false;
              return (
                <li
                  key={el[idKey]}
                  onClick={() => {
                    onChange(el);
                    dispatchToReduxStore(
                      name,
                      SET_UNSET_OBJECT_ARRAY_ITEM,
                      { name: el[displayKey], id: el[idKey] },
                      dispatch
                    );
                  }}
                >
                  <input type="checkbox" checked={isChecked} onChange={() => {}} /> {el[displayKey]}
                </li>
              );
            })}
        </ul>
      </div>
    </React.Fragment>
  );
};

const getFilteredItems = (searchText, data, displayKey) => {
  let filtered = [];

  if (searchText.length > 0 && data && data.length && Array.isArray(data)) {
    let exact = [];
    let others = [];

    data.forEach(item => {
      const searchIndex = item[displayKey].toLowerCase().indexOf(searchText.toLowerCase());
      if (searchIndex === 0) {
        exact.push(item);
      } else if (searchIndex > -1) {
        others.push(item);
      }
    });

    filtered = exact.concat(others);
  } else {
    filtered = data;
  }

  return filtered;
};

const getSelectClass = (open, autocomplete, disabled) => {
  let selectCss = ["custom-select"];
  if (open) {
    selectCss.push("open");
  }

  if (autocomplete) {
    selectCss.push("with-search");
  }

  if (disabled) {
    selectCss.push("disabled");
  }

  return selectCss;
};

MultiSelector.propTypes = {
  placeholder: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onSelect: PropTypes.func,
  displayKey: PropTypes.string,
  idKey: PropTypes.string,
  autocomplete: PropTypes.bool,
  formFieldName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  dispatch: PropTypes.func,
  searchOnBackend: PropTypes.bool,
  backendSearchEntity: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(MultiSelector);
