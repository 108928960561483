import { permissionStrings } from "./permissionStrings";

//General
//name: defines the text of <li/> item
//permissionString: defines the required permission for sections. If user dosen't have the permission
//the section won't be shown. If it is defined as an array section will be shown if the user has any of the permissions in array.
//children:array of subsection items (may be nested)
//url: defines the redirect url when item is clicked

//Fapp (special cases for fapp sidebar routes)
//checkIfUserHasAllowedFapps: check wheter user has any allowed fapps. If he doesn't, the whole fapp section will be hidden.
//checkIfFappIsSet: check wheter festival app and edition uuid is set. If it isn't, the section is disabled.

const navItemsList = [
  {
    name: "Artist",
    permissionString: permissionStrings.artist,
    children: [
      { name: "Add", url: "/add/artist" },
      { name: "Edit", url: "/list/editArtist" },
    ],
  },
  {
    name: "Venue",
    permissionString: permissionStrings.venue,
    children: [
      { name: "Add", url: "/add/venue" },
      { name: "Edit", url: "/list/editVenue" },
    ],
  },
  {
    name: "Event",
    permissionString: permissionStrings.event,
    children: [
      { name: "Add", url: "/add/event" },
      { name: "Edit", url: "/list/editEvent" },
      { name: "Matched Events List", url: "/list/matchedEvent" },
    ],
  },
  {
    permissionString: permissionStrings.festival,
    name: "Festival",
    children: [
      { name: "Add", url: "/add/festival" },
      { name: "Edit", url: "/list/editFestival" },
    ],
  },
  {
    permissionString: permissionStrings.label,
    name: "Label",
    children: [
      { name: "Add", url: "/add/label" },
      { name: "Edit", url: "/list/editLabel" },
    ],
  },
  {
    permissionString: permissionStrings.label,
    name: "Track",
    children: [
      { name: "Add", url: "/add/track" },
      { name: "Edit", url: "/list/editTrack" },
    ],
  },
  {
    name: "Article",
    permissionString: permissionStrings.article,
    children: [
      { name: "Add", url: "/add/article" },
      { name: "Edit", url: "/list/article" },
    ],
  },
  {
    name: "CMS",
    permissionString: [permissionStrings.CMSHelpCenter, permissionStrings.CMSWebinar],
    children: [
      {
        name: "Help Center",
        permissionString: permissionStrings.CMSHelpCenter,
        children: [
          {
            name: "Categories",
            children: [
              { name: "Add", url: "/add/help-center-category" },
              { name: "Edit", url: "/list/help-center-category" },
            ],
          },
          {
            name: "Topics",
            children: [
              { name: "Add", url: "/add/help-center-topic" },
              { name: "Edit", url: "/list/help-center-topic" },
            ],
          },
        ],
      },
      {
        name: "Webinars",
        permissionString: permissionStrings.CMSWebinar,
        children: [
          { name: "Add", url: "/add/webinar" },
          { name: "Edit", url: "/list/editWebinar" },
        ],
      },
      {
        name: "Feed posts",
        permissionString: permissionStrings.CMSFeedPost,
        children: [
          { name: "Add", url: "/add/feed-post" },
          { name: "Edit", url: "/list/feed-post" },
        ],
      },
    ],
  },
  /*
  {
    name: "Festival App",
    checkIfUserHasAllowedFapps: true,
    children: [
      { name: "Select festival", url: "/fapp/select" },
      {
        name: "App Content",
        checkIfFappIsSet: true,
        children: [
          {
            name: "Info",
            children: [
              { name: "Basic Info", url: "/fapp/info#basic" },
              { name: "Tickets", url: "/fapp/info#ticket_info" },
              { name: "Social Media Links", url: "/fapp/info#links" },
            ],
          },
          { name: "Official Photos And Videos", url: "/fapp/media" },
          {
            name: "Lineup",
            children: [
              { name: "Stages", url: "/fapp/lineup#stages" },
              { name: "Artist Search And Timetable", url: "/fapp/lineup#lineup" },
            ],
          },
          { name: "Experiences", url: "/fapp/experiences" },
          { name: "Festival map", url: "/fapp/festmap" },
          { name: "Merch", url: "/fapp/merch" },
          { name: "Partners", url: "/fapp/partners" },
          {
            name: "Additional App Info",
            children: [
              { name: "About The Festival", url: "/fapp/additonalinfo#about" },
              { name: "Festival Rules", url: "/fapp/additonalinfo#rules" },
              { name: "Faq", url: "/fapp/additonalinfo#faq" },
              { name: "Legal", url: "/fapp/additonalinfo#legal" },
              { name: "Contact Form", url: "/fapp/additonalinfo#contact" },
              { name: "Accommodations", url: "/fapp/additonalinfo#accommodations" },
            ],
          },
          {
            name: "Campaign",
            children: [
              { name: "Add", url: "/fapp/add/campaign" },
              { name: "Edit", url: "/fapp/list/editCampaign" },
            ],
          },
        ],
      },
      {
        name: "Dashboard",
        checkIfFappIsSet: true,
        children: [
          {
            name: "Live moments",
            children: [
              { name: "Posts", url: "/fapp/livemoments/posts" },
              { name: "Reports", url: "/fapp/livemoments/reports" },
            ],
          },
        ],
      },
    ],
  },
  */
  {
    name: "Contribution History",
    permissionString: permissionStrings.contributionHistory,
    url: "/list/contributionHistory",
  },
];

export default navItemsList;
