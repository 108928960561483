import commonDataManipulation from "../common/commonDataManipulation";

const festivalAppStagesDataManipulation = function festivalAppStagesDataManipulation() {
  const getInwardEditFestivalItem = data => {
    return data;
  };

  const getOutwardFestival = data => {
    commonDataManipulation().stages.outward(data);
  };

  return { outward: getOutwardFestival, inwardItem: getInwardEditFestivalItem };
};

export default festivalAppStagesDataManipulation();
