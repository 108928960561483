import React from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { mainStateKey } from "../../../constants/constants";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

const RadioButton = ({ name, inputName, inputValue, value, dispatch }) => {
  return (
    <input
      type="radio"
      name={inputName}
      checked={value === inputValue}
      onChange={() => {
        dispatchToReduxStore(name, SET_STATE_VALUE, inputValue, dispatch);
      }}
    />
  );
};

RadioButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  inputName: PropTypes.string,
  inputValue: PropTypes.string,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(RadioButton);
