import { find } from "lodash/fp";
import { sponsorTypes } from "../../../constants/constants";

const festivalAppSponsorsDataManipulation = function festivalAppSponsorsDataManipulation() {
  const getInwardEditFestivalItem = data => {
    return {
      sponsors: data
        ? data.map(item => {
            return {
              id: item && item.id ? item.id : null,
              link: item && item.link ? item.link : null,
              image: item && item.image ? item.image : null,
              type: item && item.type ? getSponsorTypeName(sponsorTypes, item.type) : null,
            };
          })
        : null,
    };
  };

  const getOutwardFestival = data => {
    return data && data.sponsors
      ? data.sponsors.map(item => {
          const itemID = item && item.id ? { id: item.id } : null;
          return {
            ...itemID,
            link: item && item.link ? item.link : null,
            image: item && item.image ? item.image : null,
            type: item && item.type && item.type.id ? item.type.id : null,
          };
        })
      : null;
  };

  return { outward: getOutwardFestival, inwardItem: getInwardEditFestivalItem };
};

export default festivalAppSponsorsDataManipulation();

const getSponsorTypeName = (sponsorTypes, sponsorTypeID) => {
  const sponsorType = find({ id: sponsorTypeID })(sponsorTypes);

  return sponsorType;
};
