import {
  outwardSubgenres,
  outwardMedia,
  outwardLinks,
  outwardLocation,
  outwardTickets,
  outwardOpeningHours,
  outwardWeeks,
  outwardLineupTable,
  outwardStages,
  outwardContent,
} from "./outwardCommonDataManipulation";
import {
  inwardMediaVideos,
  inwardMediaImages,
  inwardLinks,
  inwardLocation,
  inwardTickets,
  inwardOpeningHours,
  inwardArtistList,
  inwardWeeks,
  inwardLineupTable,
  inwardContentStatus,
  inwardContentItem,
  inwardSuggestionsTable,
  inwardVotingTable,
  inwardWinnersTable,
  inwardSubgenres,
  inwardItemSubgenres,
  inwardUserStatus,
  inwardFeedpostType,
} from "./inwardCommonDataManipulation";

const commonDataManipulation = function commonDataManipulation() {
  return {
    subgenres: { outward: outwardSubgenres, inwardDatatable: inwardSubgenres, inwardItem: inwardItemSubgenres },
    media: { outward: outwardMedia },
    mediaVideos: { inward: inwardMediaVideos },
    mediaImages: { inward: inwardMediaImages },
    links: { outward: outwardLinks, inward: inwardLinks },
    location: { outward: outwardLocation, inward: inwardLocation },
    tickets: { outward: outwardTickets, inward: inwardTickets },
    openingHours: { outward: outwardOpeningHours, inward: inwardOpeningHours },
    artistList: { inward: inwardArtistList },
    weeks: { outward: outwardWeeks, inward: inwardWeeks },
    lineupTable: { outward: outwardLineupTable, inward: inwardLineupTable },
    stages: { outward: outwardStages },
    contentStatus: { inward: inwardContentStatus },
    userStatus: { inward: inwardUserStatus },
    feedpostType: { inward: inwardFeedpostType },
    contentItem: { inward: inwardContentItem },
    content: { outward: outwardContent },
    suggestionsTable: { inward: inwardSuggestionsTable },
    votingTable: { inward: inwardVotingTable },
    winnersTable: { inward: inwardWinnersTable },
  };
};

export default commonDataManipulation;
