import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { mainStateKey } from "../../../constants/constants";

const Weeks = ({ connectedTo, weeks = [] }) => {
  return (
    <React.Fragment>
      {weeks &&
        weeks.map((item, index) => {
          return (
            <p key={index}>
              {item.start} - {item.end}
            </p>
          );
        })}
    </React.Fragment>
  );
};

Weeks.propTypes = {
  connectedTo: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { weeks: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state) };
};

export default connect(mapStateToProps)(Weeks);
