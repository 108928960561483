import { getOr } from "lodash/fp";
import moment from "moment";
import commonDataManipulation from "../common/commonDataManipulation";

const festivalAppCampaignDataManipulation = function festivalAppCampaignDataManipulation() {
  const getInwardItem = ({ campaignDetail, suggestions }) => {
    return {
      suggestions: commonDataManipulation().suggestionsTable.inward(suggestions),
      date: parseDate(getOr(null, "start", campaignDetail), getOr(null, "end", campaignDetail)),
      description: getOr("", "message", campaignDetail),
      voting: commonDataManipulation().votingTable.inward(getOr([], "pool", campaignDetail)),
      winners: commonDataManipulation().winnersTable.inward(getOr([], "pool", campaignDetail)),
      winnersText: getOr("", "winners_text", campaignDetail),
    };
  };

  const parseDate = (start, end) => {
    let parsedObj = {};

    if (start) {
      const startMoment = moment(start).utc();
      parsedObj["start"] = startMoment.format("YYYY-MM-DD");
      parsedObj["startDateTimeTZ"] = startMoment.format("YYYY-MM-DD") + "T00:00:00Z";
    }

    if (end) {
      const endMoment = moment(end).utc();
      parsedObj["end"] = endMoment.format("YYYY-MM-DD");
      parsedObj["endDateTimeTZ"] = endMoment.format("YYYY-MM-DD") + "T23:59:59Z";
    }

    return parsedObj;
  };

  const getOutwardItem = data => {
    return {
      start: getOr(null, "date.startDateTimeTZ", data),
      end: getOr(null, "date.endDateTimeTZ", data),
      message: getOr("", "description", data),
      pool: getOutwardPool(data && data.voting && data.voting.length ? data.voting : data.winners, data.winners),
      winners_text: getOr(null, "winnersText", data),
    };
  };

  const getOutwardPool = (data = [], winners = []) => {
    const winnerUUIDS = winners.map(item => {
      return item[0].uuid;
    });

    const parsedPool = [];
    let uuid;

    data.forEach(item => {
      uuid = getOr(null, "[0].uuid", item);

      if (uuid) {
        parsedPool.push({
          artist_uuid: uuid,
          winner: winnerUUIDS.includes(uuid) ? true : false,
        });
      }
    });

    return parsedPool;
  };

  const getInwardDatatable = (data = []) => {
    let listItems = [];

    if (data && Array.isArray(data) && data.length) {
      listItems = data.map(item => {
        const end = item.end ? item.end : null;
        let status = "N/A";

        if (end) {
          status = moment(end)
            .utc()
            .isAfter()
            ? "Campaign is in progress"
            : "Campaign has ended";
        }

        return [
          item.start
            ? moment(item.start)
                .utc()
                .format("YYYY-MM-DD")
            : "",
          item.end
            ? moment(item.end)
                .utc()
                .format("YYYY-MM-DD")
            : "",
          item.message ? item.message : "",
          status,
          item.uuid,
        ];
      });
    }

    return listItems;
  };

  return { inwardItem: getInwardItem, outward: getOutwardItem, inwardDatatable: getInwardDatatable };
};

export default festivalAppCampaignDataManipulation();
