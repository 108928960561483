import React, { useState } from "react";
import { PropTypes } from "prop-types";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { mainStateKey, defaultLineupHeader } from "../../../constants/constants";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE, REMOVE_ITEM_BY_INDEX } from "../../../redux/reducers/common/actionTypes";
import EntitySelector from "../selectors/entitySelector";
import { findObjectIndexByValue } from "../../../lib/functions/common";

const LineupTable = ({ name, value, stages, dispatch, stagesPath, customHeader = null, allowEdit = true }) => {
  if (!stages && value) {
    const stageIds = [];
    stages = [];
    value.forEach(artist => {
      if (artist.stage && artist.stage.id && !stageIds.includes(artist.stage.id)) {
        stageIds.push(artist.stage.id);
        stages.push(artist.stage);
      }
    });
  }

  const header = customHeader ? customHeader : defaultLineupHeader;
  const artistData = value ? value : [];
  const [tempValue, setTempValue] = useState(null);
  const data =
    artistData.length > 0
      ? artistData.map(artist => {
          return {
            name: artist.artist ? renderArtistCard(artist.artist) : "",
            day: artist.day ? artist.day : "",
            stage_id: artist.stage_id ? artist.stage_id : "",
            start: artist.start ? artist.start : "",
            end: artist.end ? artist.end : "",
          };
        })
      : null;

  const [editIdx, setEditIdx] = useState(-1);

  const handleRemove = i => {
    dispatchToReduxStore(name, REMOVE_ITEM_BY_INDEX, { index: i }, dispatch);
  };

  const startEditing = (i, artist, row) => {
    dispatchToReduxStore("formData.inlineSearchTxt", SET_STATE_VALUE, { name: artist }, dispatch);
    setTempValue(value[i]);
    setEditIdx(i);
  };

  const saveEditing = () => {
    dispatchToReduxStore("formData.inlineSearchTxt", SET_STATE_VALUE, null, dispatch);
    setTempValue(null);
    setEditIdx(-1);
  };

  const stopEditing = i => {
    dispatchToReduxStore(name + "[" + i + "]", SET_STATE_VALUE, tempValue, dispatch);
    dispatchToReduxStore("formData.inlineSearchTxt", SET_STATE_VALUE, null, dispatch);
    setTempValue(null);
    setEditIdx(-1);
  };

  const handleChange = (value, prop, i) => {
    dispatchToReduxStore(name + "[" + i + "]." + prop, SET_STATE_VALUE, value, dispatch);
  };

  return (
    <React.Fragment>
      <Paper>
        <Table className="table table-bordered table-striped">
          <TableHead>
            <TableRow className="filter-row">
              {header.map((x, i) => {
                return <TableCell key={`thc-${i}`}>{x.name}</TableCell>;
              })}
              {allowEdit && (
                <React.Fragment>
                  <TableCell>{editIdx !== -1 ? "Save" : "Edit"}</TableCell>
                  <TableCell>{editIdx !== -1 ? "Cancel" : "Delete"}</TableCell>
                </React.Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0
              ? data.map((x, i) => {
                  return row(
                    x,
                    i,
                    header,
                    stages,
                    startEditing,
                    handleRemove,
                    editIdx,
                    handleChange,
                    stopEditing,
                    saveEditing,
                    dispatch,
                    name,
                    allowEdit
                  );
                })
              : null}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

const row = (
  x,
  i,
  header,
  stages,
  startEditing,
  handleRemove,
  editIdx,
  handleChange,
  stopEditing,
  saveEditing,
  dispatch,
  name,
  allowEdit
) => {
  const currentlyEditing = editIdx === i;
  return (
    <TableRow key={`tr-${i}`}>
      {header.map((y, k) => {
        return (
          <TableCell key={`trc-${k}`}>
            {currentlyEditing ? (
              y.prop === "name" ? (
                <EntitySelector
                  entityType="artist"
                  placeholder="Search Artist..."
                  name="formData.inlineSearchTxt"
                  removeOnSelect={false}
                  onChange={artist => {
                    handleChange(artist, "artist", i);
                  }}
                />
              ) : y.prop === "stage_id" ? (
                <Select name="stages" onChange={e => handleChange(e.target.value, y.prop, i)} value={x[y.prop]}>
                  {stages ? getStageList(stages) : <MenuItem value={stages ? stages.id : null}>Empty</MenuItem>}
                </Select>
              ) : y.prop === "day" ? (
                <TextField
                  name={y.prop}
                  type="date"
                  defaultValue={x[y.prop]}
                  onChange={e => handleChange(e.target.value, y.prop, i)}
                />
              ) : (
                <TextField
                  name={y.prop}
                  type="datetime-local"
                  defaultValue={x[y.prop]}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={e => handleChange(e.target.value, y.prop, i)}
                />
              )
            ) : y.prop === "day" ? (
              x[y.prop] && moment(x[y.prop]).format("DD.MM.YYYY")
            ) : y.prop === "start" || y.prop === "end" ? (
              x[y.prop] && moment(x[y.prop]).format("DD.MM.YYYY HH:mm")
            ) : y.prop === "stage_id" ? (
              x[y.prop] ? (
                getStageName(x[y.prop], stages, name, dispatch, i)
              ) : (
                ""
              )
            ) : (
              x[y.prop]
            )}
          </TableCell>
        );
      })}
      {allowEdit && (
        <React.Fragment>
          <TableCell>
            {currentlyEditing ? (
              <CheckIcon onClick={() => saveEditing()} />
            ) : (
              <EditIcon onClick={() => startEditing(i, x.name.props.children[1], x)} />
            )}
          </TableCell>
          <TableCell>
            {currentlyEditing ? (
              <CloseIcon onClick={() => stopEditing(i)} />
            ) : (
              <DeleteIcon onClick={() => handleRemove(i)} />
            )}
          </TableCell>
        </React.Fragment>
      )}
    </TableRow>
  );
};

const getStageList = stages => {
  return stages.map((stage, index) => {
    return (
      <MenuItem key={index} value={stage.id}>
        {stage.name}
      </MenuItem>
    );
  });
};

const getStageName = (stage_id, stages, name, dispatch, i) => {
  let stageName = "undefined";

  stages &&
    stages.forEach(stage => {
      if (stage.id === stage_id) {
        stageName = stage.name;
      }
    });

  if (findObjectIndexByValue(stages, "id", stage_id) === -1) {
    dispatchToReduxStore(name + "[" + i + "].stage_id", SET_STATE_VALUE, null, dispatch);
  }

  return stageName;
};

const renderArtistCard = artist => {
  const country = artist && artist.country && artist.country.name ? artist.country.name : "";
  let genre = artist && artist.genre && artist.genre.name ? artist.genre.name : "";
  genre = genre ? genre : artist && artist.genres && artist.genres[0] ? artist.genres[0].name : "";

  return (
    <div className="btn-search-result btn-artist" key={artist.id}>
      <img src={artist.image ? artist.image : artist.images ? artist.images[0] : ""} alt={artist.name} />
      {artist.name}
      <span>
        {genre} | {country}
      </span>
    </div>
  );
};

LineupTable.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  tempValue: PropTypes.object,
  stages: PropTypes.array,
  dispatch: PropTypes.func,
  stagesPath: PropTypes.string,
  customHeader: PropTypes.array,
  allowEdit: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return {
    stages: getOr(null, `${mainStateKey}.${ownProps.stagesPath}`, state),
    value: getOr(null, `${mainStateKey}.${ownProps.name}`, state),
  };
};

export default connect(mapStateToProps)(LineupTable);
