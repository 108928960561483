import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import MultiSelector from "../../../input/selectors/multiSelector";
import { enumsPath, yesNoStatus, festivalStatus } from "../../../../constants/constants";
import CurrentlySelectedFiltersList from "../../../presentational/general/currentlySelectedFiltersList";
import Input from "../../../input/general/input";
import DateRange from "../../../input/date/dateRange";
import { editFestivalTableParametersData } from "../../../../constants/tableParametersData/editFestivalTableParametersData";
import Select from "../../../input/selectors/select";

const FiltersBox = ({ countries, pools }) => {
  const filtersPath = editFestivalTableParametersData.filtersPath;
  return (
    <FormBox title="Filters" style={{ zIndex: "999" }}>
      <div className="box-body">
        <table className="filters-table">
          <tbody>
            <tr>
              <td></td>
              <td>
                <small className="label">Start from-to</small>
              </td>
              <td>
                <small className="label">Updated from-to</small>
              </td>
              <td></td>
              <td></td>
              <td>
                <small className="label">Trailer</small>
              </td>
              <td>
                <small className="label">After Movie</small>
              </td>
              <td>
                <small className="label">Claimed</small>
              </td>
              <td></td>
              <td>
                <small className="label">Show only latest editions</small>
              </td>
              <td></td>
            </tr>
            <tr>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      placeholder="Search festival..."
                      className="form-control"
                      name={`${filtersPath}.festivalName`}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.start`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.updated`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "200px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Country"
                    data={countries}
                    displayKey="name"
                    idKey="code"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.country`}
                  />
                </div>
              </td>
              <td style={{ width: "200px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="City lookup filter"
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    searchOnBackend={true}
                    backendSearchEntity={["city"]}
                    name={`${filtersPath}.city`}
                  />
                </div>
              </td>
              <td style={{ width: "80px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Select idKey={"id"} displayKey="name" name={`${filtersPath}.trailer`} data={yesNoStatus} />
                  </div>
                </div>
              </td>
              <td style={{ width: "80px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Select idKey={"id"} displayKey="name" name={`${filtersPath}.after-movie`} data={yesNoStatus} />
                  </div>
                </div>
              </td>
              <td style={{ width: "80px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Select idKey={"id"} displayKey="name" name={`${filtersPath}.claimed`} data={yesNoStatus} />
                  </div>
                </div>
              </td>
              <td style={{ width: "100px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Status"
                    data={festivalStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.status`}
                  />
                </div>
              </td>
              <td style={{ width: "80px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Select idKey={"id"} displayKey="name" name={`${filtersPath}.latest`} data={yesNoStatus} />
                  </div>
                </div>
              </td>
              <td style={{ width: "120px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <MultiSelector
                      placeholder="Select pool"
                      data={pools}
                      displayKey="name"
                      idKey="id"
                      autocomplete={true}
                      name={`${filtersPath}.pool`}
                      showClearButton={false}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="row">
          <div className="col-md-12">
            <CurrentlySelectedFiltersList
              tableParamsData={editFestivalTableParametersData}
              connectedTo={editFestivalTableParametersData.filtersPath}
            />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

FiltersBox.propTypes = {
  countries: PropTypes.array,
  pools: PropTypes.array,
};

const mapStateToProps = state => ({
  countries: getOr(null, `${enumsPath}.countries`, state),
  pools: getOr(null, `${enumsPath}.pools`, state),
});

export default connect(mapStateToProps)(FiltersBox);
