import moment from "moment";
import { getOr } from "lodash/fp";
import commonDataManipulation from "../common/commonDataManipulation";
import { IsJsonString } from "../../functions/common";

const matchedEventDataManipulation = function matchedEventDataManipulation() {
  const getInwardDatatable = data => {
    const listItems = [];

    if (data && data.length) {
      data.forEach(item => {
        listItems.push([
          item.provider_id,
          moment(item.date).format("YYYY-MM-DD"),
          item.venue_name,
          item.country,
          item.city,
          item.event_name,
          item.artists,
          item.id,
          item.id,
        ]);
      });
    }

    return listItems;
  };

  const getOutwardMatchedEventItem = data => {
    const artistUuids = data && data.lineup ? data.lineup.map(artist => artist.id) : null;
    const dateTime = data && data.date && data.time ? data.date + " " + data.time : null;
    const start = dateTime ? moment(dateTime).format("YYYY-MM-DDTHH:mm:ss") : null;
    const tickets = commonDataManipulation().tickets.outward(data.ticketInfo);

    return {
      name: data && data.name ? data.name : null,
      venue_uuid: data && data.venue ? data.venue.id : null,
      artist_uuids: artistUuids,
      tickets: tickets,
      start: start,
    };
  };

  const getInwardMatchedEventItem = data => {
    let datetime = getOr(null, "data.start", data);
    datetime = datetime ? moment(datetime) : null;
    let date = datetime ? datetime.utc().format("YYYY-MM-DD") : null;
    let time = datetime ? datetime.utc().format("HH:mm:ss") : null;

    let scrappedInfo = getOr(null, "data.scraped_info", data);
    scrappedInfo = IsJsonString(scrappedInfo) ? JSON.stringify(JSON.parse(scrappedInfo), null, 2) : scrappedInfo;

    let venue = getOr(null, "data.venue", data);
    const lineup = getOr(null, "data.lineup", data);
    let name = getOr(null, "data.name", data);

    if (venue) {
      venue.id = venue.uuid;
    }

    return {
      id: getOr(null, "id", data),
      name: name,
      originalName: getOr(null, "data.name_full", data),
      originalVenueName: getOr(null, "data.venue_name", data),
      venueSearchText: venue ? {} : { name: getOr(null, "data.venue_name", data) },
      venue: venue,
      date: date,
      time: time,
      ticketInfo: getInwardTicket(getOr(null, "data.ticket_link", data)),
      lineup: getInwardLineup(lineup),
      scrappedInfo: scrappedInfo,
      nextId: getOr(null, "next_event_id", data),
    };
  };

  return {
    outward: getOutwardMatchedEventItem,
    inwardItem: getInwardMatchedEventItem,
    inwardDatatable: getInwardDatatable,
  };
};

const getInwardLineup = data => {
  const inwardLineup = [];

  if (data && data.length) {
    data.forEach(item => {
      inwardLineup.push({
        id: item.uuid,
        img: item.image,
        name: item.name,
        country: item.country ? item.country.name : null,
        genre: item.genre ? item.genre.name : null,
        slug: item.slug ? item.slug : null,
        links: item.links ? item.links : null,
      });
    });
  }

  return inwardLineup;
};

const getInwardTicket = data => {
  return {
    type: "online",
    online: true,
    providers: [data],
  };
};

export default matchedEventDataManipulation();
