import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { mainStateKey } from "../../../constants/constants";
import { checkPermissionString } from "../../../lib/functions/common";
import { redirectTo } from "../../../lib/functions/componentFunctions";

const NavItem = ({
  routerHistory,
  item,
  askForConfirmation,
  openedNavItems,
  setOpenedNavItems = () => {},
  userPermissions,
  selectedFapp,
}) => {
  item = extendItemProperties(item, openedNavItems, setOpenedNavItems);
  userPermissions = userPermissions ? userPermissions : {};

  const disabled =
    item.checkIfFappIsSet && (!selectedFapp || !selectedFapp.festivalUUID || !selectedFapp.editionUUID)
      ? { pointerEvents: "none", opacity: "0.6", cursor: "default" }
      : {};

  return (
    <React.Fragment>
      <li className={item.liClassName} style={{ cursor: "pointer", ...disabled }}>
        <a
          onClick={e => {
            handleSideBarClick(item, routerHistory, e, askForConfirmation);
          }}
        >
          <i className={item.itemIconClass} />
          <span>{item.name}</span>
          {item.menuOpenedIndicator}
        </a>
      </li>
      {item.children && item.children.length && (
        <ul className="treeview-menu" style={{ display: item.isActive ? "block" : "none" }}>
          {item.children.map((childItem, index) => {
            return (
              <React.Fragment key={index}>
                {checkPermissionString(userPermissions, childItem.permissionString) && (
                  <NavItem
                    routerHistory={routerHistory}
                    askForConfirmation={askForConfirmation}
                    openedNavItems={openedNavItems}
                    setOpenedNavItems={setOpenedNavItems}
                    item={childItem}
                    selectedFapp={selectedFapp}
                  />
                )}
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </React.Fragment>
  );
};

const extendItemProperties = (item, openedNavItems, setOpenedNavItems) => {
  let liClassName;
  let itemIconClass;
  let isActive;
  let menuOpenedIndicator = "";
  let onItemClick = () => {};

  if (item.children && item.children.length) {
    isActive = openedNavItems.menus && openedNavItems.menus[item.name] ? true : false;
    liClassName = "treeview " + (isActive ? "menu-open" : "");
    itemIconClass = "fa fa-plus-square";
    onItemClick = () => {
      setOpenedNavItems({
        ...openedNavItems,
        menus: { ...openedNavItems.menus, [item.name]: !isActive },
      });
    };

    menuOpenedIndicator = (
      <span className="pull-right-container">
        <i className="fa fa-angle-left pull-right" />
      </span>
    );
  } else {
    isActive = openedNavItems.pages && openedNavItems.pages[item.url] ? true : false;
    liClassName = isActive ? "active" : "";
    itemIconClass = "fa fa-dot-circle-o";
    onItemClick = () => {
      setOpenedNavItems({
        ...openedNavItems,
        pages: { [item.url]: !isActive },
      });
    };
  }

  return {
    ...item,
    liClassName: liClassName,
    itemIconClass: itemIconClass,
    isActive: isActive,
    menuOpenedIndicator: menuOpenedIndicator,
    onItemClick: onItemClick,
  };
};

const handleSideBarClick = (item, routerHistory, e, askForConfirmation) => {
  e.preventDefault();
  item.onItemClick();
  if (item.url) {
    let confirmed = true;

    if (askForConfirmation) {
      confirmed = window.confirm("Are you sure you want to change the page?");
    }

    if (confirmed) {
      if (item.url !== window.location.pathname) {
        redirectTo(routerHistory, item.url);
      }
    }
  }
};

NavItem.propTypes = {
  routerHistory: PropTypes.object,
  item: PropTypes.object,
  askForConfirmation: PropTypes.bool,
  openedNavItems: PropTypes.object,
  setOpenedNavItems: PropTypes.func,
  userPermissions: PropTypes.object,
  selectedFapp: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    askForConfirmation: getOr(false, `${mainStateKey}.editor.sideBarAskForConfirmation`, state),
    userPermissions: getOr(null, `${mainStateKey}.user.permissions`, state),
    selectedFapp: getOr(null, `${mainStateKey}.editor.fapp`, state),
  };
};

export default connect(mapStateToProps)(NavItem);
