import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import GeneralInfoBox from "../shared/generalInfoBox";
import { SET_SIDEBAR_ASK_FOR_CONFIRMATION } from "../../../../redux/reducers/general/actionTypes";
import { useResetPageState } from "../../../../lib/functions/customHooks";
import GenresBox from "../shared/genresBox";
import feedpostDataManipulation from "../../../../lib/dataManipulation/feedpost/feedpostDataManipulation";
import { handleAddFormSubmit, redirectTo } from "../../../../lib/functions/componentFunctions";
import ContentComponentSelector from "../../../input/selectors/contentComponentSelector";
import { useHistory } from "react-router";
import { feedpostComponents } from "../../../../constants/feedpost";
import ContentBox from "../shared/contentBox";
import { mainStateKey } from "../../../../constants/constants";

const AddFeedpost = ({ dispatch, articleID }) => {
  useResetPageState();
  const routerHistory = useHistory();
  dispatch({ type: SET_SIDEBAR_ASK_FOR_CONFIRMATION, payload: true });

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add Feedpost</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-8">
            <GeneralInfoBox />
            <div className="row">
              <div className="col-md-12">
                <ContentBox />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <GenresBox />
          </div>
        </div>
        <div style={{ height: "100px" }}></div>
        <ContentComponentSelector
          disabled={articleID}
          onSubmit={async () => {
            const submitSuccessfull = await handleAddFormSubmit(false, feedpostDataManipulation, "feedpost");
            if (submitSuccessfull) {
              redirectTo(routerHistory, "/list/feed-post");
            }
          }}
          availableComponents={feedpostComponents}
          showPreviewButton={false}
        />
      </section>
    </React.Fragment>
  );
};

AddFeedpost.propTypes = {
  dispatch: PropTypes.func,
  articleID: PropTypes.number,
};

const mapStateToProps = state => {
  return { articleID: getOr(null, `${mainStateKey}.formData.article[0].id`, state) };
};

export default connect(mapStateToProps)(AddFeedpost);
