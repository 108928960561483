import React from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import store from "../../../redux/store/store";
import { SET_FORM_SHOW_ERROR_WINDOW, SET_FORM_SHOW_ERROR_REASONS } from "../../../redux/reducers/general/actionTypes";
import { enumsPath, mainStateKey } from "../../../constants/constants";
import { capitalizeString, findObjectByValue } from "../../../lib/functions/common";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

function ErrorMessage({ showError, showDetails, reasons, errorTitle, links, channels, entityType, dispatch }) {
  return (
    <div
      className={"general-errors alert alert-danger " + (showError ? " show " : "") + (showDetails ? "expand " : "")}
    >
      <button
        type="button"
        className="btn fa fa-close hider pull-right"
        onClick={() => {
          store.dispatch({ type: SET_FORM_SHOW_ERROR_WINDOW, payload: false });
          store.dispatch({ type: SET_FORM_SHOW_ERROR_REASONS, payload: false });
        }}
      />
      <button
        type="button"
        className="btn fa fa-angle-down expander pull-right"
        onClick={() => {
          store.dispatch({
            type: SET_FORM_SHOW_ERROR_REASONS,
            payload: !showDetails,
          });
        }}
      />
      <h4>
        <i className="icon fa fa-ban" />
        {errorTitle}
      </h4>
      <ul>{getErrorReasons(reasons, links, channels, entityType, dispatch)}</ul>
    </div>
  );
}

function getErrorReasons(reasons = [], links, channels, entityType, dispatch) {
  let reasonsArray = [];

  // parse unique link errors
  const uniqueLinks = [];
  let duplicateddLinks = [];

  if (reasons && reasons.length > 0) {
    reasons.forEach((item, index) => {
      if (item.domain === "links" && item.message.includes("already taken by")) {
        const loopedReasons = item.message.split("; ");

        loopedReasons.forEach((text, index) => {
          const linkIndex = parseInt(text.split(":")[0]);
          const linkData = links[linkIndex];
          const channelID = linkData.channel_id;
          const channelName = capitalizeString(findObjectByValue(channels, "id", channelID).name);
          const splitTakenBy = text.split("already taken by ");
          const takenBy =
            index === loopedReasons.length - 1
              ? splitTakenBy[splitTakenBy.length - 1].slice(0, -3)
              : splitTakenBy[splitTakenBy.length - 1].slice(0, -2);

          const takenBySlug = takenBy.split("/")[0];
          const takenByUUID = takenBy.split("/")[1];

          const url = linkData.url;
          uniqueLinks.push({ channelName, takenBy, url, linkIndex, takenBySlug, takenByUUID });
        });
        reasons[index] = { ...reasons[index], ignore: true };
      }
    });

    reasons.forEach((reason, i) => {
      if (!reason.ignore) {
        reasonsArray.push(<li key={i}>{reason.domain + " - " + reason.message}</li>);
      }
    });
  }

  duplicateddLinks = uniqueLinks.map(item => {
    return item.url;
  });

  dispatchToReduxStore(`formErrors.duplicatedLinks`, SET_STATE_VALUE, duplicateddLinks, dispatch);

  if (uniqueLinks.length) {
    // Highlight links on the form that aren't unique

    reasonsArray.push(
      <li key={-1}>
        Links must be unique
        <ul>
          {uniqueLinks.map((item, i) => {
            return (
              <li key={i}>
                <strong>{item.channelName}</strong> <br />
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.url}
                </a>{" "}
                is already taken by <br />
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={"/edit/" + entityType + "/" + item.takenByUUID}
                  className="logo"
                >
                  {item.takenBySlug}
                </Link>
                <br />
                {/*<button className="btn btn-default btn-small">Overwrite</button>*/}
              </li>
            );
          })}
        </ul>
      </li>
    );
  }

  return reasonsArray;
}

ErrorMessage.propTypes = {
  showError: PropTypes.bool,
  showDetails: PropTypes.bool,
  reasons: PropTypes.array,
  links: PropTypes.array,
  errorTitle: PropTypes.string,
  entityType: PropTypes.string,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    showError: getOr(false, `${mainStateKey}.formStatus.formShowError`, state),
    showDetails: getOr(false, `${mainStateKey}.formStatus.formShowErrorDetails`, state),
    reasons: getOr(null, `${mainStateKey}.formStatus.formErrorReasons.reasons`, state),
    errorTitle: getOr("Error", `${mainStateKey}.formStatus.formErrorTitle`, state),
    links: getOr(null, `${mainStateKey}.formStatus.formErrorReasons.parsedData.links`, state),
    entityType: getOr(null, `${mainStateKey}.formStatus.formErrorReasons.entityType`, state),
    channels: getOr(null, `${enumsPath}.channels`, state),
  };
};

export default connect(mapStateToProps)(ErrorMessage);
