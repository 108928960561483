import React from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import CmsModuleBox from "../../presentational/general/cmsModuleBox";
import { mainStateKey } from "../../../constants/constants";

function CmsContent({ value, name }) {
  return (
    <React.Fragment>
      {value &&
        value.map((module, index) => {
          return (
            <div key={index} className="row row-module">
              <div className="col-md-12">
                {module && module.component && (
                  <CmsModuleBox
                    data={module.data}
                    name={name}
                    title={module.title || ""}
                    icon={module.icon || ""}
                    index={index}
                  >
                    {module.component}
                  </CmsModuleBox>
                )}
              </div>
            </div>
          );
        })}
    </React.Fragment>
  );
}

CmsContent.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(CmsContent);
