import React from "react";
import PictureModule from "../components/input/modules/pictureModule";
import TextModule from "../components/input/modules/textModule";
import CtaModule from "../components/input/modules/ctaModule";

export const feedpostComponents = [
  {
    component: <TextModule />,
    value: "",
    selectorTitle: "Add Text",
    selectorIcon: "fa fa-font",
    moduleTitle: "Text Module",
    moduleIcon: "fa fa-fw fa-font",
    fieldName: "text",
  },
  {
    component: <PictureModule />,
    value: null,
    selectorTitle: "Add Picture",
    selectorIcon: "fa fa-picture-o",
    moduleTitle: "Picture Module",
    moduleIcon: "fa fa-fw fa-picture-o",
    fieldName: "photo",
  },
  {
    component: <CtaModule />,
    value: null,
    selectorTitle: "Add CTA",
    selectorIcon: "fa fa-hand-o-up",
    moduleTitle: "CTA Module",
    moduleIcon: "fa fa-fw fa-hand-o-up",
    fieldName: "CTA",
  },
];
