import { getOr } from "lodash/fp";
import moment from "moment";
import { parseImage } from "../../functions/common";
import moduleDataManipulation from "./moduleParser";

export const outwardMedia = (images, gallery, videos) => {
  let media = {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  };
  let pushedValue;

  if (images && Array.isArray(images) && images.length) {
    images.forEach(image => {
      if (image.url) {
        pushedValue = { ...parseImage(image.url), media_type_id: 1 };

        if (media["1"]) {
          media["1"].push(pushedValue);
        } else {
          media["1"] = [pushedValue];
        }
      }
    });
  }

  if (gallery && Array.isArray(gallery) && gallery.length) {
    gallery.forEach(image => {
      if (image.url) {
        pushedValue = { ...parseImage(image.url), media_type_id: 4 };

        if (media["4"]) {
          media["4"].push(pushedValue);
        } else {
          media["4"] = [pushedValue];
        }
      }
    });
  }

  if (videos && Array.isArray(videos) && videos.length) {
    videos.forEach(video => {
      if (video.url && video.type_id) {
        pushedValue = { url: video.url };

        if (media[video.type_id]) {
          media[video.type_id].push(pushedValue);
        } else {
          media[video.type_id] = [pushedValue];
        }
      }
    });
  }

  return media;
};

export const outwardLocation = data => {
  const coordinates = data && data.location && data.location.coordinates ? data.location.coordinates : null;
  let cordObject = null;

  if (coordinates && typeof coordinates === "string" && coordinates.includes(",")) {
    const cordArray = coordinates.split(",");

    if (cordArray && cordArray.length) {
      cordObject = {
        lat: parseFloat(cordArray[0]),
        lng: parseFloat(cordArray[1]),
      };
    }
  }

  return {
    city_id: getOr(null, "location.city.id", data),
    city: getOr(null, "location.city.name", data),
    country_code: getOr(null, "location.country.code", data),
    country: getOr(null, "location.country.name", data),
    address: getOr(null, "location.address", data),
    coordinates: cordObject,
  };
};

export const outwardLinks = data => {
  let modifiedLinks = [];

  if (data && data.length > 0) {
    data.forEach((link, channelId) => {
      if (link && channelId) {
        modifiedLinks.push({ channel_id: channelId, url: link });
      }
    });
  }

  return modifiedLinks;
};

export const outwardTickets = data => {
  let tickets = [];
  let obj = {
    link: null,
    price_min: null,
    price_currency_id: 1,
  };

  const ticketType = data && data.type ? data.type : null;

  if (ticketType) {
    switch (ticketType) {
      case "free":
        obj.ticket_provider_id = 1;
        tickets.push(obj);
        break;
      case "door":
        obj.ticket_provider_id = 2;
        obj.price_min = data.price ? parseInt(data.price) : null;
        tickets.push(obj);
        break;
      case "online":
        obj.price_min = data.price ? parseInt(data.price) : null;
        obj.ticket_provider_id = 0;

        if (data.providers && data.providers.length > 0) {
          data.providers.forEach(item => {
            if (item) {
              obj.link = item;
              tickets.push({ ...obj });
            }
          });
        }
        break;
      default:
        break;
    }
  }

  return tickets;
};

export const outwardOpeningHours = (data, openOnEvent) => {
  const openingHours = [];

  if (data && data.length > 0) {
    data.forEach((day, counter) => {
      if (day && day.start && day.end) {
        openingHours.push({
          day: counter + 1,
          open: day.start + ":00",
          close: day.end + ":00",
        });
      }
    });
  }

  return {
    events_only: openOnEvent,
    days: openingHours,
  };
};

export const outwardSubgenres = data => {
  let subgenres = [];

  if (data && data.length) {
    data.forEach(item => {
      if (item && item.id) {
        subgenres.push(item.id);
      }
    });
  }

  return subgenres;
};

export const outwardWeeks = data => {
  let weeks = [];
  let start;
  let end;
  let firstDateStart = null;
  let firstDateEnd = null;

  if (data && Array.isArray(data) && data.length) {
    data.forEach((item, index) => {
      if (item && item.start && item.end) {
        start = moment(item.start).format("YYYY-MM-DD");
        end = moment(item.end).format("YYYY-MM-DD");

        if (index === 0) {
          firstDateStart = start;
          firstDateEnd = end;
        }

        weeks.push({
          start: start,
          end: end,
        });
      }
    });
  }

  return weeks.length === 1 ? { start: firstDateStart, end: firstDateEnd, weeks: [] } : { weeks: weeks };
};

export const outwardLineupTable = data => {
  const lineup = [];

  if (data && Array.isArray(data) && data.length) {
    data.forEach(item => {
      lineup.push({
        artist_uuid: item.artist ? item.artist.uuid : null,
        stage_id: item.stage_id ? item.stage_id : null,
        day: item.day ? item.day : null,
        start: item.start ? moment(item.start).format("YYYY-MM-DDTHH:mm:ss") : null,
        end: item.end ? moment(item.end).format("YYYY-MM-DDTHH:mm:ss") : null,
      });
    });
  }

  return lineup;
};

export const outwardStages = data => {
  const stages = [];
  let stageId;

  if (data && Array.isArray(data) && data.length) {
    data.forEach(item => {
      if (item.name && !stages.includes(item.name)) {
        stageId = item.id && item.id !== 0 ? { id: item.id } : null;
        stages.push({ ...stageId, name: item.name });
      }
    });
  }

  return stages;
};

export const outwardContent = data => {
  let content = [];

  if (data && data.length) {
    data.forEach(item => {
      content.push(moduleDataManipulation[item.fieldName].outward(item));
    });
  }

  return content;
};
