import { set, getOr } from "lodash/fp";

import { SET_FREE, SET_DOOR, SET_ONLINE, CLEAR } from "./actionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case SET_FREE: {
      const ticketInfo = { ...getOr({}, action.name, state) };
      ticketInfo.type = "free";
      ticketInfo.free = true;
      ticketInfo.door = false;
      ticketInfo.online = false;
      ticketInfo.providers = null;
      ticketInfo.priceDisabled = true;
      ticketInfo.providersDisabled = true;
      ticketInfo.price = "";
      return set(`${action.name}`, ticketInfo, state);
    }

    case SET_DOOR: {
      const ticketInfo = { ...getOr({}, action.name, state) };
      ticketInfo.type = "door";
      ticketInfo.free = false;
      ticketInfo.door = true;
      ticketInfo.online = false;
      ticketInfo.providers = null;
      ticketInfo.priceDisabled = false;
      ticketInfo.providersDisabled = true;
      return set(`${action.name}`, ticketInfo, state);
    }

    case SET_ONLINE: {
      const ticketInfo = { ...getOr({}, action.name, state) };
      ticketInfo.type = "online";
      ticketInfo.free = false;
      ticketInfo.door = false;
      ticketInfo.online = true;
      ticketInfo.priceDisabled = false;
      ticketInfo.providersDisabled = false;
      return set(`${action.name}`, ticketInfo, state);
    }

    case CLEAR: {
      return set(`${action.name}`, null, state);
    }

    default:
      return { ...state };
  }
}
