import React from "react";
import { connect } from "react-redux";
import FormBox from "../../presentational/page/formBox";
import CurrentlySelectedFiltersList from "../../presentational/general/currentlySelectedFiltersList";
import Input from "../../input/general/input";
import { entityTypes, yesNoStatus, contributionTypes } from "../../../constants/constants";
import { contributionHistoryTableParametersData } from "../../../constants/tableParametersData/contributionHistoryTableParametersData";
import DateRange from "../../input/date/dateRange";
import Select from "../../input/selectors/select";
import MultiSelector from "../../input/selectors/multiSelector";

const FiltersBox = () => {
  const filtersPath = contributionHistoryTableParametersData.filtersPath;
  return (
    <FormBox title="Filters" style={{ zIndex: "999" }}>
      <div className="box-body">
        <table className="filters-table">
          <tbody>
            <tr>
              <td></td>

              <td></td>
              <td>
                <small className="label">Created from-to</small>
              </td>
              <td>
                <small className="label">Moderated from-to</small>
              </td>
              <td>
                <small className="label">Reviewed</small>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style={{ width: "300px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <MultiSelector
                      placeholder="User lookup filter"
                      displayKey="email"
                      idKey="uuid"
                      autocomplete={true}
                      showClearButton={false}
                      searchOnBackend={true}
                      backendSearchEntity={["user"]}
                      name={`${filtersPath}.user`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      placeholder="Search entity name..."
                      className="form-control"
                      name={`${filtersPath}.entityName`}
                    />
                  </div>
                </div>
              </td>

              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.dateCreated`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.dateModerated`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "80px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Select idKey={"id"} displayKey="name" name={`${filtersPath}.review`} data={yesNoStatus} />
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <MultiSelector
                      placeholder="Entity type"
                      displayKey="name"
                      idKey="id"
                      autocomplete={true}
                      showClearButton={false}
                      searchOnBackend={false}
                      backendSearchEntity={["user"]}
                      name={`${filtersPath}.entityType`}
                      data={entityTypes}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <MultiSelector
                      placeholder="Contribution type"
                      displayKey="name"
                      idKey="id"
                      autocomplete={true}
                      showClearButton={false}
                      searchOnBackend={false}
                      backendSearchEntity={["user"]}
                      name={`${filtersPath}.contributionType`}
                      data={contributionTypes}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row">
          <div className="col-md-12">
            <CurrentlySelectedFiltersList
              tableParamsData={contributionHistoryTableParametersData}
              connectedTo={contributionHistoryTableParametersData.filtersPath}
            />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default connect(null)(FiltersBox);
