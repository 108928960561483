import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import Input from "../../input/general/input";
import Select from "../../input/selectors/select";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import { mainStateKey, videoCategory } from "../../../constants/constants";

const AddVideo = ({
  name,
  value,
  dispatch,
  onChange = () => {},
  categories = videoCategory,
  clearSelectAfterAdd = true,
  clearLinkAfterAdd = true,
  videoTypeSelectorDisabled = false,
}) => {
  return (
    <React.Fragment>
      <div className="form-group">
        <div className="input-group">
          <Input
            name={name + ".url"}
            type="text"
            placeholder="Youtube Link"
            className="form-control"
            debounceTime={0}
          />

          <span className="input-group-addon">
            <i className="fa fa-youtube-play" />
          </span>
        </div>
      </div>

      <div className="form-group">
        <div className="custom-select">
          <Select
            placeholder="Select video type"
            data={categories}
            displayKey="name"
            idKey="id"
            name={name + ".type"}
            disabled={videoTypeSelectorDisabled}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="input-group">
          <button
            type="button"
            onClick={() => {
              if (value && value.url && value.type) {
                onChange(value);
                const dispatchedObject = {
                  url: clearLinkAfterAdd ? null : value.url,
                  type: clearSelectAfterAdd ? null : value.type,
                };

                dispatchToReduxStore(name, SET_STATE_VALUE, dispatchedObject, dispatch);
              }
            }}
            className="btn btn-default pull-right btn-small"
          >
            Add Video
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

AddVideo.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  dispatch: PropTypes.func,
  onChage: PropTypes.func,
  categories: PropTypes.array,
  clearSelectAfterAdd: PropTypes.bool,
  clearLinkAfterAdd: PropTypes.bool,
  videoTypeSelectorDisabled: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(AddVideo);
