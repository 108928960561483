import { manipulateContributionHistoryFields } from "../../../constants/constants";
import commonDataManipulation from "../common/commonDataManipulation";

export const getDataByType = (data, type, entityType, getWholeObject = false) => {
  let returnedData = {};
  let fieldName = "";
  let obj;
  let id;

  if (data.details) {
    data.details.forEach(item => {
      fieldName = item.field;
      id = item.id;
      obj = getWholeObject ? item : item[type];

      if (!getWholeObject) {
        //Manipulate certain fields so we can reuse input commponents for presenting field data
        if (manipulateContributionHistoryFields.includes(fieldName) && item[type]) {
          switch (fieldName) {
            case "opening_hours":
              obj = commonDataManipulation().openingHours.inward({ days: item[type] });
              break;
            case "tickets":
              obj = commonDataManipulation().tickets.inward(item[type]);
              break;
            case "lineup":
              if (entityType === "event") {
                obj = commonDataManipulation().artistList.inward(item[type]);
              }
              break;
            default:
              console.warn("Cannot manipulate unknown field type");
          }
        }
      }

      //Tracks may have multiple links for the same channel
      if (entityType === "track" && fieldName.includes("link_")) {
        returnedData[fieldName + `_fieldID${id}`] = obj;
      } else {
        returnedData[fieldName] = obj;
      }
    });
  }

  return returnedData;
};
