import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";

import { setItemFormData } from "../../../lib/functions/componentFunctions";
import DatesBox from "./datesBox";
import { capitalizeString } from "../../../lib/functions/common";
import ContributionReview from "../contributionHistory/review/contributionReview";
import { mainStateKey } from "../../../constants/constants";
import { useResetPageState } from "../../../lib/functions/customHooks";

const ChangeHistoryLog = ({ formData }) => {
  useResetPageState();

  let { id, type, edition } = useParams();

  useEffect(() => {
    setItemFormData(id, edition ? edition : "", type + "History");
  }, [id, type, edition]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>{capitalizeString(type)} change history log</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <DatesBox />
          </div>
        </div>
      </section>
      {formData && Object.keys(formData).length > 1 && (
        <ContributionReview
          connectedTo="formData.activeItem"
          showHeader={false}
          showReview={false}
          setItemData={false}
        />
      )}
    </React.Fragment>
  );
};

ChangeHistoryLog.propTypes = {
  formData: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    formData: getOr(null, `${mainStateKey}.formData`, state),
  };
};

export default connect(mapStateToProps)(ChangeHistoryLog);
