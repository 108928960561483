import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import MultiCheckbox from "../../input/general/multiCheckbox";
import { enumsPath, mainStateKey } from "../../../constants/constants";

const Amenities = ({
  amenitiesType,
  amenities,
  name = "formData.amenities",
  initialAmenities,
  initialAmenitiesPath,
}) => {
  const amenitiesList = amenities && amenities[amenitiesType] ? amenities[amenitiesType] : [];

  return (
    <React.Fragment>
      {amenitiesList.map(item => {
        return (
          <div className="form-group float-list-item" key={item.id}>
            <div
              className={"checkbox " + (initialAmenities && initialAmenities.includes(item.id) ? "pre-selected" : "")}
            >
              <MultiCheckbox pushedItem={item.id} name={`${name}`} showClearInputButton={false} />
              <label>{item.name} </label>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

Amenities.propTypes = {
  amenitiesType: PropTypes.string,
  amenities: PropTypes.object,
  name: PropTypes.string,
  initialHighlights: PropTypes.array,
  initialHighlightsPath: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    amenities: getOr(null, `${enumsPath}.amenities`, state),
    initialAmenities: getOr(null, `${mainStateKey}.${ownProps.initialAmenitiesPath}`, state),
  };
};

export default connect(mapStateToProps)(Amenities);
