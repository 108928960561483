import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { mainStateKey } from "../../../constants/constants";

const Coordinates = ({ connectedTo, object = {} }) => {
  const lat = object ? object.lat : "";
  const lng = object ? object.lng : "";
  return (
    <React.Fragment>
      <p>Latitude:{lat}</p>
      <p>Longitude:{lng}</p>
      <button
        className="btn btn-default"
        onClick={() => {
          window.open("https://www.google.com/maps/search/?api=1&query=" + lat + "," + lng);
        }}
      >
        Go to GMAPS
      </button>
    </React.Fragment>
  );
};

Coordinates.propTypes = {
  connectedTo: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { object: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state) };
};

export default connect(mapStateToProps)(Coordinates);
