import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { capitalizeString } from "../../../../../lib/functions/common";

const DecisionColumn = ({ fieldName, reviewData }) => {
  const reason = getOr("", `${fieldName}.reason.name`, reviewData);

  return (
    <td>
      {capitalizeString(getOr("", `${fieldName}.status`, reviewData))}
      {reason && (
        <React.Fragment>
          <br />({reason})
        </React.Fragment>
      )}
    </td>
  );
};

DecisionColumn.propTypes = {
  fieldName: PropTypes.string,
  reviewData: PropTypes.object,
};

export default DecisionColumn;
