import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { modalPath, mainStateKey } from "../../../../constants/constants";

const MusicPlayerModal = ({ value }) => {
  const title = getOr("", "trackName", value);
  const URL = getOr("", "sampleUrl", value);

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 title={title} className="modal-title clamp-line-1 " style={{ maxWidth: "300px" }}>
          {title}
        </h5>
      </div>
      <div className="modal-body">
        <audio controls autoPlay>
          <source src={URL} type="audio/mp3" />
        </audio>
      </div>
    </div>
  );
};

MusicPlayerModal.propTypes = {
  value: PropTypes.object,
};

const mapStateToProps = state => {
  return { value: getOr("", `${mainStateKey}.${modalPath}.data`, state) };
};

export default connect(mapStateToProps)(MusicPlayerModal);
