import { getOr } from "lodash/fp";
import { festivalAppMapTypes, markerTypes } from "../../../constants/constants";
import { findObjectByValue, getMarkerIcon } from "../../functions/common";

const festivalAppDataManipulation = function festivalAppDataManipulation() {
  const getInwardEditFestivalItem = ({ map, stages, experiences, info }) => {
    let obj;

    return {
      selectedTab: findObjectByValue(festivalAppMapTypes, "type", getOr("interactive", "map_type", map)),
      overlayImage: getOr("", "overlay.image", map),
      map: {
        ...map,
        overlay: {
          ...getOr({}, "overlay", map),
          ...inwardBounds(map),
        },
        pins: getOr([], "pins", map).map(item => {
          obj = markerTypes.find(x => x.backendValue === item.category);
          //category -> name of specific category: "amenity","experience","stage"
          //category_id -> specific id of amenity/experience/stage instance
          //category_name -> specific name of amenity/experience/stage instance
          return {
            id: item.category_id,
            entityName: item.category_name,
            categoryName: obj && obj.name ? obj.name : "",
            coordinates: item.coordinates,
            icon: getMarkerIcon(item.category, item.category_id),
          };
        }),
      },
      stages,
      experiences,
      amenities: getOr([], "amenities", info),
    };
  };

  const getOutwardFestival = data => {
    let obj;
    const selectedMapType = getOr("interactive", "selectedTab.type", data);

    return {
      overlay: { ...outwardOverlay(selectedMapType, data) },
      pins:
        selectedMapType === "image"
          ? []
          : getOr([], "map.pins", data).map(item => {
              //category -> name of specific category: "amenity","experience","stage"
              //category_id -> specific id of amenity/experience/stage instance
              obj = markerTypes.find(x => x.name === item.categoryName);
              return {
                category: obj && obj.backendValue ? obj.backendValue : "",
                category_id: item.id,
                coordinates: item.coordinates,
              };
            }),
    };
  };

  return { outward: getOutwardFestival, inwardItem: getInwardEditFestivalItem };
};

const outwardOverlay = (selectedMapType, data) => {
  let image;
  let edgeNe;
  let edgeSw;
  let rotation;
  let opacity;

  switch (selectedMapType) {
    case "static":
      image = null;
      edgeNe = { lat: 0, lng: 0 };
      edgeSw = { lat: 0, lng: 0 };
      rotation = 0;
      opacity = 0;
      break;
    case "image":
      image = getOr("", "overlayImage", data);
      edgeNe = { lat: 0, lng: 0 };
      edgeSw = { lat: 0, lng: 0 };
      rotation = 0;
      opacity = 0;
      break;
    case "interactive":
    default:
      image = getOr("", "overlayImage", data);
      edgeNe = getOr("", "map.overlay.edge_ne", data);
      edgeSw = getOr("", "map.overlay.edge_sw", data);
      rotation = parseInt(getOr("0", "map.overlay.rotation", data));
      opacity = parseFloat(getOr("0.7", "map.overlay.opacity", data));
  }

  return {
    image,
    opacity,
    rotation,
    edge_ne: edgeNe,
    edge_sw: edgeSw,
  };
};

const inwardBounds = data => {
  let edgeNe = getOr({}, "overlay.edge_ne", data);
  let edgeSw = getOr({}, "overlay.edge_sw", data);

  if ((edgeNe.lat === 0 && edgeNe.lng === 0) || edgeNe === {} || !edgeNe) {
    edgeNe = null;
  }

  if ((edgeSw.lat === 0 && edgeSw.lng === 0) || edgeSw === {} || !edgeSw) {
    edgeSw = null;
  }

  return {
    edge_ne: edgeNe,
    edge_sw: edgeSw,
  };
};

export default festivalAppDataManipulation();
