import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import navItemsList from "../../../constants/sidebarRoutes";
import NavItem from "./navItem";
import { mainStateKey } from "../../../constants/constants";
import { checkPermissionString } from "../../../lib/functions/common";

function SideBar({ currentPage, userPermissions, allowedFapps }) {
  userPermissions = userPermissions ? userPermissions : {};

  const routerHistory = useHistory();
  const [openedNavItems, setOpenedNavItems] = useState({
    menus: getOpenedSidebarMenusFromUrl(currentPage),
    pages: { [currentPage]: true },
  });

  return (
    <React.Fragment>
      <aside className="main-sidebar">
        <section className="sidebar">
          <ul className="sidebar-menu" data-widget="tree">
            <li className="header">MAIN NAVIGATION</li>
            {navItemsList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {checkPermissionString(userPermissions, item.permissionString) &&
                    checkFapp(item.checkIfUserHasAllowedFapps, allowedFapps) && (
                      <NavItem
                        routerHistory={routerHistory}
                        openedNavItems={openedNavItems}
                        setOpenedNavItems={setOpenedNavItems}
                        item={item}
                      />
                    )}
                </React.Fragment>
              );
            })}
          </ul>
        </section>
      </aside>
    </React.Fragment>
  );
}

const checkFapp = (checkIfUserHasAllowedFapps, allowedFapps) => {
  let show = true;

  if (checkIfUserHasAllowedFapps) {
    show = allowedFapps.length ? true : false;
  }

  return show;
};

const getOpenedSidebarMenusFromUrl = currentPage => {
  let activeMenus = [];

  for (let i = 0; i < navItemsList.length; i++) {
    if (foundInNavItemsList(navItemsList[i], activeMenus, currentPage)) {
      break;
    } else {
      activeMenus = [];
    }
  }

  let openedMenus = {};
  activeMenus.forEach(item => {
    openedMenus[item] = true;
  });

  return openedMenus;
};

const foundInNavItemsList = (item, activeMenus, currentPage) => {
  if (item.children && item.children.length) {
    for (let i = 0; i < item.children.length; i++) {
      if (foundInNavItemsList(item.children[i], activeMenus, currentPage)) {
        activeMenus.push(item.name);
        return true;
      }
    }
  } else {
    if (item.url === currentPage) {
      return true;
    }
  }
};

SideBar.propTypes = {
  allowedFapps: PropTypes.array,
  currentPage: PropTypes.string,
  userPermissions: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    userPermissions: getOr(null, `${mainStateKey}.user.permissions`, state),
    allowedFapps: getOr(null, `${mainStateKey}.user.allowedFapps`, state),
  };
};

export default connect(mapStateToProps)(SideBar);
