import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { makeid } from "../../../lib/functions/common";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { mainStateKey, modalPath } from "../../../constants/constants";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

const UploadImageButton = ({
  value,
  onChange = () => {},
  onRemove = () => {},
  dispatch,
  name,
  title = "",
  imageLabel = "",
  imagePreviewOrinetation = "tr",
  deleteComponentOnRemove = false,
  disabledFileSelector = false,
  showPreviewButton = true,
  enlargeImageOnPreviewHover = false,
  showModalOnPreviewButtonClick = true,
  showOrderButtons = false,
  allowGif = false,
  onLeftOrderButtonClick = () => {},
  onRightOrderButtonClick = () => {},
  onMediaButtonClick = () => {},
  showClearButton = true,
  disabled = false,
}) => {
  const id = makeid(15);

  const imageChanged = event => {
    let reader = new FileReader();

    reader.onload = e => {
      const img = e.target.result;
      onChange(img);
      dispatchToReduxStore(name, SET_STATE_VALUE, img, dispatch);
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      event.target.value = null;
    }
  };

  const removeImage = () => {
    onChange(null);
    onRemove();
    if (deleteComponentOnRemove === false) {
      dispatchToReduxStore(name, SET_STATE_VALUE, null, dispatch);
    }
  };

  const backgroundImageStyle = value ? { backgroundImage: "url('" + value + "')" } : {};
  const active = value ? "active " : " ";
  const buttonClass = "upload-image " + active + (showOrderButtons && value ? "order" : "");

  return (
    <React.Fragment>
      <div className={buttonClass} style={backgroundImageStyle}>
        {!disabled && (
          <input
            onChange={imageChanged}
            id={id}
            type="file"
            accept={"image/png, image/jpeg" + (allowGif ? ",image/gif" : "")}
            style={{ display: "none" }}
            disabled={disabledFileSelector}
          />
        )}
        <label
          onClick={() => {
            onMediaButtonClick();
          }}
          htmlFor={id}
        >
          {!value ? <span>{title}</span> : <span>{imageLabel}</span>}
        </label>
        <button className="btn btn-default btn-order">
          <span
            className="order-prev"
            onClick={() => {
              onLeftOrderButtonClick();
            }}
          >
            {"<"}
          </span>
          <span
            className="order-next"
            onClick={() => {
              onRightOrderButtonClick();
            }}
          >
            {">"}
          </span>
        </button>
        {showClearButton && <span onClick={removeImage} className="btn-remove-item" />}
        {showPreviewButton && (
          <span
            className="btn-preview-item"
            onClick={() => {
              if (showModalOnPreviewButtonClick) {
                dispatchToReduxStore(modalPath, SET_STATE_VALUE, { show: true, data: value, type: "image" }, dispatch);
              }
            }}
          >
            {enlargeImageOnPreviewHover && (
              <div className={"preview " + imagePreviewOrinetation}>
                <img src={value} alt="" />
              </div>
            )}
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

UploadImageButton.propTypes = {
  title: PropTypes.string,
  imageLabel: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  dispatch: PropTypes.func,
  deleteComponentOnRemove: PropTypes.bool,
  imagePreviewOrinetation: PropTypes.string,
  disabledFileSelector: PropTypes.bool,
  showPreviewButton: PropTypes.bool,
  enlargeImageOnPreviewHover: PropTypes.bool,
  showModalOnPreviewButtonClick: PropTypes.bool,
  showOrderButtons: PropTypes.bool,
  onLeftOrderButtonClick: PropTypes.func,
  onRightOrderButtonClick: PropTypes.func,
  onMediaButtonClick: PropTypes.func,
  showClearButton: PropTypes.bool,
  disabled: PropTypes.bool,
  allowGif: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(UploadImageButton);
