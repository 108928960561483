import React, { useEffect } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { handleEditFormSubmit, setItemFormData } from "../../../../lib/functions/componentFunctions";
import Footer from "../../../presentational/page/footer";
import FieldsBox from "./fieldsBox";
import GeneralInfoBox from "./generalInfoBox";
import CommentBox from "./commentBox";
import contributionHistoryDataManipulation from "../../../../lib/dataManipulation/contributionHistory/contributionHistoryDataManipulation";
import { connect } from "react-redux";

const ContributionReview = ({ connectedTo = "formData", showHeader = true, showReview = true, setItemData = true }) => {
  let { id } = useParams();

  useEffect(() => {
    if (setItemData) {
      setItemFormData(id, "", "contributionReview");
    }
  }, [id, setItemData]);

  return (
    <React.Fragment>
      {showHeader && (
        <section className="content-header">
          <h1>User Review</h1>
        </section>
      )}
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <GeneralInfoBox connectedTo={connectedTo} />
          </div>
        </div>
        <React.Fragment>
          <div className="row">
            <div className="col-md-12">
              <FieldsBox connectedTo={connectedTo} showReview={showReview} />
            </div>
          </div>
          {showReview && (
            <React.Fragment>
              <div className="row">
                <div className="col-md-12">
                  <CommentBox connectedTo={connectedTo} />
                </div>
              </div>
              <Footer
                showClearButton={false}
                onSave={() => {
                  handleEditFormSubmit(id, contributionHistoryDataManipulation, "contributionReview", "PUT");
                }}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </section>
    </React.Fragment>
  );
};

ContributionReview.propTypes = {
  connectedTo: PropTypes.string,
  showHeader: PropTypes.bool,
  showReview: PropTypes.bool,
};

export default connect(null)(ContributionReview);
