export const editArtistTableParametersData = {
  statePath: "tables.editArtistTableParams",
  filtersPath: "tables.editArtistTableParams.filters",
  sortPath: "tables.editArtistTableParams.sort",

  columns: [
    {
      name: "artistName",
      label: "Artist Name",
      sortBackendName: "name",
      filterDefinition: {
        backendFieldValue: {
          name: "name",
        },
      },
    },
    {
      name: "country",
      label: "Country",
      sortBackendName: "country",
      filterDefinition: {
        backendFieldValue: {
          country: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "mainGenre",
      label: "Main Genre",
      sortBackendName: "genre",
      filterDefinition: {
        backendFieldValue: {
          genre: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "subgenres",
      label: "Subgenres",
      sortBackendName: "subgenre",
      filterDefinition: {
        backendFieldValue: {
          subgenre: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "status",
      label: "Status",
      sortBackendName: "status",
      filterDefinition: {
        backendFieldValue: {
          status: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "dateAdded",
      label: "Date Added",
      sortBackendName: "created",
      filterDefinition: {
        backendFieldValue: {
          "created-from": "startDateTimeTZ",
          "created-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "lastChange",
      label: "Last Change",
      sortBackendName: "updated",
      filterDefinition: {
        backendFieldValue: {
          "updated-from": "startDateTimeTZ",
          "updated-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "chartRank",
      label: "Chart Rank",
      sortBackendName: "rank",
      filterDefinition: {
        backendFieldValue: {
          "rank-min": "min",
          "rank-max": "max",
        },
      },
    },
    {
      name: "claimed",
      label: "Claimed",
      sortBackendName: "claimed",
      filterDefinition: {
        backendFieldValue: {
          claimed: "id",
        },
      },
    },
    {
      name: "youtubeSuggestionStatus",
      label: "Youtube status",
      hideInTable: true,
      filterDefinition: {
        backendFieldValue: {
          yt_suggestion_status: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    { name: "edit", label: "Edit" },
    { name: "youtube", label: "Youtube Videos" },
  ],
};
