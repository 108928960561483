import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { dispatchToReduxStore, redirectTo } from "../../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { handleDeleteEntity } from "../../../../lib/functions/componentFunctions";
import { getStateValue } from "../../../../lib/functions/componentFunctions";
import { makeid } from "../../../../lib/functions/common";
import { sortByDate, defaultSort, getTableTheme } from "../../../../lib/functions/tableFunctions";

const MatchedEventTable = ({ data, dispatch }) => {
  const routerHistory = useHistory();
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const datatableOptions = {
    selectableRows: "none",
    print: false,
    download: false,
    viewColumns: false,
    responsive: "stacked",
    filter: false,
    search: true,
    rowsPerPage: getStateValue(10, "tables.matchedEventsTable.rows"),
    page: tableData && tableData.length ? getStateValue(0, "tables.matchedEventsTable.page") : 0,
    onTableInit: () => {
      scrollToPreviousVerticalPosition();
    },
    onChangeRowsPerPage: rows => {
      dispatchToReduxStore("tables.matchedEventsTable.rows", SET_STATE_VALUE, rows, dispatch);
    },
    onChangePage: page => {
      dispatchToReduxStore("tables.matchedEventsTable.page", SET_STATE_VALUE, page, dispatch);
    },
    customSort: (data, colIndex, order) => {
      if (colIndex === 1) {
        dispatchToReduxStore("tables.matchedEventsTable.dateSort", SET_STATE_VALUE, order, dispatch);
        return sortByDate(data, colIndex, order);
      } else {
        return defaultSort(data, colIndex, order);
      }
    },
  };

  const columns = [
    "Scrape ID",
    {
      name: "Date",
      options: {
        sortDirection: getStateValue("asc", "tables.matchedEventsTable.dateSort"),
      },
    },
    "Venue",
    "Country",
    "City",
    "Event Name",
    "Lineup",
    {
      name: "Edit",
      options: {
        customBodyRender: (value, row) => (
          <button
            className="btn btn-default fa fa-edit"
            onClick={() => {
              setCurrentScrollPosition(dispatch);
              redirectTo(routerHistory, "/add/matchedEvent/");
            }}
          />
        ),
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRender: (value, row) => (
          <button
            className="btn btn-default"
            onClick={async () => {
              handeDeleteMatchedEvent(value, row, tableData, setTableData);
            }}
          >
            <span className="fa fa-trash" />
          </button>
        ),
      },
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <MuiThemeProvider theme={getTableTheme()}>
          <MUIDataTable
            key={makeid(3)} //This is needed so the table gets rerendered with dynamic pagination. See https://github.com/gregnb/mui-datatables/issues/957
            title={"Matched Events"}
            data={tableData}
            columns={columns}
            options={datatableOptions}
          />
        </MuiThemeProvider>
      </div>
    </div>
  );
};

const handeDeleteMatchedEvent = async (value, row, tableData, setTableData) => {
  const confirmed = window.confirm("Are you sure you want to delete this matched event?");

  if (confirmed) {
    const deleteSuccess = await handleDeleteEntity(value, "matchedEvent");

    if (deleteSuccess) {
      setTableData(
        tableData.filter((item, index) => {
          return index !== row.rowIndex;
        })
      );
    }
  }
};

const setCurrentScrollPosition = dispatch => {
  dispatchToReduxStore(
    "tables.matchedEventsTable.scrollPosition",
    SET_STATE_VALUE,
    document.documentElement.scrollTop,
    dispatch
  );
};

const scrollToPreviousVerticalPosition = () => {
  window.scrollTo(0, getStateValue(0, "tables.matchedEventsTable.scrollPosition"));
};

MatchedEventTable.propTypes = {
  data: PropTypes.array,
  dispatch: PropTypes.func,
};

export default connect(null)(MatchedEventTable);
