import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { viberatecomSiteUrl } from "../../../constants/constants";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_UNSET_OBJECT_ARRAY_ITEM } from "../../../redux/reducers/common/actionTypes";
import { getLinkStringFromLinksArray } from "../../../lib/functions/common";

const ArtistListCard = ({ artist, dispatch, matched = false, formName, disabled = false }) => {
  const country = artist.country ? artist.country : "";
  const genre = artist.genre ? artist.genre : "";
  const seperator = country && genre ? " | " : "";
  const span = artist.spanContent ? <span>{artist.spanContent}</span> : <span>{country + seperator + genre}</span>;

  return (
    <button
      type="button"
      className={"btn btn-default btn-artist btn-remove " + (matched ? "match" : "")}
      onClick={() => {
        if (artist.slug) {
          window.open(viberatecomSiteUrl[process.env.REACT_APP_ENVIRONMENT] + "artist/" + artist.slug);
        }

        const facebookLink = getLinkStringFromLinksArray(artist.links, "facebook");

        if (facebookLink) {
          window.open(facebookLink);
        }
      }}
    >
      <img src={artist.img} alt="" />
      {artist.name}
      {span}
      <span
        className="remove fa fa-remove"
        onClick={e => {
          e.stopPropagation();
          if (!disabled) {
            dispatchToReduxStore(formName, SET_UNSET_OBJECT_ARRAY_ITEM, artist, dispatch);
          }
        }}
      />
    </button>
  );
};

ArtistListCard.propTypes = {
  artist: PropTypes.object,
  dispatch: PropTypes.func,
  matched: PropTypes.bool,
  formName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default connect(null)(ArtistListCard);
