import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import store from "../../../../redux/store/store";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { mainStateKey } from "../../../../constants/constants";

const LastAddedLineupsBox = ({ numberOfLineups }) => {
  const storedLineups =
    typeof window !== "undefined" && window.localStorage && localStorage.getItem("lastAddedLineups")
      ? JSON.parse(localStorage.getItem("lastAddedLineups"))
      : [];

  storedLineups.reverse();

  return (
    <FormBox title={"Last " + numberOfLineups + " added lineups"}>
      <div className="box-body">
        <div className="form-group last-5-places">
          {storedLineups.map((lineup, index) => {
            return (
              <button
                onClick={() => {
                  store.dispatch({ type: SET_STATE_VALUE, name: "formData.lineup", payload: lineup });
                }}
                key={index}
                type="button"
                className="btn btn-default btn-search-result btn-lineup"
              >
                <span>{lineup.map(artist => artist.name).join(" | ")}</span>
              </button>
            );
          })}
        </div>
      </div>
    </FormBox>
  );
};

LastAddedLineupsBox.propTypes = {
  numberOfLineups: PropTypes.number,
  storageChangedCount: PropTypes.number,
};

const mapStateToProps = state => {
  return { storageChangedCount: getOr(null, `${mainStateKey}.storageChangedCount`, state) };
};

export default connect(mapStateToProps)(LastAddedLineupsBox);
