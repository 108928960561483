import React from "react";

import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import MultiSelector from "../../../input/selectors/multiSelector";
import { trackStatus, enumsPath } from "../../../../constants/constants";
import CurrentlySelectedFiltersList from "../../../presentational/general/currentlySelectedFiltersList";
import Input from "../../../input/general/input";
import { editTrackTableParametersData } from "../../../../constants/tableParametersData/editTrackTableParametersData";
import DateRange from "../../../input/date/dateRange";
import { prependNumberToSubgenreName } from "../../../../lib/functions/common";

const FiltersBox = ({ subgenres, genres }) => {
  const filtersPath = editTrackTableParametersData.filtersPath;
  return (
    <FormBox title="Filters" style={{ zIndex: "999" }}>
      <div className="box-body">
        <table className="filters-table">
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <small className="track">Date added from-to</small>
              </td>
              <td>
                <small className="track">Date Updated from-to</small>
              </td>
              <td>
                <small className="track">Release date from-to</small>
              </td>
            </tr>
            <tr>
              <td style={{ width: "200px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input placeholder="Search track..." className="form-control" name={`${filtersPath}.trackName`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "160px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input placeholder="Spotify ID..." className="form-control" name={`${filtersPath}.spotifyID`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input placeholder="Search ISRC..." className="form-control" name={`${filtersPath}.ISRC`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Status"
                    data={trackStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.status`}
                  />
                </div>
              </td>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <MultiSelector
                      placeholder="Label lookup filter"
                      displayKey="name"
                      idKey="uuid"
                      autocomplete={true}
                      showClearButton={false}
                      searchOnBackend={true}
                      backendSearchEntity={["label"]}
                      name={`${filtersPath}.label`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <MultiSelector
                      placeholder="Artist lookup filter"
                      displayKey="name"
                      idKey="uuid"
                      autocomplete={true}
                      showClearButton={false}
                      searchOnBackend={true}
                      backendSearchEntity={["artist"]}
                      name={`${filtersPath}.artists`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Genres"
                    data={genres}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.genre`}
                  />
                </div>
              </td>
              <td style={{ width: "200px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Subgenres"
                    data={prependNumberToSubgenreName(subgenres)}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.subgenre`}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.dateAdded`} />
                  </div>
                </div>
              </td>
              <td>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.lastChange`} />
                  </div>
                </div>
              </td>
              <td>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.releaseDate`} />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="row">
          <div className="col-md-12">
            <CurrentlySelectedFiltersList
              tableParamsData={editTrackTableParametersData}
              connectedTo={editTrackTableParametersData.filtersPath}
            />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

FiltersBox.propTypes = {
  subgenres: PropTypes.array,
  genres: PropTypes.array,
};

const mapStateToProps = state => ({
  subgenres: getOr(null, `${enumsPath}.subgenres`, state),
  genres: getOr(null, `${enumsPath}.genres`, state),
});

export default connect(mapStateToProps)(FiltersBox);
