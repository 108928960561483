import { orderBy } from "lodash/fp";

const festivalAppMerchDataManipulation = function festivalAppMerchDataManipulation() {
  const getInwardEditFestivalItem = data => {
    return {
      merchList: data
        ? orderBy("order")("asc")(data).map(item => {
            return {
              uuid: item && item.uuid ? item.uuid : null,
              type: item && item.type === "wide" ? 1 : 0,
              title: item && item.title ? item.title : null,
              description: item && item.description ? item.description : null,
              image: item && item.image ? item.image : null,
              price: item && item.price ? item.price : null,
              order: item && item.order !== undefined ? String(item.order) : null,
              id: item && item.uuid ? item.uuid : null,
            };
          })
        : null,
    };
  };

  const getOutwardMerch = data => {
    return data && data.merchList
      ? data.merchList.map(item => {
          const uuid = item && item.uuid ? { uuid: item.uuid } : null;
          return {
            ...uuid,
            type: item && item.type ? "wide" : "normal",
            title: item && item.title ? item.title : null,
            description: item && item.description ? item.description : null,
            image: item && item.image ? item.image : null,
            price: item && item.price ? parseInt(item.price) : null,
            order: item && item.order ? parseInt(item.order) : null,
            currency: { id: 0, name: "Euro", code: "EUR", symbol: "€" },
          };
        })
      : null;
  };

  return { outward: getOutwardMerch, inwardItem: getInwardEditFestivalItem };
};

export default festivalAppMerchDataManipulation();
