import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "../../../../node_modules/moment/moment";
import {
  searchByString,
  makeid,
  getLinkStringFromLinksArray,
  sortArrayOfObjectsByProperty,
  thousandsSeparator,
} from "../../../lib/functions/common";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import { mainStateKey, viberatecomSiteUrl } from "../../../constants/constants";
import { useOutsideComponentClick } from "../../../lib/functions/customHooks";
import ClearInputButton from "../general/clearInputButton";

const EntitySelector = ({
  onChange = () => {},
  onSearchTextChange = () => {},
  removeOnSelect = true,
  showAddNewButton = true,
  label,
  value,
  dispatch,
  name,
  entityType,
  placeholder,
  showFilteredItemsAsLinks = false,
  showOpenInNewViberateTabButton = true,
  className = "",
  focusOnSelect = false,
  showClearButton = false,
  minTextLength = 2,
}) => {
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const myid = makeid(15);

  const ref = useRef(null);
  const inputRef = useRef(null);

  useOutsideComponentClick(ref, setOpen);

  useEffect(() => {
    if (!value || !value.name) {
      setSearchText("");
    }
  }, [value]);

  const selectEntity = obj => {
    const dispatchedObj = removeOnSelect ? {} : obj;

    if (removeOnSelect) {
      setSearchText("");
    }

    dispatchToReduxStore(name, SET_STATE_VALUE, dispatchedObj, dispatch);

    onChange(obj);
    setItems([]);

    if (focusOnSelect) {
      inputRef.current.focus();
    }
  };

  const searchTextChanged = async e => {
    dispatchToReduxStore(name, SET_STATE_VALUE, { name: e.target.value }, dispatch);
    setSearchText(e.target.value);
    onSearchTextChange(e.target.value);
    showResults(setItems, setOpen, entityType, e, setResultsLoading, minTextLength);
  };

  const inputValue = value && value.name ? value.name : value && value.title ? value.title : searchText;

  return (
    <div
      id={myid}
      className={
        "input-group relative  " + (resultsLoading && !showAddNewButton && !items.length ? "loading " : "") + className
      }
      ref={ref}
    >
      {label ? <label>{label}</label> : ""}
      <div className="input-group">
        <React.Fragment>
          <input
            ref={inputRef}
            type="text"
            className="form-control"
            placeholder={placeholder}
            onChange={e => {
              searchTextChanged(e);
            }}
            onFocus={e => {
              showResults(setItems, setOpen, entityType, e, setResultsLoading);
            }}
            value={inputValue}
          />
          {showClearButton ? (
            <ClearInputButton
              onClick={e => {
                e.stopPropagation();
                dispatchToReduxStore(name, SET_STATE_VALUE, "", dispatch);
                onChange(null);
              }}
            />
          ) : (
            ""
          )}
        </React.Fragment>
      </div>
      <div className={"results-for-search " + (open ? "open " : "") + (resultsLoading ? "loading" : "")}>
        {open && items && items.length > 0
          ? items.map((item, index) => {
              return getSelectorJSX(
                entityType,
                item,
                selectEntity,
                showFilteredItemsAsLinks,
                setOpen,
                showOpenInNewViberateTabButton,
                index
              );
            })
          : getAddNewButton(showAddNewButton, entityType)}
      </div>
    </div>
  );
};

const getSelectorJSX = (
  type,
  item,
  selectEntity,
  showFilteredItemsAsLinks,
  setOpen,
  showOpenInNewViberateTabButton,
  index
) => {
  let image = null;
  let id;
  let country;
  let genre;
  let seperator;
  let spanContent;
  let date;
  let city;
  let address;
  let releaseDate;
  let channelName;
  let views;
  let slug = getOr(null, "slug", item);
  let authorName = "";
  let authorSurname = "";
  let fbLink = getLinkStringFromLinksArray(getOr(null, "links", item), "facebook");

  switch (type) {
    case "artist":
      image = getOr(null, "images[0]", item);
      id = getOr(null, "uuid", item);
      country = getOr("", "country.name", item);
      genre = getOr("", "genres[0].name", item);
      seperator = genre && country ? " | " : "";
      spanContent = country + seperator + genre;
      break;
    case "label":
      image = getOr(null, "image", item);
      id = getOr(null, "uuid", item);
      spanContent = getOr(null, "status", item);
      break;
    case "place":
    case "venue":
      id = getOr(null, "uuid", item);
      country = getOr("", "country.name", item);
      city = getOr("", "city.name", item);
      slug = getOr(null, "slug", item);
      address = getOr(null, "address", item);
      seperator = city && country ? " | " : "";
      spanContent = country + seperator + city;
      break;
    case "event":
      id = getOr(null, "uuid", item);
      country = getOr("", "country.name", item);
      city = getOr("", "city.name", item);
      date = getOr("", "date", item);
      slug = getOr(null, "slug", item);
      seperator = city && country ? " | " : "";
      spanContent = country + seperator + city + " " + date;
      break;
    case "festival":
      id = getOr(null, "uuid", item);
      country = getOr("", "country.name", item);
      city = getOr("", "city.name", item);
      slug = getOr(null, "slug", item);
      seperator = city && country ? " | " : "";
      spanContent = country + seperator + city;
      break;
    case "city":
      id = getOr(null, "id", item);
      spanContent = getOr("", "country_alpha2", item);
      slug = getOr(null, "slug", item);
      break;
    case "article":
      id = getOr(null, "id", item);
      slug = getOr(null, "slug", item);
      authorName = getOr("", "author.name", item);
      authorSurname = getOr("", "author.surname", item);
      spanContent =
        authorName && authorSurname
          ? authorName + " " + authorSurname
          : authorName
          ? authorName
          : authorSurname
          ? authorSurname
          : "";
      break;
    case "youtube_video":
      id = getOr(null, "yt_video_id", item);
      image = `https://img.youtube.com/vi/${id}/default.jpg`;
      channelName = getOr(null, "channel.name", item);
      views = thousandsSeparator(getOr(null, "views", item));
      releaseDate = item.release_date ? moment(item.release_date).format("YYYY-MM-DD") : "";
      spanContent = `Views: ${views} | Release date:${releaseDate} | Channel: ${channelName}`;
      break;
    default:
      console.warn("No span content for entitySelector - unknown entity type");
  }

  item = { ...item, spanContent: spanContent, id: id, img: image };

  let selectorJSX = (
    <React.Fragment>
      {image && <img src={image} alt="" />}
      <em>{item.name || item.title}</em>
      {address && <em>{address}</em>}
      <span>{spanContent}</span>
    </React.Fragment>
  );

  selectorJSX = showFilteredItemsAsLinks ? (
    <Link key={id + index} className={"btn btn-default btn-search-result btn-" + type} to={"/edit/" + type + "/" + id}>
      {selectorJSX}
    </Link>
  ) : (
    <div className="search-result-item" key={id + index}>
      <button
        className={"btn btn-default btn-search-result btn-" + type}
        onClick={() => {
          selectEntity(item);
          setOpen(false);
        }}
      >
        {selectorJSX}
      </button>
      {showOpenInNewViberateTabButton && (
        <button
          type="button"
          className="btn btn-default btn-duplicated-entity"
          onClick={() => {
            if (slug && type) {
              const entityType = type === "place" ? "venue" : type;
              window.open(viberatecomSiteUrl[process.env.REACT_APP_ENVIRONMENT] + entityType + "/" + slug);
            }

            if (fbLink) {
              window.open(fbLink);
            }
          }}
        ></button>
      )}
    </div>
  );

  return selectorJSX;
};

const getAddNewButton = (showAddNewButton, type) => {
  return showAddNewButton ? (
    <button
      type="button"
      className="btn btn-default btn-search-result"
      onClick={() => {
        window.open("/add/" + type + "/", "_blank");
      }}
    >
      No results. Add new
    </button>
  ) : (
    ""
  );
};

const showResults = async (setItems, setOpen, entityType, e, setResultsLoading, minTextLength) => {
  setOpen(true);
  setResultsLoading(true);
  let filteredItems = await searchByString(
    e.target.value,
    [entityType === "place" ? "venue" : entityType],
    minTextLength
  );

  if (filteredItems && filteredItems.length > 0 && (filteredItems[0].name || filteredItems[0].title)) {
    filteredItems = entityType === "city" ? sortArrayOfObjectsByProperty(filteredItems, "name") : filteredItems;
    setItems(filteredItems);
  } else {
    setItems([]);
  }
  setResultsLoading(false);
};

EntitySelector.propTypes = {
  removeOnSelect: PropTypes.bool,
  onChange: PropTypes.func,
  showAddNewButton: PropTypes.bool,
  label: PropTypes.string,
  onSearchTextChange: PropTypes.func,
  value: PropTypes.object,
  dispatch: PropTypes.func,
  name: PropTypes.string,
  entityType: PropTypes.string,
  placeholder: PropTypes.string,
  showFilteredItemsAsLinks: PropTypes.bool,
  showOpenInNewViberateTabButton: PropTypes.bool,
  className: PropTypes.string,
  focusOnSelect: PropTypes.bool,
  showClearButton: PropTypes.bool,
  minTextLength: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(EntitySelector);
