import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { APPEND_ITEM_TO_ARRAY, REMOVE_ITEM_BY_INDEX } from "../../../redux/reducers/common/actionTypes";
import DateRange from "./dateRange";
import { mainStateKey } from "../../../constants/constants";

const Weeks = ({ name, value, dispatch, showInitialDateRangeInput = true }) => {
  useEffect(() => {
    if (showInitialDateRangeInput) {
      addDateRange(name, dispatch);
    }
  }, [dispatch, name, showInitialDateRangeInput]);

  return (
    <React.Fragment>
      <div className="form-group">
        <label className="col-sm-3 control-label">Date</label>
        <div className="col-sm-9 multiple-dates">
          {value &&
            Array.isArray(value) &&
            value.length > 0 &&
            value.map((item, index) => {
              return (
                <div className="input-group" key={index}>
                  <DateRange className="zIndex4" name={name + ".[" + index + "]"} />
                  {index > 0 && (
                    <div className="input-group-btn btn-clear-field">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          removeDateRange(name, index, dispatch);
                        }}
                      >
                        <span className="fa fa-trash" />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-3 control-label">
          <button
            className="btn btn-default btn-small"
            onClick={() => {
              addDateRange(name, dispatch);
            }}
          >
            + ADD DATE
          </button>
        </label>
      </div>
    </React.Fragment>
  );
};

const addDateRange = (name, dispatch) => {
  dispatchToReduxStore(name, APPEND_ITEM_TO_ARRAY, {}, dispatch);
};

const removeDateRange = (name, index, dispatch) => {
  dispatchToReduxStore(name, REMOVE_ITEM_BY_INDEX, { index: index }, dispatch);
};

Weeks.propTypes = {
  name: PropTypes.string,
  dispatch: PropTypes.func,
  value: PropTypes.array,
  showInitialDateRangeInput: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(Weeks);
