import React from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import { feedpostStatus, userStatus, enumsPath, feedPostType } from "../../../../constants/constants";
import CurrentlySelectedFiltersList from "../../../presentational/general/currentlySelectedFiltersList";
import Input from "../../../input/general/input";
import { editFeedpostTableParametersData } from "../../../../constants/tableParametersData/editFeedpostTableParametersData";
import DateRange from "../../../input/date/dateRange";

import MultiSelector from "../../../input/selectors/multiSelector";

const FiltersBox = ({ genres }) => {
  const filtersPath = editFeedpostTableParametersData.filtersPath;
  return (
    <FormBox title="Filters" style={{ zIndex: "999" }}>
      <div className="box-body">
        <table className="filters-table">
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <small className="label">Feed post starts from-to</small>
              </td>
              <td>
                <small className="label">Date added from-to</small>
              </td>
              <td>
                <small className="label">Date Updated from-to</small>
              </td>
            </tr>
            <tr>
              <td style={{ width: "300px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      placeholder="Search feedpost..."
                      className="form-control"
                      name={`${filtersPath}.feedpostTitle`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "300px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      placeholder="Search feedpost by lead text..."
                      className="form-control"
                      name={`${filtersPath}.feedpostLead`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "300px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <MultiSelector
                      placeholder="Article lookup filter"
                      displayKey="title"
                      idKey="id"
                      autocomplete={true}
                      showClearButton={false}
                      searchOnBackend={true}
                      backendSearchEntity={["article"]}
                      name={`${filtersPath}.article`}
                    />
                  </div>
                </div>
              </td>

              <td style={{ width: "100px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Status"
                    data={feedpostStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.status`}
                  />
                </div>
              </td>
              <td style={{ width: "100px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="User status"
                    data={userStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.userStatus`}
                  />
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Type"
                    data={feedPostType}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.type`}
                  />
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Genres"
                    data={genres}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.genres`}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <DateRange name={`${filtersPath}.feedpostDate`} />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.dateAdded`} />
                  </div>
                </div>
              </td>
              <td>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.lastChange`} />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="row">
          <div className="col-md-12">
            <CurrentlySelectedFiltersList
              tableParamsData={editFeedpostTableParametersData}
              connectedTo={editFeedpostTableParametersData.filtersPath}
            />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

FiltersBox.propTypes = {
  genres: PropTypes.array,
};

const mapStateToProps = state => ({
  genres: getOr(null, `${enumsPath}.genres`, state),
});

export default connect(mapStateToProps)(FiltersBox);
