import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextEditor from "../general/textEditor";

const TextModule = ({ index, name }) => {
  return (
    <div className="box-body">
      <TextEditor name={`${name}.[${index}].data`} />
    </div>
  );
};

TextModule.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
};

export default connect()(TextModule);
