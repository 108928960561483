import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { modalPath, mainStateKey } from "../../../../constants/constants";

const ImageModal = ({ value }) => {
  return <img src={value} alt="modal" />;
};

ImageModal.propTypes = {
  data: PropTypes.object,
};

const mapStateToProps = state => {
  return { value: getOr("", `${mainStateKey}.${modalPath}.data`, state) };
};

export default connect(mapStateToProps)(ImageModal);
