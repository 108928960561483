import React from "react";
import FormBox from "../../../presentational/page/formBox";
import Location from "../../../input/location/location";

const LocationBox = () => {
  return (
    <FormBox title="Location">
      <div className="box-body">
        <div className="row">
          <Location name="formData.location" gmapsHeight="112px" />
        </div>
      </div>
    </FormBox>
  );
};

export default LocationBox;
