const festivalAppAddInfoDataManipulation = function festivalAppAddInfoDataManipulation() {
  const getInwardEditFestivalItem = data => {
    return {
      ...data,
      legalData: getLegalData(data),
    };
  };

  const getOutwardFestival = data => {
    return data ? data : null;
  };

  return { outward: getOutwardFestival, inwardItem: getInwardEditFestivalItem };
};

export default festivalAppAddInfoDataManipulation();

const getLegalData = data => {
  const text = data && data.tos ? data.tos : data.privacy ? data.privacy : "";
  const company = new RegExp('(?:<span id="company">)(.*?)(?:</span>)', "ig").exec(text);
  const address = new RegExp('(?:<span id="address">)(.*?)(?:</span>)', "ig").exec(text);
  const vat = new RegExp('(?:<span id="vat">)(.*?)(?:</span>)', "ig").exec(text);
  const email = new RegExp('(?:<span id="email">)(.*?)(?:</span>)', "ig").exec(text);

  return {
    festival: data && data.festivalName ? data.festivalName : null,
    company: company && company.length > 1 ? company[1] : null,
    address: address && address.length > 1 ? address[1] : null,
    vat: vat && vat.length > 1 ? vat[1] : null,
    email: email && email.length > 1 ? email[1] : null,
  };
};
