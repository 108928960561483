import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import Input from "../general/input";

import { mainStateKey } from "../../../constants/constants";
import { dispatchToReduxStore, getStateValue } from "../../../lib/functions/componentFunctions";
import { REMOVE_ITEM_BY_INDEX, SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import { componentCkEditorKeyPath } from "../../../constants/article";

const TableOfContents = ({ index, name, value, dispatch }) => {
  const generateLinks = () => {
    const content = getStateValue([], "formData.content");

    if (content && content.length) {
      let htmlString = "";
      let dom;
      let headerElements;
      let headerText = "";
      let headerCounter = 0;
      let id;
      let valuesToDispatch = [];
      let ckEditorKeyPath = "";
      content.forEach((item, contentIndex) => {
        ckEditorKeyPath =
          item.fieldName && componentCkEditorKeyPath[item.fieldName] ? componentCkEditorKeyPath[item.fieldName] : "";
        if (ckEditorKeyPath) {
          htmlString = getOr("", ckEditorKeyPath, item);
          dom = htmlString ? new DOMParser().parseFromString(htmlString, "text/html") : null;
          headerElements = dom ? Array.prototype.slice.call(dom.getElementsByTagName("h2")) : [];

          if (headerElements && headerElements.length) {
            headerElements.forEach(header => {
              headerCounter++;
              headerText = header.innerText;
              id = "header-url-" + headerCounter;
              header.setAttribute("id", id);
              valuesToDispatch.push({ headerText, customText: headerText, link: id });
            });
          }

          htmlString = dom ? dom.body.innerHTML : htmlString;

          dispatchToReduxStore(
            `formData.content[${contentIndex}]`,
            SET_STATE_VALUE,
            { ...item, [ckEditorKeyPath]: htmlString },
            dispatch
          );

          if (valuesToDispatch.length) {
            dispatchToReduxStore(`${name}[${index}].data.listOfElements`, SET_STATE_VALUE, valuesToDispatch, dispatch);
          }
        }
      });
    }
  };

  const handleRemove = removeIndex => {
    dispatchToReduxStore(
      `${name}[${index}].data.listOfElements`,
      REMOVE_ITEM_BY_INDEX,
      { index: removeIndex },
      dispatch
    );
  };

  return (
    <div className="box-body">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <button className="btn btn-default btn-small" onClick={generateLinks}>
              Generate links
            </button>
            <div className="input-group">
              {value &&
                value.data &&
                value.data.listOfElements &&
                !!value.data.listOfElements.length &&
                value.data.listOfElements.map((item, elementsIndex) => {
                  return (
                    <div className="row" key={elementsIndex}>
                      <div className="col-md-6">
                        <Input
                          className="form-control"
                          name={`${name}.[${index}].data.listOfElements[${elementsIndex}].headerText`}
                          showClearInputButton={false}
                          debounceTime={0}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-5">
                        <Input
                          className="form-control"
                          placeholder="Custom title.."
                          name={`${name}.[${index}].data.listOfElements[${elementsIndex}].customText`}
                          showClearInputButton={false}
                          debounceTime={0}
                        />
                      </div>
                      <div className="col-md-1">
                        <button
                          type="button"
                          className="btn btn-default fa fa-remove"
                          title="Remove"
                          onClick={() => {
                            handleRemove(elementsIndex);
                          }}
                        ></button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TableOfContents.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}.[${ownProps.index}]`, state) };
};

export default connect(mapStateToProps)(TableOfContents);
