import moment from "moment";
import { getOr } from "lodash/fp";
import { parseImage, capitalizeString } from "../../functions/common";
import commonDataManipulation from "../common/commonDataManipulation";

const eventDataManipulation = function eventDataManipulation() {
  const getOutwardEvent = data => {
    const artistUuids = data && data.lineup ? data.lineup.map(artist => artist.id) : null;
    const start = data && data.dateTime ? moment(data.dateTime).format("YYYY-MM-DDTHH:mm:ss") : null;
    const image = data && data.image ? parseImage(data.image) : null;
    const tickets = data && data.ticketInfo ? commonDataManipulation().tickets.outward(data.ticketInfo) : null;

    return {
      name: data.name ? data.name : null,
      venue_uuid: data.venue ? data.venue.id : null,
      artist_uuids: artistUuids,
      image: image,
      tickets: tickets,
      start: start,
    };
  };

  const getInwardEditEventItem = data => {
    return {
      name: data && data.name ? data.name : null,
      initialEventName: data && data.name ? data.name : null,
      image: data && data.image ? data.image : null,
      venue: data && data.venue ? { ...data.venue, id: data.venue.uuid } : null,
      dateTime: data && data.start ? new Date(data.start) : null,
      ticketInfo: data && data.tickets ? commonDataManipulation().tickets.inward(data.tickets) : null,
      lineup: data && data.lineup ? commonDataManipulation().artistList.inward(data.lineup) : null,
    };
  };

  const getInwardDatatable = data => {
    const listItems = [];

    if (data && data.length) {
      data.forEach(item => {
        listItems.push([
          item.name,
          getOr(null, "venue.name", item),
          getOr(null, "venue.country.name", item),
          getOr(null, "venue.city.name", item),
          item.start ? moment(item.start).format("YYYY-MM-DD HH:mm") : null,
          item.lineup ? getLineup(item.lineup) : null,
          item.start ? moment(item.created_at).format("YYYY-MM-DD HH:mm") : null,
          item.status ? capitalizeString(item.status) : null,
          item.uuid,
          item.uuid,
        ]);
      });
    }

    return listItems;
  };

  return { outward: getOutwardEvent, inwardItem: getInwardEditEventItem, inwardDatatable: getInwardDatatable };
};

const getLineup = data => {
  const lineupArray = [];

  if (data && Array.isArray(data) && data.length) {
    data.forEach(item => {
      if (item.name) {
        lineupArray.push(item.name);
      }
    });
  }

  return lineupArray.join(",");
};

export default eventDataManipulation();
