import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { APPEND_ITEM_TO_ARRAY } from "../../../redux/reducers/common/actionTypes";

function ContentComponentSelector({
  dispatch,
  setShowPreview,
  onSubmit = () => {},
  availableComponents = {},
  showPreviewButton = true,
  showDeleteButton = false,
  onDelete = () => {},
  disabled,
}) {
  const appendModule = component => {
    if (component.component) {
      let obj = {
        title: component.moduleTitle,
        icon: component.moduleIcon,
        component: component.component,
        fieldName: component.fieldName,
        ckEditorKeyPath: component.ckEditorKeyPath,
      };

      if (component.data) {
        obj.data = component.data;
      }

      dispatchToReduxStore("formData.content", APPEND_ITEM_TO_ARRAY, obj, dispatch);
    }
  };

  return (
    <div className="box box-default fixed-floater toolbar-article">
      <button
        type="button"
        className="btn btn-default bg-black margin pull-right"
        onClick={() => {
          onSubmit();
        }}
      >
        Save
      </button>
      {showDeleteButton && (
        <button
          onKeyPress={e => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          type="button"
          className="btn btn-danger margin pull-right"
          onClick={e => {
            onDelete(e);
          }}
        >
          Delete
        </button>
      )}
      {showPreviewButton && (
        <button
          type="button"
          className="btn btn-default bg-black margin pull-right fa fa-eye"
          title="Preview"
          onClick={() => {
            setShowPreview(true);
          }}
        />
      )}
      <span className="divider" />
      {availableComponents.map((component, index) => {
        return (
          <button
            disabled={disabled}
            key={index}
            type="button"
            className={"btn btn-default margin pull-right " + (component.selectorIcon ? component.selectorIcon : "")}
            title={component.selectorTitle ? component.selectorTitle : ""}
            onClick={() => {
              appendModule(component);
            }}
          />
        );
      })}
    </div>
  );
}

ContentComponentSelector.propTypes = {
  dispatch: PropTypes.func,
  setShowPreview: PropTypes.func,
  onSubmit: PropTypes.func,
  availableComponents: PropTypes.array,
  showPreviewButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
};

export default connect(null)(ContentComponentSelector);
