import React, { useEffect, useState } from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import Header from "./components/presentational/page/header";
import ErrorMessage from "./components/presentational/error/errorMessage";
import { mainStateKey, modalPath } from "./constants/constants";
import EditorRouter from "./editorRouter";
import SideBar from "./components/presentational/sidebar/sidebar";
import MainModal from "./components/presentational/modals/mainModal";
import Login from "./components/forms/login/login";
import { getLoggedInUserData } from "./lib/functions/editorFunctions";
import { setEnums } from "./lib/functions/enumsFunctions";

const Editor = ({ editorLoading, formStatus, dispatch, showModal }) => {
  const [userIsPermitted, setUserIsPermitted] = useState();
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(true);
  const [enumsAreSet, setEnumsAreSet] = useState(false);

  useEffect(() => {
    setEnums(dispatch, setEnumsAreSet);
    getLoggedInUserData(dispatch, setUserIsPermitted, setUserIsLoggedIn);
    setUserIsPermitted();
  }, [dispatch, userIsLoggedIn]);

  const formSuccess = getOr(false, `formSuccess`, formStatus);
  const formFail = getOr(false, `formFail`, formStatus);
  const formLocked = getOr(false, `formLocked`, formStatus);

  if (userIsPermitted === false) {
    return (
      <Login userIsPermitted={userIsPermitted} userIsLoggedIn={userIsLoggedIn} setUserIsLoggedIn={setUserIsLoggedIn} />
    );
  } else if (userIsLoggedIn === true && userIsPermitted === true && enumsAreSet) {
    return (
      <div
        className={
          "veditor hold-transition skin-red fixed kucko-mode " +
          (editorLoading ? "loading " : "") +
          (formSuccess ? "success " : "") +
          (formFail ? "fail" : "") +
          (formLocked ? "locked" : "")
        }
      >
        <Header setUserIsLoggedIn={setUserIsLoggedIn} />
        <SideBar currentPage={window.location.pathname + (window.location.hash ? window.location.hash : "")} />

        <div className="content-wrapper" style={{ minHeight: "937px" }}>
          {showModal && <MainModal />}
          <ErrorMessage />
          <EditorRouter />
        </div>
      </div>
    );
  } else {
    return <div className="veditor  loading" />;
  }
};

Editor.propTypes = {
  editorLoading: PropTypes.bool,
  formStatus: PropTypes.object,
  dispatch: PropTypes.func,
  showModal: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    editorLoading: getOr(null, `${mainStateKey}.editor.editorLoading`, state),
    formStatus: getOr(null, `${mainStateKey}.formStatus`, state),
    showModal: getOr(false, `${mainStateKey}.${modalPath}.show`, state),
  };
};

export default connect(mapStateToProps)(Editor);
