import React from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { mainStateKey, viberatecomSiteUrl } from "../../../constants/constants";
import { getVenueButtonTextFromObject } from "../../../lib/functions/common";

const VenueButton = ({ value, connectedTo }) => {
  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-default btn-search-result btn-place"
        onClick={() => {
          if (value && value.slug) {
            window.open(viberatecomSiteUrl[process.env.REACT_APP_ENVIRONMENT] + "venue/" + value.slug);

            if (value.links && value.links.length) {
              value.links.forEach(item => {
                if (item && item.link && item.channel === "facebook") {
                  window.open(item.link);
                }
              });
            }
          }
        }}
      >
        {getVenueButtonTextFromObject(value)}
      </button>
    </React.Fragment>
  );
};

VenueButton.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state) };
};

export default connect(mapStateToProps)(VenueButton);
