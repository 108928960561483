import React from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { mainStateKey } from "../../../constants/constants";
import { SET_UNSET_OBJECT_ARRAY_ITEM } from "../../../redux/reducers/common/actionTypes";
import { findObjectByValue } from "../../../lib/functions/common";

const CurrentlySelectedFiltersList = ({ currentlySelectedFilters, dispatch, tableParamsData = {}, connectedTo }) => {
  const filtersWindow = [];
  let valueKey;
  let filterDefinition;

  if (currentlySelectedFilters) {
    Object.keys(currentlySelectedFilters).forEach(filterName => {
      filterDefinition = findObjectByValue(tableParamsData.columns, "name", filterName);
      filterDefinition = filterDefinition ? filterDefinition.filterDefinition : null;

      if (
        filterDefinition &&
        filterDefinition.filterListButtonValueKey &&
        currentlySelectedFilters &&
        currentlySelectedFilters[filterName] &&
        currentlySelectedFilters[filterName].length
      ) {
        valueKey = filterDefinition.filterListButtonValueKey;

        if (Array.isArray(currentlySelectedFilters[filterName])) {
          currentlySelectedFilters[filterName].forEach(item => {
            filtersWindow.push(
              <button
                key={item.id}
                type="button"
                className="btn btn-default btn-remove-small"
                onClick={() => {
                  onFilterClick(connectedTo, filterName, item, dispatch);
                }}
              >
                {item[valueKey]}
              </button>
            );
          });
        }
      }
    });
  }
  return filtersWindow;
};

const onFilterClick = (connectedTo, filterName, item, dispatch) => {
  dispatchToReduxStore(
    connectedTo + "." + filterName,
    SET_UNSET_OBJECT_ARRAY_ITEM,
    { name: item.name, id: item.id },
    dispatch
  );
};

CurrentlySelectedFiltersList.propTypes = {
  currentlySelectedFilters: PropTypes.object,
  tableParamsData: PropTypes.object,
  dispatch: PropTypes.func,
  connectedTo: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { currentlySelectedFilters: getOr(null, `${mainStateKey}.${ownProps.connectedTo}`, state) };
};

export default connect(mapStateToProps)(CurrentlySelectedFiltersList);
