import React from "react";
import { connect } from "react-redux";
import FormBox from "../../../../presentational/page/formBox";
import Select from "../../../../input/selectors/select";
import { articleStatus } from "../../../../../constants/constants";
import Input from "../../../../input/general/input";

const GeneralInfoBox = () => {
  return (
    <FormBox title="General Info">
      <div className="row">
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Title</label>
              <div className="input-group">
                <Input name="formData.title" type="text" placeholder="Title" className="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="box-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>&nbsp;</label>
                  <div className="input-group">
                    <label>
                      <Input type="checkbox" name="formData.featured" showClearInputButton={false} /> Featured
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status</label>
                  <Select
                    placeholder="Select status"
                    data={articleStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={false}
                    name="formData.status"
                    showClearButton={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Order - can be a decimal number</label>
              <div className="input-group">
                <Input name="formData.order" placeholder="Order" className="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default connect(null)(GeneralInfoBox);
