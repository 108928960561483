import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import EntitySelector from "../selectors/entitySelector";
import { mainStateKey } from "../../../constants/constants";
import { objectValueExistsInArray } from "../../../lib/functions/common";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE, SET_UNSET_OBJECT_ARRAY_ITEM } from "../../../redux/reducers/common/actionTypes";

const SimilarArticles = ({ value, name, dispatch, swapOnSelect = false, onSelect = () => {} }) => {
  const appendArticleToList = article => {
    if (swapOnSelect) {
      dispatchToReduxStore(name, SET_STATE_VALUE, [article], dispatch);
      onSelect(article);
    } else {
      if (!objectValueExistsInArray("id", article.id, value)) {
        if (!value || (value && value.length < 3)) {
          onSelect(article);
          dispatchToReduxStore(name, SET_UNSET_OBJECT_ARRAY_ITEM, article, dispatch);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <EntitySelector
        entityType="article"
        placeholder="Search Article..."
        showAddNewButton={false}
        name={`formData.articleSearchText`}
        showOpenInNewViberateTabButton={false}
        onChange={article => {
          appendArticleToList(article);
        }}
      />

      {value &&
        value.length > 0 &&
        value.map((article, index) => {
          return (
            <button
              key={index}
              type="button"
              className={"btn btn-default  btn-remove wrap"}
              onClick={e => {
                e.stopPropagation();
                dispatchToReduxStore(name, SET_UNSET_OBJECT_ARRAY_ITEM, article, dispatch);
              }}
            >
              {article.title}
              <span className="remove fa fa-remove" />
            </button>
          );
        })}
    </React.Fragment>
  );
};

SimilarArticles.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
  dispatch: PropTypes.func,
  disabled: PropTypes.bool,
  formGroupClassName: PropTypes.string,
  swapOnSelect: PropTypes.bool,
  onSelect: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(SimilarArticles);
