import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { enumsPath } from "../../../../../constants/constants";
import FieldNameColumn from "./fieldNameColumn";
import DecisionColumn from "./decisionColumn";
import ReviewColumn from "./reviewColumn";

const LinkRows = ({ submitted, accepted, reviewData, channels, old, showReview = true }) => {
  let links = {};
  let linkName = "";
  let foundLink;
  let url;
  let acceptedUrl;
  let oldUrl;

  let decision;
  let className = "";

  if (submitted) {
    Object.keys(submitted).forEach(field => {
      if (field.includes("link_")) {
        links[field] = submitted[field];
      }
    });
  }

  return (
    <React.Fragment>
      {!!Object.keys(links).length &&
        Object.keys(links).map((link, index) => {
          foundLink = channels
            ? channels.find(item => {
                return item.id.toString() === link.split("_")[1];
              })
            : null;
          linkName = foundLink ? foundLink.name : "";
          url = links[link] ? links[link] : "";
          acceptedUrl = accepted[link] ? accepted[link] : "";
          oldUrl = old[link] ? old[link] : "";

          decision = getOr("", `${link}.status`, reviewData);
          className = decision === "accepted" ? "green" : decision === "denied" ? "red" : "";

          return (
            <tr key={index}>
              <FieldNameColumn fieldName={linkName} />
              <td className="break-word">
                <a href={oldUrl} target="_blank" rel="noopener noreferrer">
                  {oldUrl}
                </a>
              </td>
              <td className={className + " break-word"}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              </td>
              <td className="break-word">
                <a href={acceptedUrl} target="_blank" rel="noopener noreferrer">
                  {acceptedUrl}
                </a>
              </td>
              <DecisionColumn fieldName={link} reviewData={reviewData} />
              {showReview && <ReviewColumn fieldName={link} reviewData={reviewData} />}
            </tr>
          );
        })}
    </React.Fragment>
  );
};

LinkRows.propTypes = {
  submitted: PropTypes.object,
  accepted: PropTypes.object,
  reviewData: PropTypes.object,
  channels: PropTypes.array,
  old: PropTypes.object,
  showReview: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    channels: getOr(null, `${enumsPath}.channels`, state),
  };
};

export default connect(mapStateToProps)(LinkRows);
