import React, { useEffect } from "react";
import Footer from "../../../presentational/page/footer";
import GeneralInfoBox from "../shared/generalInfoBox";
import { handleAddFormSubmit, setFormData } from "../../../../lib/functions/componentFunctions";
import LinksBox from "../shared/linksBox";
import { useResetPageState } from "../../../../lib/functions/customHooks";
import labelDataManipulation from "../../../../lib/dataManipulation/label/labelDataManipulation";

const Addlabel = () => {
  useResetPageState();

  useEffect(() => {
    setFormData({ status: { id: 0, name: "Active" } });
  }, []);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add label</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <GeneralInfoBox />
          </div>
          <div className="col-md-6">
            <LinksBox showOpenAllLinksButton={false} />
          </div>
        </div>
        <Footer
          onSave={() => handleAddFormSubmit(false, labelDataManipulation, "label")}
          onSaveAndClear={() => {
            handleAddFormSubmit(true, labelDataManipulation, "label");
          }}
        />
      </section>
    </React.Fragment>
  );
};

export default Addlabel;
