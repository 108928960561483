import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormBox from "../../../presentational/page/formBox";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";

const LastAddedVenuesBox = ({ numberOfVenues = 5, dispatch }) => {
  const storedVenues =
    typeof window !== "undefined" && window.localStorage && localStorage.getItem("lastAddedVenues")
      ? JSON.parse(localStorage.getItem("lastAddedVenues"))
      : [];

  storedVenues.reverse();

  return (
    <FormBox title={"Last " + numberOfVenues + " added venues"}>
      <div className="box-body">
        <div className="form-group last-5-places">
          {storedVenues.map(venue => {
            return (
              <button
                type="button"
                key={venue.id}
                className="btn btn-default btn-search-result btn-place"
                onClick={() => {
                  dispatchToReduxStore("formData.venue", SET_STATE_VALUE, venue, dispatch);
                }}
              >
                {venue.name}
                <span>{venue.spanContent}</span>
              </button>
            );
          })}
        </div>
      </div>
    </FormBox>
  );
};

LastAddedVenuesBox.propTypes = {
  numberOfVenues: PropTypes.number,
  dispatch: PropTypes.func,
};

export default connect(null)(LastAddedVenuesBox);
