import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { mainStateKey, enumsPath } from "../../../constants/constants";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import {
  REMOVE_ITEM_BY_INDEX,
  APPEND_ITEM_TO_ARRAY,
  SWAP_ARRAY_ITEM_LEFT,
  SWAP_ARRAY_ITEM_RIGHT,
} from "../../../redux/reducers/common/actionTypes";

import { getYoutubeThumbnail, findObjectByValue, objectValueExistsInArray } from "../../../lib/functions/common";
import AddVideo from "./addVideo";
import UploadImageButton from "./uploadImageButton";

const VideoGallery = ({
  name,
  value,
  dispatch,
  addVideoColumnWidth = "2",
  videoGalleryColumnWidth = "10",
  clearSelectAfterAdd = true,
  clearLinkAfterAdd = true,
  mediaTypes,
  videoTypeSelectorDisabled = false,
}) => {
  const addVideoToGallery = video => {
    const url = video && video.url ? video.url : null;
    const typeId = video && video.type && video.type.id ? video.type.id : null;
    const thumbUrl = video && video.url ? getYoutubeThumbnail(video.url) : null;
    let title;

    if (mediaTypes && Array.isArray(mediaTypes) && mediaTypes.length && typeId) {
      title = findObjectByValue(mediaTypes, "id", typeId).name;
    }

    dispatchToReduxStore(
      name,
      APPEND_ITEM_TO_ARRAY,
      { url: url, type_id: typeId, thumbnail: thumbUrl, title: title },
      dispatch
    );
  };

  return (
    <React.Fragment>
      <label>Video section</label>

      <div className="row">
        <div className={"col-md-" + addVideoColumnWidth}>
          <AddVideo
            videoTypeSelectorDisabled={videoTypeSelectorDisabled}
            clearSelectAfterAdd={clearSelectAfterAdd}
            clearLinkAfterAdd={clearLinkAfterAdd}
            onChange={video => {
              if (video && !objectValueExistsInArray("url", video.url, value)) {
                addVideoToGallery(video);
              }
            }}
            name="formData.video"
          />
        </div>

        <div className={"col-md-" + videoGalleryColumnWidth}>
          <div className="form-group">
            <div className="list-media-wrapper">
              <div className="list-media">
                {value && value.length > 0
                  ? value.map((video, index) => {
                      return (
                        <div className="list-item" key={index}>
                          <UploadImageButton
                            imageLabel={video.title}
                            name={name + ".[" + index + "].thumbnail"}
                            deleteComponentOnRemove={true}
                            disabledFileSelector={true}
                            showPreviewButton={false}
                            showOrderButtons={true}
                            onMediaButtonClick={() => {
                              if (video.url) {
                                window.open(video.url);
                              }
                            }}
                            onRemove={() => {
                              dispatchToReduxStore(name, REMOVE_ITEM_BY_INDEX, { index: index }, dispatch);
                            }}
                            onLeftOrderButtonClick={() => {
                              dispatchToReduxStore(name, SWAP_ARRAY_ITEM_LEFT, { index: index }, dispatch);
                            }}
                            onRightOrderButtonClick={() => {
                              dispatchToReduxStore(name, SWAP_ARRAY_ITEM_RIGHT, { index: index }, dispatch);
                            }}
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

VideoGallery.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  dispatch: PropTypes.func,
  clearSelectAfterAdd: PropTypes.bool,
  clearLinkAfterAdd: PropTypes.bool,
  mediaTypes: PropTypes.array,
  videoTypeSelectorDisabled: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getOr(null, `${mainStateKey}.${ownProps.name}`, state),
    mediaTypes: getOr(null, `${enumsPath}.media_types`, state),
  };
};

export default connect(mapStateToProps)(VideoGallery);
