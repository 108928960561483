import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import GeneralInfoBox from "../shared/generalInfoBox";

import { useResetPageState } from "../../../../lib/functions/customHooks";
import GenresBox from "../shared/genresBox";

import feedpostDataManipulation from "../../../../lib/dataManipulation/feedpost/feedpostDataManipulation";
import { handleEditFormSubmit, setItemFormData } from "../../../../lib/functions/componentFunctions";
import { useParams } from "react-router";
import ContentComponentSelector from "../../../input/selectors/contentComponentSelector";
import { feedpostComponents } from "../../../../constants/feedpost";
import ContentBox from "../shared/contentBox";
import { mainStateKey } from "../../../../constants/constants";

const EditFeedPostItem = ({ articleID }) => {
  useResetPageState();

  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "feedpost");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit feedpost</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-8">
            <GeneralInfoBox />
            <div className="row">
              <div className="col-md-12">
                <ContentBox />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <GenresBox />
          </div>
        </div>
        <ContentComponentSelector
          disabled={articleID}
          onSubmit={() => {
            handleEditFormSubmit(id, feedpostDataManipulation, "feedpost", "PUT");
          }}
          availableComponents={feedpostComponents}
        />
      </section>
    </React.Fragment>
  );
};

EditFeedPostItem.propTypes = {
  articleID: PropTypes.number,
};

const mapStateToProps = state => {
  return { articleID: getOr(null, `${mainStateKey}.formData.article[0].id`, state) };
};

export default connect(mapStateToProps)(EditFeedPostItem);
