import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import { Provider } from "react-redux";
import Editor from "./editor";
import store from "./redux/store/store";
import "./static/css/bootstrap.min.css";
import "./static/css/adminLTE.min.css";
import "./static/css/skin-red.min.css";
import "./static/css/font-awesome.min.css";
import "./static/css/react-draft-wysiwyg.css";
import "./static/css/custom.css";
import ScrollToTop from "./routerScrollToTop";

const EditorApp = () => {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Editor />
      </Router>
    </Provider>
  );
};

ReactDOM.render(<EditorApp />, document.getElementById("root"));
