import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { mainStateKey, youtubeDomain } from "../../../constants/constants";
import YoutubeTable from "../../presentational/table/youtubeTable";
import EntitySelector from "../selectors/entitySelector";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import {
  PREPEND_ITEM_TO_ARRAY,
  REMOVE_ITEM_BY_INDEX,
  SET_STATE_VALUE,
} from "../../../redux/reducers/common/actionTypes";
import { findObjectByValue, findObjectIndexByValue } from "../../../lib/functions/common";

const YoutubeVideosTable = ({ value, dispatch, name, suggestedVideos, initialSuggestedVideos }) => {
  const [state, setState] = useState({
    openVideosDisabled: true,
    checked: [],
  });

  const deleteVideo = item => {
    const index = findObjectIndexByValue(value, "yt_video_id", item.yt_video_id);

    if (index !== -1) {
      if (window.confirm("Are you sure you want to delete this video?")) {
        dispatchToReduxStore("formData.youtubeVideos", REMOVE_ITEM_BY_INDEX, { index }, dispatch);

        // Append deleted video to suggested videos if it exists in initial suggested videos array
        if (findObjectByValue(initialSuggestedVideos, "yt_video_id", item.yt_video_id)) {
          dispatchToReduxStore("formData.youtubeSuggestions", PREPEND_ITEM_TO_ARRAY, item, dispatch);
        }
      }
    }
  };

  const onCheckedItemsChange = items => {
    setState({ ...state, openVideosDisabled: !items.length, checked: items });
  };

  const openSelected = () => {
    if (state.checked && state.checked.length) {
      state.checked.forEach(videoID => {
        window.open(`${youtubeDomain}watch?v=${videoID}`);
      });
    }
  };

  const deleteSelected = () => {
    if (window.confirm("Are you sure you want to delete this video?")) {
      if (state.checked && state.checked.length) {
        let filtered = value;
        let appendedItems = [];

        filtered = value.filter(item => {
          if (findObjectByValue(initialSuggestedVideos, "yt_video_id", item.yt_video_id)) {
            appendedItems.push(item);
          }

          return state.checked.includes(item.yt_video_id) ? false : true;
        });

        if (appendedItems.length) {
          dispatchToReduxStore(
            "formData.youtubeSuggestions",
            SET_STATE_VALUE,
            [...appendedItems, ...suggestedVideos],
            dispatch
          );
        }

        dispatchToReduxStore("formData.youtubeVideos", SET_STATE_VALUE, filtered, dispatch);
      }
    }
  };

  const addVideo = item => {
    if (!findObjectByValue(value, "yt_video_id", item.yt_video_id)) {
      const suggestedIndex = findObjectIndexByValue(suggestedVideos, "yt_video_id", item.yt_video_id);
      // Remove video from suggestions table
      if (suggestedIndex !== -1) {
        dispatchToReduxStore("formData.youtubeSuggestions", REMOVE_ITEM_BY_INDEX, { index: suggestedIndex }, dispatch);
      }

      dispatchToReduxStore(name, PREPEND_ITEM_TO_ARRAY, item, dispatch);
    }
  };

  return (
    <React.Fragment>
      <div className="form-group">
        <label className="col-sm-1 control-label">Add youtube video</label>
        <div className="col-sm-6" style={{ zIndex: 100 }}>
          <EntitySelector
            minTextLength={3}
            onChange={addVideo}
            entityType="youtube_video"
            placeholder="Search youtube videos..."
            showAddNewButton={false}
            showOpenInNewViberateTabButton={false}
            name="formData.youtubeSearchText"
          />
        </div>
        <div className="col-sm-5">
          <div style={{ float: "right" }}>
            <button
              type="button"
              className="btn btn-default default-small"
              disabled={state.openVideosDisabled}
              onClick={openSelected}
            >
              Open selected videos
            </button>
            <button
              type="button"
              className="btn btn-danger default-small"
              disabled={state.openVideosDisabled}
              onClick={deleteSelected}
            >
              Delete selected videos
            </button>
          </div>
        </div>
      </div>
      <div style={{ height: "40px" }}></div>
      <YoutubeTable
        connectedTo={name}
        zeroStateText="No youtube videos found"
        onDeleteClick={deleteVideo}
        showCheckbox={true}
        showMatchButton={false}
        onCheckedItemsChange={items => onCheckedItemsChange(items)}
      />
    </React.Fragment>
  );
};

YoutubeVideosTable.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  dispatch: PropTypes.func,
  suggestedVideos: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getOr(null, `${mainStateKey}.${ownProps.name}`, state),
    suggestedVideos: getOr([], `${mainStateKey}.${"formData.youtubeSuggestions"}`, state),
    initialSuggestedVideos: getOr([], `${mainStateKey}.${"formData.initialYoutubeSuggestions"}`, state),
  };
};

export default connect(mapStateToProps)(YoutubeVideosTable);
