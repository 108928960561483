import React from "react";
import FormBox from "../../../presentational/page/formBox";
import Highlights from "../../../input/general/highlights";

const HighlightsBox = () => {
  return (
    <FormBox title="highlights">
      <div className="box-body">
        <Highlights
          highlightsType="venue"
          name="formData.highlights"
          initialHighlightsPath="formData.initialHighlights"
        />
      </div>
    </FormBox>
  );
};

export default HighlightsBox;
