import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import Footer from "../../../presentational/page/footer";
import HighlightsBox from "../shared/highlightsBox";
import DescriptionBox from "../shared/descriptionBox";
import LinksBox from "../shared/linksBox";
import MediaBox from "../shared/mediaBox";
import OpeningHoursBox from "../shared/openingHoursBox";
import venueDataManipulation from "../../../../lib/dataManipulation/venue/venueDataManipulation";
import {
  handleEditFormSubmit,
  setItemFormData,
  handleDeleteEntity,
  redirectTo,
} from "../../../../lib/functions/componentFunctions";
import NameAndAdditionalInfoBox from "../shared/nameAndAdditionalInfoBox";
import LocationBox from "../shared/locationBox";
import VenueExternalHeaderButtons from "../../../presentational/buttons/venueExternalHeaderButtons";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const EditVenue = () => {
  useResetPageState();

  const routerHistory = useHistory();
  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "venue");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <VenueExternalHeaderButtons headerTitle="Edit Venue" connectedTo="formData.name.name" id={id} />
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-6">
                <NameAndAdditionalInfoBox />
              </div>
              <div className="col-md-6">
                <LocationBox />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <OpeningHoursBox />
                <MediaBox />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12"></div>
            </div>
          </div>
          <div className="col-md-5">
            <LinksBox showOpenAllLinksButton={true} />
            <HighlightsBox />
            <DescriptionBox />
          </div>
        </div>
        <Footer
          showDeleteButton={true}
          showClearButton={false}
          onSave={() => {
            handleEditFormSubmit(id, venueDataManipulation, "venue");
          }}
          onDelete={async () => {
            if (window.confirm("Are you sure you want to delete this venue?")) {
              const deleteSuccess = await handleDeleteEntity(id, "venue");

              if (deleteSuccess) {
                redirectTo(routerHistory, "/list/editVenue/");
              }
            }
          }}
        />
      </section>
    </React.Fragment>
  );
};

EditVenue.propTypes = {
  venueName: PropTypes.string,
};

export default EditVenue;
