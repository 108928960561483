import moment from "moment";
import { getOr } from "lodash/fp";
import { getDataByType } from "./fieldDataManipulation";

const contributionHistoryDataManipulation = function contributionHistoryDataManipulation() {
  const getInwardDatatable = data => {
    const listItems = [];
    let comment;
    let createdAt;
    let processedAt;
    let modName;

    if (data && data.length) {
      data.forEach(item => {
        comment = getOr("", "review.comment", item);
        comment = comment && comment.length > 30 ? comment.slice(0, 30) + "..." : comment;
        createdAt = getOr(null, "created_at", item);
        processedAt = getOr(null, "processed_at", item);
        createdAt = createdAt
          ? moment(createdAt)
              .local()
              .format("YYYY-MM-DD HH:mm")
          : null;
        processedAt = processedAt
          ? moment(processedAt)
              .local()
              .format("YYYY-MM-DD HH:mm")
          : null;

        modName = getOr("", "mod.name", item) + " " + getOr("", "mod.surname", item);
        modName = modName ? modName : getOr("", "mod.email", item);

        listItems.push([
          getOr("", "user.email", item),
          getOr("", "type", item),
          getOr("", "entity_type", item),
          getOr("", "entity_name", item),
          getOr("", "user_points", item),
          getOr("", "mod_points", item),
          createdAt,
          processedAt,
          modName,
          getOr("", "status", item),
          comment,
          getOr("", "review.created_at", item) ? "Yes" : "No",
          getOr(null, "uuid", item),
        ]);
      });
    }

    return listItems;
  };

  const getInwardReviewItem = data => {
    const type = getOr(null, "entity_type", data);

    return {
      ...data,
      submitted: { ...getDataByType(data, "submitted", type) },
      accepted: { ...getDataByType(data, "new", type) },
      old: { ...getDataByType(data, "old", type) },
      reviewData: { ...getDataByType(data, "", type, true) },
    };
  };

  const getOutwardItem = data => {
    const comment = getOr(null, "review.comment", data);
    const details = [];
    let changeId;
    let reviewStatus;

    if (Object.keys(data.reviewData).length) {
      Object.keys(data.reviewData).forEach(field => {
        changeId = getOr(null, `reviewData.${field}.id`, data);
        reviewStatus = getOr(null, `reviewData.${field}.review.status`, data);
        if (changeId && reviewStatus) {
          details.push({ change_detail_id: changeId, status: reviewStatus });
        }
      });
    }

    return { comment, details };
  };

  return { inwardDatatable: getInwardDatatable, inwardItem: getInwardReviewItem, outward: getOutwardItem };
};

export default contributionHistoryDataManipulation();
