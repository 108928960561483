import React from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import MultiCheckbox from "../../input/general/multiCheckbox";
import { enumsPath, mainStateKey } from "../../../constants/constants";

const Highlights = ({
  highlightsType,
  highlights,
  name = "formData.highlights",
  initialHighlights,
  initialHighlightsPath,
}) => {
  const highlightsList = highlights && highlights[highlightsType] ? highlights[highlightsType] : [];

  return (
    <React.Fragment>
      {highlightsList.map(item => {
        return (
          <div className="form-group float-list-item" key={item.id}>
            <div
              className={"checkbox " + (initialHighlights && initialHighlights.includes(item.id) ? "pre-selected" : "")}
            >
              <MultiCheckbox pushedItem={item.id} name={`${name}`} showClearInputButton={false} />
              <label>{item.name}</label>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

Highlights.propTypes = {
  initialHighlights: PropTypes.array,
  initialHighlightsPath: PropTypes.string,
  highlightsType: PropTypes.string,
  highlights: PropTypes.object,
  name: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    highlights: getOr(null, `${enumsPath}.highlights`, state),
    initialHighlights: getOr(null, `${mainStateKey}.${ownProps.initialHighlightsPath}`, state),
  };
};

export default connect(mapStateToProps)(Highlights);
