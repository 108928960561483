import moment from "moment";

const festivalAppLineupDataManipulation = function festivalAppLineupDataManipulation() {
  const getInwardEditFestivalItem = data => {
    return data && data.length > 0
      ? data.map(row => {
          return {
            artist: row.artist ? row.artist : null,
            stage_id: row.stage_id ? row.stage_id : null,
            day: row.day ? row.day : null,
            start: row.start ? row.start : null,
            end: row.end ? row.end : null,
          };
        })
      : null;
  };

  const getOutwardFestival = data => {
    return (
      data &&
      data.list &&
      data.list.length > 0 &&
      data.list.map(row => {
        return {
          artist_uuid: row.artist ? row.artist.uuid : null,
          stage_id: row.stage_id ? row.stage_id : null,
          day: row.day ? row.day : null,
          start: row.start ? moment(row.start).format("YYYY-MM-DDTHH:mm:ss") : null,
          end: row.end ? moment(row.end).format("YYYY-MM-DDTHH:mm:ss") : null,
        };
      })
    );
  };

  return { outward: getOutwardFestival, inwardItem: getInwardEditFestivalItem };
};

export default festivalAppLineupDataManipulation();
