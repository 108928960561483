import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import linkValidationRules from "../../../constants/linkValidationRules";
import { mainStateKey } from "../../../constants/constants";
import Input from "../../input/general/input";

function LinkInput({
  type,
  placeholder,
  id,
  name,
  value,
  onChange,
  showRedirectButton = true,
  showClearInputButton = true,
  children,
  onClearButtonClick,
  duplicatedLinks,
  style = {},
}) {
  const [linkIsValid, setLinkIsValid] = useState(true);
  name = id ? `${name}.[${id}]` : name;

  useEffect(() => {
    if (duplicatedLinks && duplicatedLinks.length) {
      if (duplicatedLinks.includes(value)) {
        setLinkIsValid(false);
      } else {
        setLinkIsValid(true);
      }
    }
  }, [duplicatedLinks, value]);

  return (
    <div className={"input-group " + (!linkIsValid && value ? "has-error" : "")}>
      <Input
        style={style}
        type="text"
        onChange={val => {
          setLinkIsValid(validateLink(val, linkValidationRules[type]));

          if (onChange) {
            onChange(validateLink(val, linkValidationRules[type]), val);
          }
        }}
        className="form-control"
        placeholder={placeholder}
        name={name}
        debounceTime={0}
        showClearInputButton={showClearInputButton}
        onClearButtonClick={onClearButtonClick}
        showRedirectButton={showRedirectButton}
        onRedirectButtonClick={inputValue => {
          if (inputValue) {
            window.open(inputValue);
          }
        }}
      />
      {children}
    </div>
  );
}

export function validateLink(value, url = null) {
  if (!value) {
    return true;
  }

  let expression = /^https?:\/\//;
  if (url != null) {
    expression = url + "(/[-a-zA-Z0-9@:%_+.~#?&//=]*)?";
  }

  let regex = new RegExp(expression, "gi");
  if (!value || !value.match) return false;
  if (value.match(regex)) {
    return true;
  }

  return false;
}

LinkInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  showClearInputButton: PropTypes.bool,
  showRedirectButton: PropTypes.bool,
  onClearButtonClick: PropTypes.func,
  duplicatedLinks: PropTypes.array,
  style: PropTypes.object,
  childen: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const mapStateToProps = (state, ownProps) => {
  const str = ownProps.id ? `[${ownProps.id}]` : ``;
  return {
    value: getOr(null, `${mainStateKey}.${ownProps.name}${str}`, state),
    duplicatedLinks: getOr(null, `${mainStateKey}.formErrors.duplicatedLinks`, state),
  };
};

export default connect(mapStateToProps)(LinkInput);
