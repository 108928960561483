import React from "react";
import { PropTypes } from "prop-types";

const FormBox = ({ title, children, className = "", headerContent, style, id }) => {
  return (
    <div id={id} className={"box box-default " + className} style={style}>
      <div className="box-header">
        <h3 className="box-title">{title}</h3>
        {headerContent}
      </div>
      {children}
    </div>
  );
};

FormBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  headerContent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
};

export default FormBox;
