import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AddArtist from "./components/forms/artist/add/addArtist";
import AddVenue from "./components/forms/venue/add/addVenue";
import AddEvent from "./components/forms/event/add/addEvent";
import AddFestival from "./components/forms/festival/add/addFestival";
import AddArticle from "./components/forms/article/add/addArticle";
import AddWebinar from "./components/forms/webinar/add/addWebinar";

import AddMatchedEvent from "./components/forms/event/addMatchedEvent/addMatchedEvent";
import EditArticle from "./components/forms/article/edit/editArticle";
import MatchedEventsListPage from "./components/forms/event/addMatchedEvent/matchedEventsListPage";
import EditArticleItem from "./components/forms/article/edit/editArticleItem";
import EditVenue from "./components/forms/venue/edit/editVenue";
import EditEvent from "./components/forms/event/edit/editEvent";
import EditFestival from "./components/forms/festival/edit/editFestival";
import Dashboard from "./components/presentational/general/dashboard";
import EditEventListPage from "./components/forms/event/edit/editEventListPage";
import EditArtistListPage from "./components/forms/artist/edit/editArtistListPage";
import EditLabelListPage from "./components/forms/label/edit/editLabelListPage";
import EditTrackListPage from "./components/forms/track/edit/editTrackListPage";
import EditArtist from "./components/forms/artist/edit/editArtist";
import EditVenueListPage from "./components/forms/venue/edit/editVenueListPage";
import EditFestivalListPage from "./components/forms/festival/edit/editFestivalListPage";
import ContributionHistoryListPage from "./components/forms/contributionHistory/contributionHistoryListPage";
import ContributionReview from "./components/forms/contributionHistory/review/contributionReview";
import ChangeHistoryLog from "./components/forms/changeHistoryLog/changeHistoryLog";
import AddHelpCenterTopic from "./components/forms/helpCenter/topics/add/addHelpCenterTopic";
import EditHelpCenterTopic from "./components/forms/helpCenter/topics/edit/editHelpCenterTopic";
import EditHelpCenterTopicListPage from "./components/forms/helpCenter/topics/edit/editHelpCenterTopicListPage";
import EditWebinarListPage from "./components/forms/webinar/edit/editWebinarListPage";
import EditWebinar from "./components/forms/webinar/edit/editWebinar";
import AddHelpCenterCategory from "./components/forms/helpCenter/categories/add/addHelpCenterCategory";
import EditHelpCenterCategoriesListPage from "./components/forms/helpCenter/categories/edit/editHelpCenterCategoryListPage";
import EditHelpCenterCategory from "./components/forms/helpCenter/categories/edit/editHelpCenterCategory";
/*
import AddCampaign from "./components/forms/festivalApp/campaign/add/addCampaign";
import EditCampaignListPage from "./components/forms/festivalApp/campaign/edit/editCampaignListPage";
import EditCampaign from "./components/forms/festivalApp/campaign/edit/editCampaign";
import LiveMomentsPosts from "./components/forms/festivalApp/liveMoments/liveMomentsPosts";
import LiveMomentsReports from "./components/forms/festivalApp/liveMoments/liveMomentsReports";
import SelectFapp from "./components/forms/festivalApp/selectFapp/selectFapp";
import Info from "./components/forms/festivalApp/info/info";
import Experiences from "./components/forms/festivalApp/experiences/experiences";
import Lineup from "./components/forms/festivalApp/lineup/lineup";
import FestivalMap from "./components/forms/festivalApp/festivalMap/festivalMap";
import PhotoAndVideo from "./components/forms/festivalApp/media/photoAndVideo";
import AdditionalInfo from "./components/forms/festivalApp/additionalInfo/additionalInfo";
import Sponsors from "./components/forms/festivalApp/sponsors/sponsors";
import Merch from "./components/forms/festivalApp/merch/merch";
*/
import AddLabel from "./components/forms/label/add/addLabel";
import EditLabel from "./components/forms/label/edit/editLabel";
import AddTrack from "./components/forms/track/add/addTrack";
import EditTrack from "./components/forms/track/edit/editTrack";
import EditArtistYoutube from "./components/forms/artist/edit/editArtistYoutube";
import EditFeedpostListPage from "./components/forms/feedpost/edit/editFeedpostListPage";
import AddFeedpost from "./components/forms/feedpost/add/addFeedpost";
import EditFeedpostItem from "./components/forms/feedpost/edit/editFeedpostItem";

const EditorRouter = () => {
  const { hash } = useLocation();

  //Scroll to anchor on every hash change
  useEffect(() => {
    scrollToAnchor();
  }, [hash]);

  return (
    <React.Fragment>
      <Switch>
        {/*Add */}
        <Route path="/add/artist">
          <AddArtist />
        </Route>
        <Route path="/add/venue">
          <AddVenue />
        </Route>
        <Route path="/add/event">
          <AddEvent />
        </Route>
        <Route path="/add/article">
          <AddArticle />
        </Route>
        <Route path="/add/festival">
          <AddFestival />
        </Route>
        <Route path="/add/label">
          <AddLabel />
        </Route>
        <Route path="/add/track">
          <AddTrack />
        </Route>
        <Route path="/add/help-center-topic">
          <AddHelpCenterTopic />
        </Route>
        <Route path="/add/help-center-category">
          <AddHelpCenterCategory />
        </Route>
        <Route path="/add/matchedEvent/:id">
          <AddMatchedEvent />
        </Route>
        <Route path="/add/webinar">
          <AddWebinar />
        </Route>
        <Route path="/add/matchedEvent/:id">
          <AddMatchedEvent />
        </Route>
        <Route path="/add/feed-post">
          <AddFeedpost />
        </Route>

        {/*FAPP*
        <Route path="/fapp/select">
          <SelectFapp />
        </Route>
        <Route path="/fapp/info">
          <Info />
        </Route>
        <Route path="/fapp/experiences">
          <Experiences />
        </Route>
        <Route path="/fapp/lineup">
          <Lineup />
        </Route>
        <Route path="/fapp/festmap">
          <FestivalMap />
        </Route>
        <Route path="/fapp/media">
          <PhotoAndVideo />
        </Route>
        <Route path="/fapp/additonalinfo">
          <AdditionalInfo />
        </Route>
        <Route path="/fapp/partners">
          <Sponsors />
        </Route>
        <Route path="/fapp/merch">
          <Merch />
        </Route>
        <Route path="/fapp/add/campaign">
          <AddCampaign />
        </Route>
        <Route path="/fapp/list/editCampaign">
          <EditCampaignListPage />
        </Route>
        <Route path="/fapp/edit/campaign/:campaignID">
          <EditCampaign />
        </Route>
        <Route path="/fapp/livemoments/posts">
          <LiveMomentsPosts />
        </Route>
        <Route path="/fapp/livemoments/reports">
          <LiveMomentsReports />
        </Route>
        */}

        {/*List*/}
        <Route path="/list/editEvent">
          <EditEventListPage />
        </Route>
        <Route path="/list/editArtist">
          <EditArtistListPage />
        </Route>
        <Route path="/list/editVenue">
          <EditVenueListPage />
        </Route>
        <Route path="/list/editFestival">
          <EditFestivalListPage />
        </Route>
        <Route path="/list/editLabel">
          <EditLabelListPage />
        </Route>
        <Route path="/list/editTrack">
          <EditTrackListPage />
        </Route>
        <Route path="/list/article">
          <EditArticle />
        </Route>
        <Route path="/list/contributionHistory">
          <ContributionHistoryListPage />
        </Route>
        <Route path="/list/matchedEvent">
          <MatchedEventsListPage />
        </Route>
        <Route path="/list/help-center-topic">
          <EditHelpCenterTopicListPage />
        </Route>
        <Route path="/list/help-center-category">
          <EditHelpCenterCategoriesListPage />
        </Route>
        <Route path="/list/editWebinar">
          <EditWebinarListPage />
        </Route>
        <Route path="/list/feed-post">
          <EditFeedpostListPage />
        </Route>

        {/*Edit*/}
        <Route path="/edit/artist/:id">
          <EditArtist />
        </Route>
        <Route path="/edit/artist-youtube/:id">
          <EditArtistYoutube />
        </Route>
        <Route path="/edit/venue/:id">
          <EditVenue />
        </Route>
        <Route path="/edit/event/:id">
          <EditEvent />
        </Route>
        <Route path="/edit/festival/:id/:edition">
          <EditFestival />
        </Route>
        <Route path="/edit/label/:id">
          <EditLabel />
        </Route>
        <Route path="/edit/track/:id">
          <EditTrack />
        </Route>
        <Route path="/edit/article/:id">
          <EditArticleItem />
        </Route>
        <Route path="/edit/feed-post/:id">
          <EditFeedpostItem />
        </Route>
        <Route path="/edit/help-center-topic/:id">
          <EditHelpCenterTopic />
        </Route>
        <Route path="/edit/help-center-category/:id">
          <EditHelpCenterCategory />
        </Route>
        <Route path="/edit/webinar/:id">
          <EditWebinar />
        </Route>
        <Route path="/review/contribution/:id">
          <ContributionReview />
        </Route>
        <Route path="/history/:type/:id/:edition?">
          <ChangeHistoryLog />
        </Route>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

const scrollToAnchor = () => {
  const { hash } = window.location;
  if (hash !== "") {
    let retries = 0;
    const id = hash.replace("#", "");
    const scroll = () => {
      retries += 0;
      if (retries > 50) return;
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: "smooth", block: "center" }), 0);
      } else {
        setTimeout(scroll, 100);
      }
    };
    scroll();
  }
};

export default connect(null)(EditorRouter);
