import React from "react";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import Input from "../../../input/general/input";
import TextArea from "../../../input/general/textArea";
import DateTime from "../../../input/date/dateTime";

const GeneralInfoBox = () => {
  return (
    <FormBox title="">
      <div className="row">
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Title</label>
              <div className="input-group">
                <Input
                  debounceTime={0}
                  name="formData.title"
                  type="text"
                  placeholder="Title"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Starts on</label>
              <div className="input-group">
                <DateTime name="formData.start" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Speakers</label>
              <div className="input-group">
                <Input
                  debounceTime={0}
                  name="formData.speakers"
                  type="text"
                  placeholder="Speakers"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Content URL (for Iframe source on Landing page)</label>
              <div className="input-group">
                <Input
                  debounceTime={0}
                  showRedirectButton={true}
                  name="formData.content_url"
                  type="text"
                  placeholder="Content URL"
                  className="form-control"
                  onRedirectButtonClick={inputValue => {
                    if (inputValue) {
                      window.open(inputValue);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Duration (in minutes)</label>
              <div className="input-group">
                <Input
                  debounceTime={0}
                  name="formData.duration"
                  min={0}
                  className="form-control"
                  placeholder="Duration"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="box-body">
            <div className="form-group">
              <label>Description</label>
              <div className="input-group">
                <TextArea
                  debounceTime={0}
                  showClearInputButton={false}
                  name="formData.description"
                  type="text"
                  placeholder="Description"
                  className="form-control"
                  rows="6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default connect(null)(GeneralInfoBox);
