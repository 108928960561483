import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import Footer from "../../../presentational/page/footer";
import artistDataManipulation from "../../../../lib/dataManipulation/artist/artistDataManipulation";
import { handleEditFormSubmit, setItemFormData } from "../../../../lib/functions/componentFunctions";
import { connect } from "react-redux";
import ClaimedImage from "../../../presentational/general/claimedImage";
import OpenChangeHistory from "../../../presentational/buttons/openChangeHistory";
import { useResetPageState } from "../../../../lib/functions/customHooks";
import YoutubeSuggestionsBox from "./youtubeSuggestionsBox";
import YoutubeVideosBox from "./youtubeVideosBox";
import { mainStateKey } from "../../../../constants/constants";

const EditArtistYoutube = ({ name }) => {
  useResetPageState();

  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "artist");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>
          Edit {name}'s youtube videos
          <ClaimedImage connectedTo="formData.claimed" />
          <OpenChangeHistory id={id} type="youtube" />
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <YoutubeSuggestionsBox />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <YoutubeVideosBox />
          </div>
        </div>

        <Footer
          showClearButton={false}
          onSave={() => {
            handleEditFormSubmit(id, artistDataManipulation, "artist", "POST");
          }}
        />
      </section>
    </React.Fragment>
  );
};

EditArtistYoutube.propTypes = {
  name: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    name: getOr(null, `${mainStateKey}.${"formData.name.name"}`, state),
  };
};

export default connect(mapStateToProps)(EditArtistYoutube);
