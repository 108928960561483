import articleDataManipulation from "../lib/dataManipulation/article/articleDataManipulation";
import artistDataManipulation from "../lib/dataManipulation/artist/artistDataManipulation";
import matchedEventDataManipulation from "../lib/dataManipulation/event/matchedEventDataManipulation";
import eventDataManipulation from "../lib/dataManipulation/event/eventDataManipulation";
import venueDataManipulation from "../lib/dataManipulation/venue/venueDataManipulation";
import festivalDataManipulation from "../lib/dataManipulation/festival/festivalDataManipulation";
import festivalAppInfoDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppInfo";
import festivalAppStagesDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppStages";
import festivalAppLineupDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppLineup";
import festivalAppMerchDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppMerch";
import festivalAppMediaDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppMedia";
import festivalAppAddInfoDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppAddInfo";
import festivalAppSponsorsDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppSponsors";
import festivalAppExperiencesDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppExperiences";
import festivalAppMapDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppMap";
import contributionHistoryDataManipulation from "../lib/dataManipulation/contributionHistory/contributionHistoryDataManipulation";
import helpCenterTopicDataManipulation from "../lib/dataManipulation/helpCenter/topics/helpCenterTopicDataManipulation";
import webinarDataManipulation from "../lib/dataManipulation/webinar/webinarDataManipulation";
import helpCenterCategoryDataManipulation from "../lib/dataManipulation/helpCenter/categories/helpCenterCategoryDataManipulation";
import festivalAppCampaignDataManipulation from "../lib/dataManipulation/festivalApp/festivalAppCampaign";
import labelDataManipulation from "../lib/dataManipulation/label/labelDataManipulation";
import trackDataManipulation from "../lib/dataManipulation/track/trackDataManipulation";
import changeHistoryDataManipulation from "../lib/dataManipulation/contributionHistory/changeHistoryDataManipulation";
import youtubeHistoryDataManipulation from "../lib/dataManipulation/contributionHistory/youtubeHistoryDataManipulation";
import feedpostDataManipulation from "../lib/dataManipulation/feedpost/feedpostDataManipulation";

export const entityDataManipulation = {
  //--------
  //Articles
  //--------
  editArticle: [
    {
      entityType: "article",
      target: articleDataManipulation,
      path: null,
    },
  ],

  //--------
  //Entities
  //--------
  artist: [
    {
      entityType: "artist",
      target: artistDataManipulation,
      path: null,
    },
  ],
  label: [
    {
      entityType: "label",
      target: labelDataManipulation,
      path: null,
    },
  ],
  track: [
    {
      entityType: "track",
      target: trackDataManipulation,
      path: null,
    },
  ],
  event: [
    {
      entityType: "event",
      target: eventDataManipulation,
      path: null,
    },
  ],
  venue: [
    {
      entityType: "venue",
      target: venueDataManipulation,
      path: null,
    },
  ],
  festival: [
    {
      entityType: "festival",
      target: festivalDataManipulation,
      path: null,
    },
  ],
  helpCenterTopic: [
    {
      entityType: "helpCenterTopic",
      target: helpCenterTopicDataManipulation,
      path: null,
    },
  ],
  helpCenterCategory: [
    {
      entityType: "helpCenterCategory",
      target: helpCenterCategoryDataManipulation,
      path: null,
    },
  ],
  webinar: [
    {
      entityType: "webinar",
      target: webinarDataManipulation,
      path: null,
    },
  ],
  feedpost: [
    {
      entityType: "feedpost",
      target: feedpostDataManipulation,
      path: null,
    },
  ],

  //-------------
  //Matched event
  //-------------
  addMatchedEvent: [
    {
      entityType: "matchedEvent",
      target: matchedEventDataManipulation,
      path: null,
    },
  ],

  //--------------------
  //Ccontribution Review
  //--------------------
  contributionReview: [
    {
      entityType: "contributionReview",
      target: contributionHistoryDataManipulation,
      path: null,
    },
  ],

  //--------------
  //Change History
  //--------------
  artistHistory: [
    {
      entityType: "artistHistory",
      target: changeHistoryDataManipulation,
      path: null,
    },
  ],
  labelHistory: [
    {
      entityType: "labelHistory",
      target: changeHistoryDataManipulation,
      path: null,
    },
  ],
  trackHistory: [
    {
      entityType: "trackHistory",
      target: changeHistoryDataManipulation,
      path: null,
    },
  ],
  venueHistory: [
    {
      entityType: "venueHistory",
      target: changeHistoryDataManipulation,
      path: null,
    },
  ],
  eventHistory: [
    {
      entityType: "eventHistory",
      target: changeHistoryDataManipulation,
      path: null,
    },
  ],
  festivalHistory: [
    {
      entityType: "festivalHistory",
      target: changeHistoryDataManipulation,
      path: null,
    },
  ],
  youtubeHistory: [
    {
      entityType: "youtubeHistory",
      target: youtubeHistoryDataManipulation,
      path: null,
    },
  ],

  //----
  //FAPP
  //----
  "editFestival-stages": [
    {
      entityType: "festival",
      target: festivalDataManipulation,
      path: "stages",
      manipulatedDataValueKey: "stages",
    },
  ],
  "fapp-info": [
    {
      entityType: "festivalAppInfo",
      target: festivalAppInfoDataManipulation,
      path: null,
    },
  ],
  "fapp-stages": [
    {
      entityType: "festivalAppStages",
      target: festivalAppStagesDataManipulation,
      path: "stages",
    },
  ],
  "fapp-lineup": [
    {
      entityType: "festivalAppStages",
      target: festivalAppStagesDataManipulation,
      path: "stages",
    },
    {
      entityType: "festivalAppLineup",
      target: festivalAppLineupDataManipulation,
      path: "list",
    },
  ],
  "fapp-merch": [
    {
      entityType: "festivalAppMerch",
      target: festivalAppMerchDataManipulation,
      path: null,
    },
  ],

  "fapp-media": [
    {
      entityType: "festivalAppMedia",
      target: festivalAppMediaDataManipulation,
      path: null,
    },
  ],

  "fapp-addInfo": [
    {
      entityType: "festivalAppAddInfo",
      target: festivalAppAddInfoDataManipulation,
      path: null,
    },
  ],

  "fapp-sponsors": [
    {
      entityType: "festivalAppSponsors",
      target: festivalAppSponsorsDataManipulation,
      path: null,
    },
  ],

  "fapp-experiences": [
    {
      entityType: "festivalAppExperiences",
      target: festivalAppExperiencesDataManipulation,
      path: null,
    },
  ],

  "fapp-festmap": [
    {
      entityType: "festivalAppMap",
      target: festivalAppMapDataManipulation,
      path: null,
    },
  ],
  "fapp-campaign": [
    {
      entityType: "festivalAppCampaign",
      target: festivalAppCampaignDataManipulation,
      path: null,
    },
  ],
};
