import store from "../../redux/store/store";
import {
  SET_FORM_SUCCESS,
  SET_FORM_FAIL,
  SET_FORM_ERROR_REASONS,
  SET_FORM_SHOW_ERROR_WINDOW,
  SET_FORM_SHOW_ERROR_REASONS,
  SET_FORM_ERROR_TITLE,
  SET_FORM_LOCKED,
} from "../../redux/reducers/general/actionTypes";
import apiManager from "../apiManager/apiManager";
import { getOr } from "lodash/fp";
import { SET_SIDEBAR_ASK_FOR_CONFIRMATION, SET_EDITOR_LOADING } from "../../redux/reducers/general/actionTypes";
import { mainStateKey } from "../../constants/constants";
import { getNameFromPlaceLineup, wait } from "./common";
import { entityDataManipulation } from "../../constants/entityDataManipulation";

export const dispatchToReduxStore = (name, type, payload, dispatch) => {
  if (name) {
    dispatch({ type: type, payload: payload, name: name });
  }
};

export const updateEventName = (lineup = [], place, dispatch, eventFormDataName, actionType) => {
  const name = getNameFromPlaceLineup(lineup, place);
  dispatchToReduxStore(eventFormDataName, actionType, name, dispatch);
};

export const showFormSuccess = async () => {
  store.dispatch({ type: SET_FORM_SUCCESS, payload: true });

  return wait(1500).then(() => {
    hideAppLoader();
    store.dispatch({ type: SET_FORM_SUCCESS, payload: false });
  });
};

export const showFormFail = () => {
  store.dispatch({ type: SET_FORM_FAIL, payload: true });

  setTimeout(() => {
    hideAppLoader();
    store.dispatch({ type: SET_FORM_FAIL, payload: false });
  }, 1500);
};

export const showFormErrorReasons = (e, parsedData, entityType) => {
  let formErrorReasons = e && e.response && e.response.data && e.response.data.details ? e.response.data.details : [];

  let errorDescription =
    e && e.response && e.response.data && e.response.data.error_description ? e.response.data.error_description : "";
  const httpStatusText = e && e.response && e.response.statusText ? e.response.statusText : "Something went wrong";
  errorDescription = errorDescription ? errorDescription : httpStatusText;

  if (e && e.response && e.response.status === 413) {
    errorDescription = "You sent too much data to the server. Try lowering the image file size";
  }

  formErrorReasons =
    formErrorReasons && formErrorReasons.length > 0
      ? formErrorReasons
      : [
          {
            domain: "Error",
            message: errorDescription,
          },
        ];

  store.dispatch({ type: SET_FORM_ERROR_TITLE, payload: "Error" });
  store.dispatch({ type: SET_FORM_ERROR_REASONS, payload: { reasons: formErrorReasons, parsedData, entityType } });
  store.dispatch({ type: SET_FORM_SHOW_ERROR_REASONS, payload: true });
  store.dispatch({ type: SET_FORM_SHOW_ERROR_WINDOW, payload: true });
};

export const hideFormErrorReasons = () => {
  store.dispatch({ type: SET_FORM_ERROR_REASONS, payload: null });
  store.dispatch({ type: SET_FORM_SHOW_ERROR_WINDOW, payload: false });
  store.dispatch({ type: SET_FORM_SHOW_ERROR_REASONS, payload: false });
};

export const clearFormData = () => {
  store.dispatch({ type: "CLEAR_FORM_DATA" });
};

export const setFormData = formData => {
  store.dispatch({ type: "SET_FORM_DATA", formData: formData });
};

export const handleAddFormSubmit = async (clearForm, dataManipulation, entityType, id, edition) => {
  const formData = getStateValue({}, "formData");
  const enums = getStateValue({}, "enums");
  const parsedData = dataManipulation.outward(formData, enums);

  hideFormErrorReasons();
  showAppLoader();

  return await apiManager
    .addEntity(entityType, parsedData, id, edition)
    .then(async () => {
      if (clearForm) {
        clearFormData();
      }
      await showFormSuccess();
      return true;
    })
    .catch(e => {
      console.warn(e);
      showFormFail();
      showFormErrorReasons(e, parsedData, entityType);
      return false;
    });
};

export const handleDeleteEntity = async (id, entityType, headers, edition = "", method = "DELETE", params = null) => {
  hideFormErrorReasons();
  showAppLoader();

  if (id) {
    return await apiManager
      .editEntity(id, edition, entityType, params, method, headers)
      .then(async () => {
        await showFormSuccess();
        return true;
      })
      .catch(e => {
        showFormFail();
        showFormErrorReasons(e);
        return false;
      });
  } else {
    showFormFail();
    return false;
  }
};

export const handleEditFormSubmit = async (
  id,
  dataManipulation,
  entityType,
  postMethod = "POST",
  edition,
  showSuccessAfterRequest = true,
  additionalID
) => {
  const formData = getStateValue({}, "formData");
  const enums = getStateValue({}, "enums");
  const parsedData = dataManipulation.outward(formData, enums);

  hideFormErrorReasons();
  showAppLoader();

  return await apiManager
    .editEntity(id, edition, entityType, parsedData, postMethod, null, additionalID)
    .then(async () => {
      if (showSuccessAfterRequest) {
        await setItemFormData(id, "", entityType, {}, false);
        await showFormSuccess();
      }

      return true;
    })
    .catch(e => {
      console.warn(e);
      showFormFail();
      showFormErrorReasons(e, parsedData, entityType);
      return false;
    });
};

export const setItemFormData = async (
  id,
  edition,
  entityType,
  headers,
  hideAppLoaderAfterSet = true,
  useInitialFormData = false,
  additionalID
) => {
  let formData = useInitialFormData ? getStateValue({}, "formData") : {};

  if (entityDataManipulation && entityDataManipulation[entityType] && entityDataManipulation[entityType].length > 0) {
    entityDataManipulation[entityType].map(async entity => {
      showAppLoader();
      let data = await apiManager.getEntity(entity.entityType, id, headers, edition, additionalID);

      if (data) {
        const locked = data.locked && data.locked === true ? true : false;

        if (locked) {
          store.dispatch({ type: SET_FORM_LOCKED, payload: true });
        }

        if (entity.path) {
          formData[entity.path] = entity.manipulatedDataValueKey
            ? entity.target.inwardItem(data)[entity.manipulatedDataValueKey]
            : entity.target.inwardItem(data);
        } else {
          formData = entity.target.inwardItem(data);
        }
      } else {
        store.dispatch({ type: SET_FORM_LOCKED, payload: true });
        setCustomFormError("Something went wrong", "Form Error", "Unable to receive necessary data.");
        formData = null;
      }

      setFormData(formData);
      if (hideAppLoaderAfterSet) {
        hideAppLoader();
      }
    });
  }
};

export const unlockForm = () => {
  store.dispatch({ type: SET_FORM_LOCKED, payload: false });
};

export const setCustomFormError = (title, type, message) => {
  store.dispatch({ type: SET_FORM_ERROR_TITLE, payload: title });
  store.dispatch({
    type: SET_FORM_ERROR_REASONS,
    payload: [{ domain: type, message: message }],
  });
  store.dispatch({ type: SET_FORM_SHOW_ERROR_REASONS, payload: true });
  store.dispatch({ type: SET_FORM_SHOW_ERROR_WINDOW, payload: true });
};

export const showAppLoader = () => {
  store.dispatch({ type: SET_EDITOR_LOADING, payload: true });
};

export const hideAppLoader = () => {
  store.dispatch({ type: SET_EDITOR_LOADING, payload: false });
};

export const resetSideBarAskForConfirmation = () => {
  store.dispatch({ type: SET_SIDEBAR_ASK_FOR_CONFIRMATION, payload: false });
};

export const getStateValue = (defaultValue = null, valuePath) => {
  return getOr(defaultValue, `${mainStateKey}.${valuePath}`, store.getState());
};

export const resetPageState = () => {
  clearFormData();
  hideFormErrorReasons();
  unlockForm();
  resetSideBarAskForConfirmation();
};

export const redirectTo = (routerHistory, pathname = "") => {
  if (routerHistory && pathname) {
    routerHistory.push(pathname);
  }
};
