import moment from "moment";
import { getOr, set } from "lodash/fp";
import { parseImage, capitalizeString, makeid, mapArray, thousandsSeparator, sortByKey } from "../../functions/common";
import commonDataManipulation from "../common/commonDataManipulation";

const tracKDataManipulation = () => {
  const getOutwardtrack = (data, enums) => {
    return {
      name: data.name ? data.name : "",
      image: data.image ? parseImage(data.image) : null,
      ISRC: getOr(null, "ISRC", data),
      status: data.status && data.status.name ? data.status.name.toLowerCase().replace(" ", "_") : null,
      release_date: getOr(null, "releaseDate", data) || null,
      genre_id: getOr(null, "genre.id", data),
      subgenre_ids: data.subgenres ? commonDataManipulation().subgenres.outward([...data.subgenres]) : [],
      label_uuid: getOr(null, "label.uuid", data),
      artist_uuids: data && data.artists ? data.artists.map(artist => artist.id || artist.uuid) : null,
      links: getOutwardLinkGroups(data, enums),
    };
  };

  const getInwardEdittrackItem = data => {
    let genre = getOr(null, "genre", data);
    let artistsGenre = getOr(null, "artists[0].genre", data);
    //Get genre from first artist
    genre = !genre ? artistsGenre : genre;

    let primaryLink = "";
    getOr([], "links", data).forEach(item => {
      if (item.primary) {
        primaryLink = item.url;
      }
    });

    return {
      oldName: data && data.name ? data.name : "",
      name: data && data.name ? data.name : "",
      label: getOr({}, "label", data),
      releaseDate: getOr({}, "release_date", data),
      status: data.status ? { name: capitalizeString(data.status).replace("_", " ") } : "",
      image: getOr({}, "image", data),
      artists: getOr([], "artists", data),
      genre: genre,
      oldGenre: genre,
      ISRC: getOr("", "ISRC", data),
      subgenres: data.subgenres ? commonDataManipulation().subgenres.inwardItem(data.subgenres) : [],
      oldSubgenres: data.subgenres ? commonDataManipulation().subgenres.inwardItem(data.subgenres) : [],
      links: getInwardLinkGroups(data),
      primaryLink: primaryLink,
    };
  };

  const getInwardDatatable = data => {
    let artistNames = [];
    const listItems = [];

    if (data && data.length) {
      data.forEach(item => {
        artistNames = [];

        if (item.artists) {
          artistNames = item.artists.map(artist => {
            return artist.name;
          });
        }

        listItems.push([
          item.name,
          item["ISRC"],
          item.release_date ? moment(item.release_date).format("YYYY-MM-DD") : "",
          item.status ? capitalizeString(item.status) : "",
          getOr("", "label.name", item),
          getOr("", "genre.name", item),
          commonDataManipulation().subgenres.inwardDatatable(item.subgenres),
          artistNames.join(", "),
          item.rank ? item.rank : "N/A",
          item.viberate_score ? thousandsSeparator(parseInt(Math.ceil(item.viberate_score))) : "N/A",
          item.spotify_streams ? thousandsSeparator(parseInt(item.spotify_streams)) : "N/A",
          item.created_at ? moment(item.created_at).format("YYYY-MM-DD") : "",
          item.updated_at ? moment(item.updated_at).format("YYYY-MM-DD") : "",
          item.uuid,
        ]);
      });
    }

    return listItems;
  };

  return {
    outward: getOutwardtrack,
    inwardItem: getInwardEdittrackItem,
    inwardDatatable: getInwardDatatable,
  };
};

const getOutwardLinkGroups = (data, enums) => {
  let links = [];
  let primaryLink = data.primaryLink;

  const channels = getOr([], "channels", enums);
  const mapChannels = channels && channels.length ? mapArray(channels, "name") : {};

  if (data.links && Object.keys(data.links).length) {
    for (const channelType in data.links) {
      let channelID;
      channelID = getOr(null, `${channelType}.channel.id`, data.links);
      channelID = channelID ? channelID : mapChannels[channelType].id;

      if (data.links[channelType].links) {
        data.links[channelType].links.forEach(item => {
          links.push({ url: item.link, channel_id: channelID, primary: item.link === primaryLink });
        });
      }
    }
  }

  return links;
};

const getInwardLinkGroups = data => {
  const linkGroup = getOr([], "links", data);
  let matchLinks = getOr([], "match_links", data);
  let parsedLinkGroup = {};

  let channelType;
  let url;
  let primary;
  let matched;

  if (linkGroup) {
    channelType = "";
    url = "";
    primary = false;

    linkGroup.forEach(item => {
      channelType = getOr("", "channel.name", item);
      url = item.url;
      primary = item.primary;

      if (channelType && url) {
        if (parsedLinkGroup[channelType] && parsedLinkGroup[channelType]["links"]) {
          parsedLinkGroup[channelType]["links"].push({ id: makeid(3), link: url, type: channelType, primary });
        } else {
          parsedLinkGroup = set(
            `${channelType}.links`,
            [{ id: makeid(3), link: url, type: channelType, primary }],
            parsedLinkGroup
          );
        }
      }
    });
  }

  if (matchLinks) {
    matchLinks = sortByKey(matchLinks, "engagement", false);
    channelType = "";
    url = "";

    matchLinks.forEach(item => {
      channelType = getOr("", "channel.name", item);
      url = item.link;
      primary = item.primary;

      if (channelType && url) {
        if (parsedLinkGroup[channelType] && parsedLinkGroup[channelType]["matchLinks"]) {
          parsedLinkGroup[channelType]["matchLinks"].push({ ...item, matched, primary });
        } else {
          parsedLinkGroup = set(`${channelType}.matchLinks`, [{ ...item, matched, primary }], parsedLinkGroup);
        }
      }
    });
  }

  return parsedLinkGroup;
};

export default tracKDataManipulation();
