import { com, account, editor } from "../../constants/apiCalls";
import { callApi } from "./base";
import { create_UUID } from "../functions/common";

const CLIENT_ID = "com-app";

export const refreshCookie = () => {
  return callApi(com.REFRESH_TOKEN, "GET");
};

export const getChallengeToken = () => {
  return callApi(account.CHALLENGE, "GET", { "app-id": CLIENT_ID });
};

export const signin = (authCode, bonusProps, accountCreated = false) => {
  return callApi(com.SIGNIN, "POST", { ...bonusProps, auth_code: authCode }).then(response => {
    if (response.data && response.data.data && response.data.data.token) {
      let userObject = response.data.data.user;
      return Promise.all([getUserPermissions()]).then(values => {
        userObject.accountCreated = accountCreated;
        userObject.permissions = values[0];
        return userObject;
      });
    }
  });
};

export const getUser = () => {
  return callApi(com.USER_PROFILE, "GET").then(response => {
    if (response.data && response.data.data) {
      let userObject = response.data.data;
      return Promise.all([getUserPermissions()]).then(values => {
        userObject.permissions = values[0];
        return userObject;
      });
    }
  });
};

export const getUserAllowedFapps = () => {
  return callApi(editor.GET_ALLOWED_FAPPS, "GET").then(response => {
    if (response.data && response.data.data) {
      return response.data.data;
    }
  });
};

export const getUserPermissions = () => {
  return callApi(account.USER_PERMISSIONS, "GET").then(r => {
    if (r.data && r.data.data) {
      return r.data.data;
    }
  });
};

export const authenticate = (username, password) => {
  return getChallengeToken().then(response => {
    if (response.data && response.data.data) {
      return callApi(account.AUTHENTICATE, "POST", {
        auth: {
          identity: username,
          secret: password,
        },

        challenge: response.data.data.challenge,
        csrf_token: response.data.data.csrf_token,
        client_id: CLIENT_ID,
        device: {
          id: create_UUID(),
          name: "Chrome",
        },
      }).then(response => {
        if (response.data && response.data.data && response.data.data.auth_code) {
          return signin(response.data.data.auth_code);
        } else {
          throw new Error({ response: { status: 500 } });
        }
      });
    } else {
      throw new Error({ response: { status: 500 } });
    }
  });
};

export const facebookLogin = accessToken => {
  return getChallengeToken().then(response => {
    if (response.data.data) {
      return callApi(account.FACEBOOK_LOGIN, "POST", {
        access_token: accessToken,
        challenge: response.data.data.challenge,
        csrf_token: response.data.data.csrf_token,
        client_id: CLIENT_ID,
        device: {
          id: create_UUID(),
          name: "Chrome",
        },
      }).then(response => {
        if (response.data && response.data.data) {
          return signin(
            response.data.data.auth_code,
            {
              account_consent: { privacy: true, tos: true },
              consent: { privacy: true, tos: true },
            },
            response.data.data.created
          );
        }
      });
    } else {
      throw new Error({ response: { status: 500 } });
    }
  });
};

export const signout = () => {
  return callApi(com.SIGNOUT, "POST");
};
