import React from "react";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import ArtistsTable from "../../../input/table/artistsTable";

const ArtistsBox = () => {
  return (
    <FormBox title="Artists">
      <div className="box-body" style={{ minHeight: "100px" }}>
        <div className="form-horizontal">
          <div className="relative">
            <ArtistsTable name="formData.artists" />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default connect(null)(ArtistsBox);
