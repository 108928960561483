import React, { useState } from "react";
import { GoogleMap, LoadScript, Autocomplete, Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";
import { apis } from "../../../constants/constants";

function LocationMap({
  height = "100%",
  setAutoCompleteObject,
  showMarker = false,
  showSearch = true,
  mapLoading = false,
  onMapClick = () => {},
  onPlaceChanged = () => {},
  lat = 46.0516,
  lng = 14.506005,
}) {
  const [libraries] = useState(["places"]);

  return (
    <div className={"gmap-container "}>
      <LoadScript id="script-loader" googleMapsApiKey={apis.GOOGLE_MAPS} libraries={libraries}>
        <div className={mapLoading ? "loading" : ""}>
          <GoogleMap
            mapContainerStyle={{
              height: height,
              width: "100%",
            }}
            id="google-map"
            center={{ lat: lat, lng: lng }}
            zoom={13}
            onClick={e => {
              if (mapLoading === false) {
                onMapClick(e.latLng.lat(), e.latLng.lng());
              }
            }}
            options={{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              clickableIcons: false,
            }}
            clickableIcons={false}
          >
            {showMarker && <Marker position={{ lat: lat, lng: lng }} />}
            {showSearch && (
              <div className="gmap-search">
                <Autocomplete
                  onLoad={autoCompleteObject => {
                    setAutoCompleteObject(autoCompleteObject);
                  }}
                  onPlaceChanged={() => {
                    onPlaceChanged();
                  }}
                >
                  <input type="text" placeholder="Find the location..." className="gmap-search-input" />
                </Autocomplete>
              </div>
            )}
            <div className="gmaps-search">
              <div className="relative" />
            </div>
          </GoogleMap>
        </div>
      </LoadScript>
    </div>
  );
}

LocationMap.propTypes = {
  height: PropTypes.string,
  setAutoCompleteObject: PropTypes.func,
  showMarker: PropTypes.bool,
  showSearch: PropTypes.bool,
  mapLoading: PropTypes.bool,
  onMapClick: PropTypes.func,
  onPlaceChanged: PropTypes.func,
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export default LocationMap;
