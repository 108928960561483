import React from "react";
import FormBox from "../../../presentational/page/formBox";
import Input from "../../../input/general/input";
import UploadImageButton from "../../../input/media/uploadImageButton";

const MetaTagsBox = () => {
  return (
    <FormBox title="Meta tags">
      <div className="box-body" style={{ paddingBottom: "30px" }}>
        <div className="form-horizontal">
          <div className="relative">
            <div className="form-group">
              <label className="col-sm-2 control-label">Title</label>
              <div className="col-sm-10 right-padding-110">
                <div className="input-group">
                  <Input className="form-control" name="formData.metaTagTitle" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">Description</label>
              <div className="col-sm-10 right-padding-110">
                <div className="input-group">
                  <Input className="form-control" name="formData.metaTagDescription" />
                </div>
              </div>
            </div>
            <div className="form-group absolute-top-right">
              <UploadImageButton name="formData.metaTagImage" title="Image" imagePreviewOrinetation="BL" />
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default MetaTagsBox;
