import React, { useState, useEffect, useMemo } from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { buildUrlFromTableParams } from "../../../../lib/functions/common";
import { mainStateKey, defaultListingTableRowNumber, enumsPath } from "../../../../constants/constants";

import FiltersBox from "./filtersBox";
import {
  setTableData,
  resetPageOnFilterChange,
  getColumnDefinitions,
  setEditButtonCustomRender,
} from "../../../../lib/functions/tableFunctions";
import { editFeedpostTableParametersData } from "../../../../constants/tableParametersData/editFeedpostTableParametersData";
import { usePrevious, useResetPageState } from "../../../../lib/functions/customHooks";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";
import { useHistory } from "react-router";
import ListingTable from "../../../presentational/table/listingTable";
import feedpostDataManipulation from "../../../../lib/dataManipulation/feedpost/feedpostDataManipulation";

const EditFeedpostListPage = ({ filters, sortData, row, page, dispatch, enums }) => {
  useResetPageState();

  const routerHistory = useHistory();
  const statePath = editFeedpostTableParametersData.statePath;
  const sortPath = editFeedpostTableParametersData.sortPath;
  const filtersPath = editFeedpostTableParametersData.filtersPath;
  const allColumns = editFeedpostTableParametersData.columns;
  const previousFilters = usePrevious(filters);
  const [tableRowData, setTableRowData] = useState([]);
  const [tablePaginationData, setTablePaginationData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTableData(
      setTableRowData,
      setTablePaginationData,
      setLoading,
      buildUrlFromTableParams(filters, sortData, editFeedpostTableParametersData, row, page),
      feedpostDataManipulation,
      "feedpost",
      null,
      null,
      enums
    );
  }, [filters, sortData, row, page, dispatch, enums]);

  resetPageOnFilterChange(previousFilters, filters, statePath, dispatch);
  let columns = useMemo(
    () => setColumnDefinitions(allColumns, sortPath, dispatch, statePath, routerHistory, tableRowData),
    [allColumns, dispatch, routerHistory, sortPath, statePath, tableRowData]
  );

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>
          Edit Feed Post &nbsp;
          <button
            className="btn btn-default btn-small"
            onClick={() => {
              dispatchToReduxStore(filtersPath, SET_STATE_VALUE, null, dispatch);
            }}
          >
            Clear filters
          </button>
        </h1>
      </section>
      <section className="content">
        <FiltersBox />
        <div className={loading ? "loading" : ""}>
          <div className="row">
            <div className="col-md-12">
              <ListingTable
                statePath={statePath}
                sortPath={sortPath}
                tablePaginationData={tablePaginationData}
                columnDefinitions={columns}
                tableRowData={tableRowData}
                columnsConstant={allColumns}
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const setColumnDefinitions = (allColumns, sortPath, dispatch, statePath, routerHistory) => {
  const columns = getColumnDefinitions(allColumns, sortPath);
  setEditButtonCustomRender(columns, dispatch, statePath, routerHistory, "/edit/feed-post/");
  return columns;
};

EditFeedpostListPage.propTypes = {
  filters: PropTypes.object,
  row: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sortData: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    enums: getOr(null, `${enumsPath}`, state),
    filters: getOr(null, `${mainStateKey}.${editFeedpostTableParametersData.filtersPath}`, state),
    sortData: getOr(null, `${mainStateKey}.${editFeedpostTableParametersData.sortPath}`, state),
    row: getOr(
      defaultListingTableRowNumber,
      `${mainStateKey}.${editFeedpostTableParametersData.statePath}.rows`,
      state
    ),
    page: getOr(0, `${mainStateKey}.${editFeedpostTableParametersData.statePath}.page`, state),
  };
};

export default connect(mapStateToProps)(EditFeedpostListPage);
