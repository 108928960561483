import React from "react";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import EntitySelector from "../../../input/selectors/entitySelector";
import VenueButton from "../../../presentational/general/venueButton";
import TextArea from "../../../input/general/textArea";
import { dispatchToReduxStore, updateEventName } from "../../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import UploadImageButton from "../../../input/media/uploadImageButton";
import DateTime from "../../../input/date/dateTime";
import { mainStateKey } from "../../../../constants/constants";
import { usePrevious } from "../../../../lib/functions/customHooks";
import Input from "../../../input/general/input";

const BasicInfoBox = ({ dispatch, lineup, venue, disableAutoEventName }) => {
  const prevLineup = usePrevious(lineup);
  const prevVenue = usePrevious(venue);

  if ((prevLineup || prevVenue) && !disableAutoEventName) {
    updateEventName(lineup, venue, dispatch, "formData.name", SET_STATE_VALUE);
  }

  return (
    <FormBox title="Basic Info">
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <div className="form-group">
              <label className="col-sm-3 control-label">Search Venue</label>
              <div className="col-sm-9 right-padding-110">
                <EntitySelector
                  label=""
                  placeholder="Search venue..."
                  entityType="venue"
                  name="formData.venueSearchText"
                  onChange={venue => {
                    if (venue.id) {
                      dispatchToReduxStore("formData.venue", SET_STATE_VALUE, venue, dispatch);
                    }
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Selected Venue</label>
              <div className="col-sm-9 right-padding-110">
                <VenueButton connectedTo="formData.venue" />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Date & Time</label>
              <div className="col-sm-9 right-padding-110">
                <div className="input-group">
                  <DateTime name="formData.dateTime" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Event Name</label>
              <div className="col-sm-9">
                <TextArea
                  name="formData.name"
                  placeholder="Event name"
                  className="form-control"
                  showClearInputButton={false}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Disable Auto Event Name</label>
              <div className="col-sm-9">
                <Input name="formData.disableAutoEventName" type="checkbox" showClearInputButton={false} />
              </div>
            </div>
            <div className="form-group absolute-top-right">
              <UploadImageButton
                title="Cover"
                name="formData.image"
                showModalOnPreviewButtonClick={true}
                enlargeImageOnPreviewHover={false}
              />
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

BasicInfoBox.propTypes = {
  dispatch: PropTypes.func,
  lineup: PropTypes.array,
  venue: PropTypes.object,
  initialEventName: PropTypes.string,
  disableAutoEventName: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    lineup: getOr(null, `${mainStateKey}.formData.lineup`, state),
    venue: getOr(null, `${mainStateKey}.formData.venue`, state),
    disableAutoEventName: getOr(null, `${mainStateKey}.formData.disableAutoEventName`, state),
  };
};

export default connect(mapStateToProps)(BasicInfoBox);
