import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import MultiSelector from "../../../input/selectors/multiSelector";
import { enumsPath, venueStatus, yesNoStatus } from "../../../../constants/constants";
import CurrentlySelectedFiltersList from "../../../presentational/general/currentlySelectedFiltersList";
import Input from "../../../input/general/input";
import DateRange from "../../../input/date/dateRange";
import { editVenueTableParametersData } from "../../../../constants/tableParametersData/editVenueTableParametersData";
import Select from "../../../input/selectors/select";

const FiltersBox = ({ countries, venueTypes }) => {
  const filtersPath = editVenueTableParametersData.filtersPath;
  return (
    <FormBox title="Filters" style={{ zIndex: "999" }}>
      <div className="box-body">
        <table className="filters-table">
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <small className="label">Updated from-to</small>
              </td>
              <td>
                <small className="label">Highlights from-to</small>
              </td>
              <td>
                <small className="label">Events from-to</small>
              </td>
              <td>
                <small className="label">Media from-to</small>
              </td>
              <td>
                <small className="label">Open Hours</small>
              </td>
              <td>
                <small className="label">Has email/phone</small>
              </td>
              <td>
                <small className="label">Claimed</small>
              </td>
            </tr>
            <tr>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input placeholder="Search venue..." className="form-control" name={`${filtersPath}.venueName`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Country lookup filter"
                    data={countries}
                    displayKey="name"
                    idKey="code"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.country`}
                  />
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="City lookup filter"
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    searchOnBackend={true}
                    backendSearchEntity={["city"]}
                    name={`${filtersPath}.city`}
                  />
                </div>
              </td>
              <td style={{ width: "200px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      placeholder="Search address..."
                      className="form-control"
                      name={`${filtersPath}.venueAddress`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "100px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Type"
                    data={venueTypes}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.type`}
                  />
                </div>
              </td>
              <td style={{ width: "100px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Status"
                    data={venueStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.openClosedDeleted`}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.lastUpdated`} />
                  </div>
                </div>
              </td>
              <td className="center">
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      className="form-control"
                      placeholder={"Min"}
                      type="number"
                      name={`${filtersPath}.highlights.min`}
                    />
                    <Input
                      className="form-control"
                      placeholder={"Max"}
                      type="number"
                      name={`${filtersPath}.highlights.max`}
                    />
                  </div>
                </div>
              </td>
              <td className="center">
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      className="form-control"
                      placeholder={"Min"}
                      type="number"
                      name={`${filtersPath}.activeEvents.min`}
                    />
                    <Input
                      className="form-control"
                      placeholder={"Max"}
                      type="number"
                      name={`${filtersPath}.activeEvents.max`}
                    />
                  </div>
                </div>
              </td>
              <td className="center">
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      className="form-control"
                      placeholder={"Min"}
                      type="number"
                      name={`${filtersPath}.media.min`}
                    />
                    <Input
                      className="form-control"
                      placeholder={"Max"}
                      type="number"
                      name={`${filtersPath}.media.max`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "80px" }}>
                <div className="form-group">
                  <Select idKey={"id"} displayKey="name" name={`${filtersPath}.openHours`} data={yesNoStatus} />
                </div>
              </td>
              <td style={{ width: "80px" }}>
                <div className="form-group">
                  <Select idKey={"id"} displayKey="name" name={`${filtersPath}.contactMailPhone`} data={yesNoStatus} />
                </div>
              </td>
              <td style={{ width: "80px" }}>
                <div className="form-group">
                  <Select data={yesNoStatus} displayKey="name" idKey="id" name={`${filtersPath}.claimed`} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="row">
          <div className="col-md-12">
            <CurrentlySelectedFiltersList
              tableParamsData={editVenueTableParametersData}
              connectedTo={editVenueTableParametersData.filtersPath}
            />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

FiltersBox.propTypes = {
  countries: PropTypes.array,
  subgenres: PropTypes.array,
  dispatch: PropTypes.func,
  genres: PropTypes.array,
};

const mapStateToProps = state => ({
  countries: getOr(null, `${enumsPath}.countries`, state),
  subgenres: getOr(null, `${enumsPath}.subgenres`, state),
  genres: getOr(null, `${enumsPath}.genres`, state),
  venueTypes: getOr(null, `${enumsPath}.capacity_types.venue`, state),
});

export default connect(mapStateToProps)(FiltersBox);
