import React, { useEffect } from "react";
import GeneralInfoBox from "../shared/generalInfoBox";
import Footer from "../../../presentational/page/footer";
import {
  handleDeleteEntity,
  handleEditFormSubmit,
  redirectTo,
  setItemFormData,
} from "../../../../lib/functions/componentFunctions";
import { useHistory, useParams } from "react-router";
import webinarDataManipulation from "../../../../lib/dataManipulation/webinar/webinarDataManipulation";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const EditWebinar = () => {
  useResetPageState();

  const routerHistory = useHistory();
  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "webinar");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit Webinar</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <GeneralInfoBox />
          </div>
        </div>
        <Footer
          showClearButton={false}
          showDeleteButton={true}
          onDelete={async () => {
            if (window.confirm("Are you sure you want to delete this event?")) {
              const deleteSuccess = await handleDeleteEntity(id, "webinar");

              if (deleteSuccess) {
                redirectTo(routerHistory, "/add/matchedEvent/");
              }
            }
          }}
          onSave={() => {
            handleEditFormSubmit(id, webinarDataManipulation, "webinar", "PUT", null);
          }}
        />
      </section>
    </React.Fragment>
  );
};

export default EditWebinar;
