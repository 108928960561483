export const addArtistLinks = {
  mainLinks: [
    {
      id: 16,
      name: "spotify",
      placeholder: "Spotify",
      isImportant: true,
    },
    {
      id: 25,
      name: "bandcamp",
      placeholder: "Bandcamp",
      isImportant: true,
    },
    {
      id: 4,
      name: "soundcloud",
      placeholder: "Soundcloud",
      isImportant: true,
    },
    {
      id: 24,
      name: "deezer",
      placeholder: "Deezer",
    },
    {
      id: 11,
      name: "itunes",
      placeholder: "iTunes",
      isImportant: true,
    },
    {
      id: 13,
      name: "shazam",
      placeholder: "Shazam",
      isImportant: true,
    },
    {
      id: 2,
      name: "facebook",
      placeholder: "Facebook",
      isImportant: true,
    },
    {
      id: 3,
      name: "twitter",
      placeholder: "Twitter",
      isImportant: true,
    },
    {
      id: 12,
      name: "instagram",
      placeholder: "Instagram",
      isImportant: true,
    },
    {
      id: 31,
      name: "tiktok",
      placeholder: "TikTok",
    },
    {
      id: 5,
      name: "youtube",
      placeholder: "Youtube",
      isImportant: true,
    },
    {
      id: 1,
      name: "home_page",
      placeholder: "Artist's website",
      isImportant: true,
    },
    {
      id: 9,
      name: "songkick",
      placeholder: "Songkick",
    },
    {
      id: 7,
      name: "beatport",
      placeholder: "Beatport",
    },
    {
      id: 8,
      name: "mixcloud",
      placeholder: "Mixcloud",
    },
    {
      id: 10,
      name: "bandsintown",
      placeholder: "Bandsintown",
    },
    {
      id: 26,
      name: "tidal",
      placeholder: "Tidal",
    },
    {
      id: 28,
      name: "napster",
      placeholder: "Napster",
    },
    {
      id: 29,
      name: "amazon_music",
      placeholder: "Amazon Music",
    },
    {
      id: 30,
      name: "youtube_vevo",
      placeholder: "Youtube Vevo",
    },
  ],
};

export const addVenueLinks = {
  mainLinks: [
    {
      id: 2,
      name: "facebook",
      placeholder: "Facebook",
    },
    {
      id: 3,
      name: "twitter",
      placeholder: "Twitter",
    },
    {
      id: 12,
      name: "instagram",
      placeholder: "Instagram",
    },
    {
      id: 5,
      name: "youtube",
      placeholder: "Youtube",
    },
    {
      id: 1,
      name: "home_page",
      placeholder: "Homepage",
    },
    {
      id: 18,
      name: "ticketmaster",
      placeholder: "Ticketmaster",
    },
    {
      id: 9,
      name: "songkick",
      placeholder: "Songkick",
    },
    {
      id: 10,
      name: "bandsintown",
      placeholder: "Bandsintown",
    },
  ],
};

export const addFestivalLinks = {
  mainLinks: [
    {
      id: 1,
      name: "home_page",
      placeholder: "Homepage",
      isImportant: true,
    },
    {
      id: 2,
      name: "facebook",
      placeholder: "Facebook",
      isImportant: true,
    },
    {
      id: 3,
      name: "twitter",
      placeholder: "Twitter",
      isImportant: true,
    },
    {
      id: 12,
      name: "instagram",
      placeholder: "Instagram",
      isImportant: true,
    },
    {
      id: 5,
      name: "youtube",
      placeholder: "Youtube",
      isImportant: true,
    },
    {
      id: 16,
      name: "spotify",
      placeholder: "Spotify",
      isImportant: true,
    },
    {
      id: 24,
      name: "deezer",
      placeholder: "Deezer",
      isImportant: true,
    },
  ],
  festivalAppLeft: [
    {
      id: 1,
      name: "home_page",
      placeholder: "Homepage",
    },
    {
      id: 5,
      name: "youtube",
      placeholder: "Youtube",
    },
    {
      id: 16,
      name: "spotify",
      placeholder: "Spotify",
    },
    {
      id: 24,
      name: "deezer",
      placeholder: "Deezer",
    },
  ],

  festivalAppRight: [
    {
      id: 2,
      name: "facebook",
      placeholder: "Facebook",
    },
    {
      id: 3,
      name: "twitter",
      placeholder: "Twitter",
    },
    {
      id: 12,
      name: "instagram",
      placeholder: "Instagram",
    },
  ],
};

export const addLabelLinks = {
  mainLinks: [
    {
      id: 4,
      name: "soundcloud",
      placeholder: "Soundcloud",
    },
    {
      id: 2,
      name: "facebook",
      placeholder: "Facebook",
    },
    {
      id: 3,
      name: "twitter",
      placeholder: "Twitter",
    },
    {
      id: 12,
      name: "instagram",
      placeholder: "Instagram",
    },
    {
      id: 31,
      name: "tiktok",
      placeholder: "TikTok",
    },
    {
      id: 5,
      name: "youtube",
      placeholder: "Youtube",
    },
    {
      id: 1,
      name: "home_page",
      placeholder: "Labels's website",
    },
    {
      id: 7,
      name: "beatport",
      placeholder: "Beatport",
    },
  ],
};
