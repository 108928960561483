export const editFeedpostTableParametersData = {
  statePath: "tables.editFeedpostTableParams",
  filtersPath: "tables.editFeedpostTableParams.filters",
  sortPath: "tables.editFeedpostTableParams.sort",

  columns: [
    {
      name: "feedpostTitle",
      label: "Title",
      filterDefinition: {
        backendFieldValue: {
          title: "title",
        },
      },
    },
    {
      name: "feedpostLead",
      label: "Lead",
      filterDefinition: {
        backendFieldValue: {
          lead: "lead",
        },
      },
    },
    {
      name: "article",
      hideInTable: true,
      filterDefinition: {
        backendFieldValue: {
          article_id: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "feedpostDate",
      label: "Feed post start Date",
      sortBackendName: "start",
      filterDefinition: {
        backendFieldValue: {
          "start-from": "startDateTimeTZ",
          "start-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "status",
      label: "Status",
      sortBackendName: "status",
      filterDefinition: {
        backendFieldValue: {
          status: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "userStatus",
      label: "User Status",
      filterDefinition: {
        backendFieldValue: {
          user_status: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "type",
      label: "Type",
      filterDefinition: {
        backendFieldValue: {
          type: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "genres",
      label: "Genres",
      filterDefinition: {
        backendFieldValue: {
          genres: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "dateAdded",
      label: "Date Added",
      sortBackendName: "created",
      filterDefinition: {
        backendFieldValue: {
          "created-from": "startDateTimeTZ",
          "created-to": "endDateTimeTZ",
        },
      },
    },
    {
      name: "lastChange",
      label: "Last Change",
      sortBackendName: "updated",
      filterDefinition: {
        backendFieldValue: {
          "updated-from": "startDateTimeTZ",
          "updated-to": "endDateTimeTZ",
        },
      },
    },
    { name: "edit", label: "Edit" },
  ],
};
