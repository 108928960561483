import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import apiManager from "../../../../../lib/apiManager/apiManager";
import { getTableTheme } from "../../../../../lib/functions/tableFunctions";
import { Link } from "react-router-dom";
import helpCenterCategoryDataManipulation from "../../../../../lib/dataManipulation/helpCenter/categories/helpCenterCategoryDataManipulation";
import { useResetPageState } from "../../../../../lib/functions/customHooks";

const EditHelpCenterCategoriesListPage = () => {
  useResetPageState();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    "Name",
    {
      name: "Subcategories",
      options: {
        sort: false,
        customBodyRender: value => getSubcategoriesJsx(value),
        setCellProps: () => {
          return {
            className: "w30",
          };
        },
      },
    },
    "Order",
    {
      name: "Edit",
      options: {
        customBodyRender: value => (
          <Link to={"/edit/help-center-category/" + value} className="btn btn-default fa fa-edit" />
        ),
      },
    },
  ];

  const datatableOptions = {
    selectableRows: "none",
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: "stacked",
  };

  useEffect(() => {
    setHelpCenterCategoriesListData(setData, setLoading);
  }, []);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit help center categories</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="timetable">
              <MuiThemeProvider theme={getTableTheme()}>
                <MUIDataTable
                  className={loading ? "loading" : ""}
                  title={"Categories"}
                  data={data}
                  columns={columns}
                  options={datatableOptions}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const setHelpCenterCategoriesListData = async (setData, setLoading) => {
  const data = await apiManager.getEntityList("helpCenterCategories");
  setData(helpCenterCategoryDataManipulation.inwardDatatable(data));
  setLoading(false);
  return [];
};

const getSubcategoriesJsx = subcategories => {
  let parsedData = [];

  if (subcategories && subcategories.length > 0) {
    subcategories.forEach((subcategory, index) => {
      parsedData.push(
        <span key={index} className="tag">
          {subcategory.name ? subcategory.name : ""}
        </span>
      );
    });
  }

  return (
    <React.Fragment>
      <div className="tags-list">{parsedData}</div>
    </React.Fragment>
  );
};

export default EditHelpCenterCategoriesListPage;
