import React from "react";
import { PropTypes } from "prop-types";
import FormBox from "../../../presentational/page/formBox";
import ImageGallery from "../../../input/media/imageGallery";
import VideoGallery from "../../../input/media/videoGallery";
import { connect } from "react-redux";

const MediaBox = () => {
  return (
    <FormBox title="Media">
      <div className="box-body">
        <ImageGallery name="formData.mediaImages" />
        <VideoGallery
          name="formData.mediaVideos"
          addVideoColumnWidth="6"
          videoGalleryColumnWidth="6"
          clearSelectAfterAdd={false}
          videoTypeSelectorDisabled={true}
        />
      </div>
    </FormBox>
  );
};

MediaBox.propTypes = {
  dispatch: PropTypes.func,
  mediaTypes: PropTypes.array,
};

export default connect(null)(MediaBox);
