import React from "react";
import FormBox from "../../../presentational/page/formBox";
import TextArea from "../../../input/general/textArea";

const DescriptionBox = () => {
  return (
    <FormBox title="Description">
      <div className="box-body">
        <div className="form-group">
          <label>Description</label>
          <TextArea
            name="formData.description"
            placeholder="Enter..."
            className="form-control"
            showClearInputButton={false}
          />
        </div>
      </div>
    </FormBox>
  );
};

export default DescriptionBox;
