import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import Select from "../selectors/select";
import LocationMap from "./locationMap";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { findObjectByValue, searchByString } from "../../../lib/functions/common";
import { mainStateKey, enumsPath } from "../../../constants/constants";
import Input from "../../input/general/input";
import EntitySelector from "../selectors/entitySelector";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";

const Location = ({
  value,
  countries,
  mapOrientation = "right",
  dispatch,
  name,
  showAddress = false,
  showCountry = true,
  gmapsHeight,
}) => {
  const [autoCompleteObject, setAutoCompleteObject] = useState({});
  const [mapLoading, setMapLoading] = useState(false);
  const [autoPlaceChanged, setAutoPlaceChanged] = useState(true);

  let coordinates = getOr(undefined, "coordinates", value);
  coordinates = coordinates && typeof coordinates === "string" ? coordinates.split(",") : null;

  let lat = getOr(undefined, "[0]", coordinates);
  let lng = getOr(undefined, "[1]", coordinates);
  lat = lat ? parseFloat(lat) : undefined;
  lng = lng ? parseFloat(lng) : undefined;

  const locationInputs = getLocationDataInputs(countries, name, setAutoPlaceChanged, showAddress, showCountry);
  const googleMap = getGoogleMapInput(
    name,
    dispatch,
    mapLoading,
    setMapLoading,
    countries,
    lat,
    lng,
    autoCompleteObject,
    setAutoCompleteObject,
    setAutoPlaceChanged,
    gmapsHeight
  );

  useEffect(() => {
    if (!autoPlaceChanged) {
      updateLocationStateOnCoordinatesChange(lat, lng, countries, autoCompleteObject, name, dispatch, setMapLoading);
    }
  }, [lat, lng, countries, autoCompleteObject, dispatch, name, autoPlaceChanged]);

  return (
    <React.Fragment>
      {mapOrientation === "bottom" && (
        <React.Fragment>
          {locationInputs} {googleMap}
        </React.Fragment>
      )}
      {mapOrientation === "right" && (
        <React.Fragment>
          <div className="col-md-6">{locationInputs}</div>
          <div className="col-md-6">{googleMap}</div>
        </React.Fragment>
      )}
      {mapOrientation === "left" && (
        <React.Fragment>
          <div className="col-md-6">{googleMap}</div>
          <div className="col-md-6">{locationInputs}</div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const getGoogleMapInput = (
  name,
  dispatch,
  mapLoading,
  setMapLoading,
  countries,
  lat,
  lng,
  autoCompleteObject,
  setAutoCompleteObject,
  setAutoPlaceChanged,
  gmapsHeight
) => {
  return (
    <div className="form-group" style={{ height: gmapsHeight }}>
      <LocationMap
        onMapClick={(lat, lng) => {
          setAutoPlaceChanged(false);
          dispatchToReduxStore(`${name}.coordinates`, SET_STATE_VALUE, lat + "," + lng, dispatch);
        }}
        onPlaceChanged={async () => {
          setAutoPlaceChanged(true);
          setMapLoading(true);
          await onGoogleMapPlaceChange(autoCompleteObject, countries, name, dispatch);
          setMapLoading(false);
        }}
        mapLoading={mapLoading}
        lat={lat}
        lng={lng}
        autoCompleteObject={autoCompleteObject}
        setAutoCompleteObject={setAutoCompleteObject}
        showMarker={true}
        height={gmapsHeight}
      />
    </div>
  );
};

const getLocationDataInputs = (countries, name, setAutoPlaceChanged, showAddress, showCountry) => {
  return (
    <div className="form-horizontal">
      {showAddress && (
        <div className="form-group">
          <label className="col-sm-3 control-label">Address</label>
          <div className="col-sm-9">
            <div className="input-group">
              <Input className="form-control" type="text" placeholder="Enter Address" name={`${name}.address`} />
            </div>
          </div>
        </div>
      )}
      <div className="form-group">
        <label className="col-sm-3 control-label">City</label>
        <div className="col-sm-9">
          <div className="input-group">
            <EntitySelector
              placeholder="Search for city"
              entityType="city"
              name={`${name}.city`}
              showAddNewButton={false}
              removeOnSelect={false}
            />
          </div>
        </div>
      </div>
      {showCountry && (
        <div className="form-group">
          <label className="col-sm-3 control-label">Country</label>
          <div className="col-sm-9">
            <div className="input-group">
              <Select
                placeholder="Select country"
                data={countries}
                displayKey="name"
                idKey="code"
                name={`${name}.country`}
                autocomplete={true}
              />
            </div>
          </div>
        </div>
      )}
      <div className="form-group">
        <label className="col-sm-3 control-label">Coordinates</label>
        <div className="col-sm-9">
          <div className="input-group">
            <Input
              className="form-control"
              name={`${name}.coordinates`}
              placeholder="Longitude, Latitude"
              showRedirectButton={true}
              onRedirectButtonClick={inputValue => {
                if (inputValue) {
                  window.open("https://www.google.com/maps/search/?api=1&query=" + inputValue);
                }
              }}
              onChange={() => {
                setAutoPlaceChanged(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const onGoogleMapPlaceChange = async (autocompleteObject, countries, name, dispatch) => {
  if (autocompleteObject) {
    const place = autocompleteObject.getPlace();
    const locationData = await getLocationObject(autocompleteObject, countries, place, null, null);
    dispatchToReduxStore(name, SET_STATE_VALUE, locationData, dispatch);
  }
};

const getLocationObject = async (autocompleteObject, countries, placeObject, lat = null, lng = null) => {
  let locationObject;

  if (placeObject) {
    const addressComponents = placeObject.address_components;

    if (placeObject.geometry && placeObject.geometry.location) {
      lat = lat || placeObject.geometry.location.lat();
      lng = lng || placeObject.geometry.location.lng();
    }

    const address = placeObject.adr_address ? parseAddress(autocompleteObject) : "";

    let country = getGoogleMapsEntity("country", addressComponents);
    country = findObjectByValue(countries, "code", country);

    let city = getGoogleMapsEntity("city", addressComponents);
    city = await getCityObject(city, country);

    locationObject = {
      coordinates: lat + "," + lng,
      city: city,
      address: address,
      country: country,
    };
  }

  return locationObject;
};

const getCityObject = async (searchString, country) => {
  const cityObject = {
    id: null,
    name: "",
  };

  if (searchString) {
    const cities = await searchByString(searchString, ["city"]);

    if (cities && cities.length > 0) {
      for (let city of cities) {
        if (country && country.code && city.id && city.country_alpha2 === country.code) {
          cityObject.id = city.id;
          cityObject.name = city.name;
          break;
        }
      }
    }
  }

  return cityObject;
};

const getGoogleMapsEntity = (type, addressComponents) => {
  let gmapsLookForTypes;
  let entity = "";

  switch (type) {
    case "country":
      gmapsLookForTypes = ["country"];
      break;
    case "city":
      gmapsLookForTypes = ["postal_town", "locality", "city", "administrative_area_level_1"];
      break;
    default:
      break;
  }

  if (addressComponents && addressComponents.length > 0 && gmapsLookForTypes) {
    addressComponents.forEach(element => {
      if (element.types && element.types.length > 0) {
        element.types.forEach(componentType => {
          if (gmapsLookForTypes.includes(componentType)) {
            entity = type === "country" ? element.short_name : element.long_name;
          }
        });
      }
    });
  }

  return entity;
};

const parseAddress = autocompleteObject => {
  let address = "";

  if (autocompleteObject) {
    const place = autocompleteObject.getPlace();
    const pattern = /<span class=.street-address.*?<\/span>/;
    let regexMatch = place.adr_address.match(pattern);
    address = regexMatch ? regexMatch[0] : "";
    address = address.replace('<span class="street-address">', "").replace("</span>", "");
  }

  return address;
};

const updateLocationStateOnCoordinatesChange = async (
  lat,
  lng,
  countries,
  autocompleteObject,
  name,
  dispatch,
  setMapLoading
) => {
  setMapLoading(true);
  let locationData;

  try {
    locationData = await getGoogleMapsInfoFromCoordinates(lat, lng, countries, autocompleteObject);
  } catch (e) {
    console.warn(e);
  }

  if (locationData) {
    dispatchToReduxStore(name, SET_STATE_VALUE, locationData, dispatch);
  }

  setMapLoading(false);
};

const getGoogleMapsInfoFromCoordinates = (lat, lng, countries, autocompleteObject) => {
  if (lat && lng) {
    const coordinatesObject = { lat: lat, lng: lng };

    if (window && window.google && window.google.maps && window.google.maps.Geocoder) {
      const geocoder = new window.google.maps.Geocoder();

      return new Promise(function(resolve, reject) {
        geocoder.geocode({ location: coordinatesObject }, function(results, status) {
          if (status === "OK") {
            if (results && results.length > 0) {
              const locationData = getLocationObject(autocompleteObject, countries, results[0], lat, lng);

              if (locationData) {
                resolve(locationData);
              }
            }

            reject("Received no information from google maps. Try typing correct coordinates");
          } else {
            reject("Received no information from google maps. Try typing correct coordinates");
          }
        });
      });
    }
  }
};

Location.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  mapOrientation: PropTypes.string,
  countries: PropTypes.array,
  dispatch: PropTypes.func,
  showAddress: PropTypes.bool,
  showCountry: PropTypes.bool,
  gmapsHeight: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getOr(null, `${mainStateKey}.${ownProps.name}`, state),
    countries: getOr(null, `${enumsPath}.countries`, state),
  };
};

export default connect(mapStateToProps)(Location);
