import React from "react";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import Location from "../../../input/location/location";

const LocationBox = () => {
  return (
    <FormBox title="Location">
      <div className="box-body">
        <Location
          name="formData.location"
          showAddress={true}
          showCountry={false}
          mapOrientation={"bottom"}
          gmapsHeight="150px"
        />
      </div>
    </FormBox>
  );
};

export default connect(null)(LocationBox);
