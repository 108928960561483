import React from "react";
import { PropTypes } from "prop-types";
import GeneralInfoBox from "../shared/generalInfoBox";
import Footer from "../../../presentational/page/footer";
import { handleAddFormSubmit } from "../../../../lib/functions/componentFunctions";
import webinarDataManipulation from "../../../../lib/dataManipulation/webinar/webinarDataManipulation";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const AddWebinar = () => {
  useResetPageState();

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add Webinar</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <GeneralInfoBox />
          </div>
        </div>
        <Footer
          onSave={() => handleAddFormSubmit(false, webinarDataManipulation, "webinar")}
          onSaveAndClear={() => {
            handleAddFormSubmit(true, webinarDataManipulation, "webinar");
          }}
        />
      </section>
    </React.Fragment>
  );
};

AddWebinar.propTypes = {
  dispatch: PropTypes.func,
};

export default AddWebinar;
