import React from "react";
import { PropTypes } from "prop-types";

const RedirectButton = ({ onClick = () => {} }) => {
  return (
    <span className="input-group-btn">
      <button
        type="button"
        className="btn btn-default"
        onClick={() => {
          onClick();
        }}
      >
        <i className="fa fa-external-link" />
      </button>
    </span>
  );
};

RedirectButton.propTypes = {
  onClick: PropTypes.func,
};

export default RedirectButton;
