import React from "react";
import FormBox from "../../../presentational/page/formBox";
import ArticleTags from "../../../input/article/articleTags";

const TagsBox = () => {
  return (
    <FormBox title="Tags">
      <div className="box-body">
        <label>Add Tags</label>
        <ArticleTags name="formData.tagData" />
      </div>
    </FormBox>
  );
};

export default TagsBox;
