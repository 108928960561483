import React, { useState } from "react";
import { PropTypes } from "prop-types";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import apiManager from "../../../lib/apiManager/apiManager";
import { connect } from "react-redux";
import { SET_USER_DATA } from "../../../redux/reducers/general/actionTypes";
import { apis } from "../../../constants/constants";

const Login = ({ userIsLoggedIn, userIsPermitted, setUserIsLoggedIn = () => {}, dispatch }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorString, setErrorString] = useState("");
  const [loading, setLoading] = useState(false);

  const initialErrorMessage =
    userIsLoggedIn === true && userIsPermitted === false ? "You don't have access permissions for Veditor." : "";

  return (
    <div className={"modal registration-form open " + (loading ? "loading" : "")}>
      <article className="modal-content">
        <header className="modal-header"></header>
        <aside className="modal-aside">
          <div className="aside-page">
            <h2>Log in to Viberate</h2>
            <p>Become a part of biggest music comunity and get started with your music discovery!</p>
          </div>
        </aside>
        <div className="modal-main">
          <div className="modal-scroller" id="modal">
            <div className="chapter chapter-1 login active" id="sec-1">
              <div className="page">
                <div className="form-article">
                  {!userIsLoggedIn && (
                    <React.Fragment>
                      <h2>Login with</h2>
                      <div className="buttons">
                        <FacebookLogin
                          appId={apis.FACEBOOK_APP_ID}
                          autoload
                          render={facebookClick => (
                            <button className="btn btn-circle facebook" onClick={facebookClick.onClick}>
                              Facebook
                            </button>
                          )}
                          callback={response => {
                            handleFacebookLogin(response, setLoading, setUserIsLoggedIn, setErrorString);
                          }}
                        />
                      </div>
                      <span>or continue manually</span>
                    </React.Fragment>
                  )}
                  <fieldset>
                    <div className="error-msg show">{initialErrorMessage ? initialErrorMessage : errorString}</div>
                    {!userIsLoggedIn && (
                      <React.Fragment>
                        <div className="form-row">
                          <div className="custom-input">
                            <input
                              type="email"
                              placeholder="Email"
                              value={email}
                              onChange={e => {
                                setEmail(e.target.value);
                              }}
                            />
                            <label>Email</label>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="custom-input">
                            <input
                              type="password"
                              placeholder="Password"
                              value={password}
                              onChange={e => {
                                setPassword(e.target.value);
                              }}
                            />
                            <label>Password</label>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    <div className="form-row">
                      {!userIsLoggedIn && (
                        <button
                          type="button"
                          className="btn btn-shadow btn-submit-registration"
                          onClick={() => {
                            handleLogin(email, password, setErrorString, setLoading, setUserIsLoggedIn);
                          }}
                        >
                          Login
                        </button>
                      )}
                      {userIsLoggedIn && (
                        <button
                          type="button"
                          className="btn btn-shadow btn-submit-registration"
                          onClick={() => {
                            handleLogout(dispatch, setUserIsLoggedIn, setErrorString, setLoading);
                          }}
                        >
                          Logout
                        </button>
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

const handleLogout = async (dispatch, setUserIsLoggedIn, setErrorString) => {
  setUserIsLoggedIn(false);
  apiManager
    .signout()
    .then(() => {
      dispatch({ type: SET_USER_DATA, payload: null });
    })
    .catch(e => {
      setErrorString("Unable to logout");
      console.warn(e);
    });
};

const handleLogin = (email, password, setErrorString, setLoading, setUserIsLoggedIn) => {
  setErrorString("");
  if (!email || !password) {
    setErrorString("Please insert email and password.");
    return;
  }

  setLoading(true);

  apiManager
    .authenticate(email, password)
    .then(() => {
      setLoading(false);
      setUserIsLoggedIn(true);
    })
    .catch(e => {
      if (!e.response) {
        setErrorString("Something went wrong. Please try again later.");
        setLoading(false);
        return;
      }
      let statusCode = e.response.status;
      switch (statusCode) {
        case 299:
          setErrorString("Something went wrong when account was created. Please contact support.");
          break;
        case 400:
          setErrorString("Please refresh the page and try again.");
          break;
        case 401:
        case 422:
          setErrorString("Combination of email and password is invalid.");
          break;
        default:
          setErrorString("Something went wrong. Please try again later.");
          break;
      }
      setLoading(false);
    });
};

const handleFacebookLogin = (response, setLoading, setUserIsLoggedIn, setErrorString) => {
  setErrorString("");
  setLoading(true);

  if (response && response.accessToken) {
    apiManager
      .facebookLogin(response.accessToken)
      .then(() => {
        setLoading(false);
        setUserIsLoggedIn(true);
      })
      .catch(e => {
        if (!e.response) {
          setErrorString("Something went wrong. Please try again later.");
          return;
        }

        setLoading(false);
      });
  }
  setLoading(false);
};

Login.propTypes = {
  initialErrorMessage: PropTypes.string,
  userIsLoggedIn: PropTypes.bool,
  dispatch: PropTypes.func,
  userIsPermitted: PropTypes.bool,
};

export default connect(null)(Login);
