import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import GeneralInfoBox from "../shared/generalInfoBox";
import Footer from "../../../presentational/page/footer";
import SubgenresBox from "../shared/subgenresBox";
import artistDataManipulation from "../../../../lib/dataManipulation/artist/artistDataManipulation";
import {
  handleEditFormSubmit,
  setItemFormData,
  handleDeleteEntity,
  redirectTo,
} from "../../../../lib/functions/componentFunctions";
import LinksBox from "../shared/linksBox";
import { connect } from "react-redux";
import ClaimedImage from "../../../presentational/general/claimedImage";
import OpenChangeHistory from "../../../presentational/buttons/openChangeHistory";
import { useResetPageState } from "../../../../lib/functions/customHooks";

const EditArtist = () => {
  useResetPageState();

  const routerHistory = useHistory();
  let { id } = useParams();

  useEffect(() => {
    setItemFormData(id, "", "artist");
  }, [id]);

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>
          Edit Artist
          <ClaimedImage connectedTo="formData.claimed" />
          <OpenChangeHistory id={id} type="artist" />
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <GeneralInfoBox isEditForm={true} />
            <div className="row">
              <div className="col-md-12">
                <SubgenresBox />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <LinksBox />
          </div>
        </div>

        <Footer
          showClearButton={false}
          showDeleteButton={true}
          onDelete={() => {
            deleteArtist(id, routerHistory);
          }}
          onSave={() => {
            handleEditFormSubmit(id, artistDataManipulation, "artist", "POST");
          }}
        />
      </section>
    </React.Fragment>
  );
};

const deleteArtist = async (id, routerHistory) => {
  if (window.confirm("Are you sure you want to delete this artist?")) {
    const deleteSuccess = await handleDeleteEntity(id, "artist");

    if (deleteSuccess) {
      redirectTo(routerHistory, "/list/editArtist");
    }
  }
};

export default connect(null)(EditArtist);
