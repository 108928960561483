import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UploadImageButton from "../media/uploadImageButton";
import TextEditor from "../general/textEditor";
import Input from "../general/input";

const PictureModule = ({ index, name }) => {
  return (
    <div className="box-body">
      <div className="picture-module">
        <div className="form-group">
          <UploadImageButton title="Picture" name={`${name}.[${index}].data.imageUrl`} allowGif={true} />
        </div>
        <div className="form-group">
          <TextEditor name={`${name}.[${index}].data.caption`} />
        </div>
        <div className="form-group">
          <div className="input-group">
            <Input
              debounceTime={0}
              className="form-control"
              placeholder="Alternative title"
              name={`${name}.[${index}].data.alt`}
            />
            <Input
              debounceTime={0}
              className="form-control"
              placeholder="Image link"
              name={`${name}.[${index}].data.imageLink`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PictureModule.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
};

export default connect()(PictureModule);
