import React from "react";
import { PropTypes } from "prop-types";
import { getOr } from "lodash/fp";
import RadioButton from "../../../../input/general/radioButton";

const ReviewColumn = ({ fieldName, reviewData }) => {
  const change_id = getOr(null, `${fieldName}.id`, reviewData);

  return (
    <React.Fragment>
      <td>
        {change_id ? (
          <React.Fragment>
            <RadioButton
              name={`formData.reviewData[${fieldName}].review.status`}
              inputName={fieldName}
              inputValue={"accepted"}
            />
            Accept
            <RadioButton
              name={`formData.reviewData[${fieldName}].review.status`}
              inputName={fieldName}
              inputValue={"denied"}
            />
            Deny
          </React.Fragment>
        ) : (
          ""
        )}
      </td>
    </React.Fragment>
  );
};

ReviewColumn.propTypes = {
  fieldName: PropTypes.string,
  reviewData: PropTypes.object,
};

export default ReviewColumn;
