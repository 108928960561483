import React from "react";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import FormBox from "../../../presentational/page/formBox";
import UploadImageButton from "../../../input/media/uploadImageButton";
import Select from "../../../input/selectors/select";
import EntitySelector from "../../../input/selectors/entitySelector";
import { enumsPath, artistStatus } from "../../../../constants/constants";
import { dispatchToReduxStore } from "../../../../lib/functions/componentFunctions";
import { SET_STATE_VALUE } from "../../../../redux/reducers/common/actionTypes";
import { getCountryNameByCode } from "../../../../lib/functions/enumsFunctions";

const GeneralInfoBox = ({ countries, dispatch }) => {
  const statusOptions = artistStatus.filter(item => {
    return item.id !== "deleted";
  });

  return (
    <FormBox title="General Info">
      <div className="box-body">
        <div className="form-horizontal">
          <div className="relative">
            <div className="form-group">
              <label className="col-sm-2 control-label">Name</label>
              <div className="col-sm-10 right-padding-110">
                <EntitySelector
                  placeholder="Artist Name"
                  entityType="artist"
                  name="formData.name"
                  showAddNewButton={false}
                  removeOnSelect={false}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">Country</label>
              <div className="col-sm-10 right-padding-110">
                <Select
                  placeholder="Select country"
                  data={countries}
                  displayKey="name"
                  idKey="code"
                  autocomplete={true}
                  name="formData.country"
                  showClearButton={true}
                  onChange={() => {
                    changeCityOnCountryChange(dispatch);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">City</label>
              <div className="col-sm-10 right-padding-110">
                <Select
                  placeholder="Select City"
                  displayKey="name"
                  idKey="id"
                  additionalDataKey="country_alpha2"
                  autocomplete={true}
                  searchOnBackend={true}
                  backendSearchEntity={["city"]}
                  name="formData.city"
                  onChange={city => {
                    changeCountryOnCityChange(city, dispatch);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">Status</label>
              <div className="col-sm-10 right-padding-110">
                <Select
                  placeholder="Select status"
                  data={statusOptions}
                  displayKey="name"
                  idKey="id"
                  name="formData.status"
                  showClearButton={false}
                />
              </div>
            </div>
            <div className="form-group absolute-top-right">
              <UploadImageButton
                name="formData.image"
                title="Profile"
                enlargeImageOnPreviewHover={false}
                showModalOnPreviewButtonClick={true}
              />
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

const changeCountryOnCityChange = (city, dispatch) => {
  if (city && city.country_alpha2) {
    dispatchToReduxStore(
      "formData.country",
      SET_STATE_VALUE,
      {
        code: city.country_alpha2,
        name: getCountryNameByCode(city.country_alpha2),
      },
      dispatch
    );
  }
};

const changeCityOnCountryChange = dispatch => {
  dispatchToReduxStore("formData.city", SET_STATE_VALUE, null, dispatch);
};

GeneralInfoBox.propTypes = {
  countries: PropTypes.array,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return { countries: getOr(null, `${enumsPath}.countries`, state) };
};

export default connect(mapStateToProps)(GeneralInfoBox);
