import { callApi } from "./base";
import { com, editor } from "../../constants/apiCalls";
import { sortArrayOfObjectsByProperty } from "../functions/common";

export const enums = {
  getAll: () => {
    return callApi(editor.GET_ENUMS, "GET")
      .then(r => {
        if (r.data.data.countries) {
          r.data.data.countries = sortArrayOfObjectsByProperty(r.data.data.countries, "name");
        }
        return r.data.data;
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  },
  getChannels: () => {
    return callApi(com.GET_CHANNEL, "GET")
      .then(r => {
        return r.data.data;
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  },
  getCountry: () => {
    return callApi(com.GET_COUNTRY, "GET")
      .then(r => {
        return r.data.data;
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  },
  getGenre: () => {
    return callApi(com.GET_GENRE, "GET")
      .then(r => {
        return r.data.data;
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  },
  getVenueCapacity: type => {
    return callApi(com.GET_VENUE_CAPACITY, "GET")
      .then(r => {
        return r.data.data[type];
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  },
  getFestivalCapacity: () => {
    return callApi(com.GET_FESTIVAL_CAPACITY, "GET")
      .then(r => {
        return r.data.data;
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  },
  getFestivalAmenityHighlight: () => {
    return Promise.all([
      callApi(com.GET_FESTIVAL_AMENITY, "GET").then(r => {
        return r.data.data;
      }),
      callApi(com.GET_FESTIVAL_HIGHLIGHT, "GET").then(r => {
        return r.data.data;
      }),
    ])
      .then(values => {
        return { amenities: values[0], highlights: values[1] };
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  },
  getFestivalAmenities: () => {
    return callApi(com.GET_FESTIVAL_AMENITY, "GET")
      .then(r => {
        return r.data.data;
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  },
  getFestivalHighlights: () => {
    return callApi(com.GET_FESTIVAL_HIGHLIGHT, "GET")
      .then(r => {
        return r.data.data;
      })
      .catch(response => {
        console.warn("Error in request", response);
      });
  },
  getArticleAuthors: () => {
    return callApi(editor.GET_ARTICLE_AUTHORS, "GET")
      .then(r => {
        return r.data.data;
      })
      .catch(e => {
        console.warn(e);
      });
  },
  getComCities: () => {
    return callApi(com.GET_FEATURED_CITIES, "GET")
      .then(r => {
        return r.data.data;
      })
      .catch(e => {
        console.warn(e);
      });
  },
};
