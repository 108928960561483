import React from "react";
import FormBox from "../../../presentational/page/formBox";
import Input from "../../../input/general/input";

const DateAndTimeBox = () => {
  return (
    <FormBox title="Date and Time">
      <div className="box-body">
        <div className="form-group">
          <label>Date:</label>
          <div className="input-group">
            <div className="input-group-addon">
              <i className="fa fa-calendar" />
            </div>
            <div className="input-group">
              <Input
                name="formData.date"
                type="date"
                placeholder="dd-mm-yyyy"
                className="form-control"
                max="9999-12-31"
                debounceTime={0}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Time:</label>
          <div className="input-group">
            <div className="input-group-addon">
              <i className="fa fa-clock-o" />
            </div>
            <div className="input-group">
              <Input name="formData.time" type="time" placeholder="HH:MM" className="form-control" />
            </div>
          </div>
        </div>
      </div>
    </FormBox>
  );
};

export default DateAndTimeBox;
