import React from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { mainStateKey } from "../../../constants/constants";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { REMOVE_ITEM_BY_INDEX, SET_UNSET_OBJECT_ARRAY_ITEM } from "../../../redux/reducers/common/actionTypes";
import { objectValueExistsInArray } from "../../../lib/functions/common";
import EntitySelector from "../selectors/entitySelector";

const ArtistsTable = ({ value, name, dispatch }) => {
  const appendArtistToList = artist => {
    if (!objectValueExistsInArray("uuid", artist.uuid, value)) {
      dispatchToReduxStore(name, SET_UNSET_OBJECT_ARRAY_ITEM, artist, dispatch);
    }
  };

  let artistImg;
  let artistName;

  return (
    <React.Fragment>
      <div className="form-group">
        <label className="col-sm-3 control-label">Add to track</label>
        <div className="col-sm-9" style={{ zIndex: 100 }}>
          <EntitySelector
            entityType="artist"
            placeholder="Search Artist..."
            name="formData.artistSearchText"
            onChange={artist => {
              appendArtistToList(artist);
            }}
          />
        </div>
      </div>

      <div style={{ height: "20px" }}></div>

      {value && !!value.length && (
        <table className="table table-striped table-font-12 table-header-white">
          <thead>
            <tr>
              <th>Artist</th>
              <th>Country</th>
              <th>Main Genre</th>
              <th>Primary subgenre</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {value.map((item, index) => {
              artistImg = getOr(null, "images[0]", item) || getOr(null, "image", item);
              artistName = getOr("", "name", item);

              return (
                <tr key={item.uuid}>
                  <td>
                    <div className="flex-row center">
                      {artistImg && <img height="26" width="26" src={artistImg} alt={artistName} />}{" "}
                      <div className="clamp-line-1 left-padding-8" title={artistName}>
                        {artistName}
                      </div>
                    </div>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>{getOr("", "country.name", item)}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    {getOr("", "genre.name", item) || getOr("", "genres[0].name", item)}
                  </td>
                  <td style={{ verticalAlign: "middle" }}>{getOr("", "subgenres[0].name", item)}</td>
                  <td style={{ verticalAlign: "middle" }} className="action-buttons-last-col">
                    <button
                      type="button"
                      className="btn btn-default fa fa-remove"
                      title="Remove"
                      onClick={e => {
                        dispatchToReduxStore(name, REMOVE_ITEM_BY_INDEX, { index }, dispatch);
                      }}
                    ></button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
};

ArtistsTable.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(ArtistsTable);
