import { sortBy, getOr } from "lodash/fp";
import { defaultTo } from "lodash";
import { getYoutubeThumbnail } from "../../functions/common";
import moduleDataManipulation from "./moduleParser";
import moment from "moment";

export const inwardMediaVideos = data => {
  let videoGallery = [];
  const afterVideo = data && data[2] ? data[2] : [];
  const trailerVideo = data && data[3] ? data[3] : [];
  const videos = data && data[5] ? data[5] : [];

  if (afterVideo.length > 0) {
    afterVideo.map(video =>
      videoGallery.push({ url: video, type_id: 2, thumbnail: getYoutubeThumbnail(video), title: "After Video" })
    );
  }

  if (trailerVideo.length > 0) {
    trailerVideo.forEach(video => {
      videoGallery.push({ url: video, type_id: 3, thumbnail: getYoutubeThumbnail(video), title: "Trailer Video" });
    });
  }

  if (videos.length > 0) {
    videos.map(video =>
      videoGallery.push({ url: video, type_id: 5, thumbnail: getYoutubeThumbnail(video), title: "Video" })
    );
  }

  return sortBy("type_id")(videoGallery);
};

export const inwardMediaImages = data => {
  let images = [];

  if (data && Array.isArray(data) && data.length) {
    data.forEach(image => {
      if (image) {
        images.push({ url: image });
      }
    });
  }

  return images;
};

export const inwardLocation = data => {
  return {
    coordinates:
      data && data.coordinates && data.coordinates.lat && data.coordinates.lng
        ? data.coordinates.lat + "," + data.coordinates.lng
        : null,
    city:
      data && data.city && data.city.id && data.city.name
        ? {
            id: data.city.id,
            name: data.city.name,
          }
        : null,
    address: data && data.address ? data.address : null,
    country:
      data && data.country && data.country.code && data.country.name
        ? { code: data.country.code, name: data.country.name }
        : null,
  };
};

export const inwardTickets = data => {
  const obj = { priceDisabled: false };
  const providers = [];

  if (data && data.length) {
    data.forEach((item, index) => {
      if (item && item.ticket_provider && item.ticket_provider.id) {
        obj.free = false;
        obj.door = false;
        obj.online = false;
        obj.price = item && item.price_min ? item.price_min : null;

        switch (item.ticket_provider.id) {
          case 1:
            obj.type = "free";
            obj.free = true;
            obj.priceDisabled = true;
            obj.providersDisabled = true;
            break;
          case 2:
            obj.type = "door";
            obj.door = true;
            obj.providersDisabled = true;
            break;
          default:
            obj.type = "online";
            obj.online = true;
            break;
        }

        providers[index] = item.link;
      }
    });
  }

  obj.providers = providers;

  return obj;
};

export const inwardLinks = data => {
  let links = [];

  if (data && data.length > 0) {
    data.forEach(item => {
      if (item && item.channel && item.channel.id && item.url) {
        links[item.channel.id] = item.url;
      }
    });
  }

  return links;
};

export const inwardArtistList = artists => {
  const artistList = [];

  if (artists && Array.isArray(artists) && artists.length) {
    artists.forEach(item => {
      if (item.uuid) {
        artistList.push({
          slug: item.slug,
          id: item.uuid,
          name: item.name,
          img: item.image,
          country: getOr("", "country.name", item),
          genre: getOr("", "genre.name", item),
        });
      }
    });
  }

  return artistList;
};

export const inwardOpeningHours = data => {
  const hours = [];
  let days = data && data.days ? data.days : null;
  const openOnEvent = data && data.events_only && data.events_only === true ? true : false;

  if (openOnEvent) {
    for (let i = 0; i < 7; i++) {
      hours.push({
        start: "",
        end: "",
        day: i + 1,
        disabled: true,
      });
    }
  } else if (days && days.length) {
    days.forEach(item => {
      const index = item.day - 1;
      const start = item.open.slice(0, -3);
      const end = item.close.slice(0, -3);

      hours[index] = {
        start: start,
        end: end,
        day: item.day,
      };
    });
  }

  return {
    hours: hours,
    openOnEvent: openOnEvent,
  };
};

export const inwardWeeks = (weeks, start, end) => {
  const data = [];

  if (start && end && weeks && weeks.length === 0) {
    data.push({ start: moment(start).format("YYYY-MM-DD"), end: moment(end).format("YYYY-MM-DD") });
  } else if (weeks && Array.isArray(weeks) && weeks.length) {
    weeks.forEach(item => {
      if (item.start && item.end) {
        data.push({ start: moment(item.start).format("YYYY-MM-DD"), end: moment(item.end).format("YYYY-MM-DD") });
      }
    });
  }

  return data;
};

export const inwardLineupTable = data => {
  const lineup = [];

  if (data && Array.isArray(data) && data.length) {
    data.forEach(item => {
      lineup.push({
        artist: item.artist ? item.artist : null,
        stage_id: item.stage && item.stage.id ? item.stage.id : null,
        stage: item.stage ? item.stage : null,
        day: item.day ? item.day : null,
        start: item.start ? item.start : null,
        end: item.end ? item.end : null,
      });
    });
  }

  return lineup;
};

export const inwardContentStatus = status => {
  switch (status) {
    case "finished":
      return { id: 1, name: "Finished" };
    case "draft":
      return { id: 2, name: "Draft" };
    case "inactive":
      return { id: 3, name: "Inactive" };
    default:
      return { id: 3, name: "Inactive" };
  }
};

export const inwardFeedpostType = type => {
  switch (type) {
    case "article":
      return { id: "article", name: "Article" };
    case "product_update":
    default:
      return { id: "product_update", name: "Product Update" };
  }
};

export const inwardUserStatus = status => {
  switch (status) {
    case "all":
      return { id: 1, name: "All" };
    case "registered":
      return { id: 2, name: "Registered" };
    case "premium":
      return { id: 3, name: "Premium" };
    default:
      return { id: 3, name: "All" };
  }
};

export const inwardContentItem = data => {
  let content = [];

  if (data && data.length > 0) {
    data.forEach(item => {
      if (moduleDataManipulation && moduleDataManipulation[item.type]) {
        const inwardContentItem = moduleDataManipulation[item.type].inward(item);
        if (inwardContentItem) {
          content.push(inwardContentItem);
        }
      }
    });
  }

  return content;
};

export const inwardSuggestionsTable = (data = []) => {
  return data && Array.isArray(data)
    ? data.map(item => {
        return [
          {
            image: getOr("", "image", item),
            name: String(defaultTo(getOr("N/A", "name", item), "N/A")),
            country: getOr("N/A", "country.name", item),
            genre: getOr("N/A", "genre.name", item),
            subgenre: getOr("N/A", "subgenre.name", item),
            uuid: getOr(null, "uuid", item),
          },
          defaultTo(getOr("N/A", "suggestion_count", item), "N/A"),
          item.offer ? { ...item.offer, desc: "Applied as Artist" } : { desc: "Suggested by fan" },
          item.uuid,
          item.uuid,
        ];
      })
    : [];
};

export const inwardVotingTable = (data = []) => {
  return data && Array.isArray(data)
    ? data.map(item => {
        return [
          {
            image: getOr("", "image", item),
            name: String(defaultTo(getOr("N/A", "name", item), "N/A")),
            country: getOr("N/A", "country.name", item),
            genre: getOr("N/A", "genre.name", item),
            subgenre: getOr("N/A", "subgenre.name", item),
            uuid: getOr(null, "uuid", item),
          },
          defaultTo(getOr("N/A", "count", item), "N/A"),
          item.uuid,
          item.uuid,
        ];
      })
    : [];
};

export const inwardWinnersTable = (data = []) => {
  data =
    data &&
    data.filter(item => {
      return item.winner ? true : false;
    });

  return data && Array.isArray(data)
    ? data.map(item => {
        return [
          {
            image: getOr("", "image", item),
            name: String(defaultTo(getOr("N/A", "name", item), "N/A")),
            country: getOr("N/A", "country.name", item),
            genre: getOr("N/A", "genre.name", item),
            subgenre: getOr("N/A", "subgenre.name", item),
            uuid: getOr(null, "uuid", item),
          },
          item.uuid,
        ];
      })
    : [];
};

export const inwardSubgenres = data => {
  let subgenresArray = [];

  if (data && data.length && Array.isArray(data)) {
    data.forEach(item => {
      subgenresArray.push(item["name"]);
    });
  }

  return subgenresArray.length ? subgenresArray.join(",") : "";
};

export const inwardItemSubgenres = data => {
  let subgenresArray = [];

  if (data && data.length && Array.isArray(data)) {
    data.forEach(item => {
      subgenresArray.push({ id: item.id, name: item.name });
    });
  }

  return subgenresArray;
};
