import { set } from "lodash/fp";
import {
  SET_ENUMS,
  SET_EDITOR_LOADING,
  SET_FORM_SUCCESS,
  SET_FORM_FAIL,
  SET_FORM_SHOW_ERROR_WINDOW,
  SET_FORM_SHOW_ERROR_REASONS,
  SET_FORM_ERROR_REASONS,
  SET_USER_DATA,
  SET_FORM_ERROR_TITLE,
  SET_FORM_LOCKED,
  SET_SIDEBAR_ASK_FOR_CONFIRMATION,
} from "./actionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case SET_ENUMS: {
      return set("enums", action.payload, state);
    }

    case SET_EDITOR_LOADING: {
      return set("editor.editorLoading", action.payload, state);
    }

    case SET_FORM_SUCCESS: {
      return set("formStatus.formSuccess", action.payload, state);
    }

    case SET_FORM_FAIL: {
      return set("formStatus.formFail", action.payload, state);
    }

    case SET_FORM_SHOW_ERROR_WINDOW: {
      return set("formStatus.formShowError", action.payload, state);
    }

    case SET_FORM_SHOW_ERROR_REASONS: {
      return set("formStatus.formShowErrorDetails", action.payload, state);
    }

    case SET_FORM_ERROR_REASONS: {
      return set("formStatus.formErrorReasons", action.payload, state);
    }

    case SET_USER_DATA: {
      return set("user", action.payload, state);
    }

    case SET_FORM_ERROR_TITLE: {
      return set("formStatus.formErrorTitle", action.payload, state);
    }

    case SET_FORM_LOCKED: {
      return set("formStatus.formLocked", action.payload, state);
    }

    case SET_SIDEBAR_ASK_FOR_CONFIRMATION: {
      return set("editor.sideBarAskForConfirmation", action.payload, state);
    }

    default:
      return { ...state };
  }
}
