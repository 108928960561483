import React from "react";
import { PropTypes } from "prop-types";
import TextField from "../../../../presentational/entityModules/textField";
import TextList from "../../../../presentational/entityModules/textList";
import Media from "../../../../presentational/entityModules/media";
import Weeks from "../../../../presentational/entityModules/weeks";
import Coordinates from "../../../../presentational/entityModules/coordinates";
import UploadImageButton from "../../../../input/media/uploadImageButton";
import Lineup from "../../../../presentational/entityModules/lineup";
import OpeningHours from "../../../../input/general/openingHours";
import ArtistList from "../../../../input/general/artistList";
import TicketInfo from "../../../../input/general/ticketInfo";
import Youtube from "../../../../presentational/entityModules/youtube";

const DataColumn = ({ type, fieldName, data, className = "", connectedTo, entityType, added = [], deleted = [] }) => {
  const component = getComponent(fieldName, type, connectedTo, entityType, added, deleted);
  const defaultColumnValue = typeof data[fieldName] === "object" ? "" : data[fieldName];

  return (
    <td className={className + (fieldName !== "lineup" ? " break-word" : "")}>
      {data[fieldName] && <React.Fragment>{component ? component : defaultColumnValue}</React.Fragment>}
    </td>
  );
};

DataColumn.propTypes = {
  data: PropTypes.object,
  fieldName: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  connectedTo: PropTypes.string,
  entityType: PropTypes.string,
  added: PropTypes.array,
  deleted: PropTypes.array,
};

export default DataColumn;

const getComponent = (field, type, connectedTo, entityType, added, deleted) => {
  switch (field) {
    case "city_id":
      return <TextField connectedTo={`${connectedTo}.${type}.city_id.name`} />;
    case "experiences":
      return <TextList connectedTo={`${connectedTo}.${type}.experiences`} />;
    case "country_alpha2":
      return <TextField connectedTo={`${connectedTo}.${type}.country_alpha2.name`} />;
    case "highlights":
      return <TextList connectedTo={`${connectedTo}.${type}.highlights`} />;
    case "amenities":
      return <TextList connectedTo={`${connectedTo}.${type}.amenities`} />;
    case "media":
      return <Media connectedTo={`${connectedTo}.${type}.media`} />;
    case "latest":
      return <TextField connectedTo={`${connectedTo}.${type}.latest`} />;
    case "claimed":
      return <TextField connectedTo={`${connectedTo}.${type}.claimed`} />;
    case "weeks":
      return <Weeks connectedTo={`${connectedTo}.${type}.weeks`} />;
    case "capacity_range_id":
      return <TextField connectedTo={`${connectedTo}.${type}.capacity_range_id.name`} />;
    case "coordinates":
      return <Coordinates connectedTo={`${connectedTo}.${type}.coordinates`} />;
    case "stages":
      return <TextList connectedTo={`${connectedTo}.${type}.stages`} />;
    case "image":
      return <UploadImageButton showClearButton={false} disabled={true} name={`${connectedTo}.${type}.image`} />;
    case "tickets":
      return (
        <TicketInfo
          name={`${connectedTo}.${type}.tickets`}
          formGroupClassName="margin-0"
          disabled={true}
          showResetButton={false}
        />
      );
    case "lineup":
      return entityType === "event" ? (
        <ArtistList name={`${connectedTo}.${type}.lineup`} formGroupClassName="margin-0" disabled={true} />
      ) : (
        <Lineup connectedTo={`${connectedTo}.${type}.lineup`} />
      );
    case "subgenre_ids":
      return <TextList connectedTo={`${connectedTo}.${type}.subgenre_ids`} enumsProperty="subgenres" />;
    case "opening_hours":
      return (
        <OpeningHours
          name={`${connectedTo}.${type}.opening_hours`}
          disabled={true}
          formGroupClassName="margin-0"
          showOpenEventsOnly={false}
        />
      );
    case "capacity_type_id":
      return <TextField connectedTo={`${connectedTo}.${type}.capacity_type_id.name`} />;
    case "price_range_id":
      return <TextField connectedTo={`${connectedTo}.${type}.price_range_id.name`} />;
    case "venue":
      return <TextField connectedTo={`${connectedTo}.${type}.venue.name`} />;
    case "open_events_only":
      return <TextField connectedTo={`${connectedTo}.${type}.open_events_only`} />;
    case "proof":
      return <TextField isLink={true} connectedTo={`${connectedTo}.${type}.proof`} />;
    case "beer_price":
      return <TextField isLink={true} connectedTo={`${connectedTo}.${type}.beer_price.amount`} />;
    case "genre_id":
      return <TextField connectedTo={`${connectedTo}.${type}.genre_id`} enumsProperty="genres" />;
    case "subgenre_id":
      return <TextField connectedTo={`${connectedTo}.${type}.subgenre_id`} enumsProperty="subgenres" />;
    case "artist_uuids":
      return <TextList connectedTo={`${connectedTo}.${type}.artist_uuids`} />;
    case "label_uuid":
      return <TextField connectedTo={`${connectedTo}.${type}.label_uuid.name`} />;
    case "youtube_videos":
      return (
        <Youtube
          connectedTo={`${connectedTo}.${type}.youtube_videos`}
          added={added}
          deleted={deleted}
          onlyShowDiff={type === "old" ? false : true}
        />
      );
    default:
      return null;
  }
};
