import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import {
  setItemFormData,
  showFormSuccess,
  showFormFail,
  showFormErrorReasons,
  showAppLoader,
  hideFormErrorReasons,
} from "../../../lib/functions/componentFunctions";
import { mainStateKey } from "../../../constants/constants";

import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import {
  APPEND_ITEM_TO_ARRAY,
  REMOVE_ITEM_BY_INDEX,
  SWAP_ARRAY_ITEM_LEFT,
  SWAP_ARRAY_ITEM_RIGHT,
} from "../../../redux/reducers/common/actionTypes";
import commonDataManipulation from "../../../lib/dataManipulation/common/commonDataManipulation";
import apiManager from "../../../lib/apiManager/apiManager";
import { findObjectIndexByValue } from "../../../lib/functions/common";

const Stages = ({
  uuid,
  edition,
  name,
  value,
  dispatch,
  showFooter = true,
  editEntityType = "festivalAppStages",
  setItemDataEntityType = "fapp-lineup",
  parseStagesAsObject = false,
  stagesNumber = null,
  showAddStageButton = true,
  disabled = false,
  showLabel = true,
}) => {
  const [stage, setStage] = useState("");

  const stageNameTextChanged = e => {
    setStage(e.target.value);
  };

  const resetStageInput = () => {
    setStage("");
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-4">
          {showAddStageButton && (
            <div className="form-group">
              <label>Add Stage *</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={stage}
                  placeholder="Stage Name"
                  name="stage"
                  onChange={stageNameTextChanged}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      appendStage(name, stage, dispatch, resetStageInput, value);
                    }
                  }}
                />

                <div className="input-group-btn">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={e => {
                      if (stage) {
                        appendStage(name, stage, dispatch, resetStageInput, value);
                      }
                    }}
                  >
                    + ADD STAGE
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="form-group">
        {showLabel && <label>Stages list *</label>}
        <div className="clearfix lineup-group autoheight small">
          {value && value.length > 0
            ? value.map((stage, index) => {
                return (
                  <button type="button" className="btn btn-default btn-remove btn-order" key={index}>
                    <span
                      className={"order-prev " + (disabled ? "custom-disabled" : "")}
                      onClick={() => {
                        dispatchToReduxStore(name, SWAP_ARRAY_ITEM_LEFT, { index: index }, dispatch);
                      }}
                    >
                      &lt;
                    </span>
                    <span
                      className={"order-next " + (disabled ? "custom-disabled" : "")}
                      onClick={() => {
                        dispatchToReduxStore(name, SWAP_ARRAY_ITEM_RIGHT, { index: index }, dispatch);
                      }}
                    >
                      &gt;
                    </span>

                    {stage.name}
                    <span
                      className={"close-me " + (disabled ? "custom-disabled" : "")}
                      onClick={e => {
                        dispatchToReduxStore(name, REMOVE_ITEM_BY_INDEX, { index: index }, dispatch);
                      }}
                    >
                      X
                    </span>
                  </button>
                );
              })
            : ""}
        </div>
      </div>

      {showFooter && (
        <div className="buttons">
          <button style={{ visibility: "hidden" }} type="button" className="btn btn-default  btn-small">
            Clear Stages
          </button>
          <button
            type="button"
            className="btn btn-default pull-right btn-small"
            onClick={async () => {
              showAppLoader();
              const parsedStages = commonDataManipulation().stages.outward(value);
              const parsedStageNum = stagesNumber ? { stages_num: stagesNumber } : null;
              const postData = parseStagesAsObject ? { stages: parsedStages, ...parsedStageNum } : parsedStages;

              apiManager
                .editEntity(uuid, edition, editEntityType, postData, "POST")
                .then(async () => {
                  await setItemFormData(uuid, edition, setItemDataEntityType, null, false, true);
                  showFormSuccess();
                  hideFormErrorReasons();
                })
                .catch(e => {
                  showFormFail();
                  showFormErrorReasons(e);
                });
            }}
          >
            Commit Stages
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

const appendStage = (name, stage, dispatch, resetStageInput, allStages) => {
  if (stage) {
    const stageAlreadyExists = findObjectIndexByValue(allStages, "name", stage) === -1 ? false : true;
    if (!stageAlreadyExists) {
      dispatchToReduxStore(name, APPEND_ITEM_TO_ARRAY, { id: 0, name: stage }, dispatch);
      resetStageInput();
    }
  }
};

Stages.propTypes = {
  uuid: PropTypes.string,
  edition: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  dispatch: PropTypes.func,
  showFooter: PropTypes.bool,
  editEntityType: PropTypes.string,
  setItemDataEntityType: PropTypes.string,
  parseStagesAsObject: PropTypes.bool,
  stagesNumber: PropTypes.number,
  showAddStageButton: PropTypes.bool,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(Stages);
