import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "../general/input";

const NewsletterModule = ({ index, name }) => {
  return (
    <div className="box-body">
      <div className="row">
        <div className="col-md-12">
          <div className="fomr-group">
            <div className="input-group">
              <Input
                className="form-control"
                placeholder="Title"
                name={`${name}.[${index}].data.title`}
                showClearInputButton={false}
                debounceTime={0}
              />
              <Input
                className="form-control"
                placeholder="Subtext"
                name={`${name}.[${index}].data.subtext`}
                showClearInputButton={false}
                debounceTime={0}
              />
              <Input
                className="form-control"
                placeholder="CTA text"
                name={`${name}.[${index}].data.cta`}
                showClearInputButton={false}
                debounceTime={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewsletterModule.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
};

export default connect()(NewsletterModule);
