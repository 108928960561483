import React, { useState, useEffect } from "react";
import { getOr } from "lodash/fp";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeid, searchByString, sortArrayOfObjectsByProperty } from "../../../lib/functions/common";
import ClearInputButton from "../general/clearInputButton";
import { dispatchToReduxStore } from "../../../lib/functions/componentFunctions";
import { mainStateKey } from "../../../constants/constants";
import { SET_STATE_VALUE } from "../../../redux/reducers/common/actionTypes";
import apiManager from "../../../lib/apiManager/apiManager";

const Select = ({
  data = [],
  idKey,
  displayKey,
  autocomplete = false,
  value,
  placeholder = "",
  showClearButton = true,
  disabled = false,
  name,
  onChange = () => {},
  dispatch,
  useElementDisplayKeyAsValue = false,
  searchOnBackend = false,
  backendSearchEntity = null,
  additionalDataKey = null,
  additionalDataKeyDelimiter = " - ",
  clearInputButtonMessage = "",
  getItemsFromBackendType = false, //If string is set, select items will be populated from the results of the API GET Call
}) => {
  const [dataFromBackend, setDataFromBackend] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState(getFilteredItems(searchText, data, displayKey));
  const [loading, setLoading] = useState(false);
  const myid = makeid(15);

  if (getItemsFromBackendType) {
    data = dataFromBackend;
  }

  //Used for fetching initial Select data from Backend
  useEffect(() => {
    if (getItemsFromBackendType) {
      getDataFromBackend(setLoading, setDataFromBackend, getItemsFromBackendType);
    }
  }, [getItemsFromBackendType]);

  //Used for filtering results whenever user changes the input inside Select
  useEffect(() => {
    const filteredItems = getFilteredItems(searchText, data, displayKey);

    if (filteredItems && filteredItems.length) {
      setItems(filteredItems);
    }
  }, [data, searchText, displayKey]);

  //Used for closing/opneing Select input whenever user click outside/inside of Select input
  useEffect(() => {
    return closeSelectOnOutsideClickEvent(open, setOpen, autocomplete, myid);
  }, [open, autocomplete, myid]);

  const handleSelectClick = () => {
    setOpen(!open);
  };

  const filterSelect = async e => {
    setSearchText(e.target.value);

    if (searchOnBackend && backendSearchEntity) {
      setLoading(true);
      let filteredItems = await searchByString(e.target.value, backendSearchEntity);
      filteredItems = sortArrayOfObjectsByProperty(filteredItems, "name");
      setItems(filteredItems);
      setLoading(false);
    } else {
      setItems(getFilteredItems(e.target.value, data, displayKey));
    }
  };

  const selectClass = getSelectClass(open, autocomplete, disabled);
  let additionalData = "";

  return (
    <React.Fragment>
      <div className={selectClass.join(" ")} onClick={handleSelectClick}>
        <div className="selected-wrapper">
          <div
            tabIndex={0}
            className={"selected-option " + (!value ? "placeholder " : " ") + (loading ? "loading" : "")}
          >
            <span>
              {value ? value[displayKey] : placeholder}
              {showClearButton ? (
                <ClearInputButton
                  type="select"
                  confirmationMessage={clearInputButtonMessage}
                  onClick={e => {
                    e.stopPropagation();
                    dispatchToReduxStore(name, SET_STATE_VALUE, null, dispatch);
                    onChange(null);
                  }}
                />
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
        {autocomplete && open ? (
          <input
            id={myid}
            onClick={e => e.stopPropagation()}
            placeholder="Search..."
            type="text"
            value={searchText}
            onChange={filterSelect}
          />
        ) : (
          ""
        )}
        <ul className="results" onWheel={e => e.stopPropagation()}>
          {items &&
            items.map(el => {
              additionalData = el[additionalDataKey] ? additionalDataKeyDelimiter + el[additionalDataKey] : "";
              return (
                <li
                  key={el[idKey]}
                  onClick={() => {
                    onChange(el);
                    if (autocomplete) {
                      setSearchText("");
                    }

                    let dispatchedValue = useElementDisplayKeyAsValue ? el[displayKey] : el;
                    dispatchToReduxStore(name, SET_STATE_VALUE, dispatchedValue, dispatch);
                  }}
                >
                  {el[displayKey] + additionalData}
                </li>
              );
            })}
        </ul>
      </div>
    </React.Fragment>
  );
};

const closeSelectOnOutsideClickEvent = (open, setOpen, autocomplete, myid) => {
  const closeSelect = () => {
    if (open) setOpen(!open);
  };

  window.addEventListener("click", closeSelect);

  if (autocomplete && open) {
    document.getElementById(myid).focus();
  }

  return function cleanup() {
    window.removeEventListener("click", closeSelect);
  };
};

const getFilteredItems = (searchText, data, displayKey) => {
  if (!data) {
    return [];
  }
  let filtered = [];

  if (searchText.length > 0) {
    let exact = [];
    let others = [];

    data.forEach(item => {
      const searchIndex = item[displayKey].toLowerCase().indexOf(searchText.toLowerCase());
      if (searchIndex === 0) {
        exact.push(item);
      } else if (searchIndex > -1) {
        others.push(item);
      }
    });

    filtered = exact.concat(others);
  } else {
    filtered = data;
  }

  return filtered;
};

const getSelectClass = (open, autocomplete, disabled) => {
  let selectCss = ["custom-select"];
  if (open) {
    selectCss.push("open");
  }

  if (autocomplete) {
    selectCss.push("with-search");
  }

  if (disabled) {
    selectCss.push("disabled");
  }

  return selectCss;
};

const getDataFromBackend = async (setLoading, setDataFromBackend, getItemsFromBackendType) => {
  setLoading(true);
  const data = await apiManager.getItems(getItemsFromBackendType);
  setDataFromBackend(data);
  setLoading(false);
};

Select.propTypes = {
  placeholder: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onSelect: PropTypes.func,
  displayKey: PropTypes.string,
  idKey: PropTypes.string,
  autocomplete: PropTypes.bool,
  formFieldName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  showClearButton: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  dispatch: PropTypes.func,
  useElementDisplayKeyAsValue: PropTypes.bool,
  searchOnBackend: PropTypes.bool,
  backendSearchEntity: PropTypes.array,
  additionalDataKey: PropTypes.string,
  getItemsFromBackendType: PropTypes.string,
  clearInputButtonMessage: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return { value: getOr(null, `${mainStateKey}.${ownProps.name}`, state) };
};

export default connect(mapStateToProps)(Select);
