import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import FormBox from "../../../presentational/page/formBox";
import MultiSelector from "../../../input/selectors/multiSelector";
import { enumsPath, artistStatus, yesNoStatus, youtubeSuggestionStatus } from "../../../../constants/constants";
import CurrentlySelectedFiltersList from "../../../presentational/general/currentlySelectedFiltersList";
import Input from "../../../input/general/input";
import { editArtistTableParametersData } from "../../../../constants/tableParametersData/editArtistTableParametersData";
import DateRange from "../../../input/date/dateRange";
import { prependNumberToSubgenreName } from "../../../../lib/functions/common";
import Select from "../../../input/selectors/select";

const FiltersBox = ({ countries, subgenres, genres }) => {
  const filtersPath = editArtistTableParametersData.filtersPath;
  return (
    <FormBox title="Filters" style={{ zIndex: "999" }}>
      <div className="box-body">
        <table className="filters-table">
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <small className="label">Date added from-to</small>
              </td>
              <td>
                <small className="label">Date Updated from-to</small>
              </td>
              <td></td>
              <td></td>
              <td>
                {" "}
                <small className="label">Claimed</small>
              </td>
              <td></td>
            </tr>
            <tr>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input placeholder="Search artist..." className="form-control" name={`${filtersPath}.artistName`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "180px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Country lookup filter"
                    data={countries}
                    displayKey="name"
                    idKey="code"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.country`}
                  />
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Genres"
                    data={genres}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.mainGenre`}
                  />
                </div>
              </td>
              <td style={{ width: "200px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Subgenres"
                    data={prependNumberToSubgenreName(subgenres)}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.subgenres`}
                  />
                </div>
              </td>
              <td style={{ width: "100px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Status"
                    data={artistStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.status`}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.dateAdded`} />
                  </div>
                </div>
              </td>
              <td>
                <div className="form-group">
                  <div className="input-group">
                    <DateRange name={`${filtersPath}.lastChange`} />
                  </div>
                </div>
              </td>
              <td style={{ width: "120px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      className="form-control"
                      placeholder={"Min Rank"}
                      type="number"
                      name={`${filtersPath}.chartRank.min`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "120px" }}>
                <div className="form-group">
                  <div className="input-group">
                    <Input
                      className="form-control"
                      placeholder={"Max Rank"}
                      type="number"
                      name={`${filtersPath}.chartRank.max`}
                    />
                  </div>
                </div>
              </td>
              <td style={{ width: "100px" }}>
                <div className="form-group">
                  <Select idKey={"id"} displayKey="name" name={`${filtersPath}.claimed`} data={yesNoStatus} />
                </div>
              </td>
              <td style={{ width: "200px" }}>
                <div className="form-group">
                  <MultiSelector
                    placeholder="Youtube suggestions status"
                    data={youtubeSuggestionStatus}
                    displayKey="name"
                    idKey="id"
                    autocomplete={true}
                    showClearButton={false}
                    name={`${filtersPath}.youtubeSuggestionStatus`}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="row">
          <div className="col-md-12">
            <CurrentlySelectedFiltersList
              tableParamsData={editArtistTableParametersData}
              connectedTo={editArtistTableParametersData.filtersPath}
            />
          </div>
        </div>
      </div>
    </FormBox>
  );
};

FiltersBox.propTypes = {
  countries: PropTypes.array,
  subgenres: PropTypes.array,
  genres: PropTypes.array,
};

const mapStateToProps = state => ({
  countries: getOr(null, `${enumsPath}.countries`, state),
  subgenres: getOr(null, `${enumsPath}.subgenres`, state),
  genres: getOr(null, `${enumsPath}.genres`, state),
});

export default connect(mapStateToProps)(FiltersBox);
