export const editFestivalTableParametersData = {
  statePath: "tables.editFestivalTableParams",
  filtersPath: "tables.editFestivalTableParams.filters",
  sortPath: "tables.FestivalTableParams.sort",

  columns: [
    {
      name: "editionYear",
      label: "Edition Year",
      sortBackendName: "start",
    },
    {
      name: "festivalName",
      label: "Festival Name",
      sortBackendName: "name",
      filterDefinition: {
        backendFieldValue: {
          name: "name",
        },
      },
    },
    {
      name: "start",
      label: "Date of festival start",
      sortBackendName: "start",
      filterDefinition: {
        backendFieldValue: {
          "start-from": "start",
          "start-to": "end",
        },
      },
    },
    {
      name: "end",
      label: "Date of festival end",
      sortBackendName: "end",
      filterDefinition: {
        backendFieldValue: {
          "end-from": "start",
          "end-to": "end",
        },
      },
    },
    {
      name: "country",
      label: "Country",
      sortBackendName: "country",
      filterDefinition: {
        backendFieldValue: {
          country: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "city",
      label: "City",
      sortBackendName: "city",
      filterDefinition: {
        backendFieldValue: {
          city: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "lineup",
      label: "Lineup",
      sortBackendName: "lineup",
    },
    {
      name: "highlights",
      label: "Highlights",
      sortBackendName: "highlights",
    },
    {
      name: "amenities",
      label: "Amenities",
      sortBackendName: "amenities",
    },
    {
      name: "media",
      label: "Media",
      sortBackendName: "media",
    },
    {
      name: "trailer",
      label: "Trailer",
      filterDefinition: {
        backendFieldValue: {
          trailer: "id",
        },
      },
    },
    {
      name: "after-movie",
      label: "After Movie",
      filterDefinition: {
        backendFieldValue: {
          "after-movie": "id",
        },
      },
    },
    {
      name: "rank",
      label: "Rank",
      sortBackendName: "rank",
    },
    {
      name: "latest",
      label: "Edition",
      sortBackendName: "latest",
      filterDefinition: {
        backendFieldValue: {
          latest: "id",
        },
      },
    },
    {
      name: "claimed",
      label: "Claimed",
      sortBackendName: "claimed",
      filterDefinition: {
        backendFieldValue: {
          claimed: "id",
        },
      },
    },
    {
      name: "updated",
      label: "Last updated",
      sortBackendName: "updated",
      filterDefinition: {
        backendFieldValue: {
          "updated-from": "startDateTimeTZ",
          "updated-to": "endDateTimeTZ",
        },
      },
      cellClassName: "w150px",
    },
    {
      name: "status",
      label: "Status",
      sortBackendName: "status",
      filterDefinition: {
        backendFieldValue: {
          status: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    {
      name: "pool",
      label: "Pool",
      filterDefinition: {
        backendFieldValue: {
          pool: "id",
        },
        filterListButtonValueKey: "name",
      },
    },
    { name: "edit", label: "Edit" },
  ],
};
